import { DEFAULT_SORT_FIELD } from "../../components/portfolio/constants";
import {
  IFilter,
  IFilterParams,
  IGetPropertiesParams,
  IPriceRange,
  ISortParams,
} from "../../components/portfolio/interfaces";
import { PAGE_SIZE } from "../../helpers/config";
import { apiSlice } from "../api/apiSlice";

const SORT_PARAMS_MAP: { [key: string]: string } = {
  created_at: "created_at",
  pricing: "[pricing][basePrice]",
  details: "[details][totalArea]",
};

export function buildSortParams(
  params: URLSearchParams,
  sortParams: ISortParams
) {
  let field = SORT_PARAMS_MAP[sortParams.field];
  if (!field) {
    field = SORT_PARAMS_MAP[DEFAULT_SORT_FIELD];
  }
  params.set("sort", `${field}:${sortParams.direction}`);
}

export function buildPaginationParams(
  params: URLSearchParams,
  page: number,
  pageSize?: string | undefined
) {
  params.set("pagination[page]", page.toString());
  params.set("pagination[pageSize]", pageSize ?? PAGE_SIZE);
}

export function buildSearchParams(params: URLSearchParams, search: string) {
  const splitedSearch = search.split(" ");

  if (search) {
    for (let i = 0; i < splitedSearch.length; i++) {
      const term = splitedSearch[i];
      const baseIndex = i * 3;

      params.append(`filters[$or][${baseIndex}][name][$contains]`, term);
      params.append(
        `filters[$or][${baseIndex + 1}][description][$contains]`,
        term
      );
      params.append(
        `filters[$or][${baseIndex + 2}][location][city][$contains]`,
        term
      );
    }
  }
}

export function buildPricingParams(
  params: URLSearchParams,
  pricing: IPriceRange
) {
  params.set("filters[pricing][basePrice][$gte]", pricing.start.toString());
  params.set("filters[pricing][basePrice][$lte]", pricing.end.toString());
}

export function buildCampaignStatusParams(
  params: URLSearchParams,
  campaignStatus: string
) {
  if (campaignStatus === "all") {
    return;
  }
  params.set("filters[campaignStatus]", campaignStatus);
}

export function buildUserIdParam(params: URLSearchParams, userId: string) {
  if (userId === "all") {
    params.set("all", "true");
  }

  const user = localStorage.getItem("user") || sessionStorage.getItem("user");
  if (user) {
    params.set("owner", JSON.parse(user).id);
  } else {
    return;
  }
}

function buildFilterListLocationCity(
  params: URLSearchParams,
  filters: any[],
  andIndex: number,
  fieldName: string,
  currentLanguage: string
) {
  filters.forEach((filter, index) => {
    params.append(
      `filters[$and][${andIndex}][$or][${index}]${fieldName}`,
      filter.city
    );
  });
}

function buildFilterListLocationAddress(
  params: URLSearchParams,
  filters: any[],
  andIndex: number,
  fieldName: string,
  currentLanguage: string
) {
  filters.forEach((filter, index) => {
    params.append(
      `filters[$and][${andIndex}][$or][${index}]${fieldName}`,
      filter.custom_adress
    );
  });
}

function buildFilterListPopertyStatus(
  params: URLSearchParams,
  filters: IFilter[],
  andIndex: number,
  fieldName: string,
  currentLanguage: string
) {
  filters.forEach((filter, index) => {
    params.append(
      `filters[$and][${andIndex}][$or][${index}]${fieldName}`,
      filter.translationKey
    );
  });
}

export function buildFilterParams(
  params: URLSearchParams,
  filters: IFilterParams,
  lang: string
) {
  buildPricingParams(params, filters.priceRange);
  let andIndex = 0;

  if (filters.locations && filters.locations.length > 0) {
    buildFilterListLocationCity(
      params,
      filters.locations,
      andIndex,
      "[location][city]",
      lang
    );
    andIndex++;
  }

  if (filters.locations && filters.locations.length > 0) {
    buildFilterListLocationAddress(
      params,
      filters.locations,
      andIndex,
      "[location][address]",
      lang
    );
    andIndex++;
  }

  //old
  // if (filters.locations) {
  //   buildFilterListLocation(
  //     params,
  //     filters.locations,
  //     andIndex,
  //     "[location][city]",
  //     lang
  //   );
  //   andIndex++;
  // }
  //old

  if (filters.statuses) {
    buildFilterListPopertyStatus(
      params,
      filters.statuses,
      andIndex,
      "[propertyStatus]",
      lang
    );
    andIndex++;
  }

  if (filters.types) {
    buildFilterListPopertyStatus(
      params,
      filters.types,
      andIndex,
      "[propertyType]",
      lang
    );
    andIndex++;
  }
}

function CreateGetPropertiesWithParamsURLQuery(
  params: IGetPropertiesParamsWithLang
): string {
  const urlParams = new URLSearchParams();
  urlParams.append("populate", "*");
  urlParams.append("locale", params.lang);

  buildPaginationParams(urlParams, params.params.page, params.params?.pageSize);
  buildSortParams(urlParams, params.params.sort);
  buildCampaignStatusParams(urlParams, params.params.campaignStatus);
  buildUserIdParam(urlParams, params.params.userId);

  if (params.params.search) {
    buildSearchParams(urlParams, params.params.search);
  } else {
    buildFilterParams(urlParams, params.params.filters, params.lang);
  }

  return `/properties?${urlParams.toString()}`;
}

export interface IGetPropertiesParamsWithLang {
  params: IGetPropertiesParams;
  lang: string;
}

export const propertyApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getProperties: builder.query({
      query: ({ locale }: { locale: string }) => {
        return `/properties?populate=mediaContent&locale=${locale}`;
      },
    }),

    getHomepageProperties: builder.query({
      query: ({ locale }: { locale: string }) => {
        return `/properties/homepage?locale=${locale}`;
      },
    }),

    getPropertiesWithParams: builder.query({
      query: (params: IGetPropertiesParamsWithLang) => {
        return CreateGetPropertiesWithParamsURLQuery(params);
      },
    }),

    getProperty: builder.query({
      query: ({
        documentId,
        locale,
      }: {
        documentId: string;
        locale: string;
      }) => {
        return `/properties/${documentId}?populate=*&locale=${locale}`;
      },
      transformResponse: (response: { data: any }) => response.data,
    }),
  }),
});

export const {
  useGetPropertiesQuery,
  useGetPropertiesWithParamsQuery,
  useGetPropertyQuery,
  useLazyGetPropertyQuery,
  useGetHomepagePropertiesQuery,
} = propertyApiSlice;
