import { useMediaQuery } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import BackIcon from "../../../../assets/campaignBuilder/back_blue.svg";
import { useAppDispatch } from "../../../../store/store";
import { usePortfolioNavigation } from "../../hooks/usePortfolioNavigation";
import { useLanguageTheme } from "../../../../hooks/useLanguageTheme";
import {
  campaignBuilderOpenSelector,
  setCampaingBuilderSideBarClose,
} from "../../../../store/features/common";
import { CampaignBuilderMainInfoSection } from "./CampaignBuilderMainInfoSection";
import { ContactToAgentMobileTopPanel } from "../../ContactToAgentMobileTopPanel";
import { CampaignBuilderMediaSection } from "./CampaignBuilderMediaSection";
import { ContactToAgentMobileBtn } from "../buttons/ContactToAgentMobileBtn";
import { CampaignBuilderHeader } from "../layout/CampaignBuilderHeader";
import { PreviewMode } from "../../types";
import { AiChat } from "../../ai-chat/AiChat";
import { LangToggle } from "../../../_shared/LangToggle";
import { useElementHeight } from "../../../../hooks/useHeaderHeight";
import {
  campaignBuilderDrawerWidthDesktop,
  campaignBuilderDrawerWidthMobile,
} from "../../constants";

export const CampainBuilderMainContainer = ({
  onlyView = false,
  documentId = "documentId",
  autosaveFunc = () => {},
  isDisabledSwitchLang = false,
  investmentHighlightsRef,
  keyFeaturesRef,
  settingRef,
  mainInfoRef,
  mediaRef,
  labelRef,
  aboutPropertyRef,
  aboutDeveloperRef,
  contactInformationRef,
  propertyId = -1,
  property,
  isRTL,
  currentStep,
  builderHeaderRef = null,
  builderMobileHeaderRef = null,
}: any) => {
  const { i18n, t } = useTranslation(["campaignBuilder"]);
  const dispatch = useAppDispatch();

  const { previewMode } = useSelector(
    (state: any) => state.campaignBuilderSetup
  );
  const maxWidth1070px = useMediaQuery("(max-width: 1070px)");
  const maxWidth1220px = useMediaQuery("(max-width: 1220px)");

  const { navigateToPortfolio } = usePortfolioNavigation();
  const langTheme = useLanguageTheme();
  const campaignBuilderOpen = useSelector(campaignBuilderOpenSelector);

  const headerHeight = useElementHeight(builderHeaderRef, [
    campaignBuilderOpen,
    previewMode,
  ]);

  const mobileHeaderHeight = useElementHeight(builderMobileHeaderRef, [
    campaignBuilderOpen,
    previewMode,
  ]);
  const portfolioPageView = useSelector(
    (state: any) => state.campaignBuilderSetup.pageView
  );

  return (
    <div
      style={{
        fontFamily: langTheme.mainFont(),
        width: "100%",
        display: "flex",
        justifyContent: "center",
        direction: i18n.dir(),
        position: "relative",
      }}
      onClick={
        maxWidth1070px
          ? () => dispatch(setCampaingBuilderSideBarClose())
          : undefined
      }
    >
      <div
        style={{
          padding: maxWidth1220px ? "35px 20px" : "36px 40px",
          maxWidth: previewMode === PreviewMode.DESKTOP ? "1300px" : "325px",
          width: "100%",
        }}
      >
        <div ref={settingRef} />
        {!maxWidth1070px && campaignBuilderOpen && (
          <div
            className="campaign-builder-light-blue-btn"
            style={{
              marginBottom: "20px",
            }}
            onClick={navigateToPortfolio}
          >
            <img
              src={BackIcon}
              className="campaign-builder-sidenav-back-header-img"
              style={{
                transform: i18n.dir() === "rtl" ? "scaleX(-1)" : "scaleX(1)",
              }}
            />
            <div
              style={{
                fontFamily: langTheme.mainFont(),
              }}
            >
              {t("properties")}
            </div>
          </div>
        )}
        <CampaignBuilderHeader
          onlyView={onlyView}
          documentId={documentId}
          mainInfoRef={mainInfoRef}
          propertyId={propertyId}
          property={property}
        />
        <div ref={mediaRef} />
        <div ref={labelRef} />
        <CampaignBuilderMediaSection property={property} />
        <CampaignBuilderMainInfoSection
          onlyView={onlyView}
          investmentHighlightsRef={investmentHighlightsRef}
          keyFeaturesRef={keyFeaturesRef}
          aboutPropertyRef={aboutPropertyRef}
          aboutDeveloperRef={aboutDeveloperRef}
          contactInformationRef={contactInformationRef}
        />
        <ContactToAgentMobileTopPanel />
      </div>

      {(PreviewMode.MOBILE === previewMode || maxWidth1070px) && (
        <ContactToAgentMobileBtn />
      )}

      {campaignBuilderOpen && (
        <AiChat isRTL={isRTL} currentStep={currentStep} />
      )}

      {!portfolioPageView && (
        <div
          style={{
            position: "fixed",
            right: isRTL
              ? "auto"
              : campaignBuilderOpen
              ? `calc(${
                  maxWidth1220px
                    ? campaignBuilderDrawerWidthMobile
                    : campaignBuilderDrawerWidthDesktop
                } + 40px)`
              : "40px",
            left: !isRTL
              ? "auto"
              : campaignBuilderOpen
              ? `calc(${
                  maxWidth1220px
                    ? campaignBuilderDrawerWidthMobile
                    : campaignBuilderDrawerWidthDesktop
                } + 40px)`
              : "40px",
            top: `calc(${
              maxWidth1220px ? mobileHeaderHeight : headerHeight
            }px + 22px)`,
          }}
        >
          <LangToggle
            color="white"
            className={"lang-toggle-fixed-wrapper"}
            fontSize="20px"
            autosaveFunc={autosaveFunc}
            isDisabledSwitchLang={isDisabledSwitchLang}
          />
        </div>
      )}
    </div>
  );
};
