import React from "react";
import { LangToggle } from "./LangToggle";
import { Theme, useMediaQuery } from "@mui/material";
import SigninImg from "../../assets/signin_img.png";

export const AuthWrapper = ({ children }: { children: React.ReactNode }) => {
  const downMd = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));

  return (
    <div className="auth-wrapper">
      {children}

      <div
        className="signin-img-section"
        style={{
          display: downMd ? "none" : "flex",
        }}
      >
        <img src={SigninImg} alt="Sign In" className="signin-img" />
      </div>
    </div>
  );
};
