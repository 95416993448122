import { useLanguageTheme } from "../../../../hooks/useLanguageTheme";
import { setContactInformationOpen } from "../../../../store/features/campaignBuilderSetup";
import { useAppDispatch } from "../../../../store/store";
import PhoneIcon from "../../../../assets/campaignBuilder/phone.svg";
import { useTranslation } from "react-i18next";

export const ContactToAgentMobileBtn = () => {
  const { i18n, t } = useTranslation(["campaignBuilder"]);
  const langTheme = useLanguageTheme();
  const dispatch = useAppDispatch();

  const handleOpenContactToAgentPanel = () => {
    dispatch(setContactInformationOpen());
  };

  return (
    <div
      className="contact-to-agent-btn-wrapper"
      style={{
        fontFamily: langTheme.mainFont(),
        direction: i18n.dir(),
      }}
      onClick={handleOpenContactToAgentPanel}
    >
      <div>{t("contact_to_agent")}</div>
      <img src={PhoneIcon} />
    </div>
  );
};
