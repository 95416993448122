import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  Currency,
  Language,
  PropertyStatus,
  PropertyType,
  TimePeriod,
} from "../../components/campaignBuilder/content/layout/CampaignBuilderSideNav";
import { IKeyFeature } from "../../components/campaignBuilder/content/steps/KeyFeature";
import { IPropertyLocation } from "../../components/campaignBuilder/CampaignBuilderLocationSearch";
import { ExtractedMediaData } from "../../helpers/campaignConvert";
import { CampaignCreatingStatus } from "../../components/campaignBuilder/content/steps/CampaignSetUpStep";
import { User } from "../../components/campaignBuilder/constants";
import { DAYS } from "../../components/campaignBuilder/content/selects/CampaignBuilderDurationSelect";

interface PropertyLocation {
  city?: string;
  address?: string;
  coordinates?: {
    latitude?: number;
    longitude?: number;
  };
  id?: string;
}

interface AllData {
  form: {
    language: string;
    countOfTimePeriods: number;
    timePeriod: string;
    propertyTitle: string;
    propertyDescription: string;
    propertyStatus: string;
    currency: string;
    propertyPrice: string;
    propertyLocation: PropertyLocation;
    propertyType: string;
    aboutPropertyDesc: string;
    aboutDeveloper: string;
    keyFeaturesBack: string;
    hightlightsBack: string;
    selectedUserId: string;
    labelBack: string;
    imgFilesSlice: any;
    brochureFilesSlice: any;
    floorPlanFilesSlice: any;
    videoFilesSlice: any;
    campaignStatus: string;
    mainLabelOpen: boolean;
    floorPlanSectionOpen: boolean;
    mapSectionOpen: boolean;
    videoSectionOpen: boolean;
    scheduleVewingOpen: boolean;
    agentPhotOpen: boolean;
    galleryOpen: boolean;
    previewMode: string;
    link?: string;
    phone?: string;
    nameHe?: string;
    nameEn?: string;
  };
}

export interface ICampaingBuilderSliceState {
  language: Language;
  currency: Currency;
  countOfTimePeriods: string;
  timePeriod: TimePeriod;
  propertyTitle: string;
  propertyDescription: string;
  propertyStatus: PropertyStatus;
  propertyType: PropertyType;
  propertyPrice: string;
  propertyLocation: IPropertyLocation;
  propertyImgs: any;
  aboutPropertyDesc: string;
  aboutDeveloper: string;
  keyFeaturesBack: IKeyFeature[];
  labelBack: any;
  selectedAgent: User | {};
  hightlightsBack: any;
  selectedUserId: string | number;
  imgFilesSlice: ExtractedMediaData[] | [];
  floorPlanFilesSlice: ExtractedMediaData[] | [];
  brochureFilesSlice: ExtractedMediaData[] | [];
  videoFilesSlice: ExtractedMediaData[] | [];
  agentAvatarSlice: ExtractedMediaData[] | [];
  campaignStatus: CampaignCreatingStatus;
  isChangedValue: boolean;
  createdAgentDocumentId: string;
  //setup
  mainLabelOpen: boolean;
  floorPlanSectionOpen: boolean;
  mapSectionOpen: boolean;
  videoSectionOpen: boolean;
  scheduleVewingOpen: boolean;
  agentPhotOpen: boolean;
  galleryOpen: boolean;
  oldData: AllData | null;
}

const initialState: ICampaingBuilderSliceState = {
  language: Language.ENGLISH,
  currency: Currency.NIS,
  countOfTimePeriods: DAYS[89],
  timePeriod: TimePeriod.DAYS,
  propertyTitle: "",
  propertyDescription: "",
  propertyStatus: PropertyStatus.UNDER_CONSTRUCTION,
  propertyType: PropertyType.PENTHOUSE,
  propertyPrice: "0",
  propertyLocation: {
    city: "",
    address: "",
    coordinates: {
      latitude: null,
      longitude: null,
    },
    id: "",
  },
  propertyImgs: [],
  aboutPropertyDesc: "",
  aboutDeveloper: "",
  keyFeaturesBack: [],
  labelBack: null,
  selectedAgent: {},
  hightlightsBack: [],
  selectedUserId: 0,
  imgFilesSlice: [],
  floorPlanFilesSlice: [],
  brochureFilesSlice: [],
  videoFilesSlice: [],
  agentAvatarSlice: [],
  campaignStatus: CampaignCreatingStatus.PUBLISH,
  isChangedValue: false,
  createdAgentDocumentId: "",

  //setup

  mainLabelOpen: true,
  floorPlanSectionOpen: true,
  mapSectionOpen: true,
  videoSectionOpen: true,
  scheduleVewingOpen: true,
  agentPhotOpen: true,
  galleryOpen: false,
  oldData: null,
};

const campaignBuilderSlice = createSlice({
  name: "campaignBuilder",
  initialState,
  reducers: {
    setLanguage: (state, action: PayloadAction<Language>) => {
      state.language = action.payload;
    },
    setCurrency: (state, action: PayloadAction<Currency>) => {
      state.currency = action.payload;
    },
    setIsChangedValue: (state) => {
      state.isChangedValue = true;
    },
    setCountOfTimePeriods: (state, action: PayloadAction<string>) => {
      state.countOfTimePeriods = action.payload;
    },
    setTimePeriod: (state, action: PayloadAction<TimePeriod>) => {
      state.timePeriod = action.payload;
    },
    setPropertyTitle: (state, action: PayloadAction<string>) => {
      state.propertyTitle = action.payload;
    },
    setPropertyDescription: (state, action: PayloadAction<string>) => {
      state.propertyDescription = action.payload;
    },
    setPropertyStatus: (state, action: PayloadAction<PropertyStatus>) => {
      state.propertyStatus = action.payload;
    },
    setPropertyPrice: (state, action: PayloadAction<string>) => {
      state.propertyPrice = action.payload;
    },
    setPropertyLocation: (state, action: PayloadAction<any>) => {
      state.propertyLocation = action.payload;
    },
    resetCampaignBuilder: (state) => {
      return initialState;
    },
    setImgPropertiesUrls: (state, action: PayloadAction<any>) => {
      state.propertyImgs = action.payload;
    },
    setAboutPropertyDesc: (state, action: PayloadAction<string>) => {
      state.aboutPropertyDesc = action.payload;
    },
    setAboutDeveloper: (state, action: PayloadAction<string>) => {
      state.aboutDeveloper = action.payload;
    },
    setKeyFeatureBack: (state, action: PayloadAction<IKeyFeature[]>) => {
      state.keyFeaturesBack = action.payload;
    },
    setLabelBack: (state, action: PayloadAction<any[]>) => {
      state.labelBack = action.payload;
    },
    setSelectedAgent: (state, action: PayloadAction<any>) => {
      state.selectedAgent = action.payload;
    },
    setHightlightsBack: (state, action: PayloadAction<any[]>) => {
      state.hightlightsBack = action.payload;
    },
    setSelectedUserId: (state, action: PayloadAction<any>) => {
      state.selectedUserId = action.payload;
    },
    setPropertyType: (state, action: PayloadAction<PropertyType>) => {
      state.propertyType = action.payload;
    },
    setImgFilesSlice: (state, action: PayloadAction<any>) => {
      const existingIds = new Set(state.imgFilesSlice.map((item) => item.id));
      const newItems = action.payload.filter(
        (item: any) => !existingIds.has(item.id)
      );

      if (newItems.length === 0) {
        state.imgFilesSlice = action.payload;
      } else {
        state.imgFilesSlice = [...state.imgFilesSlice, ...newItems];
      }
    },

    removeImgFileById: (state, action: PayloadAction<number>) => {
      const idToRemove = action.payload;
      state.imgFilesSlice = state.imgFilesSlice.filter(
        (file: ExtractedMediaData) => file.id !== idToRemove
      );
    },
    setFloorPlanSlice: (state, action: PayloadAction<any>) => {
      state.floorPlanFilesSlice = action.payload;
    },
    removeFloorPlanFileById: (state, action: PayloadAction<number>) => {
      const idToRemove = action.payload;
      state.floorPlanFilesSlice = state.floorPlanFilesSlice.filter(
        (file: ExtractedMediaData) => file.id !== idToRemove
      );
    },
    setBrochureFileSlice: (state, action: PayloadAction<any>) => {
      state.brochureFilesSlice = action.payload;
    },
    removeBrochureFileById: (state, action: PayloadAction<number>) => {
      const idToRemove = action.payload;
      state.brochureFilesSlice = state.brochureFilesSlice.filter(
        (file: ExtractedMediaData) => file.id !== idToRemove
      );
    },
    setVideoFileSlice: (state, action: PayloadAction<any>) => {
      state.videoFilesSlice = action.payload;
    },
    removeVideoFileById: (state, action: PayloadAction<number>) => {
      const idToRemove = action.payload;
      state.videoFilesSlice = state.videoFilesSlice.filter(
        (file: ExtractedMediaData) => file.id !== idToRemove
      );
    },
    setAvatarFileSlice: (state, action: PayloadAction<any>) => {
      state.agentAvatarSlice = action.payload;
    },
    removeAgentFileById: (state, action: PayloadAction<number>) => {
      const idToRemove = action.payload;
      state.agentAvatarSlice = state.agentAvatarSlice.filter(
        (file: ExtractedMediaData) => file.id !== idToRemove
      );
    },
    setCampaignStatus: (
      state,
      action: PayloadAction<CampaignCreatingStatus>
    ) => {
      state.campaignStatus = action.payload;
    },
    setCreatedAgentDocumentId: (state, action: PayloadAction<string>) => {
      state.createdAgentDocumentId = action.payload;
    },

    //setup
    setMainLabelOpen(state, action) {
      state.mainLabelOpen = action.payload;
    },
    setFloorPlanSectionOpen(state, action) {
      state.floorPlanSectionOpen = action.payload;
    },
    setVideoSectionOpen(state, action) {
      state.videoSectionOpen = action.payload;
    },
    setMapSectionOpen(state, action) {
      state.mapSectionOpen = action.payload;
    },
    setScheduleVewingOpen(state, action) {
      state.scheduleVewingOpen = action.payload;
    },
    setAgentPhotoOpen(state, action) {
      state.agentPhotOpen = action.payload;
    },
    setImgGalleryOpen(state, action) {
      state.galleryOpen = action.payload;
    },
    setOldData: (state, action: PayloadAction<AllData>) => {
      state.oldData = action.payload;
    },
    clearOldData: (state) => {
      state.oldData = null;
    },
  },
});

export const {
  setLanguage,
  setCurrency,
  setCountOfTimePeriods,
  setTimePeriod,
  setPropertyTitle,
  setPropertyDescription,
  setPropertyStatus,
  setPropertyPrice,
  setPropertyLocation,
  resetCampaignBuilder,
  setImgPropertiesUrls,
  setAboutPropertyDesc,
  setAboutDeveloper,
  setKeyFeatureBack,
  setLabelBack,
  setSelectedAgent,
  setHightlightsBack,
  setSelectedUserId,
  setPropertyType,
  setImgFilesSlice,
  removeImgFileById,
  setFloorPlanSlice,
  removeFloorPlanFileById,
  setBrochureFileSlice,
  removeBrochureFileById,
  setVideoFileSlice,
  removeVideoFileById,
  setAvatarFileSlice,
  removeAgentFileById,
  setCampaignStatus,
  setIsChangedValue,
  setCreatedAgentDocumentId,
  setMainLabelOpen,
  setFloorPlanSectionOpen,
  setMapSectionOpen,
  setVideoSectionOpen,
  setScheduleVewingOpen,
  setAgentPhotoOpen,
  setImgGalleryOpen,
  setOldData,
  clearOldData,
} = campaignBuilderSlice.actions;

export const selectOldData = (state: {
  campaignBuilder: ICampaingBuilderSliceState;
}) => state.campaignBuilder.oldData;

export default campaignBuilderSlice.reducer;
