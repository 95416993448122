import {
  Currency,
  CurrencyMap,
  PropertyStatus,
  propertyStatusToNumber,
  TimePeriod,
} from "../components/campaignBuilder/content/layout/CampaignBuilderSideNav";
import { IFilter } from "../components/portfolio/interfaces";
import { sortOptions } from "../constants/propertyData";
import i18n from "../i18n";
import { IPriceRanges, OptionType } from "../types/properties";
import { Property } from "./convert";
import { format, Locale } from "date-fns";
import { he, enUS } from "date-fns/locale";

export const getPriceRangeBounds = (
  selectedPriceRangesId: string[] | undefined,
  priceRanges: { [key: number]: IPriceRanges }
) => {
  if (!selectedPriceRangesId) {
    return [];
  }
  const selectedRanges = selectedPriceRangesId
    .map((id: string) => priceRanges[Number(id)])
    .filter((range): range is IPriceRanges => range !== undefined);

  if (selectedRanges.length === 0) {
    return [];
  }

  const min = Math.min(
    ...selectedRanges.map((range: IPriceRanges) => range.min)
  );
  const max = Math.max(
    ...selectedRanges.map((range: IPriceRanges) => range.max)
  );

  return {
    basePrice: {
      $lte: max,
      $gte: min,
    },
  };
};

export const convertLocations = (
  seletedLocationIds: string[],
  locations: Record<number, OptionType>
) => {
  if (!seletedLocationIds) return [];

  return seletedLocationIds.map((id: string) => {
    const location = locations[Number(id)];
    return { location: { city: location ? location.valueEn : undefined } };
  });
};

export const getAddParamByNameSortParam = (nameSortParam: string) => {
  const option = sortOptions.find(
    (option) => option.nameSortParam === nameSortParam
  );

  return option ? option.addParam : "";
};

export const mapIdsToValues = <T extends Record<number, OptionType>>(
  ids: string[],
  options: T,
  isRTL: boolean
): string[] => {
  if (!Array.isArray(ids) || ids.length === 0) {
    return [];
  }

  return ids.map((id) => {
    const option = options[Number(id)];
    return isRTL ? option.valueHe : option.valueEn;
  });
};

export const renderValueWithTrancateLength = (
  selected: IFilter[],
  truncateLength: number
) => {
  const displayValues = selected.map((filter) =>
    i18n.t(filter.translationKey, { ns: "filters" })
  );

  if (displayValues.length === 0) {
    return "";
  }

  if (displayValues.length === 1) {
    const singleValue: string | null = displayValues[0];
    if (singleValue === null) {
      return "";
    }
    if (singleValue.length > truncateLength) {
      return singleValue.slice(0, truncateLength - 3) + "...";
    }
    return singleValue;
  } else {
    const firstValue: string | null = displayValues[0];
    const secondValue: string | null = displayValues[1];
    if (firstValue === null) {
      return "";
    }
    const remainingCount = displayValues.length - 1;
    const suffix = ` (+${remainingCount})`;

    if (
      secondValue &&
      firstValue.length + secondValue.length + suffix.length <= truncateLength
    ) {
      return `${firstValue}, ${secondValue}${suffix}`;
    } else if (firstValue.length + suffix.length <= truncateLength) {
      return `${firstValue}${suffix}`;
    } else {
      return (
        firstValue.slice(0, truncateLength - suffix.length - 3) + "..." + suffix
      );
    }
  }
};

export const filterPropertiesByIds = (
  properties: Property[],
  visibleMapIds: number[]
) => {
  return properties.filter((property) => visibleMapIds.includes(property.id));
};

export function formatPrice(num: number) {
  const parts = num.toString().split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return parts.join(".");
}

export const storeLoginState = (token: string, rememberMe: boolean) => {
  const storage = rememberMe ? localStorage : sessionStorage;
  const expiry = Date.now() + 30 * 24 * 3600 * 1000; // 30 days
  storage.setItem("jwtToken", token);
  storage.setItem("expiry", String(expiry));
};

const locales: Record<string, Locale> = {
  en: enUS,
  he: he,
};

export const getFormattedEndDate = (
  countOfTimePeriods: string,
  timePeriod: TimePeriod,
  currentLanguage: string
) => {
  const now = new Date();
  let endDate: any;

  if (timePeriod === TimePeriod.DAYS) {
    endDate = new Date(now.setDate(now.getDate() + Number(countOfTimePeriods)));
  } else if (timePeriod === TimePeriod.WEEKS) {
    endDate = new Date(
      now.setDate(now.getDate() + Number(countOfTimePeriods) * 7)
    );
  } else if (timePeriod === TimePeriod.MONTHS) {
    endDate = new Date(
      now.setMonth(now.getMonth() + Number(countOfTimePeriods))
    );
  }

  const day = format(endDate, "dd", { locale: locales[currentLanguage] });
  const month = format(endDate, "MMMM", { locale: locales[currentLanguage] });
  const year = format(endDate, "yyyy", { locale: locales[currentLanguage] });

  return ` ${day} ${month} ${year}`;
};

export function calculateRemainingTime(
  countOfTimePeriods: any,
  timePeriod: any
) {
  const now = new Date();
  let futureDate = new Date(now);

  switch (timePeriod) {
    case TimePeriod.DAYS:
      futureDate.setDate(now.getDate() + parseInt(countOfTimePeriods));
      break;
    case TimePeriod.WEEKS:
      futureDate.setDate(now.getDate() + parseInt(countOfTimePeriods) * 7);
      break;
    case TimePeriod.MONTHS:
      futureDate.setMonth(now.getMonth() + parseInt(countOfTimePeriods));
      break;
    default:
      throw new Error("Invalid time period");
  }

  const diff = futureDate.getTime() - now.getTime();
  const days = Math.floor(diff / (1000 * 60 * 60 * 24));
  const hours = Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((diff % (1000 * 60)) / 1000);

  return {
    days,
    hours,
    minutes,
    seconds,
  };
}

export const filterOptions = (options: any[], filterIds: string[]) => {
  return options.filter(
    (option) =>
      filterIds.includes(option.id) || filterIds.includes(option.selectId)
  );
};

export function getStatusNumber(status: PropertyStatus): number {
  return propertyStatusToNumber[status];
}

export const getCurrencyIcon = (currency: Currency): string => {
  return CurrencyMap[currency];
};
