import React from "react";
import { useTranslation } from "react-i18next";
import ErrorIcon from "../../../../assets/campaignBuilder/error_icon.svg";

export const CampaignSetupItem = ({
  title,
  icon,
  handleOpenStep,
  errors,
}: {
  title: string;
  icon: string;
  handleOpenStep?: any;
  errors?: any;
}) => {
  const { t, i18n } = useTranslation(["campaignBuilder"]);

  const errorMessage = errors?.[title];

  return (
    <div
      className="campaign-builder-sidenav-setup-item"
      style={{
        direction: i18n.dir(),
      }}
      onClick={handleOpenStep}
    >
      <div
        style={{
          display: "flex",
          gap: "10px",
        }}
      >
        <img src={icon} />
        <div style={{
          textWrap: "nowrap"
        }}> {t(title)}</div>
      </div>

      {errorMessage && <img src={ErrorIcon} />}
    </div>
  );
};
