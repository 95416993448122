import { Switch } from "@mui/material";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { styled } from "@mui/material/styles";
import { useSelector } from "react-redux";
import {
  mapCheckedSelector,
  setMapChecked,
} from "../../../../store/features/portfolioSlice";
import { useAppDispatch } from "../../../../store/store";
import { useLanguageTheme } from "../../../../hooks/useLanguageTheme";
import { useUpdateUserMutation } from "../../../../store/api/authApiSlice";
import { useUserProfile } from "../../../../hooks/useUserProfile";

const CustomSwitch = styled(Switch)(() => ({
  width: "46px",
  height: "25px",
  padding: 3,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    "&.Mui-checked": {
      transform: "translateX(23px)",
      color: "rgba(3, 74, 115, 1)",
      "& + .MuiSwitch-track": {
        backgroundColor: "rgba(3, 74, 115, 1)",
      },
    },
  },
  "& .MuiSwitch-track": {
    borderRadius: "12px",
    height: "14px",
    width: "34px",
    backgroundColor: "#bdbdbd",
    opacity: 1,
  },
  "& .MuiSwitch-thumb": {
    width: "20px",
    height: "20px",
  },
}));

export const MapSwither = () => {
  const { t } = useTranslation(["portfolio"]);
  const { i18n, ready } = useTranslation();
  const currentLanguage = i18n.language;
  const isRTL = currentLanguage === "he";
  const langTheme = useLanguageTheme();
  const [updateUser] = useUpdateUserMutation();
  const { profile }: { profile: any } = useUserProfile();

  const dispatch = useAppDispatch();
  const mapChecked = useSelector(mapCheckedSelector);

  useEffect(() => {
    if (profile) {
      const isMapChecked = profile?.details?.isMapOpen;
      localStorage.setItem("isMapChecked", isMapChecked ? "1" : "0");
      dispatch(setMapChecked(isMapChecked));
    }
  }, [profile, dispatch]);

  const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    try {
      localStorage.setItem("isMapChecked", event.target.checked ? "1" : "0");
      dispatch(setMapChecked(event.target.checked));
      const userString = await (localStorage.getItem("user") ||
        sessionStorage.getItem("user"));
      const user = await (userString ? JSON.parse(userString) : null);

      if (user) {
        await updateUser({
          details: { ...profile?.details, isMapOpen: event.target.checked },
        }).unwrap();
      }
    } catch (error: any) {
      console.log(error.data.error);
    }
  };

  return (
    <div
      className="portfolio-map-switcher-container"
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "18px",
        marginRight: "-5px",
      }}
    >
      <div
        style={{
          fontFamily: langTheme.mainFont(),
          fontSize: "14px",
          fontWeight: 500,
        }}
      >
        {t("map")}
      </div>

      <div>
        <CustomSwitch onChange={handleChange} checked={mapChecked} />
      </div>
    </div>
  );
};
