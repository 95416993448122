import { Theme, useMediaQuery } from "@mui/material";
import { useTranslation } from "react-i18next";
import { HighlightIconMap } from "../../../../constants/campaignBuilderData";
import { CampaignSideNavStep } from "../layout/CampaignBuilderSideNav";
import { CampaignBuilderSideNavHeader } from "../layout/CampaignBuilderSideNavHeader";
import { CampainBuilderNextBtn } from "../buttons/CampainBuilderNextBtn";
import { HighlightSelect } from "../selects/HighlightSelect";
import DraggableIcon from "../../../../assets/campaignBuilder/draggable_icon.svg";
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
} from "react-beautiful-dnd";
import { useEffect, useState } from "react";
import { useAppDispatch } from "../../../../store/store";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { LangToggle } from "../../../_shared/LangToggle";
import { useResetToDraft } from "../../../../hooks/useResetToDraft";
import { MAX_HIGHLIGHT_SIZE_BACK } from "../../constants";
import { BackToMenuBtn } from "../buttons/BackToMenuBtn";
import { CampainBuilderBackBtn } from "../buttons/CampainBuilderBackBtn";

const reorder = (
  list: string[],
  startIndex: number,
  endIndex: number
): string[] => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

export const InvestmentHighlights = ({
  setCurrentStep,
  hightlightOptionsState,
  selectedHightLight,
  setSelectedHightLight,
  setHighlightForEditId,
  autosaveFunc = () => {},
  isDisabledSwitchLang = false,
}: {
  setCurrentStep: (step: CampaignSideNavStep) => void;
  hightlightOptionsState: any;
  selectedHightLight: any;
  setSelectedHightLight: any;
  setHighlightForEditId: any;
  autosaveFunc: any;
  isDisabledSwitchLang: boolean;
}) => {
  const { documentId } = useParams();
  const { t, i18n } = useTranslation(["campaignBuilder"]);
  const isRTL = i18n.dir() === "rtl";
  const downMd = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));
  const [selectedHightLightIds, setSelectedHightLightIds] = useState<any[]>([]);
  const dispatch = useAppDispatch();
  const { hightlightsBack } = useSelector(
    (state: any) => state.campaignBuilder
  );
  const resetToDraft = useResetToDraft();

  const handleNext = (step: CampaignSideNavStep) => {
    setCurrentStep(step);
  };

  useEffect(() => {
    if (selectedHightLight) {
      setSelectedHightLightIds(selectedHightLight.map((el: any) => el.id));
    }
  }, [selectedHightLight, hightlightsBack]);

  useEffect(() => {
    if (
      !documentId &&
      selectedHightLight &&
      Array.isArray(selectedHightLight) &&
      selectedHightLight.length === 0
    ) {
      dispatch(
        setSelectedHightLight(
          hightlightOptionsState
            .filter((el: any) => el.type === "system")
            .slice(0, 4)
        )
      );
    }
  }, [hightlightOptionsState]);

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }
    const reorderedIds = reorder(
      selectedHightLightIds,
      result.source.index,
      result.destination.index
    );
    dispatch(
      setSelectedHightLight(
        reorderedIds.map((id) =>
          hightlightsBack.find((item: any) => item.id === id)
        )
      )
    );
    localStorage.setItem("onChangeCompanyBuilder", "1");

    resetToDraft();
    setSelectedHightLightIds(reorderedIds);
  };

  const handleAddNewHighlight = () => {
    setHighlightForEditId(0);
    localStorage.setItem("editKeyFeatureHighlightId", "0");

    if (hightlightsBack.length < MAX_HIGHLIGHT_SIZE_BACK) {
      dispatch(
        setSelectedHightLight([
          ...selectedHightLight,
          {
            id: 0,
            iconKey: "location",
            labelNameHe: "",
            labelNameEn: "",
          },
        ])
      );
    }
    setCurrentStep(CampaignSideNavStep.addaNewHighlight);
  };

  return (
    <div className="campaign-builder-side-nav-wrapper">
      <CampaignBuilderSideNavHeader
        header="investment_highlights"
        leftBtn={
          <CampainBuilderBackBtn
            setCurrentStep={setCurrentStep}
            step={CampaignSideNavStep.labels}
          />
        }
        rightBtn={
          <CampainBuilderNextBtn
            handleNext={handleNext}
            step={CampaignSideNavStep.aboutProperty}
          />
        }
      />

      <div
        className="campaign-builder-sidenav-main-content-wrapper"
        style={{
          paddingTop: downMd ? "30px" : "40px",
          direction: i18n.dir(),
          position: "relative",
        }}
      >
        <div>
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="highlightList" type="group">
              {(provided) => (
                <div ref={provided.innerRef} {...provided.droppableProps}>
                  {selectedHightLightIds && selectedHightLightIds.length > 0
                    ? selectedHightLightIds.map(
                        (selectedId: number, index: number) => {
                          return (
                            <Draggable
                              key={selectedId.toString()}
                              draggableId={selectedId.toString()}
                              index={index}
                            >
                              {(provided) => (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  className="campaign-builder-sidenav-settings-fileds-item"
                                  style={{
                                    ...provided.draggableProps.style,
                                    display: "flex",
                                    alignItems: "center",
                                    marginBottom: "20px",
                                    gap: "17.5px",
                                  }}
                                >
                                  <img
                                    {...provided.dragHandleProps}
                                    src={DraggableIcon}
                                    alt="Drag Handle"
                                    style={{
                                      cursor: "grab",
                                    }}
                                  />
                                  <div className="campaign-builder-sidenav-settings-fileds-item">
                                    <div
                                      style={{ position: "relative" }}
                                      onClick={() => {
                                        localStorage.setItem(
                                          "editKeyFeatureHighlightId",
                                          selectedId.toString()
                                        );
                                        setCurrentStep(
                                          CampaignSideNavStep.addaNewHighlight
                                        );
                                      }}
                                    >
                                      {selectedHightLight.find(
                                        (el: any) => el.id === selectedId
                                      )?.details &&
                                      selectedHightLight.find(
                                        (el: any) => el.id === selectedId
                                      ).details[documentId ?? ""] ? (
                                        <div
                                          style={{
                                            backgroundColor: "rgba(0,0,0,0.2)",
                                            position: "absolute",
                                            width: "100%",
                                            maxWidth: "360px",
                                            height: "100%",
                                            top: 0,
                                            zIndex: 10,
                                            borderRadius: 16,
                                          }}
                                        />
                                      ) : (
                                        ""
                                      )}
                                      <HighlightSelect
                                        value={selectedId}
                                        onChangeValue={() => {}}
                                        disabled={true}
                                        selectedOptions={selectedHightLightIds}
                                        options={selectedHightLight}
                                        iconMap={HighlightIconMap}
                                      />
                                    </div>
                                  </div>
                                </div>
                              )}
                            </Draggable>
                          );
                        }
                      )
                    : ""}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </div>
        <div
          style={{
            backgroundColor: "rgba(5, 57, 87, 1)",
            position: "sticky",
            bottom: 0,
            zIndex: 11,
            display: "flex",
            gap: "15px",
          }}
        >
          <div style={{ flex: 1, maxWidth: "50%" }}>
            <BackToMenuBtn setCurrentStep={setCurrentStep} />
          </div>

          <div
            onClick={() => handleAddNewHighlight()}
            style={{
              marginTop: "20px",
              flex: 1,
              maxWidth: "50%",
            }}
            className="campaign-builder-sidenav-preview-btn"
          >
            <span>{t("add_new_highlight")}</span>
          </div>
        </div>
      </div>
    </div>
  );
};
