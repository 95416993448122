import { Box, MenuItem, Select } from "@mui/material";
import { useLanguageTheme } from "../../../../hooks/useLanguageTheme";
import { useTranslation } from "react-i18next";
import { selectStyles } from "../selects/CampaingBuilderSelect";

export const colors = [
  { name: "DarkBlue", value: "#034A73" },
  { name: "Green", value: "#318C51" },
  { name: "Red", value: "#E56A6A" },
  { name: "Yellow", value: "#D7A148" },
  { name: "Blue", value: "#4579B4" },
  { name: "Purple", value: "#6B11B0DE" },
  { name: "TealBlue", value: "#2792A9" },
  { name: "Orange", value: "#DB8F2C" },
  { name: "LightGreen", value: "#40AC62" },
];

export const ColorDropDown = ({
  selectedColor,
  setSelectedColor,
}: {
  selectedColor: any;
  setSelectedColor: any;
}) => {
  const langTheme = useLanguageTheme();
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const isRTL = currentLanguage === "he";

  const handleChange = (event: any) => {
    const selected: any = colors.find(
      (color) => color.value === event.target.value
    );
    setSelectedColor(selected);
  };

  return (
    <Select
      value={selectedColor.value}
      onChange={handleChange}
      sx={selectStyles(langTheme.mainFont(), isRTL)}
      MenuProps={{
        slotProps: {
          paper: {
            sx: {
              background: "#E8EFF2",
              borderRadius: "16px",
              "& .MuiList-root": {
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
                justifyContent: "space-between",
                padding: "17px ",
                gap: "15px",
              },

              "& .MuiMenuItem-root": {
                margin: "0px 0",
                padding: 0,
                gap: "0 2px",
              },
            },
          },
        },
      }}
      style={{
        width: "100%",
      }}
      IconComponent={() => null}
      renderValue={(value) => (
        <Box sx={{ display: "flex", alignItems: "center", gap: "7px" }}>
          <Box
            sx={{
              width: "18px",
              height: "18px",
              backgroundColor: selectedColor.value,
              borderRadius: "50%",
              boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.08)",
            }}
          />
          {selectedColor.value}
        </Box>
      )}
    >
      {colors.map((color) => (
        <MenuItem
          key={color.value}
          value={color.value}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "auto",
          }}
        >
          <Box
            sx={{
              width: "25px",
              height: "25px",
              backgroundColor: color.value,
              borderRadius: "50%",
            }}
          />
        </MenuItem>
      ))}
    </Select>
  );
};
