import React from "react";
import { useTranslation } from "react-i18next";
import { Box, MenuItem, Select, useMediaQuery } from "@mui/material";
import { useLanguageTheme } from "../../../../hooks/useLanguageTheme";
import { ReactComponent as SelectArrow } from "../../../../assets/campaignBuilder/select_arror.svg";
import { selectStyles } from "./CampaingBuilderSelect";
import {
  BuildingBlueIcon,
  HighlightIconMap,
} from "../../../../constants/campaignBuilderData";
import { truncateStringWithEllipsis } from "../../../../helpers/formatter";

export type HightlightIconKey = keyof typeof HighlightIconMap;

export interface Option {
  id: string;
  iconKey: HightlightIconKey;
  labelNameEn: string;
  labelNameHe: string;
  type: string;
}

export const HighlightSelect = ({
  value,
  onChangeValue,
  options,
  iconMap,
  selectedOptions,
  disabled = false,
}: {
  value: any;
  onChangeValue: any;
  options: Option[];
  iconMap: Record<string, React.ElementType>;
  selectedOptions?: string[];
  disabled?: boolean;
}) => {
  const { t, i18n } = useTranslation(["campaignBuilder"]);
  const maxWidth1220px = useMediaQuery("(max-width: 1220px)");

  const langTheme = useLanguageTheme();
  const isRTL = i18n.language === "he";

  const filteredOptions =
    selectedOptions && Array.isArray(selectedOptions)
      ? options.filter((el) => !selectedOptions.includes(el.id))
      : options;

  const menuSx =
    filteredOptions.length === 0
      ? { display: "none" }
      : { background: "rgba(232, 239, 242, 1)" };

  return (
    <Select
      value={value}
      onChange={(event) => onChangeValue(event.target.value as string)}
      sx={{
        ...selectStyles(langTheme.mainFont(), isRTL),
        maxWidth: maxWidth1220px ? "290px" : "360px",
        width: "100%",
        "&.Mui-disabled .MuiBox-root": {
          color: "rgba(0, 0, 0, 1) !important",
          WebkitTextFillColor: "rgba(0, 0, 0, 1) !important",
          opacity: "1 !important",
          pointerEvents: "none",
        },
      }}
      MenuProps={{
        PaperProps: {
          sx: menuSx,
        },
      }}
      disabled={disabled}
      IconComponent={disabled ? () => null : SelectArrow}
      renderValue={(id) => {
        const option = options.find((opt) => opt.id === id);
        if (!option) return null;
        const IconComponent = iconMap[option.iconKey] || BuildingBlueIcon;
        return (
          <Box sx={{ display: "flex", alignItems: "center", gap: "7px" }}>
            {IconComponent && (
              <IconComponent
                isSelected={false}
                width={17}
                height={19}
                backgroundColor={"#034A73"}
              />
            )}
            <div>
              {truncateStringWithEllipsis(
                option[i18n.language === "he" ? "labelNameHe" : "labelNameEn"],
                maxWidth1220px ? 33 : 45
              )}
            </div>
          </Box>
        );
      }}
    >
      {filteredOptions.length > 0 &&
        filteredOptions.map((option, index) => {
          const IconComponent = iconMap[option.iconKey] || BuildingBlueIcon;
          return (
            <MenuItem
              key={`${option.id}-${index}`}
              value={option.id}
              sx={{
                color: "rgba(0, 0, 0, 1)",
                fontFamily: langTheme.mainFont(),
                fontWeight: 500,
                fontSize: "14px",
                gap: "7px",
                "&.Mui-disabled": {
                  color: "rgba(0, 0, 0, 1) !important",
                  WebkitTextFillColor: "rgba(0, 0, 0, 1) !important",
                  opacity: "1 !important",
                  pointerEvents: "none",
                },
              }}
            >
              {IconComponent && (
                <IconComponent
                  isSelected={false}
                  width={17}
                  height={19}
                  backgroundColor={"#034A73"}
                />
              )}
              <div>
                {option[i18n.language === "he" ? "labelNameHe" : "labelNameEn"]}
              </div>
            </MenuItem>
          );
        })}
    </Select>
  );
};
