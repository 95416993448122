import React, { Dispatch, SetStateAction, useState } from "react";
import { useEffect } from "react";
import Map, { MapRef, Marker } from "react-map-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import { Location, Media, Property } from "../../../../helpers/convert";
import i18n2 from "../../../../i18n";
import { useTranslation } from "react-i18next";
import { convertToPoints, formatPrice } from "../../../../helpers/formatter";
import { styled } from "@mui/material";
import { ControlMapPanel } from "./ControlMapPanel";
import mapboxgl from "mapbox-gl";
import { REACT_APP_MAP_ACCESS_TOKEN } from "../../../../helpers/config";
import { useAuth } from "../../../../hooks/useAuth";
import HouseIcon from "../../../../assets/icons/house.svg";
import { useNavigate } from "react-router-dom";
import { setAuthPopupOpen } from "../../../../store/features/common";
import { useAppDispatch } from "../../../../store/store";
import { MapPropertyPopup } from "./MapPropertyPopup";
import { AuthPopupContentType } from "../../../auth/types";

export type MappedPropertyPoint = {
  id: number;
  latitude: number;
  longitude: number;
  title: string;
  basePrice: number;
  mainImg: Media;
  location: Location;
};

if (REACT_APP_MAP_ACCESS_TOKEN) {
  mapboxgl.accessToken = REACT_APP_MAP_ACCESS_TOKEN;
}

if (mapboxgl.getRTLTextPluginStatus() === "unavailable") {
  mapboxgl.setRTLTextPlugin(
    "https://api.mapbox.com/mapbox-gl-js/plugins/mapbox-gl-rtl-text/v0.2.3/mapbox-gl-rtl-text.js",
    null,
    true
  );
}

export const MapUpMd = ({
  properties,
  setVisibleMarkerIds,
  search,
}: {
  properties: Property[];
  setVisibleMarkerIds: Dispatch<SetStateAction<number[]>>;
  search: string;
}) => {
  const currentLanguage = i18n2.language;
  const isRTL = currentLanguage === "he";
  const { i18n } = useTranslation();
  const mapContainerRef = React.useRef<HTMLDivElement | null>(null);
  const mapRef = React.useRef<MapRef | null>(null);
  const isAuthenticated = useAuth();
  const navigate = useNavigate();

  const [viewState, setViewState] = useState({
    latitude: 0,
    longitude: 0,
    zoom: 8,
  });
  const dispatch = useAppDispatch();
  const [points, setPoints] = useState<MappedPropertyPoint[]>([]);
  const [clickedPointId, setClickedPointId] = useState<number | null>(null);
  const [selectedPoint, setSelectedPoint] =
    useState<MappedPropertyPoint | null>(null);

  useEffect(() => {
    const updatedPoints = convertToPoints(properties);
    setPoints(updatedPoints);

    if (updatedPoints.length > 0) {
      setViewState({
        latitude: updatedPoints[0].latitude,
        longitude: updatedPoints[0].longitude,
        zoom: 6,
      });
    }
  }, [properties, currentLanguage, search]);

  const [mapStyle, setMapStyle] = useState(
    "mapbox://styles/mapbox/streets-v11"
  );

  const handleMarkerClick = (point: MappedPropertyPoint) => {
    if (!isAuthenticated) {
      dispatch(setAuthPopupOpen(AuthPopupContentType.SIGNUP));
    } else {
      setSelectedPoint(point);
      setClickedPointId(point.id);
    }
  };

  const updateVisibleMarkers = React.useCallback(
    (map: mapboxgl.Map) => {
      const bounds = map.getBounds();

      if (!bounds) {
        return;
      }
      const visibleIds = points
        .filter((point) => bounds.contains([point.longitude, point.latitude]))
        .map((point) => point.id);

      setVisibleMarkerIds(visibleIds);
    },
    [points, currentLanguage, search]
  );

  React.useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        mapContainerRef.current &&
        !mapContainerRef.current.contains(event.target as Node)
      ) {
        setSelectedPoint(null);
        setClickedPointId(null);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleZoomIn = () => {
    setViewState((prev) => ({
      ...prev,
      zoom: Math.min(prev.zoom + 1, 22),
    }));
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const handleZoomOut = () => {
    setViewState((prev) => ({
      ...prev,
      zoom: Math.max(prev.zoom - 1, 0),
    }));
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const updateMapLanguage = (map: mapboxgl.Map) => {
    const language = currentLanguage;
    const languageField = "name_" + language;
    const style = map.getStyle();

    if (!style || !style.layers) {
      console.error("Map style or layers are not available");
      return;
    }
    style.layers.forEach(function (layer: mapboxgl.LayerSpecification) {
      if (
        layer.type === "symbol" &&
        layer.layout &&
        layer.layout["text-field"]
      ) {
        map.setLayoutProperty(layer.id, "text-field", [
          "coalesce",
          ["get", languageField],
          ["get", "name"],
        ]);
      }
    });
  };

  const onMapLoad = (event: mapboxgl.MapEvent) => {
    const map: mapboxgl.Map = event.target;
    updateVisibleMarkers(map);
    updateMapLanguage(map);
  };

  useEffect(() => {
    if (mapRef.current) {
      const map = mapRef.current.getMap();
      onMapLoad({ target: map } as mapboxgl.MapEvent);
    }
  }, [currentLanguage, search]);

  useEffect(() => {
    if (mapRef.current) {
      const map = mapRef.current.getMap();
      updateVisibleMarkers(map);
    }
  }, [points, currentLanguage, search]);

  return (
    <div
      ref={mapContainerRef}
      id={"map"}
      style={{ width: "100%", height: "100%", position: "relative" }}
    >
      <ControlMapPanel
        handleZoomIn={handleZoomIn}
        handleZoomOut={handleZoomOut}
      />
      <Map
        key={currentLanguage}
        {...viewState}
        onMove={(evt) => {
          setViewState(evt.viewState);
          updateVisibleMarkers(evt.target);
          window.scrollTo({ top: 0, behavior: "smooth" });
        }}
        onLoad={onMapLoad}
        ref={mapRef}
        style={{ width: "100%", height: "100%" }}
        mapStyle={mapStyle}
        mapboxAccessToken={REACT_APP_MAP_ACCESS_TOKEN}
      >
        {points.map((point) => (
          <Marker
            key={`${currentLanguage}-${point.id}`}
            latitude={point.latitude}
            longitude={point.longitude}
            anchor="bottom"
            style={{
              cursor: "pointer",
            }}
          >
            <TooltipElement
              onClick={() => handleMarkerClick(point)}
              isClicked={clickedPointId === point.id}
            >
              {isAuthenticated ? (
                "NIS" + " " + formatPrice(point.basePrice)
              ) : (
                <img src={HouseIcon} alt="house" />
              )}
            </TooltipElement>
          </Marker>
        ))}

        {selectedPoint && (
          <MapPropertyPopup
            selectedPoint={selectedPoint}
            setSelectedPoint={setSelectedPoint}
          />
        )}
      </Map>
    </div>
  );
};

export const TooltipElement = styled("div")<{ isClicked: boolean }>(
  ({ theme, isClicked }) => ({
    position: "relative",
    width: "60px",
    height: "12px",
    backgroundColor: isClicked
      ? "rgba(215, 161, 72, 1)"
      : "rgba(3, 74, 115, 1)",
    padding: "8px 12px",
    borderRadius: "4px",
    fontWeight: "bold",
    display: "flex",
    alignItems: "center",
    fontFamily: "DM Sans",
    fontSize: "16px",
    lineHeight: "16px",
    color: "rgba(255, 255, 255, 1)",
    justifyContent: "center",

    "&:hover": {
      backgroundColor: "rgba(215, 161, 72, 1)",
    },
    "&::after": {
      content: '""',
      position: "absolute",
      top: "100%",
      left: "50%",
      marginLeft: "-5px",
      borderWidth: "7px",
      borderStyle: "solid",
      borderColor: isClicked
        ? "rgba(215, 161, 72, 1) transparent transparent transparent"
        : "rgba(3, 74, 115, 1) transparent transparent transparent",
    },
    "&:hover::after": {
      borderColor: "rgba(215, 161, 72, 1) transparent transparent transparent",
    },
  })
);
