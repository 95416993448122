import { Currency } from "../components/campaignBuilder/content/layout/CampaignBuilderSideNav";
import { IInitialProperty } from "../types/initialProperty";
import { getImageUrl } from "./api";
import { RandomMediaGenerator } from "./fakeMedia";
import IMG from "../assets/default_property_img.png";
import { LabelIconKey } from "../constants/campaignBuilderData";

export interface Coordinates {
  latitude: number;
  longitude: number;
}

export interface Location {
  city: string;
  address: string;
  coordinates: Coordinates;
}

export interface Pricing {
  basePrice: number;
  currency: Currency;
}

export interface Detail {
  [key: string]: number | string | Array<number | string>;
  bathrooms: number;
  bedrooms: number;
  livingArea: number;
}

export interface Media {
  url: string;
}

export interface ILabel {
  id: string;
  iconKey: LabelIconKey;
  backgroundColor: string;
  labelNameEn: string;
  labelNameHe: string;
}

interface Details {
  language: string;
  isMapOpen: boolean;
}

interface Owner {
  id: number;
  email: string;
  details: Details;
  fullName: string;
  userType: string;
  username: string;
  documentId: string;
  phoneNumber: string;
}

export interface Property {
  id: number;
  name: string;
  desc: string;
  type: string;

  location: Location;
  pricing: Pricing;
  details: Detail;

  mainImage: Media;
  otherMedia: Media[];

  video: Media[];
  videoPreview: Media;
  documentId: string;
  campaignStatus?: null | string;
  propertyLabels: ILabel[];
  numberOfDaysToCompletion: number | null;
  campaignCloseDate: string;
  owner: Owner;
}

export function convertMediaContent(
  mediaContent: Media[] | undefined
): Media[] {
  if (!mediaContent || mediaContent.length == 0) {
    return [];
  }

  const allMedia: Media[] = [];

  for (const media of mediaContent) {
    const url = media === null ? "" : media.url;

    allMedia.push({
      url: getImageUrl(url),
    });
  }

  return allMedia;
}

const PREFERED_OTHER_NUM = 3;

export function convertToProperty(
  gen: RandomMediaGenerator,
  obj: IInitialProperty,
  index: number
): Property {
  let allMedia = convertMediaContent(obj?.mediaContent);

  let mainMedia: Media;
  if (allMedia.length > 0) {
    mainMedia = allMedia[0];
  } else {
    // if (index < gen.getImageLen()) {
    //   mainMedia = gen.getMainByIndex(index);
    // } else {
    //   mainMedia = gen.getRandomMain();
    // }
    mainMedia = {
      url: IMG,
    };
  }

  let otherMedia: Media[] = [];
  if (allMedia.length > 1) {
    otherMedia = allMedia.slice(1);
  }

  // for (let i = 1; i < allMedia.length; i++) {
  //   otherMedia.push(allMedia[i]);
  // }

  let videoMedia: Media[] = [];
  if (obj?.video?.length === 0) {
    videoMedia = [gen.getFakeVideo()];
  } else {
    videoMedia = obj.video;
  }

  // FAKE IMAGES ONLY
  // if (index < FAKE_MEDIA_MAIN.length) {
  //   mainMedia = FAKE_MEDIA_MAIN[index];
  // } else {
  // mainMedia = gen.getRandomMain();
  // }
  // for (let media of otherMedia) {
  //   media.url = gen.getRandomOther().url;
  // }

  // files-dev.sentom-re.com/medium_house5_b09a61392f.jpg
  //api-dev.sentom-re.comfiles-dev.sentom-re.com/house2_36f010232f.jpg
  // FAKE IMAGES ONLY

  // const pricing =
  //   Object.keys(obj.pricing).length === 0 ? { basePrice: 0 } : obj.pricing;

  const pricing =
    obj && obj.pricing && Object.keys(obj.pricing).length === 0
      ? { basePrice: 0, currency: Currency.NIS }
      : obj.pricing || { basePrice: 0, currency: "nis" };

  const details =
    !obj.details || Object.keys(obj.details).length === 0
      ? {
          parking: 1,
          bedrooms: 3,
          features: ["historical", "view", "luxury"],
          bathrooms: 3,
          totalArea: 200,
          floorLevel: 10,
          livingArea: 160,
          outdoorArea: 40,
          totalFloors: 10,
        }
      : obj.details;

  const location =
    Object.keys(obj.location).length === 0
      ? {
          city: "Tel Aviv",
          address: "100 Hayarkon St",
          amenities: [
            {
              name: "Gordon Beach",
              type: "recreation",
              distance: 200,
            },
          ],
          coordinates: {
            latitude: 32.0853,
            longitude: 34.781768,
          },
          neighborhood: "Beach Area",
        }
      : obj.location;

  return {
    id: obj.id,
    name: obj.name,
    campaignStatus: obj.campaignStatus,
    desc: obj.description,
    type: obj.propertyType,
    location: location,
    pricing: pricing,
    details: details,
    mainImage: mainMedia,
    otherMedia: otherMedia,
    video: videoMedia,
    videoPreview: gen.getRandomMain(),
    documentId: obj.documentId,
    propertyLabels: obj.propertyLabels,
    numberOfDaysToCompletion: obj.numberOfDaysToCompletion,
    campaignCloseDate: obj.campaignCloseDate,
    owner: {
      id: 189,
      email: "avidor@happyseniors.care",
      details: {
        language: "en",
        isMapOpen: false,
      },
      fullName: "Avidor Rabinovich",
      userType: "Seller",
      username: "967552aa-f55c-4dd6-9b7a-6b1ebc8ac9a2",
      documentId: "gyl2x35plc0yad43taybvjx9",
      phoneNumber: "+972546309657",
    },
  };
}

export function convertToProperties(
  gen: RandomMediaGenerator,
  data: IInitialProperty[] | null | undefined
): Property[] {
  // TODO handle bad input
  const properties: Property[] = [];
  if (!data) {
    return [];
  }
  return data.map((rawMedia, index) => convertToProperty(gen, rawMedia, index));
}

export interface IConvertedArticleContent {
  id: string;
  title: string;
  content: string[];
}

export interface ISourceArticleElement {
  type: string;
  level?: number;
  children: {
    text: string;
    type: string;
  }[];
}

export function convertToArticleContent(
  sourceData: ISourceArticleElement[]
): IConvertedArticleContent[] {
  const result: IConvertedArticleContent[] = [];
  let currentChapter: IConvertedArticleContent | null = null;
  let chapterIndex = 0;

  for (let i = 0; i < sourceData.length; i++) {
    const element = sourceData[i];

    const elementText = element.children
      .map((child) => child.text)
      .join("")
      .trim();

    if (element.type === "heading") {
      if (!elementText) {
        continue;
      }

      if (currentChapter) {
        result.push(currentChapter);
      }
      chapterIndex++;
      currentChapter = {
        id: `chapter${chapterIndex}`,
        title: elementText,
        content: [],
      };
    } else if (element.type === "paragraph") {
      if (!elementText && currentChapter) {
        continue;
      } else if (!elementText && !currentChapter) {
        continue;
      }

      if (!currentChapter) {
        chapterIndex++;
        currentChapter = {
          id: `chapter${chapterIndex}`,
          title: `Chapter ${chapterIndex}`,
          content: [],
        };
      }
      currentChapter.content.push(elementText);
    }
  }

  if (currentChapter) {
    result.push(currentChapter);
  }

  return result;
}
