import {
  getFiltersByIds,
  statusFilterMap,
  typeFilterMap,
} from "../../constants/propertyData";
import i18n from "../../i18n";
import {
  DEFAULT_CAMPAIGN_STATUS,
  DEFAULT_FILTER,
  DEFAULT_PAGE,
  DEFAULT_PRICE_RANGE_END,
  DEFAULT_PRICE_RANGE_START,
  DEFAULT_SORT_DIRECTION,
  DEFAULT_SORT_FIELD,
  PARAM_ENCODER_SEP,
  QUERY_PARAM_FILTER_CAMPAIGN_STATUS,
  QUERY_PARAM_FILTER_LOCATION,
  QUERY_PARAM_FILTER_PRICE_RANGE_END,
  QUERY_PARAM_FILTER_PRICE_RANGE_START,
  QUERY_PARAM_FILTER_PROPERTY_TYPES,
  QUERY_PARAM_FILTER_STATUSES,
  QUERY_PARAM_FILTER_USER_ID,
  QUERY_PARAM_PAGE,
  QUERY_PARAM_PAGE_SIZE,
  QUERY_PARAM_SEARCH,
  QUERY_PARAM_SORT_DIRECTION,
  QUERY_PARAM_SORT_FIELD,
} from "./constants";
import {
  IFilter,
  IFilterParams,
  IGetPropertiesParams,
  IPropertiesPaginationMeta,
  ITranslation,
} from "./interfaces";

export function getTranslation(t: ITranslation, key: string): string {
  switch (key) {
    case "he":
      return t.he;
    case "en":
      return t.en;
    default:
      return t.en;
  }
}

export function getTranslationOrI18N(
  t: ITranslation,
  i18nKey: string,
  langKey: string
): string {
  if (i18nKey) {
    return i18n.t(i18nKey, { ns: "filters" });
  }

  return getTranslation(t, langKey);
}

export function convertToPaginationMeta(
  pagination: IPropertiesPaginationMeta | undefined
): IPropertiesPaginationMeta | null {
  if (!pagination) {
    return null;
  }
  return pagination as IPropertiesPaginationMeta;
}

export function encodeParamArray(keys: string[]) {
  return keys.join(PARAM_ENCODER_SEP);
}

export function decodeParamArray(param: string | null): string[] {
  if (param === null) {
    return [];
  }

  return param.split(PARAM_ENCODER_SEP);
}

export const encodeLocationsForUrl = (locations: any[]): string => {
  return locations
    .map((location) => {
      if (!location.custom_adress || !location.city) {
        return null;
      }

      const encodedAddress = encodeURIComponent(location.custom_adress);
      const encodedCity = encodeURIComponent(location.city);

      return `${encodedAddress}:${encodedCity}`;
    })
    .filter(Boolean)
    .join(",");
};

export const decodeLocation = (encodedString: string): any[] => {
  if (!encodedString) return [];

  return encodedString.split(",").map((locationString) => {
    const [encodedAddress, encodedCity] = locationString.split(":");

    if (!encodedAddress || !encodedCity) {
      throw new Error("Invalid encoded location string");
    }

    const decodedAddress = decodeURIComponent(encodedAddress);
    const decodedCity = decodeURIComponent(encodedCity);

    return { custom_adress: decodedAddress, city: decodedCity };
  });
};

export function parseSearchParams(
  searchParams: URLSearchParams,
  userId: string
): IGetPropertiesParams {
  const pageParam = searchParams.get(QUERY_PARAM_PAGE);
  const searchParam = searchParams.get(QUERY_PARAM_SEARCH);
  const sortFieldParam = searchParams.get(QUERY_PARAM_SORT_FIELD);
  const campaingStatusParam = searchParams.get(
    QUERY_PARAM_FILTER_CAMPAIGN_STATUS
  );
  const userIdParam = searchParams.get(QUERY_PARAM_FILTER_USER_ID);

  const sortDirectionParam = searchParams.get(QUERY_PARAM_SORT_DIRECTION);
  const locationParams = searchParams.get(QUERY_PARAM_FILTER_LOCATION);
  const propertyTypesParams = searchParams.get(
    QUERY_PARAM_FILTER_PROPERTY_TYPES
  );
  const priceRangeStartParam = searchParams.get(
    QUERY_PARAM_FILTER_PRICE_RANGE_START
  );
  const priceRangeEndParam = searchParams.get(
    QUERY_PARAM_FILTER_PRICE_RANGE_END
  );
  const statusesParams = searchParams.get(QUERY_PARAM_FILTER_STATUSES);

  let filters: IFilterParams = {
    locations: locationParams ? decodeLocation(locationParams) : [],

    statuses: statusesParams
      ? getFiltersByIds(statusFilterMap, decodeParamArray(statusesParams))
      : DEFAULT_FILTER.statuses,

    types: propertyTypesParams
      ? getFiltersByIds(typeFilterMap, decodeParamArray(propertyTypesParams))
      : DEFAULT_FILTER.types,
    priceRange: {
      start: Number(priceRangeStartParam) || DEFAULT_PRICE_RANGE_START,
      end: Number(priceRangeEndParam) || DEFAULT_PRICE_RANGE_END,
    },
  };

  return {
    page: Number(pageParam) || DEFAULT_PAGE,
    sort: {
      direction: sortDirectionParam || DEFAULT_SORT_DIRECTION,
      field: sortFieldParam || DEFAULT_SORT_FIELD,
    },
    campaignStatus: campaingStatusParam || DEFAULT_CAMPAIGN_STATUS,
    userId: userIdParam || userId,

    filters: filters,
    search: searchParam || "",
  };
}

export function buildSearchParamsFromGetPropertiesParams(
  params: IGetPropertiesParams
): URLSearchParams {
  const queryParams = new URLSearchParams();

  queryParams.set(QUERY_PARAM_PAGE, "1");
  queryParams.set(QUERY_PARAM_PAGE_SIZE, (params.page * 10).toString());
  queryParams.set(QUERY_PARAM_SORT_DIRECTION, params.sort.direction);
  queryParams.set(QUERY_PARAM_SORT_FIELD, params.sort.field);
  queryParams.set(QUERY_PARAM_FILTER_CAMPAIGN_STATUS, params.campaignStatus);
  queryParams.set(QUERY_PARAM_FILTER_USER_ID, params.userId);

  if (params.search) {
    queryParams.set(QUERY_PARAM_SEARCH, params.search);
  } else {
    queryParams.delete(QUERY_PARAM_SEARCH);
  }

  let l = encodeLocationsForUrl(params.filters.locations);
  if (l) {
    queryParams.set(QUERY_PARAM_FILTER_LOCATION, l);
  } else {
    queryParams.delete(QUERY_PARAM_FILTER_LOCATION);
  }

  let s = encodeParamArray(params.filters.statuses.map((item) => item.key));
  if (s) {
    queryParams.set(QUERY_PARAM_FILTER_STATUSES, s);
  } else {
    queryParams.delete(QUERY_PARAM_FILTER_STATUSES);
  }

  let t = encodeParamArray(params.filters.types.map((item) => item.key));
  if (t) {
    queryParams.set(QUERY_PARAM_FILTER_PROPERTY_TYPES, t);
  } else {
    queryParams.delete(QUERY_PARAM_FILTER_PROPERTY_TYPES);
  }

  queryParams.set(
    QUERY_PARAM_FILTER_PRICE_RANGE_START,
    params.filters.priceRange.start.toString()
  );
  queryParams.set(
    QUERY_PARAM_FILTER_PRICE_RANGE_END,
    params.filters.priceRange.end.toString()
  );

  return queryParams;
}

export const enrichParams = async (
  params: IGetPropertiesParams
): Promise<IGetPropertiesParams> => {
  let location: IFilter | null = null;
  if (params.filters.locations.length > 0) {
    location = params.filters.locations[0];
  }

  if (location == null) {
    params.filters.locations = [];
  } else {
    params.filters.locations = [location];
  }

  return params;
};
