import { Dialog, DialogContent } from "@mui/material";
import React, { useRef, useState } from "react";
import Map, { MapRef, Marker } from "react-map-gl";
import { REACT_APP_MAP_ACCESS_TOKEN } from "../../helpers/config";
import "mapbox-gl/dist/mapbox-gl.css";
import mapboxgl from "mapbox-gl";
import { useSelector } from "react-redux";
import LocationPinIcon from "../../assets/campaignBuilder/location_pin.svg";

if (REACT_APP_MAP_ACCESS_TOKEN) {
  mapboxgl.accessToken = REACT_APP_MAP_ACCESS_TOKEN;
}

if (mapboxgl.getRTLTextPluginStatus() === "unavailable") {
  mapboxgl.setRTLTextPlugin(
    "https://api.mapbox.com/mapbox-gl-js/plugins/mapbox-gl-rtl-text/v0.2.3/mapbox-gl-rtl-text.js",
    null,
    true
  );
}
export const MapDialog = ({
  open,
  handleCloseMapDialog,
}: {
  open: boolean;
  handleCloseMapDialog: any;
}) => {
  const mapRef = useRef<MapRef>(null);
  const [mapViewport, setMapViewport] = useState({
    latitude: 37.7749,
    longitude: -122.4194,
    zoom: 12,
  });
  const [viewport, setViewport] = useState({
    latitude: 37.7749,
    longitude: -122.4194,
    zoom: 12,
  });

  const { propertyLocation } = useSelector(
    (state: any) => state.campaignBuilder
  );

  React.useEffect(() => {
    setViewport({
      latitude: propertyLocation.coordinates.latitude,
      longitude: propertyLocation.coordinates.longitude,
      zoom: 12,
    });
    setMapViewport({
      latitude: propertyLocation.coordinates.latitude,
      longitude: propertyLocation.coordinates.longitude,
      zoom: 12,
    });
  }, [propertyLocation]);

  return (
    <Dialog
      open={open}
      onClose={handleCloseMapDialog}
      PaperProps={{
        sx: {
          borderRadius: "20px",
          backgroundColor: "white",
          position: "relative",
          width: "100%",
          maxWidth: "800px",
        },
      }}
      BackdropProps={{
        sx: { backgroundColor: "rgb(0 0 0 / 72%)" },
      }}
    >
      <DialogContent sx={{ p: 0, position: "relative", overflowY: "hidden" }}>
        <div>
          <Map
            ref={mapRef}
            {...mapViewport}
            style={{
              width: "100%",
              borderRadius: "20px",
              height: "500px",
            }}
            mapStyle="mapbox://styles/mapbox/streets-v12"
            mapboxAccessToken={REACT_APP_MAP_ACCESS_TOKEN}
            onMove={(viewport) => setMapViewport(viewport.viewState)}
          >
            <Marker longitude={viewport.longitude} latitude={viewport.latitude}>
              <img src={LocationPinIcon} alt="Location Pin Icon" />
            </Marker>
          </Map>
        </div>
      </DialogContent>
    </Dialog>
  );
};
