import {
  Box,
  Typography,
  IconButton,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import { useEffect, useRef, useState } from "react";
import "swiper/css";
import "swiper/css/navigation";
import i18n from "../../i18n";
import { useGetSellerTestimonialsQuery } from "../../store/api/sellWithUsApiSlice";
import { ITestimonial } from "../../hooks/useTestimonials";
import arrow from "../../assets/icons/arrow_back.svg";

interface ISwiper {
  slideNext: () => {};
  slidePrev: () => {};
}
export default function Testimonials() {
  const { t } = useTranslation(["sellWithUs"]);
  const isRTL = i18n.language === "he";
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(850));
  const maxWidth1300px = useMediaQuery(theme.breakpoints.down(1300));
  const maxWidth1000px = useMediaQuery(theme.breakpoints.down(1000));
  const maxWidth850px = useMediaQuery(theme.breakpoints.down(850));
  const maxWidth760px = useMediaQuery(theme.breakpoints.down(760));

  const swiperRef = useRef({
    slideNext: () => {},
    slidePrev: () => {},
  } as ISwiper);
  const [testimonials, setTestimonials] = useState<ITestimonial[]>([]);
  const { data: fetchedTestimonials } = useGetSellerTestimonialsQuery({
    lang: i18n.language,
    role: isRTL ? "מוֹכֵר" : "Seller",
  });

  useEffect(() => {
    if (fetchedTestimonials) {
      setTestimonials(fetchedTestimonials.data);
    }
  }, [fetchedTestimonials]);

  return (
    <Box sx={{ pt: 0, mt: isMobile ? "70px" : "150px", textAlign: "center" }}>
      <Typography
        sx={{
          fontWeight: 600,
          fontSize: isMobile ? "24px" : "36px",
          color: "black",
          textTransform: "uppercase",
          mb: isMobile ? "30px" : "50px",
          fontFamily: isRTL ? "Noto Sans Hebrew" : "Frank Ruhl Libre",
          textAlign: "center",
          justifyContent: "center",
          display: "flex",
          // width: isMobile ? "97%" : { md: "60%", lg: "60%" },
          justifySelf: "center",
        }}
      >
        {isRTL ? (
          t("see_other_sellers")
        ) : (
          <p
            style={{
              margin: 0,
            }}
          >
            See other sellers
            <span
              style={{
                color: "gray",
                fontFamily: isRTL ? "Noto Sans Hebrew" : "Frank Ruhl Libre",
              }}
            >
              &nbsp;who have worked&nbsp;
            </span>
            with us
          </p>
        )}
      </Typography>

      <Swiper
        modules={[Navigation]}
        spaceBetween={30}
        slidesPerView={
          maxWidth760px
            ? 1
            : maxWidth850px
            ? 1.7
            : maxWidth1000px
            ? 2
            : maxWidth1300px
            ? 2.5
            : 3
        }
        centeredSlides={true}
        initialSlide={1}
        autoHeight={false}
        onSwiper={(swiper: ISwiper) => (swiperRef.current = swiper)}
        style={{
          paddingBottom: "40px",
          maxWidth: "1440px",
          width: "100%",
          height: maxWidth850px ? "auto" : "550px",
        }}
      >
        {testimonials?.map((testimonial, index) => (
          <SwiperSlide key={index}>
            {({ isActive, isPrev, isNext }) => (
              <Box
                sx={{
                  bgcolor: isMobile
                    ? "#034A73"
                    : isActive
                    ? "#034A73"
                    : "#F5F5F5DE",
                  color: isActive ? "#fff" : "#000",
                  p: isActive ? "20px" : isMobile ? "20px" : 4,
                  borderRadius: "20px",
                  boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
                  transform: isActive
                    ? "scale(1) rotate(0deg)"
                    : isPrev
                    ? "scale(0.9) rotate(-10deg)"
                    : isNext
                    ? "scale(0.9) rotate(10deg)"
                    : "scale(0.9)",
                  transition: "all 0.3s ease-in-out",
                  minHeight: "400px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  fontFamily: isRTL ? "Noto Sans Hebrew" : "DM sans",
                  letterSpacing: "4%",
                  zIndex: isActive ? 100 : -100,
                  maxWidth: isMobile ? "auto" : "400px",
                  mx: "20px",
                }}
              >
                <Box
                  sx={{
                    height: "auto",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      mb: 2,
                      flexDirection: isRTL ? "row-reverse" : "row",
                      textAlign: isRTL ? "right" : "left",
                    }}
                  >
                    <Box
                      sx={{
                        width: "67px",
                        height: "67px",
                        borderRadius: "50%",
                        mr: isRTL ? 0 : "15px",
                        ml: isRTL ? "15px" : 0,
                        overflow: "hidden",
                      }}
                    >
                      <img
                        src={testimonial?.photo?.url}
                        alt={testimonial?.name}
                        style={{
                          width: "67px",
                          height: "67px",
                          borderRadius: "50%",
                          objectFit: "cover",
                        }}
                      />
                    </Box>
                    <Box>
                      <Typography
                        variant="h6"
                        sx={{
                          fontWeight: 600,
                          fontSize: "16px",
                          fontFamily: isRTL ? "Noto Sans Hebrew" : "DM sans",
                        }}
                      >
                        {testimonial?.name}
                      </Typography>
                      <Typography
                        variant="body2"
                        sx={{
                          fontSize: "14px",
                          fontWeight: 400,
                          fontFamily: isRTL ? "Noto Sans Hebrew" : "DM sans",
                        }}
                      >
                        {testimonial?.title}
                      </Typography>
                    </Box>
                  </Box>
                  <Typography
                    variant="body1"
                    sx={{
                      fontSize: "18px",
                      lineHeight: 1.6,
                      textAlign: isRTL ? "right" : "left",
                      fontFamily: isRTL ? "Noto Sans Hebrew" : "DM sans",
                    }}
                  >
                    {testimonial?.testimonial}
                  </Typography>
                  <Box
                    sx={{
                      width: "100%",
                      height: "1px",
                      bgcolor: isActive ? "#FFFFFF57" : "#00000021",
                      mt: maxWidth1000px ? 6 : 12,
                      mb: isMobile ? 1 : 0,
                    }}
                  />
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: isRTL ? "row-reverse" : "row",
                    gap: 2,
                    mt: 2,
                  }}
                >
                  <Box sx={{ textAlign: "left", width: "80%" }}>
                    <Typography
                      variant="body2"
                      sx={{
                        fontSize: "0.9rem",
                        whiteSpace: "pre-line",
                        textAlign: isRTL ? "right" : "left",
                        color: isActive ? "white" : "black",
                        fontFamily: isRTL ? "Noto Sans Hebrew" : "DM sans",
                      }}
                    >
                      {testimonial?.propertyName}
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{
                        fontSize: "0.9rem",
                        whiteSpace: "pre-line",
                        textAlign: isRTL ? "right" : "left",
                        color: "#FCBC5C",
                        fontFamily: isRTL ? "Noto Sans Hebrew" : "DM sans",
                      }}
                    >
                      {testimonial?.propertyDescription}
                    </Typography>
                  </Box>
                  <Box sx={{ width: "30%" }}>
                    <img
                      src={testimonial?.propertyPhoto?.url}
                      alt={testimonial?.propertyName}
                      style={{
                        width: "100%",
                        height: "80px",
                        objectFit: "cover",
                        borderRadius: "10px",
                      }}
                    />
                  </Box>
                </Box>
              </Box>
            )}
          </SwiperSlide>
        ))}
      </Swiper>

      <Box sx={{ display: "flex", justifyContent: "center", gap: 2 }}>
        <IconButton
          onClick={() => swiperRef.current?.slidePrev()}
          sx={{
            width: "40px",
            height: "40px",
            borderRadius: "50%",
            border: "2px solid #666",
            color: "#666",
            "&:hover": {
              borderColor: "#034A73",
              color: "#034A73",
            },
          }}
          aria-label="Previous testimonial"
        >
          <img src={arrow} alt="" />
        </IconButton>
        <IconButton
          onClick={() => swiperRef.current?.slideNext()}
          sx={{
            width: "40px",
            height: "40px",
            borderRadius: "50%",
            border: "2px solid #666",
            color: "#666",
            "&:hover": {
              borderColor: "#034A73",
              color: "#034A73",
            },
          }}
          aria-label="Next testimonial"
        >
          <img src={arrow} alt="" style={{ transform: "rotate(180deg)" }} />
        </IconButton>
      </Box>
    </Box>
  );
}
