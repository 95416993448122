import React from 'react';
import TopGallery from '../components/sellWithUs/TopGallery';
import OurTools from '../components/sellWithUs/OurTools';
import TransparentProcess from '../components/sellWithUs/TransparentProcess';
import Testimonials from '../components/sellWithUs/Testimonials';
import Questions from '../components/sellWithUs/Questions';
import GetInTouchFooter from '../components/sellWithUs/GetInTouchFooter';
import WhyChooseUs from '../components/sellWithUs/WhyChooseUs';
import OurPartners from '../components/sellWithUs/OurPartners';
import { Header } from '../components/_shared/Header';
import { Navbar } from '../components/_shared/Navbar';
import { Footer } from '../components/_shared/Footer';
import { FooterSocialMediaLine } from '../components/_shared/FooterSocialMediaLine';
import { FooterDemo } from '../components/_shared/FooterDemo';
import { AuthPopup } from '../components/_shared/AuthPopup';
import { SideNavMenu } from '../components/_shared/SideNavMenu';

export default function SellWithUs() {
  return (
    <div style={{
      overflow: 'visible'
    }}>
      <div className="portfolio-header-sticky-wrapper">
        <div
          style={{
            backgroundColor: 'rgba(255, 255, 255, 1)',
          }}
        >
          <Header>
            <Navbar />
          </Header>
        </div>
      </div>
      <TopGallery />
      <WhyChooseUs />
      <OurTools />
      <TransparentProcess />
      <Testimonials />
      <OurPartners />
      <Questions />
      <GetInTouchFooter />
      {/* <Footer /> */}
      <FooterDemo />
      <FooterSocialMediaLine />
      <AuthPopup />
      <SideNavMenu />
    </div>
  );
}
