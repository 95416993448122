import React, { useState } from "react";
import User from "../../assets/icons/user.svg";
import Sms from "../../assets/icons/sms.svg";
import Email from "../../assets/icons/email.svg";
import { useTranslation } from "react-i18next";
import YellowArrow from "../../assets/icons/yellow_arrow.svg";
import { useNavigate, useParams } from "react-router-dom";
import { CircularProgress, Theme, useMediaQuery } from "@mui/material";
import { useLanguageTheme } from "../../hooks/useLanguageTheme";
import {
  useResendSignupEmailMutation,
  useResendSignupSmsMutation,
  useSignupMutation,
  useSmsSignupMutation,
} from "../../store/api/authApiSlice";
import { useAppDispatch } from "../../store/store";
import { setAuthPopupOpen } from "../../store/features/common";
import { AuthPopupContentType } from "./types";

export interface IResendSignupData {
  email: string;
}

export const ChooseVerificationContent = ({
  headerText,
}: {
  headerText: string;
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation(["signin"]);
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const isRTL = currentLanguage === "he";
  const langTheme = useLanguageTheme();
  const downMd = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));
  const dispatch = useAppDispatch();

  const registrationData = JSON.parse(
    localStorage.getItem("registrationData") || "{}"
  );
  const email = registrationData.email || "";
  const phoneNumber = registrationData.phoneNumber || "";

  const [signup] = useSignupMutation();
  const [signupSms] = useSmsSignupMutation();
  const [resendSms] = useResendSignupSmsMutation();
  const [resendEmailMutation] = useResendSignupEmailMutation();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");

  const handleSmsVerification = async () => {
    setIsLoading(true);
    try {
      const registrationData = JSON.parse(
        localStorage.getItem("registrationData") || "{}"
      );
      const response = await signupSms(registrationData).unwrap();
      if (response.status === "unconfirmed") {
        await resendSms({ phoneNumber }).unwrap();
      }

      dispatch(setAuthPopupOpen(AuthPopupContentType.CONFIRM_SMS));
    } catch (error: any) {
      console.log(error.data.error);
      if (error.data.error.message === "User already registered") {
        setError(t("user_already_verified"));
      } else if (
        error.data.error.message === "Request failed with status code 400"
      ) {
        setError(t("invalid_phone"));
      } else {
        setError(t("sms_error"));
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleEmailVerification = async () => {
    setIsLoading(true);
    try {
      const registrationData = JSON.parse(
        localStorage.getItem("registrationData") || "{}"
      );
      const response = await signup(registrationData).unwrap();
      if (response.status === "unconfirmed") {
        await resendEmailMutation({
          email: email,
        }).unwrap();
      }
      dispatch(setAuthPopupOpen(AuthPopupContentType.CONFIRM_EMAIL));
    } catch (error: any) {
      console.log(error.data.error);
      if (error.data.error.message === "User already registered") {
        setError(t("user_already_verified"));
      } else if (error.data.error.message === "email must be a valid email") {
        setError(t("invalid_email"));
      } else {
        setError(t("email_error"));
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleBackToSignup = () => {
    dispatch(setAuthPopupOpen(AuthPopupContentType.SIGNUP));
  };

  return (
    <div
      className="email-confirmation-section"
      style={{
        direction: i18n.dir(),
        height: downMd ? "100vh" : "",
        position: "relative",
      }}
    >
      {isLoading && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(255, 255, 255, 0.8)",
            zIndex: 9999,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress size={50} color="primary" />
        </div>
      )}
      <div className="email-confirmation-email-checked-container">
        <img src={User} alt="user icon" />
      </div>

      <div
        className="forgot-p-title"
        style={{
          fontFamily: isRTL ? "Noto Sans Hebrew" : "Frank Ruhl Libre",
        }}
      >
        {t(`${headerText}`)}
      </div>

      <div
        className="verification-option"
        onClick={handleSmsVerification}
        style={{ marginTop: 30, fontFamily: langTheme.mainFont() }}
      >
        <div>
          <img src={Sms} alt="sms icon" />
        </div>
        <div
          style={{
            marginRight: "10px",
          }}
        >
          <span>{t("verification_via_sms")}</span>
          <p style={{ direction: "ltr" }}>
            {i18n.dir() === "rtl"
              ? phoneNumber.split("").reverse().join("")
              : phoneNumber}
          </p>
        </div>
      </div>

      <div className="verification-option" onClick={handleEmailVerification}>
        <div>
          <img src={Email} alt="email icon" />
        </div>
        <div
          style={{
            marginRight: "10px",
            fontFamily: langTheme.mainFont(),
          }}
        >
          <span>{t("verification_via_email")}</span>
          <p>{email}</p>
        </div>
      </div>
      {error && (
        <>
          <p style={{ color: "red", fontFamily: langTheme.mainFont() }}>
            {error}
          </p>
        </>
      )}
      <div
        className="email-confirmation-yellow email-confirmation-back-to-signup"
        style={{
          fontFamily: langTheme.mainFont(),
          direction: i18n.dir(),
          marginTop: 50,
        }}
        onClick={handleBackToSignup}
      >
        <img src={YellowArrow} alt="arrow icon" />
        <div>{t("back_to_sign_up")}</div>
      </div>
    </div>
  );
};
