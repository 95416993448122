import imageCompression from "browser-image-compression";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Header } from "../components/_shared/Header";
import { Navbar } from "../components/_shared/Navbar";
import {
  CampaignBuilderSideNav,
  CampaignSideNavStep,
  Language,
} from "../components/campaignBuilder/content/layout/CampaignBuilderSideNav";
import { NotificationSnackBar } from "../components/campaignBuilder/NotificationSnackBar";
import {
  convertDataToCreateProperty,
  extractIds,
  extractMediaProperties,
  handleUploadFiles,
} from "../helpers/campaignConvert";
import { convertDateToTimePeriods } from "../helpers/formatter";
import { useUserProfile } from "../hooks/useUserProfile";
import {
  useCreateAgentMutation,
  useGetAgentsQuery,
  useUpdateAgentMutation,
  useUploadImgMutation,
} from "../store/api/agentApiSlice";
import {
  useCreatePropertyMutation,
  useUpdatePropertyMutation,
} from "../store/api/campaignBuilderApiSlice";
import { useLazyGetPropertyQuery } from "../store/api/propertyApiSlice";
import {
  closeToggleVisibilityNotification,
  setDeleteHighlightClose,
  setPageView,
} from "../store/features/campaignBuilderSetup";
import {
  resetCampaignBuilder,
  setAboutDeveloper,
  setAboutPropertyDesc,
  setAgentPhotoOpen,
  setAvatarFileSlice,
  setBrochureFileSlice,
  setCampaignStatus,
  setCountOfTimePeriods,
  setCurrency,
  setFloorPlanSectionOpen,
  setFloorPlanSlice,
  setHightlightsBack,
  setImgFilesSlice,
  setImgGalleryOpen,
  setKeyFeatureBack,
  setLabelBack,
  setLanguage,
  setMainLabelOpen,
  setMapSectionOpen,
  setPropertyDescription,
  setPropertyLocation,
  setPropertyPrice,
  setPropertyStatus,
  setPropertyTitle,
  setPropertyType,
  setScheduleVewingOpen,
  setSelectedAgent,
  setSelectedUserId,
  setTimePeriod,
  setVideoFileSlice,
  setVideoSectionOpen,
} from "../store/features/campaignBuilderSlice";
import { campaignBuilderOpenSelector } from "../store/features/common";
import { useAppDispatch } from "../store/store";
import { useMediaQuery } from "@mui/material";
import {
  CampaignCreatingStatus,
  NotificationStatus,
} from "../components/campaignBuilder/content/steps/CampaignSetUpStep";
import { CampainBuilderMainContainer } from "../components/campaignBuilder/content/blocks/CampainBuilderMainContainer";
import {
  campaignBuilderDrawerWidthDesktop,
  campaignBuilderDrawerWidthMobile,
  compressOptions,
} from "../components/campaignBuilder/constants";
import { CampaignBuilderMobileHeader } from "../components/campaignBuilder/content/layout/CampaignBuilderMobileHeader";
import { PreviewMode } from "../components/campaignBuilder/types";
import { PreviewModeSwiter } from "../components/campaignBuilder/content/buttons/PreviewModeSwiter";

export enum BuilderMode {
  CREATE = 0,
  EDIT = 1,
}

export const CampaignBuilder = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const { i18n, t } = useTranslation(["campaignBuilder"]);
  const isRTL = i18n.dir() === "rtl";
  const maxWidth1220px = useMediaQuery("(max-width: 1220px)");
  const maxWidth1070px = useMediaQuery("(max-width: 1070px)");

  const builderHeaderRef = useRef(null);
  const builderMobileHeaderRef = useRef(null);

  const campaignBuilderOpen = useSelector(campaignBuilderOpenSelector);
  const [createProperty] = useCreatePropertyMutation();
  const [updateProperty] = useUpdatePropertyMutation();
  const navigate = useNavigate();

  const { previewMode } = useSelector(
    (state: any) => state.campaignBuilderSetup
  );

  const portfolioPageView = useSelector(
    (state: any) => state.campaignBuilderSetup.pageView
  );

  const user = localStorage.getItem("user") || sessionStorage.getItem("user");

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarText, setSnackbarText] = useState("");
  const [snackBarType, setSnackbarType] = useState("");

  const { error } = useUserProfile();
  useEffect(() => {
    const user = localStorage.getItem("user") || sessionStorage.getItem("user");
    const jwt = localStorage.getItem("jwt") || sessionStorage.getItem("jwt");
    if (error || !jwt || !user) {
      localStorage.removeItem("jwt");
      localStorage.removeItem("user");
      sessionStorage.removeItem("jwt");
      sessionStorage.removeItem("user");
      if (window.location.pathname === "/home") {
        window.location.reload();
      } else {
        navigate("/home");
      }
    }
  }, [error]);

  React.useEffect(() => {
    dispatch(resetCampaignBuilder());
  }, []);

  const {
    language,
    countOfTimePeriods,
    timePeriod,
    propertyTitle,
    propertyDescription,
    propertyStatus,
    currency,
    propertyPrice,
    propertyLocation,
    propertyType,
    aboutPropertyDesc,
    aboutDeveloper,
    keyFeaturesBack,
    hightlightsBack,
    selectedUserId,
    labelBack,
    imgFilesSlice,
    brochureFilesSlice,
    floorPlanFilesSlice,
    videoFilesSlice,
    campaignStatus,
    mainLabelOpen,
    floorPlanSectionOpen,
    mapSectionOpen,
    videoSectionOpen,
    scheduleVewingOpen,
    agentPhotOpen,
    galleryOpen,
    selectedAgent,
    agentAvatarSlice,
  } = useSelector((state: any) => state.campaignBuilder);

  const {
    deleteHighlightOpen,
    deleteHightlightMessage,
    toggleVisibilityNotification,
    toggleVisibilityNotificationMessage,
  } = useSelector((state: any) => state.campaignBuilderSetup);

  const [isAutosaveLoading, setIsAutosaveLoading] = useState(false);

  const [currentStep, setCurrentStep] = React.useState<CampaignSideNavStep>(
    () => {
      const savedStep = localStorage.getItem("currentStep");
      return savedStep &&
        Object.values(CampaignSideNavStep).includes(
          savedStep as CampaignSideNavStep
        )
        ? (savedStep as CampaignSideNavStep)
        : CampaignSideNavStep.campaignSetUp;
    }
  );

  React.useEffect(() => {
    localStorage.setItem("currentStep", currentStep);
  }, [currentStep]);

  const { documentId } = useParams();

  useEffect(() => {
    const fetch = async () => {
      await dispatch(setLanguage(location.state?.locale as Language));
      await navigate(".", { state: null });
    };
    if (documentId && location.state?.locale) {
      setTimeout(() => {
        fetch();
      }, 0);
    }
  }, [documentId]);

  const builderMode = useMemo(() => {
    return documentId ? BuilderMode.EDIT : BuilderMode.CREATE;
  }, [documentId]);

  const [triggerGetProperty, { data: propertyData }] =
    useLazyGetPropertyQuery();

  const { data: agentsData } = useGetAgentsQuery({
    locale: "en",
  });

  React.useEffect(() => {
    const currentLanguage = i18n.language;
    if (documentId) {
      triggerGetProperty({
        documentId: documentId,
        locale: currentLanguage ?? "en",
      });
    }
  }, [language, documentId, i18n.language]);

  const [createAgent] = useCreateAgentMutation();
  const [updateAgent] = useUpdateAgentMutation();
  const useBeforeUnload = () => {
    useEffect(() => {
      const handleBeforeUnload = (event: BeforeUnloadEvent) => {
        event.preventDefault();
      };
      const handleUnload = () => {
        localStorage.setItem("onChangeCompanyBuilder", "0");
      };
      if (localStorage.getItem("onChangeCompanyBuilder") == "1") {
        window.addEventListener("beforeunload", handleBeforeUnload);
        window.addEventListener("unload", handleUnload);
      } else {
        window.removeEventListener("beforeunload", handleBeforeUnload);
        window.removeEventListener("unload", handleUnload);
      }
      return () => {
        window.removeEventListener("beforeunload", handleBeforeUnload);
        window.removeEventListener("unload", handleUnload);
      };
    }, [localStorage.getItem("onChangeCompanyBuilder")]);
  };

  const useConfirmNavigation = (message: string) => {
    useEffect(() => {
      const handlePopState = (event: PopStateEvent) => {
        event.preventDefault();
        const confirmLeave = window.confirm(message);
        if (!confirmLeave) {
          window.history.pushState(null, "", window.location.href);
        } else {
          localStorage.setItem("onChangeCompanyBuilder", "0");
        }
      };

      window.history.pushState(null, "", window.location.href);
      window.addEventListener("popstate", handlePopState);

      return () => {
        window.removeEventListener("popstate", handlePopState);
      };
    }, [message]);
  };

  useBeforeUnload();
  useConfirmNavigation(
    navigator.language.includes("he")
      ? "השינויים לא יישמרו, להמשיך?"
      : navigator.language.includes("ru")
      ? "Изменения не будут сохранены, продолжить?"
      : "Changes will not be saved, continue?"
  );

  const [isCompressing, setIsCompressing] = useState(false);

  const handleAutosaveCampaign = async () => {
    setIsAutosaveLoading(true);

    try {
      let agentId = selectedUserId;
      if (selectedUserId === "create-new") {
        const agentData = {
          nameEn: selectedAgent.nameEn,
          nameHe: selectedAgent.nameHe,
          phone: selectedAgent.phone,
          email: "test@example.com",
          photo: agentAvatarSlice[0]?.id || selectedAgent?.photo?.url || [],
          link: selectedAgent.link,
        };

        const agentResponse = await createAgent({
          agentData,
          locale: "en",
        }).unwrap();

        agentId = agentResponse.data.id;

        dispatch(setSelectedUserId(agentResponse.data.id));
      } else {
        const agentData = {
          nameEn: selectedAgent.nameEn,
          nameHe: selectedAgent.nameHe,
          phone: selectedAgent.phone,
          email: "test@example.com",
          photo: agentAvatarSlice[0]?.id || selectedAgent?.photo?.id || [],
          link: selectedAgent.link,
        };
        await updateAgent({
          agentData,
          documentId: selectedAgent.documentId,
          locale: "en",
        }).unwrap();
      }

      const currentLanguage = i18n.language;
      let imgIds = extractIds(imgFilesSlice);
      let brochureIds = extractIds(brochureFilesSlice);
      let floorplanIds = extractIds(floorPlanFilesSlice);
      let videoIds = extractIds(videoFilesSlice);
      const propertyData = convertDataToCreateProperty(
        countOfTimePeriods,
        timePeriod,
        propertyTitle,
        propertyDescription,
        propertyStatus,
        currency,
        propertyPrice,
        propertyLocation,
        propertyType,
        aboutPropertyDesc,
        aboutDeveloper,
        keyFeaturesBack.map((el: any) => el?.id),
        hightlightsBack.map((el: any) => el?.id),
        agentId,
        [labelBack.id],
        imgIds,
        brochureIds,
        floorplanIds,
        videoIds,
        documentId ? campaignStatus : CampaignCreatingStatus.DRAFT,
        mainLabelOpen,
        floorPlanSectionOpen,
        mapSectionOpen,
        videoSectionOpen,
        scheduleVewingOpen,
        agentPhotOpen,
        galleryOpen,
        user
      );
      if (documentId) {
        const response = await updateProperty({
          propertyData,
          documentId: documentId,
          language: currentLanguage,
        }).unwrap();
        const dataForDiffLang = (await triggerGetProperty({
          documentId: documentId || response.data.documentId,
          locale: currentLanguage === "en" ? "he" : "en",
        })) as any;
        await updateProperty({
          propertyData: {
            ...propertyData,
            name: dataForDiffLang?.data?.name,
            description: dataForDiffLang?.data?.description,
            location: dataForDiffLang?.data.location,
            developer: dataForDiffLang?.data?.developer,
            aboutProperty: dataForDiffLang?.data?.aboutProperty,
          },
          documentId: documentId,
          language: currentLanguage === "en" ? "he" : "en",
        }).unwrap();
      } else {
        const response = await createProperty({
          propertyData,
          language: currentLanguage,
        }).unwrap();

        await updateProperty({
          propertyData: {
            ...propertyData,
            name: "",
            description: "",
            developer: "",
            aboutProperty: "",
            location: {
              city: "",
              address: "",
              coordinates: {
                latitude: null,
                longitude: null,
              },
              id: "",
            },
          },
          documentId: response.data.documentId,
          language: currentLanguage === "en" ? "he" : "en",
        }).unwrap();
        await navigate(`/campaign-page-builder/${response.data.documentId}`, {
          state: {
            locale: language === "en" ? "hebrew" : "english",
          },
        });
      }
      setIsAutosaveLoading(false);
      localStorage.setItem("onChangeCompanyBuilder", "0");
    } catch (error: any) {
      console.log("log: ", error.data.error.message);
      setIsAutosaveLoading(false);
    }
  };

  useEffect(() => {
    if (propertyData) {
      const { countOfTimePeriodsValue, timePeriodValue } =
        convertDateToTimePeriods(propertyData.campaignCloseDate);

      dispatch(setCountOfTimePeriods(countOfTimePeriodsValue));
      dispatch(setTimePeriod(timePeriodValue));
      dispatch(setPropertyTitle(propertyData.name));
      dispatch(setPropertyDescription(propertyData.description));
      dispatch(setPropertyPrice(String(propertyData.pricing.basePrice)));
      dispatch(setCurrency(propertyData.pricing.currency));
      dispatch(setCurrency(propertyData.pricing.currency));
      dispatch(setPropertyStatus(propertyData.propertyStatus));
      dispatch(setPropertyType(propertyData.propertyType));
      dispatch(setAboutPropertyDesc(propertyData.aboutProperty));
      dispatch(setAboutDeveloper(propertyData.developer));
      dispatch(setKeyFeatureBack(propertyData.key_features));
      dispatch(setHightlightsBack(propertyData.investment_highlights));
      dispatch(setLabelBack(propertyData.propertyLabels[0]));
      dispatch(setCampaignStatus(propertyData.campaignStatus));
      dispatch(
        setPropertyLocation({
          city: propertyData.location.city,
          address: propertyData.location.address,
          coordinates: {
            latitude: propertyData.location.coordinates.latitude,
            longitude: propertyData.location.coordinates.longitude,
          },
          id: propertyData.location.id,
        })
      );

      dispatch(setSelectedUserId(propertyData.agent?.id));

      const extractedImgFiles = extractMediaProperties(
        propertyData.mediaContent
      );
      dispatch(setImgFilesSlice(extractedImgFiles));

      const extractedFloorPlan = extractMediaProperties(propertyData.floorplan);
      dispatch(setFloorPlanSlice(extractedFloorPlan));

      const extractedVideo = extractMediaProperties(propertyData.video);
      dispatch(setVideoFileSlice(extractedVideo));

      const extractedBrocure = extractMediaProperties(propertyData.brochure);
      dispatch(setBrochureFileSlice(extractedBrocure));

      //setup
      dispatch(
        setMainLabelOpen(
          propertyData.details?.mainLabelOpen === null
            ? propertyData.details.mainLabelOpen
            : true
        )
      );
      dispatch(
        setFloorPlanSectionOpen(
          propertyData.details?.floorPlanSectionOpen === null
            ? propertyData.details.floorPlanSectionOpen
            : true
        )
      );
      dispatch(
        setVideoSectionOpen(
          propertyData.details?.videoSectionOpen === null
            ? propertyData.details.videoSectionOpen
            : true
        )
      );
      dispatch(
        setMapSectionOpen(
          propertyData.details?.mapSectionOpen === null
            ? propertyData.details.mapSectionOpen
            : true
        )
      );
      dispatch(
        setScheduleVewingOpen(
          propertyData.details?.scheduleVewingOpen === null
            ? propertyData.details.scheduleVewingOpen
            : true
        )
      );
      dispatch(
        setAgentPhotoOpen(
          propertyData.details?.agentPhotOpen === null
            ? propertyData.details.agentPhotOpen
            : true
        )
      );
      dispatch(
        setImgGalleryOpen(
          propertyData.details?.galleryOpen === null
            ? propertyData.details.galleryOpen
            : true
        )
      );
    }
  }, [propertyData]);

  React.useEffect(() => {
    dispatch(setSelectedUserId(0));
  }, []);

  React.useEffect(() => {
    if (agentsData && selectedUserId < 1) {
      dispatch(setSelectedUserId(agentsData[0]?.id));
    }
  }, [agentsData, selectedUserId, propertyData]);

  React.useEffect(() => {
    if (agentsData && agentsData.length > 0) {
      const selectedAgent = agentsData.find(
        (agent: any) => agent.id === selectedUserId
      );
      if (!selectedAgent) {
        return;
      }
      dispatch(setSelectedAgent(selectedAgent));
    }
  }, [agentsData, selectedUserId, dispatch]);

  const [imgFiles, setImgFiles] = React.useState<File[]>([]);
  const [floorplanFiles, setFloorplanFiles] = React.useState<File[]>([]);
  const [brochureFiles, setBrochureFiles] = React.useState<File[]>([]);
  const [videoFiles, setVideoFiles] = React.useState<File[]>([]);
  const [newAgentAvatar, setNewAgentAvatar] = React.useState<File[]>([]);

  const [uploadImg, { isLoading: isImgsLoading }] = useUploadImgMutation();
  const [uploadFloorPlan, { isLoading: isFloorPlanLoading }] =
    useUploadImgMutation();
  const [uploadBrochure, { isLoading: isBrochureLoading }] =
    useUploadImgMutation();
  const [uploadVideo, { isLoading: isVideoLoading }] = useUploadImgMutation();
  const [uploadAgentAvatar, { isLoading: isAvatarLoading }] =
    useUploadImgMutation();

  React.useEffect(() => {
    const uploadImages = async () => {
      if (imgFiles.length > 0) {
        try {
          for (let i = 0; i < imgFiles.length; i++) {
            try {
              setIsCompressing(true);
              const compressedFile = await imageCompression(
                imgFiles[i],
                compressOptions
              );
              imgFiles[i] = new File([compressedFile], compressedFile.name, {
                type: compressedFile.type,
                lastModified: Date.now(),
              });
            } catch (error) {
              console.error("Error compressing property image:", error);
            } finally {
              setIsCompressing(false);
            }
          }
          const formData = handleUploadFiles(imgFiles);
          const filesResponse = await uploadImg(formData).unwrap();
          const extractedFiles = extractMediaProperties(filesResponse);

          dispatch(setImgFilesSlice(extractedFiles));
          setImgFiles([]);
        } catch (error) {
          console.error("Error uploading images:", error);
        }
      }
    };

    uploadImages();
  }, [imgFiles]);

  React.useEffect(() => {
    const upoadFiles = async () => {
      if (floorplanFiles.length > 0) {
        try {
          for (let i = 0; i < floorplanFiles.length; i++) {
            try {
              setIsCompressing(true);
              const compressedFile = await imageCompression(
                floorplanFiles[i],
                compressOptions
              );
              floorplanFiles[i] = new File(
                [compressedFile],
                compressedFile.name,
                {
                  type: compressedFile.type,
                  lastModified: Date.now(),
                }
              );
            } catch (error) {
              console.error("Error compressing floorplan image:", error);
            } finally {
              setIsCompressing(false);
            }
          }
          const formData = handleUploadFiles(floorplanFiles);
          const filesResponse = await uploadFloorPlan(formData).unwrap();
          const extractedFiles = extractMediaProperties(filesResponse);
          dispatch(setFloorPlanSlice(extractedFiles));
          setFloorplanFiles([]);
        } catch (error) {
          console.error("Error uploading floorplan:", error);
        }
      }
    };

    upoadFiles();
  }, [floorplanFiles]);

  React.useEffect(() => {
    const upoadFiles = async () => {
      if (brochureFiles.length > 0) {
        try {
          const formData = handleUploadFiles(brochureFiles);
          const filesResponse = await uploadBrochure(formData).unwrap();
          const extractedFiles = extractMediaProperties(filesResponse);
          dispatch(setBrochureFileSlice(extractedFiles));
          setBrochureFiles([]);
        } catch (error) {
          console.error("Error uploading brochureFiles:", error);
        }
      }
    };

    upoadFiles();
  }, [brochureFiles]);

  React.useEffect(() => {
    const upoadFiles = async () => {
      if (videoFiles.length > 0) {
        try {
          const formData = handleUploadFiles(videoFiles);
          const filesResponse = await uploadVideo(formData).unwrap();
          const extractedFiles = extractMediaProperties(filesResponse);
          dispatch(setVideoFileSlice(extractedFiles));
          setVideoFiles([]);
        } catch (error) {
          console.error("Error uploading videoFiles:", error);
        }
      }
    };

    upoadFiles();
  }, [videoFiles]);

  React.useEffect(() => {
    const upoadFiles = async () => {
      if (newAgentAvatar.length > 0) {
        try {
          for (let i = 0; i < newAgentAvatar.length; i++) {
            try {
              setIsCompressing(true);
              const compressedFile = await imageCompression(
                newAgentAvatar[i],
                compressOptions
              );
              newAgentAvatar[i] = new File(
                [compressedFile],
                compressedFile.name,
                {
                  type: compressedFile.type,
                  lastModified: Date.now(),
                }
              );
            } catch (error) {
              console.error("Error compressing agent image:", error);
            } finally {
              setIsCompressing(false);
            }
          }
          const formData = handleUploadFiles(newAgentAvatar);
          const filesResponse = await uploadAgentAvatar(formData).unwrap();
          const extractedFiles = extractMediaProperties(filesResponse);
          dispatch(setAvatarFileSlice(extractedFiles));
          setNewAgentAvatar([]);
        } catch (error) {
          console.error("Error uploading agentAvatar:", error);
        }
      }
    };

    upoadFiles();
  }, [newAgentAvatar]);

  const settingRef = useRef<HTMLDivElement | null>(null);
  const mainInfoRef = useRef<HTMLDivElement | null>(null);
  const mediaRef = useRef<HTMLDivElement | null>(null);
  const labelRef = useRef<HTMLDivElement | null>(null);
  const aboutPropertyRef = useRef<HTMLDivElement | null>(null);
  const aboutDeveloperRef = useRef<HTMLDivElement | null>(null);
  const contactInformationRef = useRef<HTMLDivElement | null>(null);

  const investmentHighlightsRef = useRef<HTMLDivElement | null>(null);
  const keyFeaturesRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (
      currentStep === CampaignSideNavStep.investmentHighlights &&
      investmentHighlightsRef.current
    ) {
      investmentHighlightsRef.current.scrollIntoView({ behavior: "smooth" });
    } else if (
      currentStep === CampaignSideNavStep.keyFeatures &&
      keyFeaturesRef.current
    ) {
      keyFeaturesRef.current.scrollIntoView({ behavior: "smooth" });
    } else if (
      currentStep === CampaignSideNavStep.settings &&
      settingRef.current
    ) {
      settingRef.current.scrollIntoView({ behavior: "smooth" });
    } else if (
      currentStep === CampaignSideNavStep.mainInformation &&
      mainInfoRef.current
    ) {
      mainInfoRef.current.scrollIntoView({ behavior: "smooth" });
    } else if (currentStep === CampaignSideNavStep.media && mediaRef.current) {
      mediaRef.current.scrollIntoView({ behavior: "smooth" });
    } else if (currentStep === CampaignSideNavStep.labels && labelRef.current) {
      labelRef.current.scrollIntoView({ behavior: "smooth" });
    } else if (
      currentStep === CampaignSideNavStep.aboutProperty &&
      aboutPropertyRef.current
    ) {
      aboutPropertyRef.current.scrollIntoView({ behavior: "smooth" });
    } else if (
      currentStep === CampaignSideNavStep.aboutDeveloper &&
      aboutDeveloperRef.current
    ) {
      aboutDeveloperRef.current.scrollIntoView({ behavior: "smooth" });
    } else if (
      currentStep === CampaignSideNavStep.contactInformation &&
      contactInformationRef.current
    ) {
      contactInformationRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [currentStep]);

  const [allFieldsErrors, setAllFieldsErrors] = React.useState<any>({});

  const handleSaveKeyFeatures = async () => {
    setIsAutosaveLoading(true);

    try {
      let agentId = selectedUserId;
      if (selectedUserId === "create-new") {
        const agentData = {
          nameEn: selectedAgent.nameEn,
          nameHe: selectedAgent.nameHe,
          phone: selectedAgent.phone,
          email: "test@example.com",
          photo: agentAvatarSlice[0]?.id || selectedAgent?.photo?.url || [],
          link: selectedAgent.link,
        };

        const agentResponse = await createAgent({
          agentData,
          locale: "en",
        }).unwrap();

        agentId = agentResponse.data.id;

        dispatch(setSelectedUserId(agentResponse.data.id));
      } else {
        const agentData = {
          nameEn: selectedAgent.nameEn,
          nameHe: selectedAgent.nameHe,
          phone: selectedAgent.phone,
          email: "test@example.com",
          photo: agentAvatarSlice[0]?.id || selectedAgent?.photo?.id || [],
          link: selectedAgent.link,
        };
        await updateAgent({
          agentData,
          documentId: selectedAgent.documentId,
          locale: "en",
        }).unwrap();
      }

      const currentLanguage = i18n.language;
      let imgIds = extractIds(imgFilesSlice);
      let brochureIds = extractIds(brochureFilesSlice);
      let floorplanIds = extractIds(floorPlanFilesSlice);
      let videoIds = extractIds(videoFilesSlice);
      const propertyData = convertDataToCreateProperty(
        countOfTimePeriods,
        timePeriod,
        propertyTitle,
        propertyDescription,
        propertyStatus,
        currency,
        propertyPrice,
        propertyLocation,
        propertyType,
        aboutPropertyDesc,
        aboutDeveloper,
        keyFeaturesBack.map((el: any) => el?.id),
        hightlightsBack.map((el: any) => el?.id),
        agentId,
        [labelBack.id],
        imgIds,
        brochureIds,
        floorplanIds,
        videoIds,
        documentId ? campaignStatus : CampaignCreatingStatus.DRAFT,
        mainLabelOpen,
        floorPlanSectionOpen,
        mapSectionOpen,
        videoSectionOpen,
        scheduleVewingOpen,
        agentPhotOpen,
        galleryOpen,
        user
      );
      if (documentId) {
        await updateProperty({
          propertyData: {
            key_features: keyFeaturesBack.map((el: any) => el?.id),
          },
          documentId: documentId,
          language: currentLanguage,
        }).unwrap();
        await updateProperty({
          propertyData: {
            key_features: keyFeaturesBack.map((el: any) => el?.id),
          },
          documentId: documentId,
          language: currentLanguage === "en" ? "he" : "en",
        }).unwrap();
      } else {
        const response = await createProperty({
          propertyData,
          language: currentLanguage,
        }).unwrap();

        await updateProperty({
          propertyData: {
            ...propertyData,
            name: "",
            description: "",
            developer: "",
            aboutProperty: "",
            location: {
              city: "",
              address: "",
              coordinates: {
                latitude: null,
                longitude: null,
              },
              id: "",
            },
          },
          documentId: response.data.documentId,
          language: currentLanguage === "en" ? "he" : "en",
        }).unwrap();
        await navigate(`/campaign-page-builder/${response.data.documentId}`, {
          state: {
            locale: language === "en" ? "hebrew" : "english",
          },
        });
      }
      setIsAutosaveLoading(false);
      setOpenSnackbar(true);
      setSnackbarType(NotificationStatus.SUCCESSFULL);
      setSnackbarText(t("key_feature_was_saved"));
      setCurrentStep(CampaignSideNavStep.keyFeatures);
    } catch (e) {
      console.log("log: ", e);
      setIsAutosaveLoading(false);
    }
  };

  const handleSaveLabel = async () => {
    setIsAutosaveLoading(true);

    try {
      let agentId = selectedUserId;
      if (selectedUserId === "create-new") {
        const agentData = {
          nameEn: selectedAgent.nameEn,
          nameHe: selectedAgent.nameHe,
          phone: selectedAgent.phone,
          email: "test@example.com",
          photo: agentAvatarSlice[0]?.id || selectedAgent?.photo?.url || [],
          link: selectedAgent.link,
        };

        const agentResponse = await createAgent({
          agentData,
          locale: "en",
        }).unwrap();

        agentId = agentResponse.data.id;

        dispatch(setSelectedUserId(agentResponse.data.id));
      } else {
        const agentData = {
          nameEn: selectedAgent.nameEn,
          nameHe: selectedAgent.nameHe,
          phone: selectedAgent.phone,
          email: "test@example.com",
          photo: agentAvatarSlice[0]?.id || selectedAgent?.photo?.id || [],
          link: selectedAgent.link,
        };
        await updateAgent({
          agentData,
          documentId: selectedAgent.documentId,
          locale: "en",
        }).unwrap();
      }

      const currentLanguage = i18n.language;
      let imgIds = extractIds(imgFilesSlice);
      let brochureIds = extractIds(brochureFilesSlice);
      let floorplanIds = extractIds(floorPlanFilesSlice);
      let videoIds = extractIds(videoFilesSlice);
      const propertyData = convertDataToCreateProperty(
        countOfTimePeriods,
        timePeriod,
        propertyTitle,
        propertyDescription,
        propertyStatus,
        currency,
        propertyPrice,
        propertyLocation,
        propertyType,
        aboutPropertyDesc,
        aboutDeveloper,
        keyFeaturesBack.map((el: any) => el?.id),
        hightlightsBack.map((el: any) => el?.id),
        agentId,
        [labelBack.id],
        imgIds,
        brochureIds,
        floorplanIds,
        videoIds,
        documentId ? campaignStatus : CampaignCreatingStatus.DRAFT,
        mainLabelOpen,
        floorPlanSectionOpen,
        mapSectionOpen,
        videoSectionOpen,
        scheduleVewingOpen,
        agentPhotOpen,
        galleryOpen,
        user
      );
      if (documentId) {
        await updateProperty({
          propertyData: {
            propertyLabels: [labelBack.id],
          },
          documentId: documentId,
          language: currentLanguage,
        }).unwrap();
        await updateProperty({
          propertyData: {
            propertyLabels: [labelBack.id],
          },
          documentId: documentId,
          language: currentLanguage === "en" ? "he" : "en",
        }).unwrap();
      } else {
        const response = await createProperty({
          propertyData,
          language: currentLanguage,
        }).unwrap();

        await updateProperty({
          propertyData: {
            ...propertyData,
            name: "",
            description: "",
            developer: "",
            aboutProperty: "",
            location: {
              city: "",
              address: "",
              coordinates: {
                latitude: null,
                longitude: null,
              },
              id: "",
            },
          },
          documentId: response.data.documentId,
          language: currentLanguage === "en" ? "he" : "en",
        }).unwrap();
        await navigate(`/campaign-page-builder/${response.data.documentId}`, {
          state: {
            locale: language === "en" ? "hebrew" : "english",
          },
        });
      }
      setOpenSnackbar(true);
      setSnackbarType(NotificationStatus.SUCCESSFULL);
      setSnackbarText(t("label_was_saved"));
      setCurrentStep(CampaignSideNavStep.labels);

      setIsAutosaveLoading(false);
    } catch (e) {
      console.log("log: ", e);
      setIsAutosaveLoading(false);
    }
  };

  const handleSaveHighlights = async () => {
    setIsAutosaveLoading(true);

    try {
      let agentId = selectedUserId;
      if (selectedUserId === "create-new") {
        const agentData = {
          nameEn: selectedAgent.nameEn,
          nameHe: selectedAgent.nameHe,
          phone: selectedAgent.phone,
          email: "test@example.com",
          photo: agentAvatarSlice[0]?.id || selectedAgent?.photo?.url || [],
          link: selectedAgent.link,
        };

        const agentResponse = await createAgent({
          agentData,
          locale: "en",
        }).unwrap();

        agentId = agentResponse.data.id;

        dispatch(setSelectedUserId(agentResponse.data.id));
      } else {
        const agentData = {
          nameEn: selectedAgent.nameEn,
          nameHe: selectedAgent.nameHe,
          phone: selectedAgent.phone,
          email: "test@example.com",
          photo: agentAvatarSlice[0]?.id || selectedAgent?.photo?.id || [],
          link: selectedAgent.link,
        };
        await updateAgent({
          agentData,
          documentId: selectedAgent.documentId,
          locale: "en",
        }).unwrap();
      }

      const currentLanguage = i18n.language;
      let imgIds = extractIds(imgFilesSlice);
      let brochureIds = extractIds(brochureFilesSlice);
      let floorplanIds = extractIds(floorPlanFilesSlice);
      let videoIds = extractIds(videoFilesSlice);
      const propertyData = convertDataToCreateProperty(
        countOfTimePeriods,
        timePeriod,
        propertyTitle,
        propertyDescription,
        propertyStatus,
        currency,
        propertyPrice,
        propertyLocation,
        propertyType,
        aboutPropertyDesc,
        aboutDeveloper,
        keyFeaturesBack.map((el: any) => el?.id),
        hightlightsBack.map((el: any) => el?.id),
        agentId,
        [labelBack.id],
        imgIds,
        brochureIds,
        floorplanIds,
        videoIds,
        documentId ? campaignStatus : CampaignCreatingStatus.DRAFT,
        mainLabelOpen,
        floorPlanSectionOpen,
        mapSectionOpen,
        videoSectionOpen,
        scheduleVewingOpen,
        agentPhotOpen,
        galleryOpen,
        user
      );

      if (documentId) {
        await updateProperty({
          propertyData: {
            investment_highlights: hightlightsBack.map((el: any) => el?.id),
          },
          documentId: documentId,
          language: currentLanguage,
        }).unwrap();
        await updateProperty({
          propertyData: {
            investment_highlights: hightlightsBack.map((el: any) => el?.id),
          },
          documentId: documentId,
          language: currentLanguage === "en" ? "he" : "en",
        }).unwrap();
      } else {
        const response = await createProperty({
          propertyData,
          language: currentLanguage,
        }).unwrap();

        await updateProperty({
          propertyData: {
            ...propertyData,
            name: "",
            description: "",
            developer: "",
            aboutProperty: "",
            location: {
              city: "",
              address: "",
              coordinates: {
                latitude: null,
                longitude: null,
              },
              id: "",
            },
          },
          documentId: response.data.documentId,
          language: currentLanguage === "en" ? "he" : "en",
        }).unwrap();
        await navigate(`/campaign-page-builder/${response.data.documentId}`, {
          state: {
            locale: language === "en" ? "hebrew" : "english",
          },
        });
      }

      setOpenSnackbar(true);
      setSnackbarType(NotificationStatus.SUCCESSFULL);
      setSnackbarText(t("investment_highlight_was_saved"));
      setCurrentStep(CampaignSideNavStep.investmentHighlights);

      setIsAutosaveLoading(false);
    } catch (e) {
      console.log("log: ", e);
      setIsAutosaveLoading(false);
    }
  };

  const initialStep = CampaignSideNavStep.campaignSetUp;

  useEffect(() => {
    if (location.pathname === "/campaign-page-builder") {
      localStorage.removeItem("currentStep");
      setCurrentStep(initialStep);
    }
  }, [location.pathname, initialStep]);

  React.useEffect(() => {
    dispatch(setPageView(false));
  }, []);

  return (
    <div
      style={{
        display: "flex",
        height: "100vh",
        width: "100%",
        position: "relative",
        overflow: "hidden",
        direction: i18n.dir(),
      }}
    >
      <div
        style={{
          width: "100%",
          marginRight: maxWidth1070px
            ? 0
            : campaignBuilderOpen && !isRTL
            ? maxWidth1220px
              ? campaignBuilderDrawerWidthMobile
              : campaignBuilderDrawerWidthDesktop
            : 0,
          marginLeft: maxWidth1070px
            ? 0
            : campaignBuilderOpen && isRTL
            ? maxWidth1220px
              ? campaignBuilderDrawerWidthMobile
              : campaignBuilderDrawerWidthDesktop
            : 0,
          transition: "margin-right 0.3s",
          overflow: "auto",
        }}
      >
        {!maxWidth1070px && !campaignBuilderOpen && (
          <div
            className="portfolio-header-sticky-wrapper"
            ref={builderHeaderRef}
          >
            <div className="mode-switcher-wrapper">
              <PreviewModeSwiter />

              {PreviewMode.DESKTOP === previewMode && (
                <Header disabled={true} isDisplayBack={true}>
                  <Navbar disabled={true} />
                </Header>
              )}
            </div>
          </div>
        )}

        {maxWidth1070px && (
          <CampaignBuilderMobileHeader
            builderMobileHeaderRef={builderMobileHeaderRef}
          />
        )}

        <CampainBuilderMainContainer
          investmentHighlightsRef={investmentHighlightsRef}
          keyFeaturesRef={keyFeaturesRef}
          autosaveFunc={handleAutosaveCampaign}
          isDisabledSwitchLang={isAutosaveLoading}
          settingRef={settingRef}
          mainInfoRef={mainInfoRef}
          mediaRef={mediaRef}
          labelRef={labelRef}
          aboutPropertyRef={aboutPropertyRef}
          aboutDeveloperRef={aboutDeveloperRef}
          contactInformationRef={contactInformationRef}
          property={propertyData}
          isRTL={isRTL}
          currentStep={currentStep}
          builderHeaderRef={builderHeaderRef}
          builderMobileHeaderRef={builderMobileHeaderRef}
        />
      </div>

      <CampaignBuilderSideNav
        currentStep={currentStep}
        setCurrentStep={setCurrentStep}
        setImgFiles={setImgFiles}
        setNewAgentAvatar={setNewAgentAvatar}
        setBrochureFiles={setBrochureFiles}
        setFloorplanFiles={setFloorplanFiles}
        setVideoFiles={setVideoFiles}
        isImgsLoading={isImgsLoading || isCompressing}
        isBrochureLoading={isBrochureLoading}
        isVideoLoading={isVideoLoading}
        isFloorPlanLoading={isFloorPlanLoading || isCompressing}
        isAvatarLoading={isAvatarLoading || isCompressing}
        allFieldsErrors={allFieldsErrors}
        setAllFieldsErrors={setAllFieldsErrors}
        autosaveFunc={handleAutosaveCampaign}
        isDisabledSwitchLang={isAutosaveLoading}
        handleSaveHighlights={handleSaveHighlights}
        handleSaveKeyFeatures={handleSaveKeyFeatures}
        handleSaveLabel={handleSaveLabel}
      />

      <NotificationSnackBar
        snackbarText={snackbarText}
        onClose={() => setOpenSnackbar(false)}
        type={snackBarType}
        open={openSnackbar}
      />

      <NotificationSnackBar
        snackbarText={deleteHightlightMessage}
        onClose={() => dispatch(setDeleteHighlightClose())}
        type={NotificationStatus.SUCCESSFULL}
        open={deleteHighlightOpen}
      />

      <NotificationSnackBar
        snackbarText={toggleVisibilityNotificationMessage}
        onClose={() => dispatch(closeToggleVisibilityNotification())}
        type={NotificationStatus.SUCCESSFULL}
        open={toggleVisibilityNotification}
      />
    </div>
  );
};
