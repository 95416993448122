import { Theme, useMediaQuery } from "@mui/material";
import { useTranslation } from "react-i18next";
import {
  formatDateWithMonthByLocale,
  truncateStringWithEllipsis,
} from "../../../helpers/formatter";
import { useNavigate } from "react-router-dom";
import DefaultArticleImg from "../../../assets/learningCenter/building.png";
import { IArticle } from "../interfaces";

export const MustReadMainCard = ({
  article,
  descTrancateLength,
}: {
  article: IArticle;
  descTrancateLength: number;
}) => {
  const { i18n, t } = useTranslation(["learningCenter"]);
  const downMd = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));
  const downLg = useMediaQuery((theme: Theme) => theme.breakpoints.down("lg"));

  const isRTL = i18n.dir() === "rtl";
  const navigate = useNavigate();

  return (
    <div
      className="must-read-main-arcticle-card"
      style={{
        background: `url(${
          !article?.mediaContent || article?.mediaContent?.length === 0
            ? DefaultArticleImg
            : article?.mediaContent[0]?.url
        }) center center / cover no-repeat`,
        padding: downLg ? "15px 15px 50px 15px" : "30px 30px 34px 31px",
        direction: i18n.dir(),
      }}
      onClick={() => navigate(`/learning-center/${article.documentId}`)}
    >
      <div className="must-read-main-arcticle-card-info-wrapper">
        <div
          className="clue-container"
          style={{
            marginBottom: "10px",
          }}
        >
          {article?.tags.map((tag, index) => (
            <div className="clue-white-blue" key={index}>
              {t(tag.title)}
            </div>
          ))}
        </div>

        <div className="main-yellow-acticle-additional-info-wrapper">
          <div>
            {formatDateWithMonthByLocale(
              article?.createdAt,
              isRTL ? "he-IL" : "en-US"
            )}
          </div>
          <div className="main-yellow-acticle-additional-info-line"></div>
          <div>
            {article?.minutesToRead} {t("min_read")}
          </div>
        </div>

        <div
          className="must-read-card-title"
          style={{
            fontFamily: isRTL ? "Noto Sans Hebrew" : "Frank Ruhl Libre",
            fontSize: downMd ? "18px" : "22px",
            marginTop: downMd ? "15px" : "10px",
            marginBottom: downMd ? "10px" : "15px",
          }}
        >
          {article?.title}
        </div>

        <div className="must-read-card-desc">
          {truncateStringWithEllipsis(
            article?.shortDescription,
            descTrancateLength
          )}
        </div>
      </div>
    </div>
  );
};
