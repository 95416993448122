import {
  Button,
  InputAdornment,
  Menu,
  MenuItem,
  TextField,
  Theme,
  useMediaQuery,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useAppDispatch } from "../../../../store/store";
import { useLanguageTheme } from "../../../../hooks/useLanguageTheme";
import { useEffect, useRef, useState } from "react";
import {
  KeyFeatureIconMap,
  keyFeatureOptions,
  StairBlueIcon,
} from "../../../../constants/campaignBuilderData";
import {
  useUpdateKeyFeatureMutation,
  useCreateKeyFeatureMutation,
  useLazyGetKeyFeaturesWithParamsQuery,
} from "../../../../store/api/campaignBuilderApiSlice";
import TrashIcon from "../../../../assets/campaignBuilder/trash-icon.svg";
import HideIcon from "../../../../assets/campaignBuilder/eye-off.svg";
import UnhideIcon from "../../../../assets/campaignBuilder/eye.svg";
import { useParams } from "react-router-dom";
import { ExpandMore } from "@mui/icons-material";
import { useResetToDraft } from "../../../../hooks/useResetToDraft";
import { useSelector } from "react-redux";
import { setKeyFeatureBack } from "../../../../store/features/campaignBuilderSlice";
import {
  setDeleteHighlightOpen,
  setToggleVisibilityNotification,
} from "../../../../store/features/campaignBuilderSetup";
import { CampaignSideNavStep } from "../layout/CampaignBuilderSideNav";
import { CampaignBuilderSideNavHeader } from "../layout/CampaignBuilderSideNavHeader";
import { MAX_FEATURE_LENGTH } from "../../constants";
import { IconSelect } from "../selects/IconSelect";
import { textFieldStyles3 } from "./ContactInformation";
import { CampainBuilderBackBtn } from "../buttons/CampainBuilderBackBtn";

export type KeyFetureIconKey = keyof typeof KeyFeatureIconMap;

export interface OptionKeyFeature {
  id: string;
  iconKey: KeyFetureIconKey;
  labelNameEn: string;
  labelNameHe: string;
  type: string;
}

export const AddNewFeature = ({
  setCurrentStep,
  keyfeatureOptionsState,
  selectedFeature,
  setSelectedFeature,
  handleSaveFeature,
  featuresForEditId,
}: {
  setCurrentStep: (step: CampaignSideNavStep) => void;
  keyfeatureOptionsState: any;
  selectedFeature: any;
  setSelectedFeature: any;
  handleSaveFeature: any;
  featuresForEditId: any;
}) => {
  const langTheme = useLanguageTheme();
  const { t, i18n } = useTranslation(["campaignBuilder"]);
  const currentLanguage = i18n.language;
  const isRTL = currentLanguage === "he";
  const downMd = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));

  const [createKeyFeature] = useCreateKeyFeatureMutation();
  const [updateKeyFeature] = useUpdateKeyFeatureMutation();
  const [triggerKeyFeaturesWithParams] = useLazyGetKeyFeaturesWithParamsQuery();

  const dispatch = useAppDispatch();
  const dropdownRef = useRef<any>(null);
  const [debounceTrigger, setDebounceTrigger] = useState(false);
  const userId = JSON.parse(
    localStorage.getItem("user") || sessionStorage.getItem("user") || "{}"
  )?.id;
  const { documentId } = useParams();

  const { keyFeaturesBack } = useSelector(
    (state: any) => state.campaignBuilder
  );

  const resetToDraft = useResetToDraft();

  const [openCustomDropdown, setOpenCustomDropdown] = useState(false);
  const componentRef = useRef<any>(null);
  const highlightDefConfig = {
    id: 0,
    labelNameEn: "",
    labelNameHe: "",
    iconKey: "stair",
    authorId: userId,
    type: "custom",
    details: {},
  };
  const [editFeatureParams, setEditFeatureParams] =
    useState<any>(highlightDefConfig);

  const [anchorEl, setAnchorEl] = useState<any>(null);

  const createFeature = async (creationParams?: any) => {
    let params = {} as any;
    const optionData = {
      labelNameEn: "",
      labelNameHe: "",
      iconKey: "stair",
      authorId: userId,
      type: "custom",
      details: {},
    };
    params = await createKeyFeature({
      optionData: creationParams ?? optionData,
    });
    params = params.data.data;
    resetToDraft();
    localStorage.setItem("onChangeCompanyBuilder", "1");
    return params;
  };

  useEffect(() => {
    const editKeyFeatureId = localStorage.getItem("editKeyFeatureHighlightId");
    if (
      editKeyFeatureId &&
      keyfeatureOptionsState &&
      Array.isArray(keyfeatureOptionsState) &&
      keyfeatureOptionsState.length > 0 &&
      editKeyFeatureId != "0"
    ) {
      const highlightParams = keyfeatureOptionsState.find(
        (el: any) => el.id == editKeyFeatureId
      ) as any;
      setEditFeatureParams(highlightParams);
    }
  }, [keyfeatureOptionsState]);

  const handleDeleteOption = async (documentId: string) => {
    dispatch(
      setSelectedFeature(
        selectedFeature.filter((el: any) => el.documentId !== documentId)
      )
    );
    resetToDraft();
    localStorage.setItem("onChangeCompanyBuilder", "1");
    dispatch(
      setDeleteHighlightOpen({
        deleteHightlightMessage: t("key_feature_was_deleted"),
      })
    );
    triggerKeyFeaturesWithParams({ userId });
    setCurrentStep(CampaignSideNavStep.keyFeatures);
  };

  function updateItemById(array: any[], id: number, newData: any) {
    return array.map((item) =>
      item.id === id ? { ...item, ...newData } : item
    );
  }
  const handleHideOption = async (
    documentIdFeature: string,
    isHide = true,
    params: any
  ) => {
    let hideParams = { ...params.details };
    if (isHide) {
      hideParams = { ...hideParams, [documentId ?? ""]: documentIdFeature };
    } else {
      delete hideParams[documentId ?? ""];
    }

    await updateKeyFeature({
      documentId: documentIdFeature,
      optionData: {
        details: hideParams,
      },
    });
    setEditFeatureParams((prev: any) => ({ ...prev }));
    dispatch(
      setSelectedFeature(
        updateItemById(selectedFeature, editFeatureParams.id, {
          details: hideParams,
        })
      )
    );

    dispatch(
      setToggleVisibilityNotification({
        toggleVisibilityNotificationMessage: isHide
          ? t("key_feature_hided")
          : t("key_feature_showed"),
      })
    );
    localStorage.setItem("onChangeCompanyBuilder", "1");
    resetToDraft();
    triggerKeyFeaturesWithParams({ userId });
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      fetchData(editFeatureParams);
    }, 1000);

    return () => clearTimeout(timeoutId);
  }, [debounceTrigger]);

  const fetchData = async (params: any) => {
    try {
      const obj = { ...params };
      const documentId = obj.documentId;
      if (documentId) {
        if (obj.type === "custom") {
          await updateKeyFeature({
            documentId: documentId,
            optionData: {
              iconKey: obj.iconKey,
              labelNameEn: obj.labelNameEn,
              labelNameHe: obj.labelNameHe,
            },
          });
        } else {
          params = await createFeature({
            authorId: userId,
            backgroundColor: obj.backgroundColor,
            iconKey: obj.iconKey,
            labelNameEn: obj.labelNameEn,
            labelNameHe: obj.labelNameHe,
            locale: obj.locale,
            type: "custom",
          });
        }
        setEditFeatureParams(params);
        triggerKeyFeaturesWithParams({ userId });
        localStorage.setItem("editKeyFeatureHighlightId", params.id.toString());
        dispatch(
          setSelectedFeature(
            updateItemById(selectedFeature, editFeatureParams.id, {
              ...params,
            })
          )
        );
      } else {
        if (
          (i18n.language === "he" && editFeatureParams.labelNameHe) ||
          (i18n.language === "en" && editFeatureParams.labelNameEn) ||
          highlightDefConfig.iconKey !== editFeatureParams.iconKey
        ) {
          let params = await createFeature({
            authorId: userId,
            iconKey: editFeatureParams.iconKey,
            labelNameEn: editFeatureParams.labelNameEn,
            labelNameHe: editFeatureParams.labelNameHe,
            type: "custom",
          });
          triggerKeyFeaturesWithParams({ userId });
          setEditFeatureParams(params);
          localStorage.setItem(
            "editKeyFeatureHighlightId",
            params.id.toString()
          );
          dispatch(
            setSelectedFeature(
              updateItemById(selectedFeature, editFeatureParams.id, {
                ...params,
              })
            )
          );
        }
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleSelect = (option: any, id: number) => {
    setEditFeatureParams(option);
    dispatch(
      setSelectedFeature(updateItemById(selectedFeature, id, { ...option }))
    );
    localStorage.setItem("onChangeCompanyBuilder", "1");
    resetToDraft();
    setOpenCustomDropdown((prev) => !prev);
  };

  const handleBackBtn = (step: CampaignSideNavStep) => {
    setCurrentStep(step);
    localStorage.removeItem("editKeyFeatureHighlightId");
    if (keyFeaturesBack) {
      const filteredKeyFeatures = keyFeaturesBack.filter(
        (item: any) => item.id !== 0
      );
      dispatch(setKeyFeatureBack(filteredKeyFeatures));
    }
  };

  return (
    <div className="campaign-builder-side-nav-wrapper">
      <CampaignBuilderSideNavHeader
        header="add_a_new_feature"
        leftBtn={
          <CampainBuilderBackBtn
            setCurrentStep={(step) => {
              handleBackBtn(step);
            }}
            step={CampaignSideNavStep.keyFeatures}
          />
        }
        rightBtn={<div className="campaign-builder-sidenav-empty-btn"></div>}
      />

      <div
        className="campaign-builder-sidenav-main-content-wrapper"
        style={{
          paddingTop: downMd ? "30px" : "40px",
          direction: i18n.dir(),
        }}
        ref={componentRef}
      >
        <div className="campaign-builder-sidenav-settings-fileds-container">
          <div className="campaign-builder-sidenav-settings-fileds-item highlight-add-update-elem-wrapper">
            <div className="highlight-add-update-elem">
              <div
                className="campaign-builder-sidenav-settings-fileds-item"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 20,
                }}
              >
                <div>
                  <div>
                    <Button
                      variant="outlined"
                      onClick={(event) => {
                        if (event.detail === 0) return;
                        setOpenCustomDropdown((prev) => !prev);
                        setAnchorEl(event.currentTarget);
                      }}
                      disableRipple
                      ref={dropdownRef}
                      sx={{
                        width: "100%",
                        justifyContent: "space-between",
                        textTransform: "none",
                        borderRadius: "16px",
                        height: 53,
                        paddingLeft: 0,
                        paddingRight: 0,
                        backgroundColor: "#fff",
                        borderColor: "#fff",
                        color: "#000",
                        zIndex: 14,
                      }}
                      endIcon={
                        <ExpandMore
                          style={{
                            marginRight: isRTL ? "15px" : "20px",
                            marginLeft: isRTL ? "20px" : "15px",
                          }}
                        />
                      }
                    >
                      <TextField
                        fullWidth
                        placeholder={t("message_placeholder")}
                        value={
                          editFeatureParams
                            ? editFeatureParams[
                                currentLanguage === "he"
                                  ? "labelNameHe"
                                  : "labelNameEn"
                              ]
                            : ""
                        }
                        onKeyDown={(e) => {
                          if (e.key === " ") {
                            e.stopPropagation();
                          }
                        }}
                        onClick={(e) => {
                          e.stopPropagation();
                          e.preventDefault();
                        }}
                        onChange={(e) => {
                          e.stopPropagation();
                          e.preventDefault();
                          setEditFeatureParams((prev: any) => ({
                            ...prev,
                            labelNameEn:
                              currentLanguage === "en"
                                ? e.target.value
                                : undefined,
                            labelNameHe:
                              currentLanguage === "he"
                                ? e.target.value
                                : undefined,
                          }));

                          setDebounceTrigger((prev) => !prev);
                        }}
                        sx={textFieldStyles3(langTheme.mainFont(), isRTL)}
                        autoComplete="off"
                        inputProps={{
                          maxLength: MAX_FEATURE_LENGTH,
                        }}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment
                              sx={{
                                height: "100%",
                                ml: 0,
                                maxHeight: 50,
                                marginRight: isRTL ? "-14px" : "0",
                                pl: isRTL ? "20px" : "0px",
                                pr: isRTL ? "-14px" : "-14px",
                                color: "rgba(0, 0, 0, 1)",
                                zIndex: 15,
                                backgroundColor: "transparent",
                              }}
                              position="start"
                            >
                              <div
                                className="campaign-builder-sidenav-number-of-char"
                                style={{
                                  fontFamily: langTheme.mainFont(),
                                }}
                              >
                                {editFeatureParams
                                  ? editFeatureParams[
                                      currentLanguage === "he"
                                        ? "labelNameHe"
                                        : "labelNameEn"
                                    ]?.length || 0
                                  : 0}
                                /{MAX_FEATURE_LENGTH}
                              </div>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Button>

                    <Menu
                      anchorEl={anchorEl}
                      open={openCustomDropdown}
                      onClose={() => {
                        setOpenCustomDropdown((prev) => !prev);
                      }}
                      PaperProps={{
                        sx: {
                          width: dropdownRef?.current?.offsetWidth,
                          maxHeight: componentRef?.current?.offsetHeight - 110,
                          direction: i18n.dir(),
                        },
                      }}
                    >
                      <div
                        className="campaign-builder-sidenav-field-name"
                        style={{
                          color: "#000",
                          fontFamily: "DM Sans",
                          margin: "10px 15px",
                          fontSize: 16,
                          fontWeight: 500,
                        }}
                      >
                        {t("system_features")}
                      </div>
                      <>
                        {keyfeatureOptionsState
                          .filter(
                            (elem: any) =>
                              !selectedFeature.find(
                                (el: any) => el.id === elem.id
                              ) && elem.type === "system"
                          )
                          .map((option: OptionKeyFeature, index: number) => {
                            const IconComponent =
                              KeyFeatureIconMap[option.iconKey] ||
                              StairBlueIcon;
                            return (
                              <MenuItem
                                key={index}
                                onClick={() =>
                                  handleSelect(option, editFeatureParams?.id)
                                }
                              >
                                <div style={{ display: "flex", gap: 5 }}>
                                  {IconComponent && (
                                    <IconComponent
                                      isSelected={false}
                                      width={17}
                                      height={19}
                                      backgroundColor={"#034A73"}
                                    />
                                  )}
                                  <p
                                    style={{ margin: 0, fontFamily: "DM Sans" }}
                                  >
                                    {
                                      option[
                                        currentLanguage === "he"
                                          ? "labelNameHe"
                                          : "labelNameEn"
                                      ]
                                    }
                                  </p>
                                </div>
                              </MenuItem>
                            );
                          })}
                      </>

                      <div
                        className="campaign-builder-sidenav-field-name"
                        style={{
                          color: "#000",
                          fontFamily: "DM Sans",
                          margin: "25px 15px 10px",
                          fontSize: 16,
                          fontWeight: 500,
                        }}
                      >
                        {t("custom_features")}
                      </div>
                      <>
                        {keyfeatureOptionsState
                          .filter(
                            (elem: any) =>
                              !selectedFeature.find(
                                (el: any) => el.id === elem.id
                              ) && elem.type === "custom"
                          )
                          .map((option: OptionKeyFeature, index: number) => {
                            const IconComponent =
                              KeyFeatureIconMap[option.iconKey] ||
                              StairBlueIcon;
                            return (
                              <MenuItem
                                key={index}
                                onClick={() =>
                                  handleSelect(option, editFeatureParams?.id)
                                }
                              >
                                <div style={{ display: "flex", gap: 5 }}>
                                  {IconComponent && (
                                    <IconComponent
                                      isSelected={false}
                                      width={17}
                                      height={19}
                                      backgroundColor={"#034A73"}
                                    />
                                  )}
                                  <p
                                    style={{ margin: 0, fontFamily: "DM Sans" }}
                                  >
                                    {
                                      option[
                                        currentLanguage === "he"
                                          ? "labelNameHe"
                                          : "labelNameEn"
                                      ]
                                    }
                                  </p>
                                </div>
                              </MenuItem>
                            );
                          })}
                      </>
                    </Menu>
                  </div>
                </div>
              </div>
              <div className="campaign-builder-sidenav-settings-fileds-item">
                <div className="campaign-builder-sidenav-settings-fileds-item">
                  <div className="campaign-builder-sidenav-field-name">
                    {t("choose_icon")}
                  </div>
                  <IconSelect
                    selectedIcon={editFeatureParams?.iconKey}
                    setSelectedIcon={(param) => {
                      setEditFeatureParams((prev: any) => ({
                        ...prev,
                        iconKey: param,
                      }));
                      dispatch(
                        setSelectedFeature(
                          updateItemById(
                            selectedFeature,
                            editFeatureParams.id,
                            { ...editFeatureParams, iconKey: param }
                          )
                        )
                      );
                      setDebounceTrigger((prev) => !prev);
                    }}
                    options={keyFeatureOptions}
                    iconMap={KeyFeatureIconMap}
                  />
                </div>
                <div style={{ display: "flex", gap: 15 }}>
                  <div
                    className="campaign-builder-sidenav-add-new-btn update-highlight-btn"
                    onClick={() =>
                      handleDeleteOption(editFeatureParams?.documentId)
                    }
                    style={{ width: 70 }}
                  >
                    <img src={TrashIcon} />
                    <span>{t("delete")}</span>
                  </div>
                  {documentId ? (
                    <div
                      className="campaign-builder-sidenav-add-new-btn update-highlight-btn"
                      onClick={() =>
                        handleHideOption(
                          editFeatureParams?.documentId,
                          !(
                            editFeatureParams?.details &&
                            editFeatureParams?.details[documentId]
                          ),
                          editFeatureParams
                        )
                      }
                      style={{ width: 70 }}
                    >
                      <img
                        src={
                          editFeatureParams?.details &&
                          editFeatureParams?.details[documentId]
                            ? UnhideIcon
                            : HideIcon
                        }
                        alt="hide"
                        style={{ fill: "#fff" }}
                      />
                      <span>
                        {editFeatureParams?.details &&
                        editFeatureParams?.details[documentId]
                          ? t("show")
                          : t("hide")}
                      </span>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="campaign-builder-sidenav-line" />
            </div>
          </div>
        </div>

        <div
          className={`campaign-builder-sidenav-add-label-btn-big ${
            editFeatureParams.id === 0 ? "disabled" : ""
          }`}
          onClick={async () => {
            if (editFeatureParams.id === 0) return;
            await handleSaveFeature();
          }}
          style={{
            pointerEvents: editFeatureParams.id === 0 ? "none" : "auto",
            opacity: editFeatureParams.id === 0 ? 0.5 : 1,
          }}
        >
          {t("save")}
        </div>
      </div>
    </div>
  );
};
