import { ThemeProvider } from "@emotion/react";
import { Navigate, Outlet, Route, Routes } from "react-router-dom";
import "./App.scss";
import { GoogleMapsWrapper } from "./components/_shared/GoogleMapsWrapper";
import { Layout } from "./components/_shared/Layout";
import { LayoutCampaignPageView } from "./components/_shared/LayoutCampaignPageView";
import { ScrollToTop } from "./components/_shared/ScrollToTop";
import { CampaignBuilder } from "./pages/CampaignBuilder";
import { CampaignPageViewMode } from "./pages/CampaignPageViewMode";
import { ChooseVerification } from "./pages/ChooseVerification";
import { DedicatedLearningCenter } from "./pages/DedicatedLearningCenter";
import { DemoLogin } from "./pages/DemoLogin";
import { EmailConfirmation } from "./pages/EmailConfirmation";
import { FinishGoogle } from "./pages/FinishGoogle";
import { ForgotPassword } from "./pages/ForgotPassword";
import { Home } from "./pages/Home";
import { LearningCenter } from "./pages/LearningCenter";
import { Portfolio } from "./pages/Portfolio";
import Profile from "./pages/Profile";
import { RedirectWithGoogle } from "./pages/RedirectWithGoogle";
import SellWithUs from "./pages/SellWithUs";
import { SignIn } from "./pages/SignIn";
import { Signup } from "./pages/Signup";
import { SmsConfirmation } from "./pages/SmsConfirmation";
import { WishlistDemo } from "./pages/WishlistDemo";
import { muiTheme } from "./theme/create-components";
import { ContactUs } from "./pages/ContactUs";
import { Terms } from "./pages/Terms";

function PrivateRoute() {
  const isAuthenticated = localStorage.getItem("isAuthenticated") === "true";

  if (!isAuthenticated) {
    localStorage.removeItem("isAuthenticated");

    return <Navigate to="/demo-login" />;
  }

  return (
    <>
      <Outlet />
    </>
  );
}

function App() {
  console.log("log : test log ", )
  return (
    <ThemeProvider theme={muiTheme}>
      <div className="App">
        <ScrollToTop />
        <Routes>
          <Route element={<PrivateRoute />}>
            <Route element={<Layout />}>
              <Route
                path="/home"
                element={
                  <GoogleMapsWrapper>
                    <Home />
                  </GoogleMapsWrapper>
                }
              />
              <Route
                path="/portfolio"
                element={
                  <GoogleMapsWrapper>
                    <Portfolio />
                  </GoogleMapsWrapper>
                }
              />
              <Route
                path="/campaign-page-builder/:documentId?"
                element={
                  <GoogleMapsWrapper>
                    <CampaignBuilder />
                  </GoogleMapsWrapper>
                }
              />
              <Route path="/learning-center" element={<LearningCenter />} />
              <Route
                path="/learning-center/:documentId"
                element={<DedicatedLearningCenter />}
              />
              <Route path="/profile" element={<Profile />} />
              <Route
                path="/wishlist"
                element={
                  <GoogleMapsWrapper>
                    <WishlistDemo />
                  </GoogleMapsWrapper>
                }
              />
              <Route path="/contact-us" element={<ContactUs />} />
              <Route path="/terms" element={<Terms />} />
            </Route>
            <Route element={<LayoutCampaignPageView />}>
              <Route
                path="/campaign-page-view-mode/:documentId?"
                element={<CampaignPageViewMode />}
              />
            </Route>
            <Route path="sell-with-us" element={<SellWithUs />} />
          </Route>

          <Route>
            <Route path="/demo-login" element={<DemoLogin />} />
            <Route path="/" element={<Navigate to="/home" />} />
            <Route path="/signin" element={<SignIn />} />
            <Route path="/signup" element={<Signup />} />
            <Route path="/forgotpassword" element={<ForgotPassword />} />
            <Route
              path="/confirm-email/:email"
              element={<EmailConfirmation />}
            />
            <Route
              path="/confirm-sms/:phoneNumber"
              element={<SmsConfirmation />}
            />
            <Route
              path="/connect/google/redirect"
              element={<RedirectWithGoogle />}
            />
            <Route path="/connect/google/finish" element={<FinishGoogle />} />
            <Route
              path="/choose-verification/:email/:phoneNumber"
              element={<ChooseVerification />}
            />
          </Route>
        </Routes>
      </div>
    </ThemeProvider>
  );
}

export default App;
