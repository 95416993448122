import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  Button,
  useMediaQuery,
  Theme,
  Typography,
} from "@mui/material";
import { Property } from "../../helpers/convert";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { useLanguageTheme } from "../../hooks/useLanguageTheme";
import { CampaignComparePropertiesProps } from "./interfaces";
import { VISIBLE_ROWS_TO_COMAPARE_PROP } from "./constants";

export const CampaignCompareProperties: React.FC<
  CampaignComparePropertiesProps
> = ({ data, triggerGetProperty, language, city }) => {
  const { t, i18n } = useTranslation(["campaignView"]);
  const langTheme = useLanguageTheme();
  const isRTL = i18n.dir() === "rtl";
  const downMd = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));

  const navigate = useNavigate();

  return data.length > 0 ? (
    <div
      className="campaign-view-mode-table-wrapper"
      style={{
        fontFamily: langTheme.mainFont(),
        marginTop: downMd ? "70px" : "130px",
        marginBottom: downMd ? "70px" : "130px",
      }}
    >
      <div className="compare-title-wrapper">
        <div className="compare-title-container">
          <p
            className="compare-properties-title"
            style={{
              fontFamily: langTheme.mainFont(),
            }}
          >
            {t("compareProperties")}
          </p>
          <p
            className="compare-properties-desc"
            style={{
              fontFamily: isRTL ? "Noto Sans Hebrew" : "Frank Ruhl Libre",
            }}
          >
            {t("similarProp") + " " + city}
          </p>
        </div>
      </div>
      <div
        style={{ overflowX: "auto", direction: i18n.dir() }}
        className="table-cammaign-view-custom-scrollbar"
      >
        <TableContainer component={Paper} sx={{}}>
          <Table
            style={{ minWidth: 650, direction: i18n.dir(), border: "none" }}
          >
            <TableRow
              className={i18n.dir() === "rtl" ? "table-row-he" : "table-row"}
            >
              <TableCell
                key={0}
                align={i18n.dir() === "rtl" ? "right" : "left"}
                sx={{
                  border: "none",
                }}
              />
              {data
                .slice(0, VISIBLE_ROWS_TO_COMAPARE_PROP)
                .map((item: Property, index: number) => (
                  <TableCell
                    key={index + 1}
                    align={i18n.dir() === "rtl" ? "right" : "left"}
                    sx={{
                      verticalAlign: "top",
                      position: "relative",
                      paddingBottom: "80px",
                      border: "none",
                    }}
                    className="table-img-cell"
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: 15,
                      }}
                    >
                      <div
                        className="table-img"
                        style={{
                          background: `url(${item?.mainImage?.url}) center center / cover no-repeat`,
                        }}
                      ></div>
                      <p
                        className="main-title"
                        style={{
                          fontSize: downMd ? "18px" : "22px",
                          fontFamily: isRTL
                            ? "Noto Sans Hebrew"
                            : "Frank Ruhl Libre",
                        }}
                      >
                        {item.name}
                      </p>
                    </div>
                    <Button
                      variant="contained"
                      className="table-btn"
                      sx={{
                        position: "absolute",
                        bottom: 40,
                        textTransform: "capitalize",
                        fontFamily: langTheme.mainFont(),
                        fontSize: "16px",
                        zIndex: 1,
                      }}
                      onClick={() => {
                        triggerGetProperty({
                          documentId: item.documentId,
                          locale: language.slice(0, 2) ?? "en",
                        });
                        navigate(`/campaign-page-view-mode/${item.documentId}`);
                        window.location.reload();
                      }}
                    >
                      {t("seeMore")}
                    </Button>
                  </TableCell>
                ))}
            </TableRow>
            <TableBody
              sx={{
                fontFamily: langTheme.mainFont(),
                border: "none",
              }}
            >
              <TableRow
                style={{
                  backgroundColor: "rgba(235, 240, 244, 1)",
                  fontFamily: langTheme.mainFont(),
                  border: "none",
                }}
                className={i18n.dir() === "rtl" ? "table-row-he" : "table-row"}
              >
                <TableCell
                  key={0}
                  align={i18n.dir() === "rtl" ? "right" : "left"}
                  className="highlight-text campaign-view-sticky-col"
                  style={{
                    right: isRTL ? 0 : "auto",
                    left: isRTL ? "auto" : 0,
                    backgroundColor: "rgba(235, 240, 244, 1)",
                    border: "none",
                  }}
                >
                  <Typography
                    variant="h4"
                    sx={{
                      fontFamily: langTheme.mainFont(),
                    }}
                  >
                    {t("price")}
                  </Typography>
                </TableCell>
                {data
                  .slice(0, VISIBLE_ROWS_TO_COMAPARE_PROP)
                  .map((item: Property, index: number) => (
                    <TableCell
                      key={index + 1}
                      align={i18n.dir() === "rtl" ? "right" : "left"}
                      sx={{
                        fontFamily: langTheme.mainFont(),
                        border: "none",
                      }}
                    >
                      ₪{item.pricing.basePrice}
                    </TableCell>
                  ))}
              </TableRow>
              <TableRow
                className={i18n.dir() === "rtl" ? "table-row-he" : "table-row"}
              >
                <TableCell
                  key={0}
                  align={i18n.dir() === "rtl" ? "right" : "left"}
                  className="highlight-text campaign-view-sticky-col"
                  style={{
                    right: isRTL ? 0 : "auto",
                    left: isRTL ? "auto" : 0,
                    background: "white",
                    border: "none",
                  }}
                >
                  <Typography
                    variant="h4"
                    sx={{
                      fontFamily: langTheme.mainFont(),
                    }}
                  >
                    {t("address")}
                  </Typography>
                </TableCell>
                {data
                  .slice(0, VISIBLE_ROWS_TO_COMAPARE_PROP)
                  .map((item: Property, index: number) => (
                    <TableCell
                      key={index}
                      align={i18n.dir() === "rtl" ? "right" : "left"}
                      sx={{
                        fontFamily: langTheme.mainFont(),
                        border: "none",
                      }}
                    >
                      {item.location.address}
                    </TableCell>
                  ))}
              </TableRow>
              {/* <TableRow
                style={{ backgroundColor: "rgba(235, 240, 244, 1)" }}
                className={i18n.dir() === "rtl" ? "table-row-he" : "table-row"}
              >
                <TableCell
                  key={0}
                  align={i18n.dir() === "rtl" ? "right" : "left"}
                  className="highlight-text campaign-view-sticky-col"
                  style={{
                    right: isRTL ? 0 : "auto",
                    left: isRTL ? "auto" : 0,
                    backgroundColor: "rgba(235, 240, 244, 1)",
                    border: "none",
                  }}
                >
                  <Typography
                    variant="h4"
                    sx={{
                      fontFamily: langTheme.mainFont(),
                    }}
                  >
                    {t("developer")}
                  </Typography>
                </TableCell>
                {data
                  .slice(0, VISIBLE_ROWS_TO_COMAPARE_PROP)
                  .map((item: Property, index: number) => (
                    <TableCell
                      key={index}
                      align={i18n.dir() === "rtl" ? "right" : "left"}
                      sx={{
                        fontFamily: langTheme.mainFont(),
                        border: "none",
                      }}
                    >
                      {t("name")}
                    </TableCell>
                  ))}
              </TableRow> */}
              <TableRow
                className={i18n.dir() === "rtl" ? "table-row-he" : "table-row"}
                style={{ backgroundColor: "rgba(235, 240, 244, 1)" }}
              >
                <TableCell
                  key={0}
                  align={i18n.dir() === "rtl" ? "right" : "left"}
                  className="highlight-text campaign-view-sticky-col"
                  style={{
                    right: isRTL ? 0 : "auto",
                    left: isRTL ? "auto" : 0,
                    backgroundColor: "rgba(235, 240, 244, 1)",

                    border: "none",
                  }}
                >
                  <Typography
                    variant="h4"
                    sx={{
                      fontFamily: langTheme.mainFont(),
                    }}
                  >
                    {t("type")}
                  </Typography>
                </TableCell>
                {data
                  .slice(0, VISIBLE_ROWS_TO_COMAPARE_PROP)
                  .map((item: Property, index: number) => (
                    <TableCell
                      key={index}
                      align={i18n.dir() === "rtl" ? "right" : "left"}
                      sx={{
                        fontFamily: langTheme.mainFont(),
                        border: "none",
                      }}
                    >
                      {t(item.type)}
                    </TableCell>
                  ))}
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  ) : (
    // </div>
    <></>
  );
};
