import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-http-backend";

if (!localStorage.getItem("i18nextLng")) {
  localStorage.setItem("i18nextLng", "en");
}

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    backend: {
      loadPath: "/locales/{{lng}}/{{ns}}.json",
    },
    fallbackLng: "en",
    lng: localStorage.getItem("i18nextLng") || "en",
    ns: ["navbar", "home", "portfolio", "signin", "filters", "campaignBuilder"],
    defaultNS: "home",
    interpolation: {
      escapeValue: false,
    },
    detection: {
      order: ["localStorage", "navigator"],
      caches: ["localStorage"],
    },
  });

export default i18n;
