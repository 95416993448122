import { useNavigate } from "react-router-dom";

export const usePortfolioNavigation = () => {
  const navigate = useNavigate();

  const navigateToPortfolio = () => {
    if (localStorage.getItem("onChangeCompanyBuilder") === "1") {
      const confirmLeave = window.confirm(
        navigator.language.includes("he")
          ? "השינויים לא יישמרו, להמשיך?"
          : navigator.language.includes("ru")
          ? "Изменения не будут сохранены, продолжить?"
          : "Changes will not be saved, continue?"
      );
      if (confirmLeave) {
        localStorage.setItem("onChangeCompanyBuilder", "0");
        navigate("/portfolio");
      }
    } else {
      navigate("/portfolio");
    }
  };

  return { navigateToPortfolio };
};
