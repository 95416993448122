import { Avatar, Drawer, useMediaQuery } from "@mui/material";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../store/store";
import { useTranslation } from "react-i18next";
import {
  setContactInformationClose,
} from "../../store/features/campaignBuilderSetup";
import ContactCrossCloseIcon from "../../assets/campaignBuilder/contact_cross_close.svg";
import { ScheduleAViewingBtn } from "./content/layout/ScheduleAViewingBtn";
import { SpeakToAnAgentBtn } from "./content/buttons/SpeakToAnAgentBtn";
import { useLanguageTheme } from "../../hooks/useLanguageTheme";
import { useLocation } from "react-router-dom";
import { PreviewMode } from "./types";

export const ContactToAgentMobileTopPanel = () => {
  const { i18n, t } = useTranslation(["campaignBuilder"]);
  const dispatch = useAppDispatch();
  const langTheme = useLanguageTheme();

  const location = useLocation();
  const maxWidth1220px = useMediaQuery("(max-width: 1220px)");

  const { previewMode } = useSelector(
    (state: any) => state.campaignBuilderSetup
  );

  const {
    selectedAgent,
    selectedUserId,
    agentAvatarSlice,
    scheduleVewingOpen,
    agentPhotOpen,
    propertyTitle,
    propertyLocation,
  } = useSelector((state: any) => state.campaignBuilder);

  const shouldShowPhoto =
    agentPhotOpen && (selectedAgent?.photo?.url || agentAvatarSlice[0]?.url);

  const agentInitial = selectedAgent?.name
    ? selectedAgent.name.charAt(0).toUpperCase()
    : "";

  const { contactToAgentOpen } = useSelector(
    (state: any) => state.campaignBuilderSetup
  );

  return (
    <Drawer
      anchor="top"
      open={contactToAgentOpen}
      onClose={() => dispatch(setContactInformationClose())}
      PaperProps={{
        sx: {
          width: "100%",
          maxWidth: PreviewMode.DESKTOP === previewMode ? "100%" : "345px",
          margin: "0 auto",
          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
          padding: "20px 20px 53px 20px",
          top: maxWidth1220px
            ? "81px"
            : PreviewMode.DESKTOP === previewMode
            ? "160px"
            : "110px",
          boxSizing: "border-box",
        },
      }}
      BackdropProps={{ invisible: true }}
    >
      <div
        style={{
          direction: i18n.dir(),
        }}
      >
        <div className="contact-information-cross-close">
          <img
            src={ContactCrossCloseIcon}
            onClick={() => dispatch(setContactInformationClose())}
            className="contact-information-cross-close-icon"
          />
        </div>
        <div>
          <div
            className="campaign-builder-agent-info-block-name"
            style={{
              marginBottom: "51px",
            }}
          >
            {selectedUserId == "create-new" ? (
              <Avatar
                src={
                  shouldShowPhoto
                    ? agentAvatarSlice[0]?.url || selectedAgent?.photo?.url
                    : null
                }
                sx={{ width: 47, height: 47 }}
              >
                {!shouldShowPhoto && agentInitial}
              </Avatar>
            ) : (
              <Avatar
                src={shouldShowPhoto ? selectedAgent?.photo?.url : null}
                sx={{ width: 47, height: 47 }}
              >
                {!shouldShowPhoto && agentInitial}
              </Avatar>
            )}

            <div
              style={{
                fontFamily: langTheme.mainFont(),
              }}
            >
              <div className="campaign-builder-agent-info-block-agent-name">
                {i18n.language === "he"
                  ? selectedAgent?.nameHe
                  : selectedAgent?.nameEn}
              </div>
              <div className="campaign-builder-agent-info-block-agent-role">
                {t("agent")}
              </div>
            </div>
          </div>

          <div
            className="contact-to-agent-location-contaniner"
            style={{
              fontFamily: langTheme.mainFont(),
            }}
          >
            <div className="contact-to-agent-title">
              {propertyTitle ? propertyTitle : t("title_of_property_plug")}
            </div>
            <div className="contact-to-agent-location">
              {propertyLocation?.address
                ? propertyLocation?.address
                : t("adress")}
            </div>
          </div>

          <div className="campaign-builder-agent-info-block-btns">
            {scheduleVewingOpen && <ScheduleAViewingBtn />}
            <SpeakToAnAgentBtn />
          </div>
        </div>
      </div>
    </Drawer>
  );
};
