import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface ICampaingBuilderSliceState {
  wishlistSnackbarOpen: boolean;
  snackBarLikeMessage: string;
}

const initialState: ICampaingBuilderSliceState = {
  wishlistSnackbarOpen: false,
  snackBarLikeMessage: "",
};

const wishlistSlice = createSlice({
  name: "wishlist",
  initialState,
  reducers: {
    setWishlistSnackbarOpen(state, action) {
      state.wishlistSnackbarOpen = true;
      state.snackBarLikeMessage = action.payload.message;
    },
    setWishlistSnackbarClose(state) {
      state.wishlistSnackbarOpen = false;
      state.snackBarLikeMessage = "";
    },
  },
});

export const { setWishlistSnackbarOpen, setWishlistSnackbarClose } =
  wishlistSlice.actions;

export default wishlistSlice.reducer;
