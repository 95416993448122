import {
  Button,
  InputAdornment,
  Menu,
  MenuItem,
  TextField,
  Theme,
  useMediaQuery,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useAppDispatch } from "../../../../store/store";
import { CampaignSideNavStep } from "../layout/CampaignBuilderSideNav";
import { CampaignBuilderSideNavHeader } from "../layout/CampaignBuilderSideNavHeader";
import { useLanguageTheme } from "../../../../hooks/useLanguageTheme";
import React, { useEffect, useRef, useState } from "react";
import { IconSelect } from "../selects/IconSelect";
import {
  HotPriceIcon,
  LabelIconMap,
  labelOptions,
} from "../../../../constants/campaignBuilderData";
import { ExpandMore } from "@mui/icons-material";
import { Option } from "../selects/LabelSelect";
import {
  useCreateLabelMutation,
  useUpdateLabelMutation,
  useLazyGetLabelsWithParamsQuery,
} from "../../../../store/api/campaignBuilderApiSlice";
import { useResetToDraft } from "../../../../hooks/useResetToDraft";
import { textFieldStyles3 } from "./ContactInformation";
import { MAX_LABEL_LENGTH } from "../../constants";
import { ColorDropDown, colors } from "../layout/ColorDropDown";
import { CampainBuilderBackBtn } from "../buttons/CampainBuilderBackBtn";

export const AddNewLabel = ({
  setCurrentStep,
  labelOptionsState,
  selectedLabel,
  setSelectedLabel,
  handleSaveLabel,
}: {
  setCurrentStep: (step: CampaignSideNavStep) => void;
  labelOptionsState: any;
  selectedLabel: any;
  setSelectedLabel: any;
  handleSaveLabel: any;
}) => {
  const dispatch = useAppDispatch();
  const langTheme = useLanguageTheme();
  const { t, i18n } = useTranslation(["campaignBuilder"]);
  const currentLanguage = i18n.language;
  const isRTL = currentLanguage === "he";
  const downMd = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));

  const resetToDraft = useResetToDraft();

  const dropdownRef = useRef<any>(null);
  const [debounceTrigger, setDebounceTrigger] = useState(false);
  const [openCustomDropdown, setOpenCustomDropdown] = useState(false);

  const componentRef = useRef<any>(null);

  const [labelName, setLabelName] = React.useState("");
  const [selectedColor, setSelectedColor] = useState(colors[0]);
  const [selectedIconKey, setSelectedIconKey] =
    React.useState<keyof typeof LabelIconMap>("hot_price");
  const [anchorEl, setAnchorEl] = useState<any>(null);

  const [createLabel] = useCreateLabelMutation();
  const [updateLabel] = useUpdateLabelMutation();
  const [triggerLabelsWithParams] = useLazyGetLabelsWithParamsQuery();

  const [wasChanged, setWasChanged] = useState<any>(false);

  const handleChangeLabelName = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = event.target.value;
    resetToDraft();
    setLabelName(value);
    localStorage.setItem("onChangeCompanyBuilder", "1");
  };

  const userId = JSON.parse(
    localStorage.getItem("user") || sessionStorage.getItem("user") || "{}"
  )?.id;

  useEffect(() => {
    if (localStorage.getItem("isAddNewLabel") === "0" && selectedLabel) {
      setLabelName(
        i18n.language === "he"
          ? selectedLabel.labelNameHe
          : selectedLabel.labelNameEn
      );
      setSelectedColor(
        colors.find((el) => el.value === selectedLabel?.backgroundColor) ??
          colors[0]
      );
      setSelectedIconKey(selectedLabel.iconKey);
      setWasChanged(true);
    }
  }, [selectedLabel]);

  const handleSelect = (option: any) => {
    setLabelName(
      i18n.language === "he" ? option.labelNameHe : option.labelNameEn
    );
    setSelectedColor(
      colors.find((el) => el.value === option.backgroundColor) ?? colors[0]
    );
    setSelectedIconKey(option.iconKey);
    setWasChanged(true);
    dispatch(setSelectedLabel(option));
    resetToDraft();
    localStorage.setItem("onChangeCompanyBuilder", "1");
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      fetchData(
        selectedLabel,
        labelName,
        selectedColor,
        selectedIconKey,
        wasChanged
      );
    }, 1000);
    return () => clearTimeout(timeoutId);
  }, [debounceTrigger]);

  const fetchData = async (
    params: any,
    name: string,
    color: any,
    icon: string,
    wasChanged: boolean
  ) => {
    try {
      let respParams;
      const obj = { ...params };
      const documentId = obj.documentId;

      if (
        documentId &&
        localStorage.getItem("isAddNewLabel") === "0" &&
        wasChanged
      ) {
        if (obj.type === "custom") {
          respParams = await updateLabel({
            documentId: documentId,
            optionData: {
              iconKey: icon,
              backgroundColor: color.value,
              labelNameEn: i18n.language === "en" ? name : obj.labelNameEn,
              labelNameHe: i18n.language === "he" ? name : obj.labelNameHe,
            },
          });
        } else {
          respParams = await createLabel({
            optionData: {
              authorId: userId,
              backgroundColor: color.value,
              iconKey: icon,
              labelNameEn: i18n.language === "en" ? name : params.labelNameEn,
              labelNameHe: i18n.language === "he" ? name : params.labelNameHe,
              locale: i18n.language,
              type: "custom",
            },
          });
        }
        dispatch(setSelectedLabel(respParams?.data?.data));
        triggerLabelsWithParams({ userId });
      } else {
        if (wasChanged) {
          localStorage.setItem("isAddNewLabel", "0");

          let respParams = await createLabel({
            optionData: {
              authorId: userId,
              backgroundColor: color.value,
              iconKey: icon,
              labelNameEn: i18n.language === "en" ? name : "",
              labelNameHe: i18n.language === "he" ? name : "",
              locale: i18n.language,
              type: "custom",
            },
          });

          dispatch(setSelectedLabel(respParams?.data?.data));
          triggerLabelsWithParams({ userId });
        }
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <div className="campaign-builder-side-nav-wrapper">
      <CampaignBuilderSideNavHeader
        header="add_a_new_label"
        leftBtn={
          <CampainBuilderBackBtn
            setCurrentStep={setCurrentStep}
            step={CampaignSideNavStep.labels}
          />
        }
        rightBtn={<div className="campaign-builder-sidenav-empty-btn"></div>}
      />

      <div
        className="campaign-builder-sidenav-main-content-wrapper"
        style={{
          paddingTop: downMd ? "30px" : "40px",
          direction: i18n.dir(),
        }}
        ref={componentRef}
      >
        <div className="campaign-builder-sidenav-settings-fileds-container">
          <div className="campaign-builder-sidenav-settings-fileds-item">
            <div className="campaign-builder-sidenav-settings-fileds-item">
              <div>
                <div>
                  <Button
                    variant="outlined"
                    onClick={(event) => {
                      if (event.detail === 0) return;
                      setOpenCustomDropdown((prev) => !prev);
                      setAnchorEl(event.currentTarget);
                    }}
                    disableRipple
                    ref={dropdownRef}
                    sx={{
                      width: "100%",
                      justifyContent: "space-between",
                      textTransform: "none",
                      borderRadius: "16px",
                      height: 53,
                      paddingLeft: 0,
                      paddingRight: 0,
                      backgroundColor: "#fff",
                      borderColor: "#fff",
                      color: "#000",
                      zIndex: 14,
                    }}
                    endIcon={
                      <ExpandMore
                        style={{
                          marginRight: isRTL ? "15px" : "20px",
                          marginLeft: isRTL ? "20px" : "15px",
                        }}
                      />
                    }
                  >
                    <TextField
                      fullWidth
                      placeholder={t("message_placeholder")}
                      value={labelName}
                      onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                      }}
                      onKeyDown={(e) => {
                        if (e.key === " ") {
                          e.stopPropagation();
                        }
                      }}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        e.stopPropagation();
                        e.preventDefault();
                        handleChangeLabelName(e);
                        // setOpenCustomDropdown((prev) => !prev);
                        setAnchorEl(null);
                        setWasChanged(true);
                        setDebounceTrigger((prev) => !prev);
                      }}
                      sx={textFieldStyles3(langTheme.mainFont(), isRTL)}
                      autoComplete="off"
                      inputProps={{
                        maxLength: MAX_LABEL_LENGTH,
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment
                            sx={{
                              height: "100%",
                              ml: 0,
                              maxHeight: 50,
                              marginRight: isRTL ? "-14px" : "0",
                              pl: isRTL ? "20px" : "0px",
                              pr: isRTL ? "-14px" : "-14px",
                              color: "rgba(0, 0, 0, 1)",
                              zIndex: 15,
                              backgroundColor: "transparent",
                            }}
                            position="start"
                          >
                            <div
                              className="campaign-builder-sidenav-number-of-char"
                              style={{
                                fontFamily: langTheme.mainFont(),
                              }}
                            >
                              {labelName?.length}/{MAX_LABEL_LENGTH}
                            </div>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Button>

                  <Menu
                    anchorEl={anchorEl}
                    open={openCustomDropdown}
                    onClose={() => {
                      setOpenCustomDropdown((prev) => !prev);
                    }}
                    PaperProps={{
                      sx: {
                        width: dropdownRef?.current?.offsetWidth,
                        maxHeight: componentRef?.current?.offsetHeight - 110,
                        direction: i18n.dir(),
                      },
                    }}
                  >
                    <div
                      className="campaign-builder-sidenav-field-name"
                      style={{
                        color: "#000",
                        fontFamily: "DM Sans",
                        margin: "10px 15px",
                        fontSize: 16,
                        fontWeight: 500,
                      }}
                    >
                      {t("system_labels")}
                    </div>
                    <>
                      {labelOptionsState
                        .filter(
                          (elem: any) =>
                            selectedLabel?.id !== elem.id &&
                            elem.type === "system"
                        )
                        .map((option: Option, index: number) => {
                          const IconComponent =
                            LabelIconMap[option.iconKey] || HotPriceIcon;
                          return (
                            <MenuItem
                              key={index}
                              onClick={() => handleSelect(option)}
                            >
                              <div style={{ display: "flex", gap: 5 }}>
                                {IconComponent && (
                                  <IconComponent
                                    isSelected={false}
                                    width={17}
                                    height={19}
                                    backgroundColor={"#034A73"}
                                  />
                                )}
                                <p style={{ margin: 0, fontFamily: "DM Sans" }}>
                                  {
                                    option[
                                      currentLanguage === "he"
                                        ? "labelNameHe"
                                        : "labelNameEn"
                                    ]
                                  }
                                </p>
                              </div>
                            </MenuItem>
                          );
                        })}
                    </>

                    <div
                      className="campaign-builder-sidenav-field-name"
                      style={{
                        color: "#000",
                        fontFamily: "DM Sans",
                        margin: "25px 15px 10px",
                        fontSize: 16,
                        fontWeight: 500,
                      }}
                    >
                      {t("custom_labels")}
                    </div>
                    <>
                      {labelOptionsState
                        .filter(
                          (elem: any) =>
                            selectedLabel?.id !== elem.id &&
                            elem.type === "custom"
                        )
                        .map((option: Option, index: number) => {
                          const IconComponent =
                            LabelIconMap[option.iconKey] || HotPriceIcon;
                          return (
                            <MenuItem
                              key={index}
                              onClick={() => handleSelect(option)}
                            >
                              <div style={{ display: "flex", gap: 5 }}>
                                {IconComponent && (
                                  <IconComponent
                                    isSelected={false}
                                    width={17}
                                    height={19}
                                    backgroundColor={"#034A73"}
                                  />
                                )}
                                <p style={{ margin: 0, fontFamily: "DM Sans" }}>
                                  {
                                    option[
                                      currentLanguage === "he"
                                        ? "labelNameHe"
                                        : "labelNameEn"
                                    ]
                                  }
                                </p>
                              </div>
                            </MenuItem>
                          );
                        })}
                    </>
                  </Menu>
                </div>
              </div>
            </div>
          </div>

          <div className="campaign-builder-sidenav-settings-fileds-item">
            <div className="campaign-builder-sidenav-settings-fileds-item">
              <div className="campaign-builder-sidenav-field-name">
                {t("color")}
              </div>

              <ColorDropDown
                selectedColor={selectedColor}
                setSelectedColor={(e: any) => {
                  setSelectedColor(e);
                  setWasChanged(true);
                  setDebounceTrigger((prev) => !prev);
                  localStorage.setItem("onChangeCompanyBuilder", "1");
                }}
              />
            </div>
          </div>

          <div className="campaign-builder-sidenav-settings-fileds-item">
            <div className="campaign-builder-sidenav-settings-fileds-item">
              <div className="campaign-builder-sidenav-field-name">
                {t("choose_icon")}
              </div>

              <IconSelect
                selectedIcon={selectedIconKey}
                setSelectedIcon={setSelectedIconKey}
                options={labelOptions}
                iconMap={LabelIconMap}
                trigger={() => {
                  setWasChanged(true);
                  setDebounceTrigger((prev) => !prev);
                }}
              />
            </div>
          </div>
        </div>

        <div
          className={`campaign-builder-sidenav-add-label-btn-big`}
          onClick={async () => {
            // if (labelName.length === 0 && !wasChanged) return;
            await handleSaveLabel();
          }}
          style={{
            pointerEvents:
              labelName.length === 0 && !wasChanged ? "none" : "auto",
            opacity: labelName.length === 0 && !wasChanged ? 0.5 : 1,
          }}
        >
          {t("save")}
        </div>
      </div>
    </div>
  );
};
