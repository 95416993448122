import { MenuItem, Select, SelectChangeEvent } from "@mui/material";
import React from "react";
import { ReactComponent as SelectArrow } from "../../assets/icons/select_arrow_black.svg";
import i18n from "../../i18n";
import { useTranslation } from "react-i18next";
import {
  FilterMap,
  getFiltersByIds,
  getFiltersFromMap,
} from "../../constants/propertyData";
import { renderValueWithTrancateLength } from "../../helpers/dataConverts";
import { useLanguageTheme } from "../../hooks/useLanguageTheme";
import {
  OptionType
} from "../../types/properties";
import { IFilter } from "../portfolio/interfaces";
import { ALL } from "../portfolio/constants";

export const FilterSideBarSelect = <T extends OptionType>({
  filterMap = {},
  selectedFilters = [],
  onChange,
  truncateLength,
}: {
  filterMap: FilterMap;
  truncateLength: number;
  selectedFilters: IFilter[];
  onChange: (filters: IFilter[]) => void;
}) => {
  const { t } = useTranslation(["filters"]);

  const currentLanguage = i18n.language;
  const isRTL = currentLanguage === "he";
  const langTheme = useLanguageTheme();

  const filters = React.useMemo(
    () => getFiltersFromMap(filterMap),
    [filterMap]
  );

  // const handleChangeOption = (event: SelectChangeEvent<string[]>) => {
  //   const value = event.target.value as string[];

  //   if (value.includes(ALL)) {
  //     let eq = false;

  //     if (filters.length == selectedFilters.length) {
  //       eq = true;
  //       for (let i = 0; i < filters.length; i++) {
  //         if (filterMap[i].key != selectedFilters[i].key) {
  //           eq = false;
  //           break;
  //         }
  //       }
  //     }

  //     if (eq) {
  //       onChange([]);
  //     } else {
  //       onChange(filters);
  //     }
  //   } else {
  //     onChange(getFiltersByIds(filterMap, value));
  //   }
  // };

  const handleChangeOption = (event: SelectChangeEvent<string[]>) => {
    const value = event.target.value as string[];
    if (value.includes(ALL)) {
      if (selectedFilters.length === filters.length) {
        onChange([]);
      } else {
        onChange(filters);
      }
    } else {
      onChange(getFiltersByIds(filterMap, value));
    }
  };

  return (
    <Select
      multiple
      value={selectedFilters.map((filter) => filter.key)}
      onChange={handleChangeOption}
      renderValue={(selected) =>
        selected.length === filters.length
          ? t("all")
          : renderValueWithTrancateLength(selectedFilters, truncateLength)
      }
      fullWidth
      MenuProps={{
        slotProps: {
          paper: {
            sx: {
              background: "rgba(255, 255, 255, 1)",
            },
          },
        },
      }}
      sx={{
        color: "rgba(0, 0, 0, 1)",
        borderRadius: "16px",
        fontFamily: langTheme.mainFont(),
        fontWeight: 400,
        fontSize: "14px",
        height: "50px",
        "& .MuiOutlinedInput-notchedOutline": {
          border: "1px solid rgba(224, 229, 242, 1)",
        },
        "& .MuiPaper-root": {
          backgroundColor: "transparent",
        },
        "& .MuiSelect-select": {
          display: "flex",
          alignItems: "center",
          paddingRight: isRTL ? "20px" : "20px",
          paddingLeft: isRTL ? "20px" : "20px",
        },
        "& .MuiSelect-icon": {
          top: "calc(50% - 0.2em)",
          right: isRTL ? "auto" : "20px",
          left: isRTL ? "20px" : "auto",
        },
        "&:hover .MuiOutlinedInput-notchedOutline": {
          borderColor: "rgba(129, 154, 221, 1)",
        },
        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
          borderColor: "rgba(129, 154, 221, 1)",
        },
      }}
      IconComponent={SelectArrow}
    >
      <MenuItem
        value={ALL}
        sx={{
          color: "rgba(0, 0, 0, 1)",
          fontFamily: langTheme.mainFont(),
          fontWeight: 500,
          fontSize: "14px",
          direction: i18n.dir(),
          "&:hover": {
            backgroundColor: "#acc3d954",
          },
        }}
      >
        {t("all")}
      </MenuItem>
      {filters.map((filter) => (
        <MenuItem
          value={filter.key}
          key={filter.key}
          sx={{
            color: "rgba(0, 0, 0, 1)",
            fontFamily: langTheme.mainFont(),
            fontWeight: 500,
            fontSize: "14px",
            direction: i18n.dir(),
            "&:hover": {
              backgroundColor: "#acc3d954",
            },
          }}
        >
          {t(filter.translationKey)}
        </MenuItem>
      ))}
    </Select>
  );
};
