import { Box, Theme, Typography, useMediaQuery } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import TextInACircleEn from "../../assets/text_in_a_circleEn.png";
import TextInACircleHe from "../../assets/text_in_a_circleHe.png";
import LogoWithoutText from "../../assets/logo_without_text.png";
import LogoWithoutText2 from "../../assets/logoWithoutText2.png";
import { useLanguageTheme } from "../../hooks/useLanguageTheme";
import { useAuth } from "../../hooks/useAuth";
import { UserTypes } from "../_shared/Navbar";

export const Overview = () => {
  const { t } = useTranslation(["home"]);
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const isRTL = currentLanguage === "he";
  const langTheme = useLanguageTheme();
  const isAuthenticated = useAuth();

  const downMd = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));
  const storedUser =
    localStorage.getItem("user") || sessionStorage.getItem("user");
  const user = storedUser ? JSON.parse(storedUser) : null;

  return (
    <div
      className="overview-wrapper xl-width"
      style={{ direction: i18n.dir() }}
    >
      <div
        className="overview-text-container"
        style={{
          paddingBottom:
            isAuthenticated && user && user.userType === UserTypes.SELLER
              ? "95px"
              : "50px",
        }}
      >
        <div className="overview-left-container">
          <div
            className="overview-title-container"
            style={{
              textAlign: isRTL ? "right" : "left",
              fontFamily: isRTL ? "Noto Sans Hebrew" : "Frank Ruhl Libre",
            }}
          >
            <Typography
              variant="h1"
              sx={{
                fontWeight: 600,
                lineHeight: downMd ? "28px" : "52px",
                // letterSpacing: isRTL ? "-1.64px" : "",
              }}
            >
              {t("title")}
            </Typography>
            <div
              className="overview-title-colored-words"
              style={{
                gap: isRTL ? "0" : "9px",
                lineHeight: downMd ? "28px" : "52px",
                // letterSpacing: isRTL ? "-1.64px" : "",
              }}
            >
              <Typography
                className="overview-title-colored-words-text1"
                variant="h1"
                sx={{
                  color: "#034A73",
                  fontWeight: 600,
                }}
              >
                {t("homes")}
              </Typography>
              <Typography
                className="overview-title-colored-words-text2"
                variant="h1"
                sx={{
                  color: "#4F819D",
                  fontWeight: 600,
                  marginRight: isRTL ? "9px" : 0,
                }}
              >
                {t("that")}
              </Typography>
              <Typography
                className="overview-title-colored-words-text3"
                variant="h1"
                sx={{
                  color: "#D7A148",
                  fontWeight: 600,
                  marginRight: isRTL ? "9px" : 0,
                }}
              >
                {t("impress")}
              </Typography>
            </div>
          </div>
          <p
            style={{
              maxWidth: isRTL ? "292px" : "563px",
              textAlign: isRTL ? "right" : "left",
              fontSize: downMd ? "16px" : "18px",
              lineHeight: downMd ? "18px" : "22px",
              letterSpacing: "0.64px",
              fontFamily: langTheme.mainFont(),
            }}
          >
            {t("description")}
          </p>
        </div>

        {!downMd && (
          <div
            className="overview-right-container"
            style={{
              marginRight: isRTL ? "0" : "20px",
              marginLeft: isRTL ? "20px" : "0",
            }}
          >
            <div
              className="overview-logo-container"
              style={{
                marginRight: isRTL ? "0" : "10px",
                marginLeft: isRTL ? "10px" : "0",
              }}
            >
              <img
                className="overview-logo"
                src={LogoWithoutText2}
                style={{
                  height: "60px",
                  width: "73px",
                }}
              />
              <img
                src={isRTL ? TextInACircleHe : TextInACircleEn}
                className="overview-text-in-a-circle"
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
