import { InputAdornment, TextField, Theme, useMediaQuery } from "@mui/material";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import MagnifierImg from "../../../assets/learningCenter/magnifier.svg";
import { skipToken } from "@reduxjs/toolkit/query";
import InfiniteScroll from "react-infinite-scroll-component";
import { useLanguageTheme } from "../../../hooks/useLanguageTheme";
import {
  ARTICLE_PAGE_SIZE,
  buildSearchParamsFromGetArticlesParams,
  DEFAULT_PAGE,
  ITag,
  parseSearchParams,
  tagMap,
} from "../../../constants/articleData";
import { IArticle } from "../interfaces";
import {
  IGetArticlesParamsWithLang,
  useGetArticlesWithParamsQuery,
} from "../../../store/api/articlesApiSlice";
import { GalleryOfArticles } from "./GalleryOfArticles";

export const BrowseTheLibrary = () => {
  const { i18n, t } = useTranslation(["learningCenter"]);
  const langTheme = useLanguageTheme();
  const downMd = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));
  const isRTL = i18n.dir() === "rtl";
  const [_, setSearchParams] = useSearchParams();

  const [page, setPage] = useState<number>(DEFAULT_PAGE);
  const [search, setSearch] = useState<string>("");
  const [tags, setTags] = React.useState<ITag[]>([]);

  const [articles, setArticles] = React.useState<IArticle[]>([]);
  const [hasMore, setHasMore] = useState<boolean>(true);

  React.useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const params = parseSearchParams(searchParams);

    setPage(params.page);
    setSearch(params.search);
    setTags(params.tags);
  }, []);

  useEffect(() => {
    setSearchParams(
      buildSearchParamsFromGetArticlesParams({
        page,
        search,
        tags,
      })
    );
  }, [page, search, tags]);

  const handleSelectTag = (tagId: string) => {
    const tag = tagMap[tagId];

    if (tag) {
      const tagExists = tags.some((t) => t.id === tagId);

      if (tagExists) {
        setTags(tags.filter((t) => t.id !== tagId));
      } else {
        setTags([...tags, tag]);
      }
    }
    setPage(1);
  };

  const handleChangeSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSearch(value);
    setPage(1);
  };

  const getArticlesParams = useMemo<IGetArticlesParamsWithLang | null>(() => {
    return {
      params: {
        page: 1,
        pageSize: (page * 10).toString(),
        search: search,
        tags: tags,
      },
      lang: i18n.language,
    };
  }, [page, search, i18n.language, tags]);

  const { data } = useGetArticlesWithParamsQuery(
    getArticlesParams ?? skipToken
  );

  useEffect(() => {
    if (data) {
      setArticles(data.data);
      setHasMore(data.data.length === ARTICLE_PAGE_SIZE);
    }
  }, [data, page]);

  const loadMore = useCallback(() => {
    setPage((prevPage) => prevPage + 1);
  }, []);

  return (
    <>
      <div
        className="browse-the-library-wrapper xxl-width"
        style={{
          direction: i18n.dir(),
          fontFamily: langTheme.mainFont(),
        }}
      >
        <div
          className="browse-the-library-container"
          style={{
            paddingBottom: downMd ? "83px" : "94px",
            paddingTop: downMd ? "83px" : "94px",
          }}
        >
          <div className="browse-the-library-info-wrapper">
            <div
              className="browse-the-library-title"
              style={{
                fontFamily: isRTL ? "Noto Sans Hebrew" : "Frank Ruhl Libre",
                fontSize: downMd ? "24px" : "36px",
                lineHeight: downMd ? "24px" : "36px",
                marginBottom: downMd ? "15px" : "10px",
              }}
            >
              {t("browse_the_library_title")}
            </div>
            <div className="browse-the-library-desc">
              {t("browse_the_library_desc")}
            </div>

            <TextField
              variant="outlined"
              fullWidth
              value={search}
              autoComplete="off"
              onChange={handleChangeSearch}
              placeholder={t("search")}
              sx={{
                width: "331px",
                backgroundColor: "#17587e",
                border: "1px solid rgba(165, 176, 210, 1)",
                borderRadius: "16px",
                p: "7.5px 10px 7.5px 5px",
                boxSizing: "border-box",

                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    border: "none",
                  },
                },
                "& .MuiInputBase-input::placeholder": {
                  color: "rgba(165, 176, 210, 1)",
                  opacity: 1,
                  fontWeight: 400,
                  fontFamily: langTheme.mainFont(),
                  fontSize: "14px",
                },
                "& .MuiInputBase-input": {
                  fontWeight: 400,
                  fontFamily: langTheme.mainFont(),
                  boxSizing: "border-box",
                  fontSize: "14px",
                  color: "white",
                },
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <img
                      src={MagnifierImg}
                      alt="arrow"
                      style={{
                        transform: isRTL ? "scaleX(-1)" : " scaleX(1)",
                      }}
                    />
                  </InputAdornment>
                ),
              }}
            />

            <div
              className="clue-container"
              style={{
                marginTop: downMd ? "25px" : "30px",
                flexWrap: "wrap",
                justifyContent: "center",
              }}
            >
              {Object.keys(tagMap).map((tagId) => (
                <div
                  key={tagId}
                  className={`clue-white-blue ${
                    tags.some((t) => t.id === tagId) ? "active" : ""
                  }`}
                  onClick={() => handleSelectTag(tagId)}
                >
                  {t(tagMap[tagId].title)}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      <div
        className=" xl-width"
        style={{
          direction: i18n.dir(),
        }}
      >
        {articles.length === 0 ? (
          <div
            className="no-articles-container"
            style={{
              direction: i18n.dir(),
              fontFamily: langTheme.mainFont(),
              paddingBottom: downMd ? "50px" : "150px",
            }}
          >
            {t("no_articles_found")}
          </div>
        ) : (
          <InfiniteScroll
            dataLength={articles.length}
            next={loadMore}
            hasMore={hasMore}
            loader={null}
            scrollThreshold={window.innerWidth > 1800 ? 0.2 : 0.3}
          >
            <div
              className="gallery-of-articles-wrapper"
              style={{
                paddingBottom: downMd ? "50px" : "150px",
              }}
            >
              <GalleryOfArticles articles={articles} />
            </div>
          </InfiniteScroll>
        )}
      </div>
    </>
  );
};
