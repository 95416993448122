import { Theme, useMediaQuery } from "@mui/material";
import { useTranslation } from "react-i18next";
import { IConvertedArticleContent } from "../../helpers/convert";

export const ArtcileSideBar = ({
  items,
  activeItemId,
}: {
  items: IConvertedArticleContent[];
  activeItemId: string | null;
}) => {
  const { i18n, t } = useTranslation(["learningCenter"]);
  const downLg = useMediaQuery((theme: Theme) => theme.breakpoints.down("lg"));

  const handleLinkClick = (
    event: React.MouseEvent<HTMLAnchorElement>,
    id: string
  ) => {
    event.preventDefault();

    const element = document.getElementById(id);
    if (element) {
      const headerOffset = 170;
      const elementPosition = element.getBoundingClientRect().top;
      const offsetPosition =
        elementPosition + window.pageYOffset - headerOffset;

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
    }
  };

  return (
    <div
      className="article-sidebar"
      style={{
        direction: i18n.dir(),
        width: downLg ? "300px" : "364px",
      }}
    >
      <div className="article-sidebar-title">{t("table_of_contents")}</div>
      <ul>
        {items.map((item, index: number) => (
          <div key={index}>
            <div className="article-sidebar-line"></div>
            <li
              key={item.id}
              className={
                activeItemId === String(item.id) ? "active-arctile" : ""
              }
            >
              <a
                href={`#${item.id}`}
                onClick={(e) => handleLinkClick(e, String(item.id))}
              >
                {item.title}
              </a>
            </li>
          </div>
        ))}
      </ul>
    </div>
  );
};
