import { Dialog, Theme, useMediaQuery } from "@mui/material";
import { SignupContent } from "../auth/SignupContent";
import BlackCross from "../../assets/icons/blackCross.svg";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import {
  setAuthPopupClose,
  authPopupOpenSelector,
  authPopupContentTypeSelector,
  setForgotPasswordStep,
} from "../../store/features/common";
import { useAppDispatch } from "../../store/store";
import { SigninContent } from "./SigninContent";
import { AuthPopupContentType } from "../auth/types";
import { ForgotPasswordStep } from "../../pages/ForgotPassword";
import { ForgotPasswordContent } from "./ForgotPasswordContent";
import { useSearchParams } from "react-router-dom";
import { ChooseVerificationContent } from "../auth/ChooseVerificationContent";
import { EmailConfirmationContent } from "../auth/EmailConfirmationContent";
import { SmsConfirmationContent } from "../auth/SmsConfirmationContent";
import { FinishSignupContent } from "../auth/FinishSignupContent";
import { WelcomeOnBoardContent } from "../auth/WelcomeOnBoardContent";

export const AuthPopup = () => {
  const dispatch = useAppDispatch();
  const { i18n } = useTranslation(["signin"]);
  const isRTL = i18n.dir() === "rtl";
  const downSm = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));

  const authPopupOpen = useSelector(authPopupOpenSelector);
  const authPopupContentType = useSelector(authPopupContentTypeSelector);

  const queryParams = new URLSearchParams(window.location.search);
  const code = queryParams.get("code");

  const [_, setSearchParams] = useSearchParams();

  const handleCloseAuthPopup = () => {
    dispatch(setAuthPopupClose());
    dispatch(setForgotPasswordStep(ForgotPasswordStep.enterEmail));
    if (code) {
      queryParams.delete("code");
      setSearchParams(queryParams);
    }
  };

  if (!authPopupContentType) {
    return null;
  }

  return (
    <Dialog
      open={authPopupOpen}
      onClose={handleCloseAuthPopup}
      sx={{
        ".MuiPaper-root": {
          padding: downSm ? "10px" : "35px 25px",
          maxHeight: "calc(100% - 140px);",
          minWidth: "410px",
          maxWidth:
            authPopupContentType === AuthPopupContentType.SIGNIN
              ? "410px"
              : "auto",
          height: "auto",
          boxSizing: "border-box",
        },
      }}
    >
      <div
        style={{
          direction: isRTL ? "ltr" : "rtl",
        }}
      >
        <div onClick={handleCloseAuthPopup} className="signup-close-cross">
          <img src={BlackCross} />
        </div>
      </div>

      {authPopupContentType === AuthPopupContentType.SIGNIN ? (
        <SigninContent isPopup={true} />
      ) : authPopupContentType === AuthPopupContentType.SIGNUP ? (
        <SignupContent
          additionalDescription={"additionalDescription"}
          isPopup={true}
        />
      ) : authPopupContentType === AuthPopupContentType.FORGOT_PASSWORD ? (
        <ForgotPasswordContent />
      ) : authPopupContentType === AuthPopupContentType.CHOOSE_VERIFICATION ? (
        <ChooseVerificationContent headerText={"choose_verification"} />
      ) : authPopupContentType === AuthPopupContentType.CONFIRM_EMAIL ? (
        <EmailConfirmationContent headerText={"email_confirmation"} />
      ) : authPopupContentType === AuthPopupContentType.CONFIRM_SMS ? (
        <SmsConfirmationContent />
      ) : authPopupContentType ===
        AuthPopupContentType.FINISH_SIGNUP_WITH_GOOGLE ? (
        <FinishSignupContent additionalDescription={""} isPopup={true} />
      ) : authPopupContentType === AuthPopupContentType.WELCOME_ON_BOARD ? (
        <WelcomeOnBoardContent />
      ) : null}
    </Dialog>
  );
};
