import { CampaignSideNavStep } from "../layout/CampaignBuilderSideNav";
import { useTranslation } from "react-i18next";
import NextIcon from "../../../../assets/campaignBuilder/next_btn.svg";

export const CampainBuilderMenuBtn = ({
  handleNext,
  step,
}: {
  handleNext: (step: CampaignSideNavStep) => void;
  step: CampaignSideNavStep;
}) => {
  const { t, i18n } = useTranslation(["campaignBuilder"]);

  return (
    <div
      className="campaign-builder-sidenav-back"
      onClick={() => handleNext(step)}
    >
      <div className="campaign-builder-sidenav-back-text">{t("menu")}</div>
      <div
        style={{
          transform: i18n.dir() === "rtl" ? "scaleX(-1)" : "scaleX(1)",
        }}
      >
        <img src={NextIcon} />
      </div>
    </div>
  );
};
