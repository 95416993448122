import { MenuItem, Select, SelectChangeEvent } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as SelectArrow } from "../../../../assets/icons/select_arrow_black.svg";
import { useLanguageTheme } from "../../../../hooks/useLanguageTheme";
import i18n from "../../../../i18n";
import { ISortOption, ISortParams } from "../../interfaces";

export const CampaignStatusSelect = ({
  options,
  initSort,
  onSortChange,
  styles,
}: {
  options: any[];
  initSort: string;
  onSortChange: (sortParams: ISortParams) => void;
  styles: any;
}) => {
  const currentLanguage = i18n.language;
  const langTheme = useLanguageTheme();
  const isRTL = currentLanguage === "he";
  const { t } = useTranslation(["portfolio"]);

  const initialSelectedOption = React.useMemo(() => {
    const matchedOption = options.find(
      (option) => option.nameParam === initSort
    );

    return matchedOption || options[0];
  }, [initSort, options]);

  const [selectedOption, setSelectedOption] = React.useState<ISortOption>(
    initialSelectedOption
  );

  React.useEffect(() => {
    setSelectedOption(initialSelectedOption);
  }, [initialSelectedOption]);

  const handleChangeOption = (event: SelectChangeEvent<string>) => {
    const selected = options.find(
      (option) => option.id.toString() === event.target.value
    );

    if (!selected) {
      return;
    }

    setSelectedOption(selected);
    onSortChange(selected.nameParam);
  };
  return (
    <Select
      fullWidth
      MenuProps={{
        slotProps: {
          paper: {
            sx: {
              background: "rgba(255, 255, 255, 1)",
            },
          },
        },
      }}
      sx={{
        direction: i18n.dir(),
        borderRadius: styles.borderRadius,

        backgroundColor: styles.backgroundColor,
        width: styles.width,
        border: styles.border,
        fontWeight: 500,
        color: "rgba(0, 0, 0, 1)",
        fontFamily: langTheme.mainFont(),
        height: styles.height,
        boxSizing: "border-box",
        "& .MuiSelect-select": {
          padding: styles.padding,
          fontSize: styles.fontSize,
        },
        "& .MuiSelect-icon": {
          top: "calc(50% - 0.2em)",
          right: isRTL ? "auto" : "20px",
          left: isRTL ? "20px" : "auto",
        },
        "& .MuiOutlinedInput-notchedOutline": {
          border: "none",
        },
        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
          border: "none",
        },
        "& .MuiOutlinedInput-input": {
          paddingRight: 0,
        },
      }}
      value={selectedOption.id.toString()}
      onChange={handleChangeOption}
      IconComponent={SelectArrow}
    >
      {options.map((option: ISortOption, index: number) => (
        <MenuItem
          value={option.id.toString()}
          key={option.id}
          sx={{
            color: "rgba(0, 0, 0, 1)",
            fontFamily: langTheme.mainFont(),
            fontWeight: 500,
            fontSize: "14px",
            direction: i18n.dir(),
            "&:hover": {
              backgroundColor: "#acc3d954",
            },
          }}
        >
          {t(option.translationKey)}
        </MenuItem>
      ))}
    </Select>
  );
};
