import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { AuthPopup } from "../components/_shared/AuthPopup";
import { Footer } from "../components/_shared/Footer";
import { FooterSocialMediaLine } from "../components/_shared/FooterSocialMediaLine";
import { Header } from "../components/_shared/Header";
import { Navbar, UserTypes } from "../components/_shared/Navbar";
import { NotificationSnackBar } from "../components/campaignBuilder/NotificationSnackBar";
import { ExploreTopListing } from "../components/home/ExploreBlock/ExploreTopListing";
import { FeaturedProperties } from "../components/home/FeaturedProperties";
import { ForBuyers } from "../components/home/ForBuyers/ForBuyers";
import { GetInTouchBlock } from "../components/home/GetInTouchBlock";
import { HeroImg } from "../components/home/HeroImg/HeroImg";
import { Overview } from "../components/home/Overview";
import { PropertyTypesBlock } from "../components/home/PropertyTypesBlock";
import { Testimonials } from "../components/home/Testimonials/Testimonials";
import {
  DEFAULT_CAMPAIGN_STATUS,
  DEFAULT_FILTER,
  DEFAULT_PAGE,
  DEFAULT_SORT,
  DEFAULT_USER_ID,
} from "../components/portfolio/constants";
import { IFilterParams } from "../components/portfolio/interfaces";
import { convertToProperties, Property } from "../helpers/convert";
import { randomMediaGenerator } from "../helpers/fakeMedia";
import { useGetHomepagePropertiesQuery } from "../store/api/propertyApiSlice";
import { setWishlistSnackbarClose } from "../store/features/wishlistSlice";
import { useAppDispatch } from "../store/store";
import { buildSearchParamsFromGetPropertiesParams } from "../components/portfolio/logic";
import React from "react";
import { ForgotPasswordStep } from "./ForgotPassword";
import {
  setAuthPopupOpen,
  setForgotPasswordStep,
} from "../store/features/common";
import { AuthPopupContentType } from "../components/auth/types";
import { NotificationStatus } from "../components/campaignBuilder/content/steps/CampaignSetUpStep";
import { FooterDemo } from "../components/_shared/FooterDemo";

export const PREFERED_PROPERTY_NUM = 5;

export const Home = () => {
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const user = localStorage.getItem("user") || sessionStorage.getItem("user");

  const navigateToPortfolioWithFilters = (filters: IFilterParams) => {
    const params = buildSearchParamsFromGetPropertiesParams({
      filters: filters,
      page: DEFAULT_PAGE,
      sort: DEFAULT_SORT,
      search: "",
      campaignStatus: DEFAULT_CAMPAIGN_STATUS,
      userId:
        user && JSON.parse(user).userType === UserTypes.SELLER
          ? JSON.parse(user).id
          : DEFAULT_USER_ID,
    });

    navigate(`/portfolio?${params.toString()}`);
  };

  const navigateToPortfolioWithSearch = (search: string) => {
    if (search === "") {
      return;
    }

    const params = buildSearchParamsFromGetPropertiesParams({
      filters: DEFAULT_FILTER,
      page: DEFAULT_PAGE,
      sort: DEFAULT_SORT,
      search: search,
      campaignStatus: DEFAULT_CAMPAIGN_STATUS,
      userId:
        user && JSON.parse(user).userType === UserTypes.SELLER
          ? JSON.parse(user).id
          : DEFAULT_USER_ID,
    });
    navigate(`/portfolio?${params.toString()}`);
  };

  const [featuredProperties, setFeaturedProperties] = useState<Property[]>([]);
  const [propertyTypes, setPropertyTypes] = useState<Property[]>([]);
  const [topProperties, setTopProperties] = useState<Property[]>([]);
  const { data: fetchedProperties } = useGetHomepagePropertiesQuery({
    locale: currentLanguage,
  });

  useEffect(() => {
    if (fetchedProperties?.data) {
      const { exceptionalResults, differentResults, exploreResults } =
        fetchedProperties.data;
      const exceptionalProps = convertToProperties(
        randomMediaGenerator,
        exceptionalResults
      );
      const differentProps = convertToProperties(
        randomMediaGenerator,
        differentResults
      );
      const exploreProps = convertToProperties(
        randomMediaGenerator,
        exploreResults
      );

      setFeaturedProperties(exceptionalProps);
      setPropertyTypes(differentProps);
      setTopProperties(exploreProps);
      // TODO fallback when api failed
      // FALLBACK on fetch number
      // while (props.length < PREFERED_PROPERTY_NUM) {
      //   props.push(getRandomProperty());
      // }
    }
  }, [fetchedProperties]);

  const { wishlistSnackbarOpen, snackBarLikeMessage } = useSelector(
    (state: any) => state.wishlist
  );

  const handleCloseLikeSnackBar = () => {
    dispatch(setWishlistSnackbarClose());
  };

  //forgot password popup
  const queryParams = new URLSearchParams(window.location.search);
  const code = queryParams.get("code");

  React.useEffect(() => {
    if (code) {
      dispatch(setForgotPasswordStep(ForgotPasswordStep.newPassword));
      dispatch(setAuthPopupOpen(AuthPopupContentType.FORGOT_PASSWORD));
    }
  }, []);
  //forgot password popup

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <>
        <div className="portfolio-header-sticky-wrapper">
          <div
            style={{
              backgroundColor: "rgba(255, 255, 255, 1)",
            }}
          >
            <Header>
              <Navbar />
            </Header>
          </div>
        </div>

        <Overview />
        <HeroImg
          filters={DEFAULT_FILTER}
          onChangeFilters={navigateToPortfolioWithFilters}
          search={""}
          onChangeSearch={navigateToPortfolioWithSearch}
        />
        <AuthPopup />
        <FeaturedProperties properties={featuredProperties} />
        {/* <PropertyTypesBlock properties={propertyTypes} /> */}
        <ForBuyers />
        <Testimonials />
        {/* <ExploreTopListing properties={topProperties} /> */}
        <GetInTouchBlock />
        {/* <Footer /> */}
        <FooterDemo />
        <FooterSocialMediaLine />
        <NotificationSnackBar
          open={wishlistSnackbarOpen}
          onClose={handleCloseLikeSnackBar}
          type={NotificationStatus.SUCCESSFULL}
          snackbarText={snackBarLikeMessage}
        />
      </>
    </div>
  );
};
