import {
  FormControlLabel,
  Radio,
  RadioGroup,
  SelectChangeEvent,
} from "@mui/material";
import React, { Dispatch, SetStateAction, useState } from "react";
import { UserType } from "../../../auth/UserTypeSelect";
import { useTranslation } from "react-i18next";
import { useLanguageTheme } from "../../../../hooks/useLanguageTheme";

export const UserTypeCheckboxSelect = ({
  userType,
  setUserType,
}: {
  userType: UserType;
  setUserType: Dispatch<SetStateAction<UserType>>;
}) => {
  const { t, i18n } = useTranslation(["contactUs"]);
  const langTheme = useLanguageTheme();

  const handleChange = (event: SelectChangeEvent<UserType>) => {
    setUserType(event.target.value as UserType);
  };

  return (
    <RadioGroup
      name="role"
      value={userType}
      onChange={handleChange}
      sx={{
        display: "flex",
        flexDirection: "row",
        gap: "0 40px",
        marginBottom: "15px",
      }}
    >
      {Object.keys(UserType).map((type) => (
        <FormControlLabel
          key={type}
          value={type}
          sx={{ marginRight: 0 }}
          control={
            <Radio
              sx={{
                color: "rgba(163, 174, 208, 1)",
                "&.Mui-checked": {
                  color: "rgba(3, 74, 115, 1)",
                },
              }}
            />
          }
          label={
            <div
              className="contact-us-radio-label"
              style={{
                fontFamily: langTheme.mainFont(),
              }}
            >
              {t(type)}
            </div>
          }
        />
      ))}
    </RadioGroup>
  );
};
