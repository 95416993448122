import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Theme, useMediaQuery } from "@mui/material";
import { useParams } from "react-router-dom";
import {
  KeyFeatureIconMapBlue,
  KeyFuatureIconKey,
} from "../../../../constants/campaignBuilderData";
import { PreviewMode } from "../../types";

export interface IKeyFeatureOption {
  id: string;
  iconKey: KeyFuatureIconKey;
  labelNameEn: string;
  labelNameHe: string;
  selectedToCard: boolean;
  selectId: string;
}
export const KeyFeturesBlock = () => {
  const { t, i18n } = useTranslation(["campaignBuilder"]);
  const { documentId } = useParams();
  const { keyFeaturesBack } = useSelector(
    (state: any) => state.campaignBuilder
  );

  const { previewMode } = useSelector(
    (state: any) => state.campaignBuilderSetup
  );
  const downMd = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));

  return (
    <div>
      <div className="campaign-builder-main-header">{t("key_features")}</div>
      <div>
        <div
          className="campaign-builder-key-feature-items-block"
          style={{
            gridTemplateColumns:
              previewMode === PreviewMode.MOBILE || downMd
                ? "1fr"
                : "repeat(2, 1fr)",
          }}
        >
          {keyFeaturesBack && Array.isArray(keyFeaturesBack)
            ? keyFeaturesBack
                .filter((el) => !(el?.details && el?.details[documentId ?? ""]))
                .map((option: IKeyFeatureOption, index) => {
                  const IconComponent =
                    KeyFeatureIconMapBlue[option.iconKey ?? "stair"];
                  return (
                    <div
                      key={index}
                      className="campaign-builder-investment-highlights-item"
                    >
                      {IconComponent ? (
                        <IconComponent
                          isSelected={false}
                          width={30}
                          height={30}
                          backgroundColor={"#034A73"}
                        />
                      ) : (
                        ""
                      )}
                      <div>
                        {i18n.language === "he"
                          ? option?.labelNameHe
                          : option?.labelNameEn}
                      </div>
                    </div>
                  );
                })
            : ""}
        </div>
      </div>
    </div>
  );
};
