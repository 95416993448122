import { Box, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import React from "react";
import { countryCodes } from "../../helpers/constants";
import Flag from "react-world-flags";
import { useLanguageTheme } from "../../hooks/useLanguageTheme";
import { ReactComponent as SelectArrowBlue } from "../../assets/icons/blue_arrow_down.svg";
import { useTranslation } from "react-i18next";

export const PhoneSelect = ({
  selectedCode,
  handleCodeChange,
}: {
  selectedCode: string | undefined;
  handleCodeChange: (event: SelectChangeEvent<string>) => void;
}) => {
  const langTheme = useLanguageTheme();
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const isRTL = currentLanguage === "he";

  return (
    <Select
      value={selectedCode}
      onChange={handleCodeChange}
      variant="standard"
      disableUnderline
      sx={{
        display: "flex",
        alignItems: "center",
        minWidth: "80px",
        "& .MuiSelect-select": {
          display: "flex",
          alignItems: "center",
          gap: "4px",
          fontSize: "14px",
        },
        "& .MuiSelect-icon": {
          top: "14px",
          right: isRTL ? "5px" : "auto",
          left: isRTL ? "auto" : "67px",
        },
      }}
      IconComponent={SelectArrowBlue}
    >
      {countryCodes.map((country) => (
        <MenuItem key={country.code + country.flag} value={country.code}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
              fontFamily: langTheme.mainFont(),
              color: "rgba(43, 54, 116, 1)",
              fontWeight: "500",
              fontSize: "14px",
            }}
          >
            <Flag code={country.flag} height={"13px"} />
            {country.code}
          </Box>
        </MenuItem>
      ))}
    </Select>
  );
};
