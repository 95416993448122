import { Slider } from "@mui/material";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { formatPrice } from "../../helpers/formatter";
import { useLanguageTheme } from "../../hooks/useLanguageTheme";
import { IPriceRange } from "../portfolio/interfaces";

export const PriceRangeSlider = ({
  priceRangeValue,
  setPriceRangeValue,
  styles,
}: {
  priceRangeValue: IPriceRange;
  setPriceRangeValue: Dispatch<SetStateAction<IPriceRange>>;
  styles: {
    color: string;
  };
}) => {
  const { i18n } = useTranslation();

  const currentLanguage = i18n.language;
  const isRTL = currentLanguage === "he";
  const langTheme = useLanguageTheme();

  const formatValueLabel = (value: number) => {
    return `${isRTL ? "₪" : "$"} ${formatPrice(value)}`;
  };

  // const handleChange = (e: any) => {
  //   const value = e.target.value as number[];

  //   let start = DEFAULT_PRICE_RANGE_START;
  //   let end = DEFAULT_PRICE_RANGE_END;

  //   if (value.length > 0) {
  //     start = value[0];
  //   }

  //   if (value.length > 1) {
  //     end = value[1];
  //   }

  //   setPriceRangeValue({
  //     start,
  //     end,
  //   });
  // };

  const [localValue, setLocalValue] = useState<[number, number]>([
    priceRangeValue.start,
    priceRangeValue.end,
  ]);

  useEffect(() => {
    setLocalValue([priceRangeValue.start, priceRangeValue.end]);
  }, [priceRangeValue]);

  const handleChange = (
    event: React.SyntheticEvent | Event,
    value: number | number[]
  ) => {
    if (Array.isArray(value)) {
      setLocalValue([value[0], value[1]]);
    }
  };

  const handleChangeCommitted = (
    event: React.SyntheticEvent | Event,
    value: number | number[]
  ) => {
    if (Array.isArray(value)) {
      setPriceRangeValue({ start: value[0], end: value[1] });
    }
  };

  return (
    <div
      style={{
        paddingTop: "20px",
      }}
    >
      <Slider
        value={localValue}
        onChangeCommitted={handleChangeCommitted}
        onChange={handleChange}
        max={100000000}
        valueLabelDisplay="on"
        valueLabelFormat={formatValueLabel}
        sx={{
          "& .MuiSlider-thumb": {
            backgroundColor: "rgba(3, 74, 115, 1)",
            width: "14px",
            height: "14px",
            "&:hover": {
              boxShadow: "0 0 0 8px #034a7324",
            },
          },
          "& .MuiSlider-track": {
            backgroundColor: "rgba(3, 74, 115, 1)",
            border: "none",
            height: "7px",
          },
          "& .MuiSlider-rail": {
            opacity: 0.5,
            backgroundColor: "rgba(169, 188, 196, 1)",
            height: "7px",
          },
          "& .MuiSlider-valueLabel": {
            backgroundColor: "transparent",
            color: "rgba(0, 0, 0, 1)",
            fontWeight: 400,
            fontSize: "14px",
            lineHeight: "14px",
            fontFamily: langTheme.mainFont(),
            transform: "translateY(-50%)",
            p: 0,
          },
        }}
      />
    </div>
  );
};
