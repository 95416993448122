import { useTranslation } from "react-i18next";
import { Footer } from "../components/_shared/Footer";
import { FooterSocialMediaLine } from "../components/_shared/FooterSocialMediaLine";
import { Header } from "../components/_shared/Header";
import { Navbar } from "../components/_shared/Navbar";
import { GetInTouchBlock } from "../components/home/GetInTouchBlock";
import { LearningCenterMustReadSection } from "../components/learningCenter/content/LearningCenterMustReadSection";
import { TopArticleSection } from "../components/learningCenter/content/TopArticleSection";
import { useGetArticlesBySectionsQuery } from "../store/api/articlesApiSlice";
import React from "react";
import { IArticle } from "../components/learningCenter/interfaces";
import { AdditionalArticlesContainer } from "../components/learningCenter/content/AdditionalArticlesContainer";
import { BrowseTheLibrary } from "../components/learningCenter/content/BrowseTheLibrary";
import { FooterDemo } from "../components/_shared/FooterDemo";
import { AuthPopup } from "../components/_shared/AuthPopup";

export const LearningCenter = () => {
  const { i18n } = useTranslation();

  const { data } = useGetArticlesBySectionsQuery(i18n.language);

  const [topArticles, setTopArticles] = React.useState<IArticle[]>([]);
  const [mustToReadArtcles, setMustToReadArtciles] = React.useState<IArticle[]>(
    []
  );

  React.useEffect(() => {
    if (data) {
      setTopArticles(data.data.newestResults);
      setMustToReadArtciles(data.data.differentResults);
    }
  }, [data]);

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <>
        <div className="portfolio-header-sticky-wrapper">
          <div
            style={{
              backgroundColor: "rgba(255, 255, 255, 1)",
            }}
          >
            <Header>
              <Navbar />
            </Header>
          </div>
        </div>
        <TopArticleSection articles={topArticles} />
        <AdditionalArticlesContainer articles={topArticles.slice(2)} />
        <LearningCenterMustReadSection articles={mustToReadArtcles} />
        <BrowseTheLibrary />
        <GetInTouchBlock />
        {/* <Footer /> */}
        <FooterDemo />

        <FooterSocialMediaLine />
        <AuthPopup />
      </>
    </div>
  );
};
