import React from "react";
import { LoadScript } from "@react-google-maps/api";
import { useTranslation } from "react-i18next";
import { REACT_APP_GOOGLE_API_KEY } from "../../helpers/config";

const libraries: ["places"] = ["places"];

export const GoogleMapsWrapper = ({ children }: any) => {
  const { i18n } = useTranslation(["portfolio"]);

  if (!REACT_APP_GOOGLE_API_KEY) {
    throw new Error("Google Maps API key is not defined");
  }

  return (
    <LoadScript
      googleMapsApiKey={REACT_APP_GOOGLE_API_KEY as string}
      libraries={libraries}
      language={i18n.language}
    >
      {children}
    </LoadScript>
  );
};
