import { AuthWrapper } from "../components/_shared/AuthWrapper";
import { EmailConfirmationContent } from "../components/auth/EmailConfirmationContent";

export const EmailConfirmation = () => {
  return (
    <AuthWrapper>
      <EmailConfirmationContent headerText={"email_confirmation"} />
    </AuthWrapper>
  );
};
