/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Popover, Theme, useMediaQuery } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import React, { useEffect, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import InfiniteScroll from "react-infinite-scroll-component";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ArrowBlack from "../../../../assets/icons/arrow_black.svg";
import HousePlusIcon from "../../../../assets/icons/house_plus.svg";
import {
  campaignStatusOptions,
  propertyByUserOptions,
  sortOptions,
} from "../../../../constants/propertyData";
import { Property } from "../../../../helpers/convert";
import { filterPropertiesByIds } from "../../../../helpers/dataConverts";
import { useAuth } from "../../../../hooks/useAuth";
import { useLanguageTheme } from "../../../../hooks/useLanguageTheme";
import {
  mapCheckedSelector,
  propertyErrorSelector,
} from "../../../../store/features/portfolioSlice";
import { CampaignStatusSelect } from "../filters/CampaignStatusSelect";
import { PropertyCard } from "../propertyCard/PropertyCard";
import { BelongToUserSelect } from "../filters/BelongToUserSelect";
import { MapUpMd } from "../map/MapUpMd";
import { MapSideBar } from "../map/MapSideBar";
import { IPropertiesPaginationMeta, ISortParams } from "../../interfaces";
import { PropertiesSortSelect } from "../filters/PropertiesSortSelect";
import { UserTypes } from "../../../_shared/Navbar";

export const portfolioSortStyles = {
  width: "231px",
  backgroundColor: "rgba(3, 74, 115, 0.05)",
  border: "none",
  height: "42px",
  borderRadius: "30px",
  fontSize: "16px",
  padding: "20px",
};

export const PropertiesContainer = ({
  allProperties,
  onPageChange,
  initSort,
  onSortChange,
  paginationMeta,
  isLoading,
  headerHeight,
  search,
  filters,
  initCampaingStatus,
  onCampaingChange,
  initUserId,
  onUserIdChange,
}: {
  allProperties: Property[];
  onPageChange: (pageNum: number) => void;
  initSort: ISortParams;
  onSortChange: (sortParams: ISortParams) => void;
  paginationMeta: IPropertiesPaginationMeta | null;
  isLoading: boolean;
  headerHeight: number;
  search: string;
  filters: any;
  initCampaingStatus: any;
  onCampaingChange: any;
  initUserId: any;
  onUserIdChange: any;
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation(["portfolio"]);
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const isRTL = currentLanguage === "he";
  const langTheme = useLanguageTheme();
  const isAuthenticated = useAuth();

  const downMd = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));
  const isFilterDesktop = useMediaQuery("(max-width: 1090px)");
  const isFilterDesktop2 = useMediaQuery("(max-width: 1200px)");

  const user = localStorage.getItem("user") || sessionStorage.getItem("user");
  const [visibleMarkerIds, setVisibleMarkerIds] = React.useState<number[]>([]);
  const mapChecked = useSelector(mapCheckedSelector);
  const [currPage, setCurrPage] = React.useState(1);
  const propertyError = useSelector(propertyErrorSelector);
  const tableRef = useRef<HTMLDivElement | null>(null);
  const elemRef = useRef<HTMLDivElement | null>(null);
  const [scrollThreshold, setScrollThreshold] = React.useState("80%");
  const allFilteredElements = useMemo(() => {
    if (!mapChecked && !isFilterDesktop) {
      return allProperties;
    }
    if (visibleMarkerIds.length === 0 && isFilterDesktop) {
      return allProperties;
    }
    return filterPropertiesByIds(allProperties, visibleMarkerIds);
  }, [
    allProperties,
    visibleMarkerIds,
    mapChecked,
    currentLanguage,
    search,
    isFilterDesktop,
  ]);

  useEffect(() => {
    onPageChange(1);
    setCurrPage(1);
  }, []);

  useEffect(() => {
    onPageChange(1);
    setCurrPage(1);
  }, [filters]);

  useEffect(() => {
    const updateThreshold = () => {
      if (!tableRef.current) return;
      const containerWidth = tableRef.current.offsetWidth;
      const itemWidth = elemRef?.current?.offsetWidth ?? 150;
      const rowCount = Math.floor(containerWidth / itemWidth) || 1;
      const totalRows = Math.ceil(allFilteredElements.length / rowCount);
      if (totalRows > 1) {
        setScrollThreshold(`${100 - 100 / totalRows}%`);
      }
    };

    updateThreshold();
    window.addEventListener("resize", updateThreshold);
    return () => window.removeEventListener("resize", updateThreshold);
  }, [allFilteredElements]);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const loadNewData = (mapCheckedL: boolean, visibleMarkerIdsL: any[]) => {
    onPageChange(currPage + 1);
    setCurrPage(currPage + 1);
  };
  return (
    <div
      style={{
        boxSizing: "border-box",
        paddingRight: "20px",
        paddingLeft: "20px",
        maxWidth: "2360px",
        width: "100%",
      }}
    >
      <div>
        {propertyError && (
          <div
            className="portfolio-error-container xl-width"
            style={{
              marginTop: downMd ? "30px" : "40px",
              marginBottom: downMd ? "30px" : "40px",
              fontFamily: langTheme.mainFont(),
            }}
          >
            Something went wrong
          </div>
        )}

        {isLoading ? (
          <div className="portfolio-error-container">
            <CircularProgress sx={{ color: "rgb(3, 74, 115)" }} />
          </div>
        ) : (
          <div
            className={`layout-container ${
              mapChecked && !isFilterDesktop ? "map-open" : ""
            }`}
            style={{
              direction: i18n.dir(),
              padding: "0 0 50px",
            }}
          >
            <div
              style={{
                paddingTop: downMd ? "30px" : "40px",
              }}
            >
              <div
                className="properties-top-info-container"
                style={{
                  direction: i18n.dir(),
                  flexDirection: downMd ? "column" : "row",
                  alignItems: isFilterDesktop ? "flex-start" : "center",
                  gap: isFilterDesktop || mapChecked ? "20px" : "",
                  marginBottom: downMd ? "35px" : "40px",
                  display: "flex",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  <div
                    style={{
                      fontFamily: langTheme.mainFont(),
                      fontWeight: isRTL ? 700 : 600,
                      fontSize: downMd ? "16px" : "22px",
                    }}
                  >
                    {mapChecked
                      ? allFilteredElements.length
                      : paginationMeta?.total}
                    <span>&nbsp;</span>
                    {t("properties_for_sale")}
                  </div>
                </div>

                {user && JSON.parse(user).userType === UserTypes.SELLER ? (
                  <div
                    style={{
                      display: "flex",
                      gap: "12px",
                    }}
                  >
                    {!isFilterDesktop && (mapChecked || isFilterDesktop2) && (
                      <>
                        <Button
                          aria-describedby={id}
                          onClick={handleClick}
                          sx={{
                            backgroundColor: "#034A730D",
                            textTransform: "none",
                            gap: "12px",
                            borderRadius: "21px",
                            padding: "7px 20px 7px 20px",
                          }}
                        >
                          <div
                            style={{
                              fontSize: "16px",
                              fontWeight: "500",
                              fontFamily: langTheme.mainFont(),
                              color: "#000000",
                            }}
                          >
                            {t("filters")}
                          </div>
                          <img
                            src={ArrowBlack}
                            style={{
                              transform: anchorEl ? "scaleY(1)" : "scaleY(-1)",
                            }}
                          />
                        </Button>
                        <Popover
                          id={id}
                          open={open}
                          anchorEl={anchorEl}
                          onClose={handleClose}
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "right",
                          }}
                          transformOrigin={{
                            vertical: "top",
                            horizontal: "right",
                          }}
                          PaperProps={{
                            elevation: 0,
                            sx: {
                              borderRadius: "10px",
                              overflow: "visible",
                              filter:
                                "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                              mt: "10px",
                            },
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              gap: "15px",
                              padding: "20px",
                              borderRadius: "20px",
                            }}
                          >
                            <PropertiesSortSelect
                              initSort={initSort}
                              onSortChange={onSortChange}
                              options={sortOptions}
                            />
                            <BelongToUserSelect
                              initSort={initUserId}
                              onSortChange={onUserIdChange}
                              options={propertyByUserOptions}
                              styles={portfolioSortStyles}
                            />
                            <CampaignStatusSelect
                              initSort={initCampaingStatus}
                              onSortChange={onCampaingChange}
                              options={campaignStatusOptions}
                              styles={portfolioSortStyles}
                            />
                          </div>
                        </Popover>
                      </>
                    )}

                    {!isFilterDesktop && !mapChecked && !isFilterDesktop2 && (
                      <div
                        style={{
                          display: "flex",
                          gap: "15px",
                          borderRadius: "20px",
                        }}
                      >
                        <BelongToUserSelect
                          initSort={initUserId}
                          onSortChange={onUserIdChange}
                          options={propertyByUserOptions}
                          styles={portfolioSortStyles}
                        />
                        <CampaignStatusSelect
                          initSort={initCampaingStatus}
                          onSortChange={onCampaingChange}
                          options={campaignStatusOptions}
                          styles={portfolioSortStyles}
                        />
                        <PropertiesSortSelect
                          initSort={initSort}
                          onSortChange={onSortChange}
                          options={sortOptions}
                        />
                      </div>
                    )}

                    {isFilterDesktop && (
                      <PropertiesSortSelect
                        initSort={initSort}
                        onSortChange={onSortChange}
                        options={sortOptions}
                      />
                    )}

                    <div
                      onClick={() => navigate("/campaign-page-builder")}
                      style={{
                        width: downMd ? "42px" : "198px",
                        height: "42px",
                        background:
                          "linear-gradient(90deg, #F0F4F7 45%, #FFE2B7 100%)",
                        borderRadius: "21px",
                        color: "black",
                        textTransform: "none",
                        padding: "0px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        fontSize: "1rem",
                        position: "relative",
                        fontFamily: langTheme.mainFont(),
                        gap: "10px",
                        cursor: "pointer",
                      }}
                    >
                      <img src={HousePlusIcon} />
                      {!downMd && <div>{t("new_campaign")}</div>}
                    </div>
                  </div>
                ) : (
                  <PropertiesSortSelect
                    initSort={initSort}
                    onSortChange={onSortChange}
                    options={sortOptions}
                  />
                )}
              </div>

              <InfiniteScroll
                dataLength={
                  mapChecked ? allProperties.length : allFilteredElements.length
                }
                next={() => loadNewData(mapChecked, visibleMarkerIds)}
                hasMore={
                  mapChecked
                    ? allProperties.length < Number(paginationMeta?.total) &&
                      visibleMarkerIds.length > 0
                    : allFilteredElements.length < Number(paginationMeta?.total)
                }
                // scrollThreshold={scrollThreshold}
                scrollThreshold={window.innerWidth > 1800 ? 0.2 : 0.3}
                loader={null}
                style={{ overflow: "hidden" }}
              >
                <div
                  className="cards-container"
                  id="scrollableDiv"
                  style={{
                    direction: i18n.dir(),
                    padding: "0 10px 10px",
                  }}
                  ref={tableRef}
                >
                  {allFilteredElements.map((property, index) => (
                    <div key={index} className="property-card" ref={elemRef}>
                      <PropertyCard property={property} />
                    </div>
                  ))}
                </div>
              </InfiniteScroll>
            </div>
            {!isFilterDesktop && mapChecked && (
              <div
                className="map-container"
                style={{
                  top: `${headerHeight}px`,
                  height: `calc(100vh - ${headerHeight}px)`,
                  marginBottom: 10,
                }}
              >
                <MapUpMd
                  properties={allProperties}
                  setVisibleMarkerIds={setVisibleMarkerIds}
                  search={search}
                />
              </div>
            )}
          </div>
        )}
      </div>
      <MapSideBar
        properties={allProperties}
        setVisibleMarkerIds={setVisibleMarkerIds}
      />
    </div>
  );
};
