import React from "react";
import { useTranslation } from "react-i18next";
import NextIcon from "../../../../assets/campaignBuilder/next_btn.svg";
import { useAppDispatch } from "../../../../store/store";
import { useLanguageTheme } from "../../../../hooks/useLanguageTheme";
import { usePortfolioNavigation } from "../../hooks/usePortfolioNavigation";
import { setCampaingBuilderSideBarOpen } from "../../../../store/features/common";

export const CampaignBuilderMobileHeader = ({
  builderMobileHeaderRef,
}: {
  builderMobileHeaderRef: any;
}) => {
  const { i18n, t } = useTranslation(["campaignBuilder"]);
  const dispatch = useAppDispatch();
  const langTheme = useLanguageTheme();
  const { navigateToPortfolio } = usePortfolioNavigation();

  const handleOpen = () => {
    dispatch(setCampaingBuilderSideBarOpen());
  };

  return (
    <div
      className="camapign-builder-mobile-header"
      style={{
        fontFamily: langTheme.mainFont(),
      }}
      ref={builderMobileHeaderRef}
    >
      <div
        className="campaign-builder-mobile-header-back-text"
        onClick={navigateToPortfolio}
      >
        {t("back")}
      </div>
      <div
        className="campaign-builder-sidenav-back-mobile"
        onClick={handleOpen}
      >
        <div className="camapign-builder-mobile-header-set-up">
          {t("campaign_set_up")}
        </div>
        <img
          src={NextIcon}
          style={{
            transform: i18n.dir() === "rtl" ? "scaleX(-1)" : "scaleX(1)",
          }}
        />
      </div>
    </div>
  );
};
