import { useState, useEffect, RefObject } from "react";

export function useElementHeight<T>(
  elementRef: RefObject<HTMLElement>,
  dependencies: T[] = [] as unknown as T[]
): number {
  const [height, setHeight] = useState(0);

  useEffect(() => {
    const calculateHeight = () => {
      if (elementRef?.current) {
        setHeight(elementRef.current.offsetHeight);
      } else {
        setHeight(0);
      }
    };
    calculateHeight();
    window.addEventListener("resize", calculateHeight);
    return () => {
      window.removeEventListener("resize", calculateHeight);
    };
  }, dependencies);

  return height;
}
