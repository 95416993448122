import React from "react";
import HeroImg from "../../assets/learningCenter/heroImgLearningCenter.png";
import { useTranslation } from "react-i18next";
import { Theme, useMediaQuery } from "@mui/material";
import { useNavigate } from "react-router-dom";
import YellowBackBtn from "../../assets/learningCenter/yellowBackBtn.svg";
import { useLanguageTheme } from "../../hooks/useLanguageTheme";
import { formatDateWithMonthByLocale } from "../../helpers/formatter";
import { IArticle } from "../learningCenter/interfaces";

export const DedicatedLearningCenterHeroImg = ({
  article,
}: {
  article: IArticle;
}) => {
  const { i18n, t } = useTranslation("learningCenter");
  const downMd = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));
  const isRTL = i18n.dir() === "rtl";
  const langTheme = useLanguageTheme();
  const navigate = useNavigate();

  return (
    <div
      className="dedicated-learning-center-hero-img-wrapper"
      style={{
        direction: i18n.dir(),
        height: downMd ? "375px" : "476px",
        background: `url(${
          !article?.mediaContent || article?.mediaContent?.length === 0
            ? HeroImg
            : article?.mediaContent[0]?.url
        }) center center / cover no-repeat`,
        fontFamily: langTheme.mainFont(),
      }}
    >
      <div className="background-shadow">
        <div className="dedicated-learning-center-hero-img-info-wrapper xl-width">
          <div className="dedicated-learning-center-addition-info">
            <div
              className="dedicated-learning-center-back-btn"
              onClick={() => navigate(-1)}
            >
              <img
                src={YellowBackBtn}
                style={{
                  transform: isRTL ? "scaleX(-1)" : " scaleX(1)",
                }}
              />
              <div>{t("back")}</div>
            </div>
            <div className="main-yellow-acticle-additional-info-line"></div>

            <div className="dedicated-learning-center-addition-info-wrapper">
              <div>
                {article?.minutesToRead} {t("min_read")}
              </div>
              <div className="main-yellow-acticle-additional-info-line"></div>
              <div>
                {formatDateWithMonthByLocale(
                  article?.createdAt,
                  isRTL ? "he-IL" : "en-US"
                )}
              </div>
            </div>
          </div>
          <div
            className="dedicated-learning-center-title"
            style={{
              fontFamily: isRTL ? "Noto Sans Hebrew" : "Frank Ruhl Libre",
              marginTop: downMd ? "15px" : "10px",
              fontSize: downMd ? "24px" : "40px",
              lineHeight: downMd ? "28px" : "42px",
              whiteSpace: "normal",
              overflowWrap: "normal",
              wordBreak: "normal",
            }}
          >
            {article?.title}
          </div>
          <div
            className="clue-container"
            style={{
              marginTop: "15px",
              flexWrap: "wrap",
            }}
          >
            {article?.tags.map((tag, index) => (
              <div className="clue-white-blue" key={index}>
                {t(tag.title)}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
