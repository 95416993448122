import { apiSlice } from '../api/apiSlice';

export const sellWithUsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getFaqs: builder.query({
      query: (lang: string) => {
        return {
          url: `faqs?locale=${lang}`,
          method: 'GET',
        };
      },
    }),
    getPartners: builder.query({
      query: (lang: string) => {
        return {
          url: `partners?populate=companyLogo&locale=${lang}`,
          method: 'GET',
        };
      },
    }),
    getSellerTestimonials: builder.query({
      query: (params) => {
        return {
          url: `testimonials?populate=*&filters[role]=${params.role}&locale=${params.lang}`,
          method: 'GET',
        };
      },
    }),
    getTransparentProcessStages: builder.query({
      query: (lang: string) => {
        return {
          url: `transparent-process-stages?populate=*&locale=${lang}`,
          method: 'GET',
        };
      },
    }),
  }),
});

export const { useGetFaqsQuery, useGetPartnersQuery, useGetSellerTestimonialsQuery, useGetTransparentProcessStagesQuery } = sellWithUsApiSlice;
