import React from "react";
import { PasswordResetEnterEmailStep } from "../auth/PasswordResetEnterEmailStep";
import { Theme, useMediaQuery } from "@mui/material";
import { ForgotPasswordStep } from "../../pages/ForgotPassword";
import { PasswordResetEmailConfirmStep } from "../auth/PasswordResetEmailConfirmStep";
import { PasswordResetNewPasswordStep } from "../auth/PasswordResetNewPasswordStep";
import { PasswordResetDoneStep } from "../auth/PasswordResetDoneStep";
import { useAppDispatch } from "../../store/store";
import { useSelector } from "react-redux";
import {
  forgotPasswordStepSelector,
  setForgotPasswordStep,
} from "../../store/features/common";

export const ForgotPasswordContent = () => {
  const downMd = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));
  const dispatch = useAppDispatch();

  const forgotpasswordCurrentStep = useSelector(forgotPasswordStepSelector);

  const [email, setEmail] = React.useState("");

  return (
    <div
      className="forgot-p-container"
      style={{
        height: downMd ? "100vh" : "",
      }}
    >
      {forgotpasswordCurrentStep === ForgotPasswordStep.enterEmail && (
        <PasswordResetEnterEmailStep
          onChangeStep={() =>
            dispatch(setForgotPasswordStep(ForgotPasswordStep.confirmEmail))
          }
          email={email}
          setEmail={setEmail}
        />
      )}

      {forgotpasswordCurrentStep === ForgotPasswordStep.confirmEmail && (
        <PasswordResetEmailConfirmStep email={email} />
      )}

      {forgotpasswordCurrentStep === ForgotPasswordStep.newPassword && (
        <PasswordResetNewPasswordStep
          onChangeStep={() =>
            dispatch(setForgotPasswordStep(ForgotPasswordStep.done))
          }
        />
      )}

      {forgotpasswordCurrentStep === ForgotPasswordStep.done && (
        <PasswordResetDoneStep />
      )}

      <div className="step-indicator">
        {Object.values(ForgotPasswordStep).map((step, index) => (
          <div
            key={step}
            className={`step ${
              forgotpasswordCurrentStep === step ? "active" : ""
            }`}
            style={{
              width: forgotpasswordCurrentStep === step ? "68px" : "40px",
              height: "5px",
              backgroundColor:
                forgotpasswordCurrentStep === step
                  ? "rgba(3, 74, 115, 1)"
                  : "rgba(3, 74, 115, 0.5)",
              borderRadius: "5px",
            }}
          />
        ))}
      </div>
    </div>
  );
};
