import React from "react";
import { CampaignSideNavStep } from "../layout/CampaignBuilderSideNav";
import { CampaignBuilderSideNavHeader } from "../layout/CampaignBuilderSideNavHeader";
import { CampainBuilderNextBtn } from "../buttons/CampainBuilderNextBtn";
import { TextField, Theme, useMediaQuery } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Errors, textFieldStyles } from "./MainInformationStep";
import { useLanguageTheme } from "../../../../hooks/useLanguageTheme";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../../../store/store";
import { setAboutPropertyDesc } from "../../../../store/features/campaignBuilderSlice";
import { useResetToDraft } from "../../../../hooks/useResetToDraft";
import { LangToggle } from "../../../_shared/LangToggle";
import { useParams } from "react-router-dom";
import {
  MAX_ABOUT_PROPERTY_LENGTH_EN,
  MAX_ABOUT_PROPERTY_LENGTH_HE,
  MIN_ABOUT_PROPERTY_LENGTH_EN,
  MIN_ABOUT_PROPERTY_LENGTH_HE,
} from "../../constants";
import { BackToMenuBtn } from "../buttons/BackToMenuBtn";
import { useContextMenu } from "react-contexify";
import AiContextMenu from "../../ai-chat/AiContextMenu";
import { CampainBuilderBackBtn } from "../buttons/CampainBuilderBackBtn";

export const AboutProperty = ({
  setCurrentStep,
  allFieldsErrors,
  setAllFieldsErrors,
  hasPublish,
  autosaveFunc = () => {},
  isDisabledSwitchLang = false,
}: {
  setCurrentStep: (step: CampaignSideNavStep) => void;
  allFieldsErrors: any;
  setAllFieldsErrors: any;
  hasPublish: boolean;
  autosaveFunc: any;
  isDisabledSwitchLang: boolean;
}) => {
  const dispatch = useAppDispatch();
  const { t, i18n } = useTranslation(["campaignBuilder"]);
  const isRTL = i18n.dir() === "rtl";
  const downMd = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));
  const langTheme = useLanguageTheme();
  const [errors, setErrors] = React.useState<any>({});
  const [hasSubmitted, setHasSubmitted] = React.useState(false);
  const resetToDraft = useResetToDraft();
  const { documentId } = useParams();

  const { aboutPropertyDesc } = useSelector(
    (state: any) => state.campaignBuilder
  );

  React.useEffect(() => {
    if (allFieldsErrors.about_property) {
      const validationErrors = validateFields();

      setErrors(validationErrors);
    }
  }, []);

  React.useEffect(() => {
    const newAllErrors = { ...allFieldsErrors };

    if (Object.keys(errors).length === 0) {
      delete newAllErrors.about_property;
    } else {
      newAllErrors.about_property = "about_property";
    }
    setAllFieldsErrors(newAllErrors);
  }, [errors]);

  const handleChangePropertyDesc = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newErrors = { ...errors };
    const value = event.target.value;
    dispatch(setAboutPropertyDesc(value));
    resetToDraft();
    localStorage.setItem("onChangeCompanyBuilder", "1");
    if (hasSubmitted || hasPublish) {
      if (!value.trim()) {
        newErrors.aboutPropertyDesc = t("errors.desc_is_required");
      } else if (
        value.trim().length <
        (isRTL ? MIN_ABOUT_PROPERTY_LENGTH_HE : MIN_ABOUT_PROPERTY_LENGTH_EN)
      ) {
        newErrors.aboutPropertyDesc = isRTL
          ? t("errors.min_800_chars_required")
          : t("errors.min_1000_chars_required");
      } else {
        delete newErrors.aboutPropertyDesc;
      }

      setErrors(newErrors);
    }
  };

  const validateFields = () => {
    let errors: Partial<Errors> = {};

    if (aboutPropertyDesc.trim() === "") {
      errors.aboutPropertyDesc = t("errors.desc_is_required");
    } else if (
      aboutPropertyDesc.trim().length <
      (isRTL ? MIN_ABOUT_PROPERTY_LENGTH_HE : MIN_ABOUT_PROPERTY_LENGTH_EN)
    ) {
      errors.aboutPropertyDesc = isRTL
        ? t("errors.min_800_chars_required")
        : t("errors.min_1000_chars_required");
    }

    return errors;
  };

  const handleNext = (step: CampaignSideNavStep) => {
    setHasSubmitted(true);
    const validationErrors = validateFields();

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      setCurrentStep(step);
    } else {
      setCurrentStep(step);
    }
    if (!documentId) {
      autosaveFunc();
    }
  };

  const { show } = useContextMenu({ id: "aiFieldContextMenu" });
  const displayMenu = (e: any, key: string, value: any) => {
    show({
      event: e,
      props: {
        key: key,
        value: value,
      },
    });
  };

  return (
    <div className="campaign-builder-side-nav-wrapper">
      <CampaignBuilderSideNavHeader
        header="about_property"
        leftBtn={
          <CampainBuilderBackBtn
            setCurrentStep={setCurrentStep}
            step={CampaignSideNavStep.investmentHighlights}
          />
        }
        rightBtn={
          <CampainBuilderNextBtn
            handleNext={handleNext}
            step={CampaignSideNavStep.keyFeatures}
          />
        }
      />
      <AiContextMenu />

      <div
        className="campaign-builder-sidenav-main-content-wrapper"
        style={{
          paddingTop: downMd ? "30px" : "40px",
          direction: i18n.dir(),
        }}
      >
        <div className="campaign-builder-sidenav-settings-fileds-container">
          <div className="campaign-builder-sidenav-settings-fileds-item">
            <div className="campaign-builder-sidenav-field-name">
              {t("message")}
            </div>

            <div style={{ position: "relative" }}>
              <TextField
                fullWidth
                multiline
                rows={6.4}
                placeholder={t("about_property_placeholder")}
                autoComplete="off"
                value={t(aboutPropertyDesc)}
                onChange={(e: any) => {
                  handleChangePropertyDesc(e);
                }}
                onContextMenu={(e) =>
                  displayMenu(e, "aboutPropertyDesc", aboutPropertyDesc)
                }
                inputProps={{
                  maxLength: isRTL
                    ? MAX_ABOUT_PROPERTY_LENGTH_HE
                    : MAX_ABOUT_PROPERTY_LENGTH_EN,
                }}
                sx={{
                  ...textFieldStyles(
                    langTheme.mainFont(),
                    errors.aboutPropertyDesc
                  ),
                  "& .MuiInputBase-root": {
                    alignItems: "flex-start",
                    gap: "3px",
                    paddingBottom: "40px",
                  },
                  "& textarea": {
                    scrollbarWidth: "thin",
                    "&::-webkit-scrollbar": {
                      width: "3px",
                    },
                    "&::-webkit-scrollbar-track": {
                      background: "transparent",
                    },
                    "&::-webkit-scrollbar-thumb": {
                      backgroundColor: "gray",
                      borderRadius: "4px",
                    },
                  },
                }}
              />

              <div
                style={{
                  position: "absolute",
                  bottom: "13px",
                  right: isRTL ? "auto" : "27px",
                  left: isRTL ? "27px" : "auto",
                  fontFamily: "DM Sans",
                  color: "#838A9E",
                  padding: "2px 4px",
                  borderRadius: "4px",
                  pointerEvents: "none",
                  fontSize: "14px",
                }}
              >
                {aboutPropertyDesc.length}/
                {isRTL
                  ? MAX_ABOUT_PROPERTY_LENGTH_HE
                  : MAX_ABOUT_PROPERTY_LENGTH_EN}
              </div>
            </div>

            {errors.aboutPropertyDesc && (
              <div className="campaign-builder-errors">
                {errors.aboutPropertyDesc}
              </div>
            )}
          </div>
        </div>
        <BackToMenuBtn setCurrentStep={setCurrentStep} />
      </div>
    </div>
  );
};
