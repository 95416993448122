import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { useTranslation } from "react-i18next";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useLanguageTheme } from "../../hooks/useLanguageTheme";
import { useUserProfile } from "../../hooks/useUserProfile";
import { Avatar, Box, Button, FormControl, Grow, Menu } from "@mui/material";
import { setCloseSideNav } from "../../store/features/common";
import { useAppDispatch } from "../../store/store";
import { useNavigate } from "react-router-dom";
import Arrow from "../../assets/icons/select_arrow_black.svg";
import { Language } from "./LangToggle";
import { useUpdateUserMutation } from "../../store/api/authApiSlice";
import React from "react";

export const ProfileSelect = React.memo(
  ({ disabled = false }: { disabled?: boolean }) => {
    const { t } = useTranslation(["navbar"]);
    const { i18n } = useTranslation();
    const currentLanguage = i18n.language;
    const langTheme = useLanguageTheme();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const {
      profile,
      isLoading,
      error,
    }: { profile: any; isLoading: any; error: any } = useUserProfile();
    const [updateUser] = useUpdateUserMutation();
    const fullName = profile?.fullName ? profile.fullName.split(" ")[0] : "";
    const profilePicture = profile?.profilePhoto?.formats?.thumbnail?.url;
    const selectedLang =
      currentLanguage ||
      (localStorage.getItem("lang") as Language) ||
      Language.ENGLISH;

    useEffect(() => {
      try {
        if (
          profile &&
          profile?.details?.language &&
          currentLanguage !== profile?.details?.language
        ) {
          const language = profile?.details?.language;
          i18n.changeLanguage(language);
          localStorage.setItem("lang", language);
        }
      } catch (error: any) {
        console.log(error.data.error);
      }
    }, [profile]);

    // const [currency, setCurrency] = useState('usd');
    const [anchorEl, setAnchorEl] = useState(null);
    const handleClick = (event: any) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };

    const handleLogOut = () => {
      handleClose();
      dispatch(setCloseSideNav());
      localStorage.removeItem("jwt");
      localStorage.removeItem("user");
      sessionStorage.removeItem("jwt");
      sessionStorage.removeItem("user");
      if (window.location.pathname === "/home") {
        window.location.reload();
      } else {
        navigate("/home");
      }
    };

    const handleProfileClick = () => {
      navigate("/profile");
    };

    const handleWishlistClick = () => {
      navigate("/wishlist");
    };

    const handleLanguageChange = async (event: any) => {
      const newLang =
        selectedLang === Language.ENGLISH ? Language.HEBREW : Language.ENGLISH;
      localStorage.setItem("lang", newLang);
      try {
        const userString = await (localStorage.getItem("user") ||
          sessionStorage.getItem("user"));
        const user = await (userString ? JSON.parse(userString) : null);
        if (user) {
          await updateUser({
            details: { ...profile?.details, language: newLang },
          }).unwrap();
        }
      } catch (error: any) {
        console.log("error", error);

        console.log(error.data.error);
      } finally {
        setTimeout(() => {
          i18n.changeLanguage(newLang);
        }, 500);
      }
    };

    // const handleCurrencyChange = (event: any) => {
    //   setCurrency(event.target.value);
    // };

    if (isLoading) {
      return (
        <div
          style={{
            fontFamily: langTheme.mainFont(),
            color: "#000000",
            fontSize: "14px",
          }}
        >
          {t("loading")}
        </div>
      );
    }

    if (error) {
      handleLogOut();
    }

    return (
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          gap: 5,
          fontFamily: langTheme.mainFont(),
          direction: i18n.dir(),
        }}
      >
        {profilePicture ? (
          <Avatar
            src={profilePicture}
            alt="picture"
            sx={{
              width: "47px",
              height: "47px",
            }}
            // onClick={disabled ? () => {} : () => handleProfileClick()}
          />
        ) : (
          <Avatar
            alt="picture"
            sx={{
              bgcolor: "#E8F4F8",
              color: "#034A736E",
              width: "47px",
              height: "47px",
            }}
            // onClick={disabled ? () => {} : () => handleProfileClick()}
          >
            {fullName.charAt(0).toUpperCase()}
          </Avatar>
        )}
        <div style={{ height: "100%" }}>
          <Button
            aria-controls="user-menu"
            aria-haspopup="true"
            onClick={disabled ? () => {} : (e) => handleClick(e)}
            style={{
              fontFamily: langTheme.mainFont(),
              color: "#000000",
              textTransform: "none",
              gap: "5px",
            }}
          >
            {fullName}
            <img src={Arrow} alt="arrow" style={{ marginLeft: "5px" }} />
          </Button>
          <Menu
            id="user-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
            TransitionComponent={Grow}
            sx={{
              direction: i18n.dir(),
            }}
          >
            <MenuItem
              onClick={handleWishlistClick}
              sx={{
                fontFamily: langTheme.mainFont(),
              }}
            >
              {t("wishlist")}
            </MenuItem>
            <MenuItem
              // onClick={handleProfileClick}
              sx={{
                fontFamily: langTheme.mainFont(),
              }}
            >
              {t("my_profile")}
            </MenuItem>
            <MenuItem
              onClick={handleLogOut}
              sx={{
                fontFamily: langTheme.mainFont(),
              }}
            >
              {t("log_out")}
            </MenuItem>
            <Box display="flex" justifyContent="space-between" width="100%">
              <Box mx={2} my={1}>
                <FormControl variant="standard" fullWidth>
                  <Select
                    value={selectedLang}
                    onChange={handleLanguageChange}
                    label="Language"
                    sx={{
                      fontFamily: langTheme.mainFont(),
                    }}
                  >
                    <MenuItem value="he">עב</MenuItem>
                    <MenuItem
                      value="en"
                      sx={{
                        fontFamily: langTheme.mainFont(),
                      }}
                    >
                      En
                    </MenuItem>
                  </Select>
                </FormControl>
              </Box>
            </Box>
          </Menu>
        </div>
      </div>
    );
  }
);
