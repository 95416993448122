import React, { useEffect, useState } from 'react';
import { Box, Typography, Button, Divider } from '@mui/material';
import Grid from '@mui/material/Grid2';
import PropertyCard from './PropertyCard';
import DeleteIcon from '../../assets/icons/delete.svg';
import ViewListIcon from '../../assets/icons/list.svg';
import ViewGridIcon from '../../assets/icons/grid.svg';
import { useTranslation } from 'react-i18next';
import { useWishlistProperties } from '../../hooks/useWishlistProperties';

export function Wishlist({ isMobile }: { isMobile: boolean }) {
  let { properties, isLoading, error, deletePropertiesFromWishlist } =
    useWishlistProperties();
  const [isListLayout, setIsListLayout] = useState(false);
  const [wishlistProperties, setWishlistProperties] = useState(properties);
  const [selectedProperties, setSelectedProperties] = useState<number[]>([]);

  useEffect(() => {
    setWishlistProperties(properties);
  }, [properties]);

  const handleSelect = (propertyId: number) => {
    if (selectedProperties.includes(propertyId)) {
      setSelectedProperties((prev) => prev.filter((val) => val !== propertyId));
    } else {
      setSelectedProperties((prev) => [...prev, propertyId]);
    }
  };

  const handleDelete = async () => {
    if (deletePropertiesFromWishlist && selectedProperties) {
      const leftProperties = wishlistProperties
        ?.filter((p) => !selectedProperties.includes(p.id))
        .map((p) => p.id);
      if (leftProperties) {
        await deletePropertiesFromWishlist!(leftProperties);
        setSelectedProperties([]);
      }
    }
  };

  const handleRemoveProperty = (propertyId: number) => {
    setWishlistProperties((prevProperties) =>
      prevProperties?.filter((property) => property.id !== propertyId),
    );
  };

  const { t, i18n } = useTranslation(['profile']);
  const isRTL = i18n.dir() === 'rtl';

  const toggleLayout = () => {
    setIsListLayout((prev) => !prev);
  };

  if (isLoading) {
    return <div>{t('loading')}</div>;
  }
  if (error) {
    return <div>{t('error')}</div>;
  }
  if (wishlistProperties === undefined || wishlistProperties?.length === 0) {
    return <Typography variant="h3">{t('wishlist_empty')}</Typography>;
  }

  return (
    <>
      <Box
        sx={{
          width: '100%',
          borderRadius: isMobile ? 0 : '30px',
          mr: isMobile ? '0' : '30px',
          py: isMobile ? '0' : '30px',
          border: '1px mixed #E0E5F2',
          backgroundColor: 'white',
        }}
      >
        <Box
          sx={{
            direction: isRTL ? 'rtl' : 'ltr',
            mx: '30px',
          }}
        >
          <Box>
            <Typography
              gutterBottom
              color="#2B3674"
              fontSize={isMobile ? 20 : 28}
              fontFamily={'DM Sans'}
              fontWeight={'bold'}
            >
              {t('wishlist')}
            </Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              mb: 3,
            }}
          >
            <Box>
              <Typography variant="body2" color="text.secondary">
                {selectedProperties.length
                  ? selectedProperties?.length +
                    ' ' +
                    t('properties') +
                    ' ' +
                    t('selected')
                  : t('total') +
                    ':' +
                    ' ' +
                    wishlistProperties?.length +
                    ' ' +
                    t('properties')}
              </Typography>
            </Box>
            <Box
              sx={{
                display: 'flex',
                gap: 2,
                direction: isRTL ? 'rtl' : 'ltr',
              }}
            >
              {!isMobile && (
                <Button
                  variant="outlined"
                  size="small"
                  sx={{
                    textTransform: 'capitalize',
                    fontWeight: 600,
                    color: '#034A73',
                    borderColor: '#E0E5F2',
                    '&:hover': { backgroundColor: '#D9EBF3' },
                    borderRadius: '24px',
                    px: 2,
                    py: 0.5,
                  }}
                  onClick={toggleLayout}
                >
                  {isListLayout ? (
                    <>
                      <img
                        src={ViewGridIcon}
                        alt=""
                        style={{ padding: '5px' }}
                      />
                      {t('grid')}
                    </>
                  ) : (
                    <>
                      <img
                        src={ViewListIcon}
                        alt=""
                        style={{ padding: '5px' }}
                      />
                      {t('list')}
                    </>
                  )}
                </Button>
              )}

              <Button
                variant="outlined"
                size="small"
                onClick={() => handleDelete()}
                sx={{
                  textTransform: 'capitalize',
                  fontWeight: 600,
                  borderColor: selectedProperties.length
                    ? '#034A73'
                    : '#E0E5F2',
                  color: '#034A73',
                  '&:hover': { borderColor: '#023952' },
                  borderRadius: '24px',
                  px: 2,
                  py: 0.5,
                }}
              >
                <img src={DeleteIcon} alt="" style={{ padding: '5px' }} />
                {t('delete')}
              </Button>
            </Box>
          </Box>
        </Box>
        <Divider sx={{ m: 2 }} />

        {isListLayout ? (
          <Box sx={{ mx: '30px' }}>
            {wishlistProperties?.map((property: any) => (
              <Box
                key={property.name}
                sx={{ mb: 3 }}
                onClick={() => handleSelect(property.id)}
              >
                <PropertyCard
                  property={property}
                  isListLayout={isListLayout}
                  onRemoveProperty={handleRemoveProperty}
                  isMobile={isMobile}
                  isSelected={selectedProperties.includes(property.id)}
                />
              </Box>
            ))}
          </Box>
        ) : (
          <Grid
            container
            spacing={6}
            sx={{
              mr: '30px',
              pl: '20px',
              justifyContent: 'flex-start',
              minWidth: isMobile ? '300px' : '100%',
              // width: {md: '600px',lg: '800px',xl: '1175px'}
            }}
          >
            {wishlistProperties?.map((property: any) => (
              <Grid
                key={property.id}
                size={{ sm: 12, md: 6, lg: 4 }}
                onClick={() => handleSelect(property.id)}
              >
                <PropertyCard
                  property={property}
                  isListLayout={isListLayout}
                  onRemoveProperty={handleRemoveProperty}
                  isMobile={isMobile}
                  isSelected={selectedProperties.includes(property.id)}
                />
              </Grid>
            ))}
          </Grid>
        )}
      </Box>
    </>
  );
}
