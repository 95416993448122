import React from "react";
import BackIcon from "../../../../assets/campaignBuilder/back.svg";
import { useTranslation } from "react-i18next";
import { CampaignSideNavStep } from "../layout/CampaignBuilderSideNav";

export const CampainBuilderBackBtn = ({
  setCurrentStep,
  step,
}: {
  setCurrentStep: (step: CampaignSideNavStep) => void;
  step: CampaignSideNavStep;
}) => {
  const { t } = useTranslation(["campaignBuilder"]);

  return (
    <div
      className="campaign-builder-sidenav-back"
      onClick={() => setCurrentStep(step)}
    >
      <div>
        <img src={BackIcon} />
      </div>
      <div className="campaign-builder-sidenav-back-text">{t("back")}</div>
    </div>
  );
};
