import { useTranslation } from "react-i18next";
import { Theme, useMediaQuery } from "@mui/material";
import { useSelector } from "react-redux";
import EditIcon from "../../../../assets/campaignBuilder/edit.svg";
import UloadIcon from "../../../../assets/campaignBuilder/upload.svg";
import { useNavigate } from "react-router";
import { Property } from "../../../../helpers/convert";
import { calculateRemainingTime } from "../../../../helpers/dataConverts";
import { LikeDemo, LikeSize } from "../../../_shared/LikeDemo";
import { PreviewMode } from "../../types";
import { UserTypes } from "../../../_shared/Navbar";

export const CampaignBuilderHeader = ({
  onlyView,
  documentId,
  mainInfoRef,
  propertyId,
  property,
}: {
  onlyView: boolean;
  documentId: string;
  mainInfoRef: any;
  propertyId: number;
  property: Property;
}) => {
  const { t, i18n } = useTranslation(["campaignBuilder"]);
  const isRTL = i18n.dir() === "rtl";

  const user = localStorage.getItem("user") || sessionStorage.getItem("user");
  const downMd = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));
  const {
    countOfTimePeriods,
    timePeriod,
    propertyTitle,
    propertyDescription,
    propertyLocation,
  } = useSelector((state: any) => state.campaignBuilder);
  const navigate = useNavigate();
  const expireTime = calculateRemainingTime(countOfTimePeriods, timePeriod);

  const { previewMode } = useSelector(
    (state: any) => state.campaignBuilderSetup
  );

  return (
    <div
      className="campaign-builder-main-header-container"
      style={{
        flexDirection: previewMode === PreviewMode.DESKTOP ? "row" : "column",
      }}
    >
      <div>
        {!onlyView && (
          <div className="campaign-builder-main-container-expire-time">
            <div>{t("expire_after")}</div>
            <span>
              &nbsp; {expireTime.days} {t("days")}
            </span>
          </div>
        )}
        <div ref={mainInfoRef}></div>
        <div
          className="campaign-builder-main-container-header-title"
          style={{
            fontSize:
              previewMode === PreviewMode.MOBILE || downMd ? "24px" : "26px",
            fontFamily: isRTL ? "Noto Sans Hebrew" : "Frank Ruhl Libre",
          }}
        >
          {propertyTitle ? propertyTitle : t("title_of_property_plug")}
        </div>
        <div className="campaign-builder-main-container-expire-time">
          {propertyLocation?.address ? propertyLocation?.address : t("adress")}
        </div>
        <div
          className="campaign-builder-main-container-header-desc"
          style={{
            marginBottom: downMd ? "20px" : "30px",
          }}
        >
          {propertyDescription
            ? propertyDescription
            : t("description_of_property_plug")}
        </div>
      </div>
      <div
        className="campaign-builder-main-upload-section"
        style={{
          marginBottom:
            previewMode === PreviewMode.MOBILE || downMd ? "20px" : "0px",
        }}
      >
        {user &&
          JSON.parse(user).id === property?.owner?.id &&
          onlyView &&
          JSON.parse(user).userType === UserTypes.SELLER && (
            <div
              className="campaign-builder-main-edit-item"
              onClick={() => navigate(`/campaign-page-builder/${documentId}`)}
            >
              <img src={EditIcon} />
            </div>
          )}
        <div className="campaign-builder-main-upload-item">
          <img src={UloadIcon} />
        </div>
        <LikeDemo propertyId={propertyId} likeSize={LikeSize.LARGE} />
      </div>
    </div>
  );
};
