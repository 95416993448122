import React, { useEffect, useMemo, useState } from "react";
import { AiHeader } from "./AiHeader";
import { Assistant } from "./Assistant";
import { User } from "./User";
import AiFooter from "./AiFooter";
import { QuickActions } from "./QuickActions";
import sendIcon from "../../../assets/campaignBuilder/ai-chat/sendImage.svg";
import aiSparkIcon from "../../../assets/aiAssistant/AIIcon.svg";
import { useSelector } from "react-redux";
import { campaignBuilderOpenSelector } from "../../../store/features/common";
import { ThreeDots } from 'react-loader-spinner'
import { useLazyGetPropertyQuery } from "../../../store/api/propertyApiSlice";
import {
  setAboutDeveloper,
  setAboutPropertyDesc,
  setLanguage,
  setPropertyDescription,
  setPropertyLocation,
  setPropertyPrice,
  setPropertyStatus,
  setPropertyTitle,
  setPropertyType,
  setSelectedAgent,
  setOldData,
  selectOldData
} from "../../../store/features/campaignBuilderSlice";
import { useAppDispatch } from "../../../store/store";
import { CampaignSideNavStep, Language, PropertyStatus, PropertyType } from "../content/layout/CampaignBuilderSideNav";
import { useUserProfile } from "../../../hooks/useUserProfile";
import { API_API_BASE_URL, BACKEND_HOST } from "../../../helpers/api";
import axios from "axios";
import {
  Menu,
  Item,
  Separator,
  Submenu,
  useContextMenu
} from "react-contexify";
import "react-contexify/dist/ReactContexify.css";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { title } from "process";

interface PropertyLocation {
  city?: string;
  address?: string;
  coordinates?: {
    latitude?: number;
    longitude?: number;
  };
  id?: string;
}

interface AllData {
  form: {
    language: string;
    countOfTimePeriods: number;
    timePeriod: string;
    propertyTitle: string;
    propertyDescription: string;
    propertyStatus: string;
    currency: string;
    propertyPrice: string;
    propertyLocation: PropertyLocation;
    propertyType: string;
    aboutPropertyDesc: string;
    aboutDeveloper: string;
    keyFeaturesBack: string;
    hightlightsBack: string;
    selectedUserId: string;
    labelBack: string;
    imgFilesSlice: any;
    brochureFilesSlice: any;
    floorPlanFilesSlice: any;
    videoFilesSlice: any;
    campaignStatus: string;
    mainLabelOpen: boolean;
    floorPlanSectionOpen: boolean;
    mapSectionOpen: boolean;
    videoSectionOpen: boolean;
    scheduleVewingOpen: boolean;
    agentPhotOpen: boolean;
    galleryOpen: boolean;
    previewMode: string;
    link?: string;
    phone?: string;
    nameHe?: string;
    nameEn?: string;
  };
}

interface AiChatProps {
  isRTL?: boolean;
  currentStep?: CampaignSideNavStep;
}

export const AiChat = ({
  isRTL = true,
  currentStep = undefined
}: AiChatProps) => {
  const dispatch = useAppDispatch();

  const [isOpen, setIsOpen] = useState(() => {
    const savedState = localStorage.getItem("isOpen");
    return savedState ? JSON.parse(savedState) : false;
  });

  useEffect(() => {
    const handleBeforeUnload = () => {
      localStorage.removeItem("isOpen");
    };
    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  useEffect(() => {
    localStorage.setItem("isOpen", JSON.stringify(isOpen));
  }, [isOpen]);;


  const campaignBuilderOpen = useSelector(campaignBuilderOpenSelector);
  const [prompt, setPromt] = useState('');
  const [submit, setSubmit] = useState('');
  const [loading, setLoading] = useState(false);
  const [openFooterTopButton, setOpenFooterTopButton] = useState(false);
  const [applyBtn, setApplyBtn] = useState('')
  const [allData, setAllData] = useState<AllData | null>(null);
  const [formPayload, setFormPayload] = useState({});
  const [newFormPayload, setNewFormPayload] = useState({});
  const [openQuickActions, setOpenQuickActions] = useState(false)
  const [currentsection, setCurrentsection] = useState('')


  const {
    selectedAgent,
    language,
    countOfTimePeriods,
    timePeriod,
    propertyTitle,
    propertyDescription,
    propertyStatus,
    currency,
    propertyPrice,
    propertyLocation,
    propertyType,
    aboutPropertyDesc,
    aboutDeveloper,
    keyFeaturesBack,
    hightlightsBack,
    selectedUserId,
    labelBack,
    imgFilesSlice,
    brochureFilesSlice,
    floorPlanFilesSlice,
    videoFilesSlice,
    campaignStatus,
    mainLabelOpen,
    floorPlanSectionOpen,
    mapSectionOpen,
    videoSectionOpen,
    scheduleVewingOpen,
    agentPhotOpen,
    galleryOpen,
    previewMode,
  } = useSelector((state: any) => state.campaignBuilder);
  const { t } = useTranslation(["aiChart"]);
  const {
    profile,
    isLoading,
    error,
  }: { profile: any; isLoading: any; error: any } = useUserProfile();
  const [currentAction, setCurrentAction] = useState('');
  const [chatArray, setChatArray] = useState([{ type: "ai", message: `${t("welcome_back")} __FIRST_NAME__${t("help_you")}` },])
  const oldData = useSelector(selectOldData);
  const { documentId } = useParams();

  const [langageChangeformData, setLangageChangeformData] = useState({
    propertyTitle: '',
    propertyDescription: '',
    aboutDeveloper: '',
    aboutPropertyDesc: '',

  })



  useEffect(() => {
    currentStep === 'campaignSetUp' && setFormPayload({
      propertyTitle,
      propertyDescription,
      aboutDeveloper,
      aboutPropertyDesc
    });
    currentStep === 'settings' && setFormPayload({});
    currentStep === 'mainInformation' && setFormPayload({
      propertyTitle,
      propertyDescription,
    });

    currentStep === 'aboutDeveloper' && setFormPayload({
      aboutDeveloper
    });
    currentStep === 'aboutProperty' && setFormPayload({
      aboutPropertyDesc
    });

    currentStep === 'contactInformation' && setFormPayload({
      selectedAgent,
    });

  }, [
    currentStep,
    selectedAgent,
    language,
    countOfTimePeriods,
    timePeriod,
    propertyTitle,
    propertyDescription,
    propertyStatus,
    currency,
    propertyPrice,
    propertyLocation,
    propertyType,
    aboutPropertyDesc,
    aboutDeveloper,
    keyFeaturesBack,
    hightlightsBack,
    selectedUserId,
    labelBack,
    imgFilesSlice,
    brochureFilesSlice,
    floorPlanFilesSlice,
    videoFilesSlice,
    campaignStatus,
    mainLabelOpen,
    floorPlanSectionOpen,
    mapSectionOpen,
    videoSectionOpen,
    scheduleVewingOpen,
    agentPhotOpen,
    galleryOpen,
    previewMode,
  ])

  const applyAction = (title: string, action: { task: string; prompt: string }, actionSlug: string,) => {
    setPromt('');
    setOpenQuickActions(false)
    setLoading(true);

    setCurrentAction(actionSlug);
    setChatArray(prevChatArray => [...prevChatArray, { type: 'user', message: title }]);


    axios.post(API_API_BASE_URL + "/analysis", {
      form: actionSlug === 'translate' ? langageChangeformData : formPayload,
      instruction: {
        task: action.task || "Nothing",
        prompt: action.prompt || prompt
      }
    }).then(response => {
      const result = response.data;

      if (result) {
        setAllData(result);
        setSubmit('');
        setOpenFooterTopButton(true);
        const hebrewKey: Record<string, string> = {
          propertyTitle: (t("title_of_property_plug")),
          propertyDescription: (t("description_of_property_plug")),
          propertyLocation: (t("full_address")),
          aboutDeveloper: (t("aboutDeveloper")),
          aboutPropertyDesc: (t("aboutPropertyDesc"))
        }

        const formattedData = result?.invalidated?.map((key: string, index: number) => {
          const formattedKey = Object.keys(result.form).find(k => k.toLowerCase() === key);
          return formattedKey ? `${isRTL ? (hebrewKey[formattedKey] || formattedKey) : formattedKey}:- \n${result.form[formattedKey]}` : null;
        }).filter(Boolean).join(".\n \n");
        setChatArray(prevChatArray => {
          if (result?.invalidated?.length === 0 && !result?.answer) {
            return prevChatArray;
          }
          return [...prevChatArray, { type: 'ai', message: (result?.answer || formattedData) }];
        });
      }
    }).catch(error => {
      console.error('Failed to analyze campaign:', error);
    }).finally(() => {
      setLoading(false);
    })
  }


  const analyzeCampaign = async () => {
    try {
      const { data } = await axios.get(`${BACKEND_HOST}/properties/${documentId}?populate=*&locale=${isRTL ? 'he' : 'en'}`);

      const localization = data?.data?.localizations[0];
      if (!localization) return;

      const newFormData = {
        propertyTitle: '',
        propertyDescription: '',
        aboutDeveloper: '',
        aboutPropertyDesc: ''
      };

      switch (currentStep) {
        case 'aboutProperty':
          newFormData.propertyTitle = localization.name;
          newFormData.propertyDescription = localization.description;
          newFormData.aboutPropertyDesc = localization.aboutProperty;
          newFormData.aboutDeveloper = localization.developer;
          break;
        case 'mainInformation':
          newFormData.propertyTitle = localization.name;
          newFormData.propertyDescription = localization.description;
          break;
        case 'aboutDeveloper':
          newFormData.aboutDeveloper = localization.developer;
          break;
        case 'aboutProperty':
          newFormData.aboutPropertyDesc = localization.aboutProperty;
          break;
        default:
          break;
      }

      setLangageChangeformData(prev => ({
        ...prev,
        ...newFormData
      }));

    } catch (error) {
      console.error(error);
    }
  };


  useEffect(() => {
    if (submit === 'send') {
      applyAction(prompt, { task: 'Nothing', prompt: prompt }, '');
    }
    analyzeCampaign();
  }, [submit])


  useEffect(() => {
    if (!allData) return;

    if (applyBtn === 'Apply' || applyBtn === 'Preview') {
      const currentState = {
        form: {
          language,
          countOfTimePeriods,
          timePeriod,
          propertyTitle,
          propertyDescription,
          propertyStatus,
          currency,
          propertyPrice,
          propertyLocation,
          propertyType,
          aboutPropertyDesc,
          aboutDeveloper,
          keyFeaturesBack,
          hightlightsBack,
          selectedUserId,
          labelBack,
          imgFilesSlice,
          brochureFilesSlice,
          floorPlanFilesSlice,
          videoFilesSlice,
          campaignStatus,
          mainLabelOpen,
          floorPlanSectionOpen,
          mapSectionOpen,
          videoSectionOpen,
          scheduleVewingOpen,
          agentPhotOpen,
          galleryOpen,
          previewMode,
        }
      };

      dispatch(setOldData(currentState));
      applyChanges(allData);
    }

  }, [allData, applyBtn]);

  useEffect(() => {
    if (applyBtn === 'Preview Cancel' && oldData) {
      applyChanges(oldData);
    }
    else if (oldData || applyBtn === 'Cancel') {
      if (oldData &&  applyBtn === 'Cancel') {
        applyChanges(oldData);
        setOpenFooterTopButton(false);
      }
      if ( applyBtn === 'Cancel') {
        setOpenFooterTopButton(false);
        setTimeout(() => {
          setApplyBtn('')
        }, 1000);
      }
      
     
    }

  }, [applyBtn, oldData]);

  const applyChanges = (data: AllData) => {
    if (currentStep === 'campaignSetUp') {
      dispatch(setLanguage(data.form.language as Language));
      dispatch(setPropertyStatus(data.form.propertyStatus as PropertyStatus));
      dispatch(setPropertyType(data.form.propertyType as PropertyType));
    }

    if (currentStep === 'mainInformation') {
      dispatch(setPropertyTitle(data.form.propertyTitle));
      dispatch(setPropertyDescription(data.form.propertyDescription));
      // dispatch(
      //   setPropertyLocation({
      //     city: data?.form?.propertyLocation?.city,
      //     address: data?.form?.propertyLocation?.address,
      //     coordinates: {
      //       latitude: data?.form?.propertyLocation?.coordinates?.latitude,
      //       longitude: data?.form?.propertyLocation?.coordinates?.latitude
      //     },
      //     id: data?.form?.propertyLocation?.id,
      //   })
      // );
    }

    if (currentStep === 'aboutProperty') {
      dispatch(setAboutPropertyDesc(data.form.aboutPropertyDesc));
    }

    if (currentStep === 'aboutDeveloper') {
      dispatch(setAboutDeveloper(data.form.aboutDeveloper));
    }

    if (currentStep === 'contactInformation') {
      dispatch(setSelectedAgent({
        link: data.form.link,
        phone: data.form.phone,
        nameEn: data.form.nameEn || data.form.nameHe,
        nameHe: data.form.nameHe || data.form.nameEn
      }));
    }

    if (applyBtn === 'Apply') {
      setOpenFooterTopButton(false); setTimeout(() => {
        setApplyBtn('')
      }, 1000);
    }


  };




  const firstName = useMemo(() => {
    if (profile && profile.fullName) {
      return profile.fullName.split(" ")[0];
    }

    return '';
  }, [profile])



  useEffect(()=>{
       
    const sectionData = [
      {
        lable:'main Information',
        title:(t("main_information")),
       
      },
      {
        lable:'about Property',
        title:(t("about_property")),
       
      },
      {
        lable:'about Developer',
        title:(t("about_developer")),
       
      }
    ]
    const singalData = currentStep?.replace(/([A-Z])/g, ' $1');
    const matchedSection = sectionData?.find(item => item.lable === singalData);
    if (matchedSection) {
      setCurrentsection(matchedSection.title);
    } else {
      setCurrentsection('');
    }
},[currentStep])


  return (
    <>
      {isOpen && <button
        className={`ai_open_btn ${isRTL ? `left ${campaignBuilderOpen ? '' : 'sideberHideleftPosition'}` : (campaignBuilderOpen ? '' : 'sideberHidePosition')}`}
        onClick={() => setIsOpen(!isOpen)}>
        <img src={aiSparkIcon} alt="" />

      </button>}
      <div className={`ai_chat ${isRTL ? `left ${campaignBuilderOpen ? '' : 'sideberHideleftPosition'}` : (campaignBuilderOpen ? '' : 'sideberHidePosition')} ${!isOpen ? "open" : "closed"}`}>
        <div className="parrant_body_div">
          <AiHeader aiToggle={() => setIsOpen(!isOpen)} />
          <div className="ai_body">

            {chatArray.map((item, index) => (
              <div
                key={index}
                ref={(el) => {
                  if (index === chatArray.length - 1 && el && chatArray.length > 1) {
                    el.scrollIntoView({ behavior: 'smooth', block: 'start' });
                  }
                }}
              >
                {item.type === "ai"
                  ? <Assistant message={item.message?.replaceAll('__FIRST_NAME__', firstName)} />
                  : <User message={item.message} />
                }
              </div>
            ))}


            {(chatArray?.length === 1 || openQuickActions) && (
              <QuickActions
              langageChangeformData={langageChangeformData}
              currentsection={currentsection}
              chatArray={chatArray}
                currentStep={currentStep}
                isLoading={loading}
                currentAction={currentAction}
                onActionClick={applyAction} />
            )}

            {loading && <div ref={el => el?.scrollIntoView({ behavior: 'smooth', block: 'end' })}>
              <ThreeDots
                visible={true}
                height="50"
                width="50"
                color="#034a73"
                radius="9"
                ariaLabel="three-dots-loading"
                wrapperStyle={{}}
                wrapperClass=""
              />
            </div>}
          </div>
          <AiFooter setPromt={(prompt: string) => {
            setPromt(prompt);
          }}
            setSubmit={(value: string) => {
              setSubmit(value);
            }}
            setApplyBtn={(value: string) => {
              if (value === "quick_btn") {
                setCurrentAction('')
                setOpenQuickActions(true)
              }
              else {
                setApplyBtn(value);
              }

            }}
            openQuickActions={openQuickActions}
            chatArray={chatArray}
            openFooterTopButton={openFooterTopButton}
            setOpenFooterTopButton={(value: boolean) => {
              setOpenFooterTopButton(value);
            }}
            applyBtn={applyBtn}
            prompt={prompt}
            allData={allData} 
            currentsection={currentsection}/>
        </div>
      </div>
    </>
  );
};
