import { Collapse, Theme, useMediaQuery } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import ReplaceBlack from "../../../../assets/campaignBuilder/replace.svg";
import { ExtractedMediaData } from "../../../../helpers/campaignConvert";
import { truncateString } from "../../../../helpers/formatter";
import {
  removeFloorPlanFileById,
  removeVideoFileById,
  setFloorPlanSectionOpen,
  setMainLabelOpen,
  setMapSectionOpen,
  setVideoSectionOpen,
} from "../../../../store/features/campaignBuilderSlice";
import { useAppDispatch } from "../../../../store/store";
import { CampaignSideNavStep } from "../layout/CampaignBuilderSideNav";
import { CampaignBuilderSideNavHeader } from "../layout/CampaignBuilderSideNavHeader";
import { CampaignBuilderSwitcher } from "../../CampaignBuilderSwitcher";
import { CampainBuilderNextBtn } from "../buttons/CampainBuilderNextBtn";
import { FilesLoader } from "../../FilesLoader";
import { LabelSelect } from "../selects/LabelSelect";
import { useResetToDraft } from "../../../../hooks/useResetToDraft";
import { LangToggle } from "../../../_shared/LangToggle";
import { useParams } from "react-router-dom";
import {
  MAXIMUM_NUMBER_OF_FLOOR_PLAN,
  propertyImgAcceptFormat,
  videoAcceptFormat,
} from "../../constants";
import { BackToMenuBtn } from "../buttons/BackToMenuBtn";
import { FileDropZone } from "../layout/FileDropZone";
import { CampainBuilderBackBtn } from "../buttons/CampainBuilderBackBtn";
import EditIcon from "../../../../assets/campaignBuilder/edit.svg";

export const CampaignLabelsStep = ({
  setCurrentStep,
  selectedLabel,
  setSelectedLabel,
  labelOptionsState,
  setFloorplanFiles,
  setVideoFiles,
  isVideoLoading,
  isFloorPlanLoading,
  autosaveFunc = () => {},
  isDisabledSwitchLang = false,
}: {
  setCurrentStep: (step: CampaignSideNavStep) => void;
  selectedLabel: any;
  setSelectedLabel: any;
  labelOptionsState: any;
  setFloorplanFiles: React.Dispatch<React.SetStateAction<File[]>>;
  setVideoFiles: any;
  isVideoLoading: boolean;
  isFloorPlanLoading: boolean;
  autosaveFunc: any;
  isDisabledSwitchLang: boolean;
}) => {
  const dispatch = useAppDispatch();
  const { t, i18n } = useTranslation(["campaignBuilder"]);
  const isRTL = i18n.dir() === "rtl";
  const downMd = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));
  const resetToDraft = useResetToDraft();
  const { documentId } = useParams();

  const {
    mainLabelOpen,
    floorPlanSectionOpen,
    mapSectionOpen,
    videoSectionOpen,
  } = useSelector((state: any) => state.campaignBuilder);

  const handleNext = (step: CampaignSideNavStep) => {
    setCurrentStep(step);

    if (!documentId) {
      autosaveFunc();
    }
  };

  const handleChangeLabelOpen = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    localStorage.setItem("onChangeCompanyBuilder", "1");
    dispatch(setMainLabelOpen(event.target.checked));
    resetToDraft();
  };

  const handleChangeFloorPlanOpen = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    localStorage.setItem("onChangeCompanyBuilder", "1");
    dispatch(setFloorPlanSectionOpen(event.target.checked));
    resetToDraft();
  };

  const handleChangeVideoOpen = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    localStorage.setItem("onChangeCompanyBuilder", "1");
    dispatch(setVideoSectionOpen(event.target.checked));
    resetToDraft();
  };

  const handleChangeMapOpen = (event: React.ChangeEvent<HTMLInputElement>) => {
    localStorage.setItem("onChangeCompanyBuilder", "1");
    dispatch(setMapSectionOpen(event.target.checked));
    resetToDraft();
  };

  const { floorPlanFilesSlice, videoFilesSlice } = useSelector(
    (state: any) => state.campaignBuilder
  );

  const handleRemoveFloorPlanFile = (fileId: number) => {
    localStorage.setItem("onChangeCompanyBuilder", "1");
    dispatch(removeFloorPlanFileById(fileId));
    resetToDraft();
  };

  const handleRemoveVideoFile = (fileId: number) => {
    localStorage.setItem("onChangeCompanyBuilder", "1");
    dispatch(removeVideoFileById(fileId));
    resetToDraft();
  };

  return (
    <div className="campaign-builder-side-nav-wrapper">
      <CampaignBuilderSideNavHeader
        header="labels"
        leftBtn={
          <CampainBuilderBackBtn
            setCurrentStep={setCurrentStep}
            step={CampaignSideNavStep.media}
          />
        }
        rightBtn={
          <CampainBuilderNextBtn
            handleNext={handleNext}
            step={CampaignSideNavStep.investmentHighlights}
          />
        }
      />

      <div
        className="campaign-builder-sidenav-main-content-wrapper"
        style={{
          paddingTop: downMd ? "30px" : "40px",
          direction: i18n.dir(),
          boxSizing: "border-box",
          height: "100%",
        }}
      >
        <div className="campaign-builder-sidenav-settings-fileds-container">
          <div className="campaign-builder-sidenav-settings-fileds-item">
            <div className="campaign-builder-sidenav-switcher-container">
              <div className="campaign-builder-sidenav-field-name-main">
                {t("property_main_label")}
              </div>
              <CampaignBuilderSwitcher
                open={mainLabelOpen}
                handleChangeOpen={handleChangeLabelOpen}
              />
            </div>

            <Collapse in={mainLabelOpen}>
              <div>
                <div className="campaign-builder-sidenav-field-name ">
                  {t("select_label")}
                </div>
                {selectedLabel?.id && (
                  <LabelSelect
                    value={selectedLabel?.id}
                    onChangeValue={(val) => {
                      dispatch(
                        setSelectedLabel(
                          labelOptionsState.find((el: any) => el.id === val)
                        )
                      );
                      resetToDraft();
                    }}
                    options={labelOptionsState}
                  />
                )}
              </div>
              <div
                className="edit-btn-wrapper"
                onClick={() => {
                  localStorage.setItem("isAddNewLabel", "0");
                  setCurrentStep(CampaignSideNavStep.addNewALabel);
                }}
                style={{
                  marginTop: "20px",
                }}
              >
                <img src={EditIcon} />
                <div>{t("edit")}</div>
              </div>
              <div></div>
            </Collapse>
          </div>

          <div className="campaign-builder-sidenav-settings-fileds-item">
            <div className="campaign-builder-sidenav-switcher-container">
              <div className="campaign-builder-sidenav-field-name-main">
                {t("floorplan")}
              </div>
              <CampaignBuilderSwitcher
                open={floorPlanSectionOpen}
                handleChangeOpen={handleChangeFloorPlanOpen}
              />
            </div>
            <Collapse in={floorPlanSectionOpen}>
              <FileDropZone
                fileAcceptFormat={propertyImgAcceptFormat}
                limit={MAXIMUM_NUMBER_OF_FLOOR_PLAN}
                setFiles={setFloorplanFiles}
                placeholder={"placeholder_upload_property_floor_plan"}
                maxFileSizeMB={10}
              />

              {floorPlanFilesSlice.length > 0 && (
                <div
                  style={{
                    paddingTop: "20px",
                  }}
                >
                  {floorPlanFilesSlice.map(
                    (item: ExtractedMediaData, index: number) => (
                      <div
                        key={item.name + index}
                        className="drag-and-drop-file-container"
                      >
                        <div className="drag-and-drop-file-container-img-container">
                          <div
                            style={{
                              background: `url(${item.url}) center center / cover no-repeat`,
                              width: "190px",
                              height: "110px",
                            }}
                          ></div>
                          <div
                            style={{
                              right: isRTL ? "auto" : "-8px",
                              left: isRTL ? "-8px" : "auto",
                            }}
                            className="replace-cross-wrapper"
                            onClick={() => handleRemoveFloorPlanFile(item.id)}
                          >
                            <img src={ReplaceBlack} />
                          </div>
                        </div>
                        <div className="drag-and-drop-file-name">
                          {truncateString(item.name, 25)}
                        </div>
                      </div>
                    )
                  )}
                </div>
              )}
              <FilesLoader isLoading={isFloorPlanLoading} />
            </Collapse>
          </div>

          <div className="campaign-builder-sidenav-settings-fileds-item">
            <div className="campaign-builder-sidenav-switcher-container">
              <div className="campaign-builder-sidenav-field-name-main">
                {t("video")}
              </div>
              <CampaignBuilderSwitcher
                open={videoSectionOpen}
                handleChangeOpen={handleChangeVideoOpen}
              />
            </div>

            <Collapse in={videoSectionOpen}>
              <FileDropZone
                fileAcceptFormat={videoAcceptFormat}
                limit={MAXIMUM_NUMBER_OF_FLOOR_PLAN}
                setFiles={setVideoFiles}
                placeholder={"placeholder_upload_property_video"}
                maxFileSizeMB={150}
              />

              {videoFilesSlice.length > 0 && (
                <div
                  style={{
                    paddingTop: "20px",
                  }}
                >
                  {videoFilesSlice.map((file: any, index: number) => (
                    <div
                      key={file.name + index}
                      className="drag-and-drop-file-container"
                    >
                      <div className="drag-and-drop-file-container-img-container">
                        <video
                          src={file.url}
                          controls
                          style={{
                            width: "190px",
                            height: "110px",
                          }}
                        />

                        <div
                          style={{
                            right: isRTL ? "auto" : "-8px",
                            left: isRTL ? "-8px" : "auto",
                          }}
                          className="replace-cross-wrapper"
                          onClick={() => handleRemoveVideoFile(file.id)}
                        >
                          <img src={ReplaceBlack} />
                        </div>
                      </div>
                      <div className="drag-and-drop-file-name">
                        {truncateString(file.name, 25)}
                      </div>
                    </div>
                  ))}
                </div>
              )}
              <FilesLoader isLoading={isVideoLoading} />
            </Collapse>
          </div>

          <div className="campaign-builder-sidenav-settings-fileds-item">
            <div className="campaign-builder-sidenav-switcher-container">
              <div className="campaign-builder-sidenav-field-name-main">
                {t("map")}
              </div>
              <CampaignBuilderSwitcher
                open={mapSectionOpen}
                handleChangeOpen={handleChangeMapOpen}
              />
            </div>
          </div>
        </div>
        <div
          style={{
            backgroundColor: "rgba(5, 57, 87, 1)",
            position: "sticky",
            bottom: 0,
            zIndex: 11,
            display: "flex",
            gap: "15px",
          }}
        >
          <div style={{ flex: 1, maxWidth: "50%" }}>
            <BackToMenuBtn setCurrentStep={setCurrentStep} />
          </div>

          <div
            onClick={() => {
              localStorage.setItem("isAddNewLabel", "1");
              setCurrentStep(CampaignSideNavStep.addNewALabel);
            }}
            style={{
              marginTop: "20px",
              flex: 1,
              maxWidth: "50%",
            }}
            className="campaign-builder-sidenav-preview-btn"
          >
            <span>{t("add_new_label")}</span>
          </div>
        </div>
      </div>
    </div>
  );
};
