import { useState } from "react";
import { Footer } from "../components/_shared/Footer";
import { FooterSocialMediaLine } from "../components/_shared/FooterSocialMediaLine";
import { Header } from "../components/_shared/Header";
import { Navbar } from "../components/_shared/Navbar";
import { ArtcileSideBar } from "../components/dedicatedLearningCenter/ArtcileSideBar";
import { DedicatedLearningCenterHeroImg } from "../components/dedicatedLearningCenter/DedicatedLearningCenterHeroImg";
import { GetInTouchBlock } from "../components/home/GetInTouchBlock";
import { ArticleMainContent } from "../components/dedicatedLearningCenter/ArticleMainContent";
import { Theme, useMediaQuery } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useLanguageTheme } from "../hooks/useLanguageTheme";
import { RelatedArticlesSection } from "../components/dedicatedLearningCenter/RelatedArticlesSection";
import { useGetArticleByDocumentIdQuery } from "../store/api/articlesApiSlice";
import { useParams } from "react-router-dom";
import { skipToken } from "@reduxjs/toolkit/query";
import React from "react";
import {
  convertToArticleContent,
  IConvertedArticleContent,
} from "../helpers/convert";

export const DedicatedLearningCenter = () => {
  const { i18n } = useTranslation(["learningCenter"]);
  const langTheme = useLanguageTheme();
  const downMd = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));
  const isRTL = i18n.dir() === "rtl";
  const { documentId } = useParams();

  const [activeItemId, setActiveItemId] = useState<string | null>(null);

  const [mainArtcileContent, setMainArtcileContent] = React.useState<
    IConvertedArticleContent[]
  >([]);

  const { data: articleData } = useGetArticleByDocumentIdQuery(
    documentId ? { lang: i18n.language, documentId } : skipToken
  );

  React.useEffect(() => {
    if (articleData) {
      const convertedMainData = convertToArticleContent(
        articleData.data.content
      );
      setMainArtcileContent(convertedMainData);

      if (convertedMainData.length > 0) {
        setActiveItemId(convertedMainData[0].id);
      }
    }
  }, [articleData]);

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        fontFamily: langTheme.mainFont(),
      }}
    >
      <>
        <div className="portfolio-header-sticky-wrapper">
          <div
            style={{
              backgroundColor: "rgba(255, 255, 255, 1)",
            }}
          >
            <Header>
              <Navbar />
            </Header>
          </div>
        </div>
        <DedicatedLearningCenterHeroImg article={articleData?.data} />
        <div
          className="main-article-wrapper xl-width"
          style={{
            flexDirection: isRTL ? "row" : "row-reverse",
            padding: "0 20px",
            boxSizing: "border-box",
          }}
        >
          {!downMd && (
            <ArtcileSideBar
              items={mainArtcileContent}
              activeItemId={activeItemId}
            />
          )}
          <ArticleMainContent
            items={mainArtcileContent}
            setActiveItemId={setActiveItemId}
          />
        </div>
        <RelatedArticlesSection />
        <GetInTouchBlock />
        <Footer />
        <FooterSocialMediaLine />
      </>
    </div>
  );
};
