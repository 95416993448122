import { Media } from "../../helpers/convert";

export const campaignBuilderDrawerWidthDesktop = "443px";
export const campaignBuilderDrawerWidthMobile = "356px";

export const MAX_HIGHLIGHT_SIZE_BACK = 10;
export const MAX_LABEL_LENGTH = 20;
export const MAX_HIGHTLIGHT_LENGTH = 60;
export const MAX_FEATURE_LENGTH = 60;

export const MIN_ABOUT_PROPERTY_LENGTH_EN = 1000;
export const MAX_ABOUT_PROPERTY_LENGTH_EN = 3000;

export const MIN_ABOUT_PROPERTY_LENGTH_HE = 800;
export const MAX_ABOUT_PROPERTY_LENGTH_HE = 3200;

export const MIN_ABOUT_DEVELOPER_LENGTH_EN = 150;
export const MAX_ABOUT_DEVELOPER_LENGTH_EN = 1500;

export const MIN_ABOUT_DEVELOPER_LENGTH_HE = 100;
export const MAX_ABOUT_DEVELOPER_LENGTH_HE = 1000;

export const MAXIMUM_NUMBER_OF_PROPERTY_IMG = 10;
export const MAXIMUM_NUMBER_OF_BROCHURE = 1;
export const MAXIMUM_NUMBER_OF_FLOOR_PLAN = 1;
export const MAXIMUM_NUMBER_OF_AGENT_AVATAR = 1;

export const propertyImgAcceptFormat = {
  "image/png": [".png"],
  "image/jpeg": [".jpg", ".jpeg"],
  "image/webp": [".webp"],
  "image/heic": [".heic", ".heif"],
};

export const brochureAcceptFormat = {
  "application/pdf": [],
};

export const videoAcceptFormat = {
  "video/mp4": [".mp4"],
  "video/quicktime": [".mov"],
  "video/x-msvideo": [".avi"],
};

export const MAX_PROPERTY_TITLE_LENGTH_ENGLISH = 40;
export const MAX_PROPERTY_TITLE_LENGTH_HEBREW = 35;

export const MAX_PROPERTY_DESC_LENGTH_ENGLISH = 120;
export const MAX_PROPERTY_DESC_LENGTH_HEBREW = 140;

export const compressOptions = {
  maxSizeMB: 10,
  useWebWorker: true,
};

export interface User {
  id: number;
  documentId: string;
  name: string;
  phone: string;
  email: string;
  createdAt: string;
  updatedAt: string;
  publishedAt: string;
  locale: string;
  photo: Media;
}


export const VISIBLE_ROWS_TO_COMAPARE_PROP = 4;

export const urlRegex = /^(https?:\/\/)?([\w-]+\.)+[a-z]{2,}([\w.,@?^=%&:\/~+#-]*[\w@?^=%&\/~+#-])?$/i;
