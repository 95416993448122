import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import {
  HighlightIconMap,
  HightlightIconKey,
} from "../../../../constants/campaignBuilderData";
import { useParams } from "react-router-dom";

export interface Option {
  id: string;
  iconKey: HightlightIconKey;
  labelNameEn: string;
  labelNameHe: string;
  type: string;
}
export const InvestmentHighlightsBlock = () => {
  const { t, i18n } = useTranslation(["campaignBuilder"]);
  const { documentId } = useParams();
  const { hightlightsBack } = useSelector(
    (state: any) => state.campaignBuilder
  );

  return (
    <div className="campaign-builder-investment-highlights-block">
      <div className="campaign-builder-main-header ">
        {t("investment_highlights")}
      </div>
      <div className="campaign-builder-investment-highlights-items-block">
        {hightlightsBack && Array.isArray(hightlightsBack)
          ? hightlightsBack
              .filter((el) => !(el?.details && el?.details[documentId ?? ""]))
              .map((option: Option, index) => {
                const IconComponent =
                  HighlightIconMap[option?.iconKey ?? "location"];
                return (
                  <div
                    key={index}
                    className="campaign-builder-investment-highlights-item"
                  >
                    {IconComponent ? (
                      <IconComponent
                        isSelected={false}
                        width={30}
                        height={30}
                        backgroundColor={"#034A73"}
                      />
                    ) : (
                      ""
                    )}
                    <div>
                      {i18n.language === "he"
                        ? option?.labelNameHe
                        : option?.labelNameEn}
                    </div>
                  </div>
                );
              })
          : ""}
      </div>
    </div>
  );
};
