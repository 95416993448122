import React from "react";
import { useTranslation } from "react-i18next";

export const CampaignBuilderSideNavHeader = ({
  header,
  nearHeaderComponent,
  leftBtn,
  rightBtn,
}: {
  header: string;
  nearHeaderComponent?: React.ReactNode | null;
  leftBtn: React.ReactNode;
  rightBtn: React.ReactNode;
}) => {
  const { t, i18n } = useTranslation(["campaignBuilder"]);
  const currentLanguage = i18n.language;
  const isRTL = currentLanguage === "he";

  return (
    <div className="campaign-builder-sidenav-header">
      {leftBtn}
      <div
        className="campaign-builder-sidenav-header-title"
        style={{
          letterSpacing: isRTL ? "" : "-0.32px",
          display: "flex",
          gap: 15,
        }}
      >
        {t(header)}
        {nearHeaderComponent}
      </div>

      {rightBtn}
    </div>
  );
};
