import React from "react";
import { useTranslation } from "react-i18next";
import { Theme, useMediaQuery } from "@mui/material";
import { HomeFilterUpMd } from "../HomeFilterUpMd";
import { HomeFilterDownMd } from "../HomeFilterDownMd";
import { HeroImgStatisticsUpMd } from "./HeroImgStatisticsUpMd";
import { HeroImgStatisticsDownMd } from "./HeroImgStatisticsDownMd";
import { IFilterParams } from "../../portfolio/interfaces";
import { UserTypes } from "../../_shared/Navbar";

export const HeroImg = ({
  filters,
  onChangeFilters,
  search,
  onChangeSearch,
}: {
  filters: IFilterParams;
  onChangeFilters: any;
  search: string;
  onChangeSearch: (search: string) => void;
}) => {
  const { i18n } = useTranslation();
  const downMd = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));
  const upLg = useMediaQuery((theme: Theme) => theme.breakpoints.up("lg"));
  const user = localStorage.getItem("user") || sessionStorage.getItem("user");
  const parsedUser = user ? JSON.parse(user) : null;

  return (
    <div
      className="overview-hero-img-container"
      style={{
        direction: i18n.dir(),
        height: upLg ? "650px" : downMd ? "317px" : "617px",
        maxWidth: "3000px",
      }}
    >
      {parsedUser &&
        parsedUser.userType === UserTypes.SELLER &&
        (downMd ? (
          <HomeFilterDownMd
            filters={filters}
            onChangeFilters={onChangeFilters}
            search={search}
            onChangeSearch={onChangeSearch}
          />
        ) : (
          <HomeFilterUpMd
            filters={filters}
            onChangeFilters={onChangeFilters}
            search={search}
            onChangeSearch={onChangeSearch}
          />
        ))}

      {downMd ? <HeroImgStatisticsDownMd /> : <HeroImgStatisticsUpMd />}
    </div>
  );
};
