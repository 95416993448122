import { useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";
import UploadFileIcon from "../../../../assets/campaignBuilder/upload_files.svg";
import { useState } from "react";
import { ImageFormatMap } from "../steps/CampaignMediaStep";
import { useResetToDraft } from "../../../../hooks/useResetToDraft";

export const FileDropZone = ({
  fileAcceptFormat,
  limit,
  setFiles,
  placeholder,
  maxFileSizeMB = 100,
  additionalPlaceholder,
}: {
  fileAcceptFormat: ImageFormatMap;
  limit: number;
  setFiles?: any;
  placeholder: string;
  maxFileSizeMB?: number;
  additionalPlaceholder?: any;
}) => {
  const { t } = useTranslation(["campaignBuilder"]);
  const [errorMessage, setErrorMessage] = useState("");

  const resetToDraft = useResetToDraft();

  const { getRootProps, getInputProps } = useDropzone({
    accept: fileAcceptFormat,
    maxSize: maxFileSizeMB * 1024 * 1024,
    onDrop: (acceptedFiles) => {
      setFiles((prevFiles: any[]) => {
        const newFiles = acceptedFiles.map((file, index) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
            id: `${file.name}-${index}-${Date.now()}`,
          })
        );
        localStorage.setItem("onChangeCompanyBuilder", "1");
        resetToDraft();

        return [...prevFiles, ...newFiles].slice(0, limit);
      });
    },
    onDropRejected: (rejectedFiles) => {
      const errors = rejectedFiles.map((file) => {
        return file.errors[0].code === "file-too-large"
          ? `${file.file.name}: ${t("fileTooLarge")}`
          : "";
      });
      setErrorMessage(errors.join("\n"));
    },
  });

  // useEffect(() => {
  //   return () =>
  //     files.forEach((file: any) => URL.revokeObjectURL(file.preview));
  // }, [files]);

  return (
    <div>
      <div className="drag-and-drop-area-wrapper">
        <div
          {...getRootProps({ className: "dropzone" })}
          className="drag-and-drop-area-container"
        >
          <img src={UploadFileIcon} className="drag-and-drop-area-upload-img" />
          <div className="drag-and-drop-area-upload-img-text">
            {t(placeholder)}
            {additionalPlaceholder}
          </div>
          <input {...getInputProps()} />
        </div>
      </div>
      {errorMessage && <p style={{ color: "#fff" }}>{errorMessage}</p>}
    </div>
  );
};
