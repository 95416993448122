import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PreviewMode } from "../../components/campaignBuilder/types";

export interface ICampaingBuilderSliceState {
  previewMode: PreviewMode;
  deleteHighlightOpen: boolean;
  deleteHightlightMessage: string;
  toggleVisibilityNotification: boolean;
  toggleVisibilityNotificationMessage: string;
  contactToAgentOpen: boolean;
  pageView: boolean;
}

const initialState: ICampaingBuilderSliceState = {
  previewMode: PreviewMode.DESKTOP,
  deleteHighlightOpen: false,
  deleteHightlightMessage: "",
  toggleVisibilityNotification: false,
  toggleVisibilityNotificationMessage: "",
  contactToAgentOpen: false,
  pageView: false,
};

const campaignBuilderSetupSlice = createSlice({
  name: "campaignBuilderSetup",
  initialState,
  reducers: {
    setPreviewMode(state, action) {
      state.previewMode = action.payload;
    },
    setDeleteHighlightOpen(state, action) {
      state.deleteHighlightOpen = true;
      state.deleteHightlightMessage = action.payload.deleteHightlightMessage;
    },
    setDeleteHighlightClose(state) {
      state.deleteHighlightOpen = false;
      state.deleteHightlightMessage = "";
    },
    setToggleVisibilityNotification(state, action) {
      state.toggleVisibilityNotification = true;
      state.toggleVisibilityNotificationMessage =
        action.payload.toggleVisibilityNotificationMessage;
    },
    closeToggleVisibilityNotification(state) {
      state.toggleVisibilityNotification = false;
      state.toggleVisibilityNotificationMessage = "";
    },
    setContactInformationOpen(state) {
      state.contactToAgentOpen = true;
    },
    setContactInformationClose(state) {
      state.contactToAgentOpen = false;
    },
    setPageView(state, action) {
      state.pageView = action.payload;
    },
  },
});

export const {
  setPreviewMode,
  setDeleteHighlightClose,
  setDeleteHighlightOpen,
  setToggleVisibilityNotification,
  closeToggleVisibilityNotification,
  setContactInformationOpen,
  setContactInformationClose,
  setPageView,
} = campaignBuilderSetupSlice.actions;

export default campaignBuilderSetupSlice.reducer;
