import { Drawer, useMediaQuery } from "@mui/material";
import { skipToken } from "@reduxjs/toolkit/query";
import React, { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useLanguageTheme } from "../../../../hooks/useLanguageTheme";
import {
  useGetHightLightsWithParamsQuery,
  useGetKeyFeaturesWithParamsQuery,
  useGetLabelsWithParamsQuery,
} from "../../../../store/api/campaignBuilderApiSlice";
import {
  setHightlightsBack,
  setKeyFeatureBack,
  setLabelBack,
} from "../../../../store/features/campaignBuilderSlice";
import {
  campaignBuilderOpenSelector,
  setCampaingBuilderSideBarClose,
} from "../../../../store/features/common";
import { useAppDispatch } from "../../../../store/store";
import { AboutProperty } from "../steps/AboutProperty";
import { AddNewHightlight } from "../steps/AddNewHightlight";
import { CampaignMediaStep } from "../steps/CampaignMediaStep";
import { ContactInformation } from "../steps/ContactInformation";
import { InvestmentHighlights } from "../steps/InvestmentHighlights";
import { KeyFeature } from "../steps/KeyFeature";
import { MainInformationStep } from "../steps/MainInformationStep";
import { AddNewFeature } from "../steps/AddNewFeature";
import { AboutDeveloper } from "../steps/AboutDeveloper";
import { AddNewLabel } from "../steps/AddNewLabel";
import { CampaignLabelsStep } from "../steps/CampaignLabelsStep";
import { CampaignSettitngsStep } from "../steps/CampaignSettitngsStep";
import { CampaignSetUpStep } from "../steps/CampaignSetUpStep";
import {
  campaignBuilderDrawerWidthDesktop,
  campaignBuilderDrawerWidthMobile,
} from "../../constants";

export enum CampaignSideNavStep {
  campaignSetUp = "campaignSetUp",
  settings = "settings",
  mainInformation = "mainInformation",
  media = "media",
  labels = "labels",
  investmentHighlights = "investmentHighlights",
  aboutProperty = "aboutProperty",
  keyFeatures = "keyFeatures",
  aboutDeveloper = "aboutDeveloper",
  contactInformation = "contactInformation",
  addNewALabel = "addNewALabel",
  addaNewHighlight = "addaNewHighlight",
  addaNewFeature = "addaNewFeature",
}

export enum Language {
  ENGLISH = "english",
  HEBREW = "hebrew",
}

export enum Currency {
  NIS = "nis",
  USD = "usd",
}

export const CurrencyMap: Record<Currency, string> = {
  [Currency.NIS]: "₪",
  [Currency.USD]: "$",
};

export enum TimePeriod {
  DAYS = "days",
  WEEKS = "weeks",
  MONTHS = "months",
}

export enum PropertyStatus {
  UNDER_CONSTRUCTION = "under_construction",
  READY_FOR_OCCUPANCY = "ready_for_occupancy",
  PRE_SALE = "pre_sale_on_paper",
  AVAILABLE = "immediate_availability",
  COMING_SOON = "coming_soon",
}

export const propertyStatusToNumber: Record<PropertyStatus, number> = {
  [PropertyStatus.UNDER_CONSTRUCTION]: 1,
  [PropertyStatus.READY_FOR_OCCUPANCY]: 2,
  [PropertyStatus.PRE_SALE]: 3,
  [PropertyStatus.AVAILABLE]: 4,
  [PropertyStatus.COMING_SOON]: 5,
};

export enum PropertyType {
  PENTHOUSE = "penthouse",
  GARDEN_APARTMENT = "garden_apartment",
  DUPLEX = "duplex",
  TRIPLEX = "triplex",
  VILLA_HOUSE = "villa_house",
  TOWNHOUSE = "townhouse",
  ROOFTOP_APARTMENT = "rooftop_apartment",
  STUDIO_APARTMENT_PREMIUM = "studio_apartment_premium",
  OFFICE_SPACE = "office_space",
  RETAIL_SPACE = "retail_space",
  MIXED_USE_DEVELOPMENT = "mixed_use_development",
  COMMERCIAL_BUILDING = "commercial_building",
  BUSINESS_CENTER = "business_center",
  LUXURY_APARTMENT = "luxury_apartment",
  LUXURY_TOWER = "luxury_tower",
}

export const CampaignBuilderSideNav = ({
  currentStep,
  setCurrentStep,
  setImgFiles,
  setNewAgentAvatar,
  setBrochureFiles,
  setFloorplanFiles,
  setVideoFiles,
  isImgsLoading,
  isBrochureLoading,
  isVideoLoading,
  isFloorPlanLoading,
  isAvatarLoading,
  allFieldsErrors,
  setAllFieldsErrors,
  autosaveFunc = () => {},
  isDisabledSwitchLang = false,
  handleSaveHighlights,
  handleSaveKeyFeatures,
  handleSaveLabel,
}: {
  currentStep: CampaignSideNavStep;
  setCurrentStep: (step: CampaignSideNavStep) => void;
  setImgFiles: React.Dispatch<React.SetStateAction<File[]>>;
  setNewAgentAvatar: React.Dispatch<React.SetStateAction<File[]>>;
  setBrochureFiles: React.Dispatch<React.SetStateAction<File[]>>;
  setFloorplanFiles: React.Dispatch<React.SetStateAction<File[]>>;
  setVideoFiles: React.Dispatch<React.SetStateAction<File[]>>;
  isImgsLoading: boolean;
  isBrochureLoading: boolean;
  isVideoLoading: boolean;
  isFloorPlanLoading: boolean;
  isAvatarLoading: boolean;
  allFieldsErrors: any;
  setAllFieldsErrors: any;
  autosaveFunc: any;
  isDisabledSwitchLang: boolean;
  handleSaveHighlights: any;
  handleSaveKeyFeatures: any;
  handleSaveLabel: any;
}) => {
  const dispatch = useAppDispatch();
  const langTheme = useLanguageTheme();
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const isRTL = currentLanguage === "he";
  const campaignBuilderOpen = useSelector(campaignBuilderOpenSelector);
  const { documentId } = useParams();
  const [hasPublish, setHasPublish] = React.useState(false);

  const maxWidth1220px = useMediaQuery("(max-width: 1220px)");

  const userId = JSON.parse(
    localStorage.getItem("user") || sessionStorage.getItem("user") || "{}"
  )?.id;

  const getPropertiesParams = useMemo(() => {
    if (!userId) return null;
    return {
      userId: userId,
    };
  }, [userId]);

  const [allHighlightsState, setAllHighlightsState] = React.useState<any>([]);
  const [highlightForEditId, setHighlightForEditId] = React.useState<any>(null);
  const [allFeaturesState, setAllFeaturesState] = React.useState<any>([]);
  const [featuresForEditId, setFeaturesForEditId] = React.useState<any>(null);
  const [allLabelsState, setAllLabelsState] = React.useState<any>([]);

  const { data: dataHightlights } = useGetHightLightsWithParamsQuery(
    getPropertiesParams ?? skipToken
  );
  const { data: dataKeyFeatures } = useGetKeyFeaturesWithParamsQuery(
    getPropertiesParams ?? skipToken
  );
  const { data: dataLabels } = useGetLabelsWithParamsQuery(
    getPropertiesParams ?? skipToken
  );

  const { keyFeaturesBack, hightlightsBack, labelBack } = useSelector(
    (state: any) => state.campaignBuilder
  );

  useEffect(() => {
    if (dataHightlights?.data) {
      setAllHighlightsState(dataHightlights.data);
    }
  }, [dataHightlights]);

  useEffect(() => {
    if (dataKeyFeatures?.data) {
      setAllFeaturesState(dataKeyFeatures.data);
    }
  }, [dataKeyFeatures]);

  useEffect(() => {
    if (dataLabels?.data) {
      setAllLabelsState(dataLabels.data);
    }
  }, [dataLabels]);

  useEffect(() => {
    if (!documentId && !labelBack) {
      if (allLabelsState.filter((el: any) => el.type === "system")[0]) {
        dispatch(
          setLabelBack(
            allLabelsState.filter((el: any) => el.type === "system")[0]
          )
        );
      }
    }
  }, [allLabelsState]);

  useEffect(() => {
    if (
      !documentId &&
      keyFeaturesBack &&
      Array.isArray(keyFeaturesBack) &&
      keyFeaturesBack.length === 0
    ) {
      dispatch(
        setKeyFeatureBack(
          allFeaturesState.filter((el: any) => el.type === "system").slice(0, 4)
        )
      );
    }
  }, [allFeaturesState]);

  useEffect(() => {
    if (
      !documentId &&
      hightlightsBack &&
      Array.isArray(hightlightsBack) &&
      hightlightsBack.length === 0
    ) {
      dispatch(
        setHightlightsBack(
          allHighlightsState
            .filter((el: any) => el.type === "system")
            .slice(0, 4)
        )
      );
    }
  }, [allHighlightsState]);

  const content = (
    <div
      className="campaign-builder-sidenav-wrapper"
      style={{
        fontFamily: langTheme.mainFont(),
        boxSizing: "border-box",
        height: "100%",
      }}
    >
      <div
        className="campaign-builder-sidenav-main-content"
        style={{
          height: "100%",
        }}
      >
        {currentStep === CampaignSideNavStep.campaignSetUp && (
          <CampaignSetUpStep
            setCurrentStep={setCurrentStep}
            currentStep={currentStep}
            allFieldsErrors={allFieldsErrors}
            setAllFieldsErrors={setAllFieldsErrors}
            setHasPublish={setHasPublish}
            autosaveFunc={autosaveFunc}
            isDisabledSwitchLang={isDisabledSwitchLang}
          />
        )}

        {currentStep === CampaignSideNavStep.settings && (
          <CampaignSettitngsStep
            setCurrentStep={setCurrentStep}
            autosaveFunc={autosaveFunc}
            isDisabledSwitchLang={isDisabledSwitchLang}
          />
        )}

        {currentStep === CampaignSideNavStep.mainInformation && (
          <MainInformationStep
            setCurrentStep={setCurrentStep}
            allFieldsErrors={allFieldsErrors}
            setAllFieldsErrors={setAllFieldsErrors}
            autosaveFunc={autosaveFunc}
            isDisabledSwitchLang={isDisabledSwitchLang}
          />
        )}

        {currentStep === CampaignSideNavStep.media && (
          <CampaignMediaStep
            setCurrentStep={setCurrentStep}
            setImgFiles={setImgFiles}
            setBrochureFiles={setBrochureFiles}
            isImgsLoading={isImgsLoading}
            isBrochureLoading={isBrochureLoading}
            autosaveFunc={autosaveFunc}
            isDisabledSwitchLang={isDisabledSwitchLang}
          />
        )}

        {currentStep === CampaignSideNavStep.labels && (
          <CampaignLabelsStep
            setCurrentStep={setCurrentStep}
            selectedLabel={labelBack}
            setSelectedLabel={setLabelBack}
            labelOptionsState={allLabelsState ?? []}
            setFloorplanFiles={setFloorplanFiles}
            setVideoFiles={setVideoFiles}
            isVideoLoading={isVideoLoading}
            isFloorPlanLoading={isFloorPlanLoading}
            autosaveFunc={autosaveFunc}
            isDisabledSwitchLang={isDisabledSwitchLang}
          />
        )}

        {currentStep === CampaignSideNavStep.addNewALabel && (
          <AddNewLabel
            setCurrentStep={setCurrentStep}
            labelOptionsState={allLabelsState ?? []}
            selectedLabel={labelBack}
            setSelectedLabel={setLabelBack}
            handleSaveLabel={handleSaveLabel}
          />
        )}

        {currentStep === CampaignSideNavStep.investmentHighlights && (
          <InvestmentHighlights
            setCurrentStep={setCurrentStep}
            hightlightOptionsState={allHighlightsState ?? []}
            selectedHightLight={hightlightsBack}
            setSelectedHightLight={setHightlightsBack}
            setHighlightForEditId={setHighlightForEditId}
            autosaveFunc={autosaveFunc}
            isDisabledSwitchLang={isDisabledSwitchLang}
          />
        )}

        {currentStep === CampaignSideNavStep.addaNewHighlight && (
          <AddNewHightlight
            setCurrentStep={setCurrentStep}
            hightlightOptionsState={allHighlightsState ?? []}
            selectedHightLight={hightlightsBack}
            setSelectedHightLight={setHightlightsBack}
            handleSaveHighlights={handleSaveHighlights}
            highlightForEditId={highlightForEditId}
          />
        )}

        {currentStep === CampaignSideNavStep.aboutProperty && (
          <AboutProperty
            setCurrentStep={setCurrentStep}
            allFieldsErrors={allFieldsErrors}
            setAllFieldsErrors={setAllFieldsErrors}
            hasPublish={hasPublish}
            autosaveFunc={autosaveFunc}
            isDisabledSwitchLang={isDisabledSwitchLang}
          />
        )}

        {currentStep === CampaignSideNavStep.keyFeatures && (
          <KeyFeature
            setCurrentStep={setCurrentStep}
            keyfeatureOptionsState={allFeaturesState ?? []}
            selectedFeature={keyFeaturesBack}
            setSelectedFeature={setKeyFeatureBack}
            setFeatureForEditId={setFeaturesForEditId}
            autosaveFunc={autosaveFunc}
            isDisabledSwitchLang={isDisabledSwitchLang}
          />
        )}

        {currentStep === CampaignSideNavStep.addaNewFeature && (
          <AddNewFeature
            setCurrentStep={setCurrentStep}
            keyfeatureOptionsState={allFeaturesState ?? []}
            selectedFeature={keyFeaturesBack}
            setSelectedFeature={setKeyFeatureBack}
            handleSaveFeature={handleSaveKeyFeatures}
            featuresForEditId={featuresForEditId}
          />
        )}
        {currentStep === CampaignSideNavStep.aboutDeveloper && (
          <AboutDeveloper
            setCurrentStep={setCurrentStep}
            allFieldsErrors={allFieldsErrors}
            setAllFieldsErrors={setAllFieldsErrors}
            hasPublish={hasPublish}
            autosaveFunc={autosaveFunc}
            isDisabledSwitchLang={isDisabledSwitchLang}
          />
        )}

        {currentStep === CampaignSideNavStep.contactInformation && (
          <ContactInformation
            setCurrentStep={setCurrentStep}
            setNewAgentAvatar={setNewAgentAvatar}
            isAvatarLoading={isAvatarLoading}
            autosaveFunc={autosaveFunc}
            isDisabledSwitchLang={isDisabledSwitchLang}
          />
        )}
      </div>
    </div>
  );

  return (
    <div>
      <Drawer
        variant="persistent"
        anchor={isRTL ? "left" : "right"}
        open={campaignBuilderOpen}
        onClose={() => dispatch(setCampaingBuilderSideBarClose())}
        PaperProps={{
          sx: {
            width: maxWidth1220px
              ? campaignBuilderDrawerWidthMobile
              : campaignBuilderDrawerWidthDesktop,
            backgroundColor: "rgba(5, 57, 87, 1)",
          },
        }}
      >
        {content}
      </Drawer>
    </div>
  );
};
