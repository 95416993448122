import { Box, Typography, useMediaQuery, Theme, useTheme } from "@mui/material";
import React, { useEffect, useRef } from "react";
import houseImage from "../../assets/sellWithUs/topBackgroundHouse.png";
import houseOneImage from "../../assets/sellWithUs/house1.png";
import houseTwoImage from "../../assets/sellWithUs/house2.png";
import houseThreeImage from "../../assets/sellWithUs/house3.png";
import Arrow from "../../assets/icons/arrow.svg";
import RocketIcon from "../../assets/icons/rocket.svg";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useFormattedSocialProof } from "../../hooks/useFormattedSocialProof";

export default function TopGallery() {
  const navigate = useNavigate();
  const { t } = useTranslation(["sellWithUs"]);
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const isRTL = currentLanguage === "he";
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const downLg = useMediaQuery((theme: Theme) => theme.breakpoints.down("lg"));
  const downMd = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));
  const { activeInvestors, successfulProjects, formattedSalesAmount } =
    useFormattedSocialProof();

  const sliderRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const scrollToStart = () => {
      if (sliderRef.current) {
        const scrollPosition = isRTL
          ? sliderRef.current.scrollWidth - sliderRef.current.clientWidth
          : 0;
        sliderRef.current.scrollTo({
          left: scrollPosition,
          behavior: "auto",
        });
      }
    };
    requestAnimationFrame(scrollToStart);
  }, [isRTL]);

  return (
    <>
      {/* Hero Section */}
      <Box
        sx={{
          background: `linear-gradient(to left, rgba(255, 255, 255, 0.25) -100%, rgba(5, 5, 5, 0.25) 36.34%, rgba(0, 0, 0, 0.25) 37%), url(${houseImage})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: { xs: "auto", md: "cover" },
          backgroundPosition: { xs: "top", md: "center" },
          minHeight: { xs: "auto", md: "600px" },
          maxHeight: "790px",
          mx: { xs: "20px", md: "20px" },
          display: "flex",
          flexDirection: {
            xs: "column",
            md: isRTL ? "row-reverse" : "row",
          },
          justifyContent: { xs: "flex-start", md: "space-between" },
          alignItems: {
            xs: isRTL ? "flex-end" : "flex-start",
            md: "flex-end",
          },
          position: "relative",
          overflow: "hidden",
          borderRadius: "20px",
          padding: { xs: "20px", md: "30px" },
          pt: { xs: "65px", md: "65px" },
          pb: { xs: "20px", md: "45px" },
        }}
      >
        {/* Text Section */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            color: "white",
            gap: { xs: "15px", md: "20px" },
            fontFamily: isRTL ? "Noto Sans Hebrew" : "DM sans",
            width: { xs: "75%", sm: "80%", md: "50%" },
            textAlign: isRTL ? "right" : "left",
            mb: { xs: "60px", md: "0" },
            alignSelf: isMobile && isRTL ? "flex-end" : "",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: isRTL ? "flex-end" : "flex-start",
              background: "#FFFFFF52",
              backdropFilter: "blur(40px)",
              borderRadius: "7px",
              p: "8px 16px",
              width: "fit-content",
              gap: "8px",
              alignSelf: isRTL ? "flex-end" : "flex-start",
              flexDirection: isRTL ? "row-reverse" : "row",
            }}
          >
            <img
              src={RocketIcon}
              alt="rocket"
              style={{ width: "16px", height: "16px" }}
            />
            <Typography
              sx={{
                fontSize: { xs: "12px", md: "16px" },
                fontWeight: "500",
                fontFamily: isRTL ? "Noto Sans Hebrew" : "DM sans",
                color: "white",
              }}
            >
              {successfulProjects}+
            </Typography>
            <Typography
              sx={{
                fontSize: { xs: "12px", md: "16px" },
                fontWeight: "400",
                fontFamily: isRTL ? "Noto Sans Hebrew" : "DM sans",
                color: "white",
                textTransform: "uppercase",
              }}
            >
              {t("successful")} {t("projects")}
            </Typography>
          </Box>
          {!isMobile && (
            <Typography
              sx={{
                fontSize: { xs: "12px", md: "18px" },
                fontWeight: "500",
                fontFamily: isRTL ? "Noto Sans Hebrew" : "DM sans",
              }}
            >
              {t("start")}
            </Typography>
          )}
          <Box>
            <Typography
              sx={{
                fontSize: { xs: "20px", sm: "24px", md: "36px" },
                fontWeight: "600",
                textTransform: "uppercase",
                lineHeight: 1.2,
                fontFamily: isRTL ? "Noto Sans Hebrew" : "Frank Ruhl Libre",
                mb: "10px",
                direction: i18n.dir(),
              }}
            >
              {t("maximize")}
            </Typography>
            <Typography
              sx={{
                fontSize: { xs: "12px", md: "18px" },
                fontWeight: "400",
                fontFamily: isRTL ? "Noto Sans Hebrew" : "DM sans",
              }}
            >
              {t("ai")}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: isRTL ? "flex-end" : "flex-start",
              color: "#fff",
              background: "linear-gradient(to right, #034A73, #0A7992)",
              p: downLg ? "10px 18px" : "15px 25px",
              cursor: "pointer",
              borderRadius: "24px",
              width: "fit-content",
              gap: "8px",
              flexDirection: isRTL ? "row-reverse" : "row",
              alignSelf: isRTL ? "flex-end" : "flex-start",
            }}
            onClick={() => navigate("/contact-us")}
          >
            <Typography
              sx={{
                mr: isRTL ? "12px" : "0",
                ml: isRTL ? "0" : "12px",
                fontFamily: isRTL ? "Noto Sans Hebrew" : "DM Sans",
                fontWeight: isRTL ? 400 : 500,
                lineHeight: "16px",
                fontSize: downLg ? "14px" : "16px",
              }}
            >
              {t("partner")}
            </Typography>
            <img
              src={Arrow}
              alt="arrow"
              style={{
                transform: isRTL ? "scaleX(-1)" : "scaleX(1)",
                width: "16px",
                height: "16px",
              }}
            />
          </Box>
        </Box>

        {/* Statistics Slider */}
        <Box
          ref={sliderRef}
          sx={{
            display: "flex",
            flexDirection: "row",
            overflowX: isMobile ? "auto" : "hidden",
            gap: { xs: "15px", md: "20px" },
            height: "80%",
            alignItems: "flex-start",
            justifyContent: downMd
              ? isRTL
                ? "flex-end" //{ xs: 'center', md: 'flex-end' }
                : "flex-start"
              : isRTL
              ? "flex-end"
              : "flex-end",
            color: "white",
            fontFamily: isRTL ? "Noto Sans Hebrew" : "DM Sans",
            mb: "0",
            width: "100%",
            scrollSnapType: downMd ? "x mandatory" : "none",
            scrollbarWidth: "none",
            "&::-webkit-scrollbar": { display: "none" },
            padding: downMd ? "0 10px" : "0",
            scrollBehavior: "smooth",
            direction: isRTL ? "rtl" : "ltr",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "row", md: "column" },
              gap: "40px",
              alignItems: "flex-end",
              maxWidth: {
                xs: "40vw",
                md: isRTL ? "20%" : "45%",
                xl: "30%",
              },
              mr: isRTL ? "" : { sm: 0, md: "10%", lg: 0 },
              ml: isRTL ? { sm: 0, md: 0, xl: 0 } : "",
              justifyContent: isRTL
                ? "flex-end" //{ xs: 'center', md: 'flex-end' }
                : "flex-start",
            }}
          >
            {/* Active Investors - First Stat */}
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: isRTL ? "flex-end" : "flex-start",
                alignItems: "center",
                gap: { xs: "8px", sm: "10px", md: "12px" },
                // width: { xs: '40vw', md: isRTL ? '20%' : '30%', lg: '20%' },
                scrollSnapAlign: downMd ? (isRTL ? "end" : "start") : "none",
                px: downMd ? 1 : 0,
                width: "100%",
              }}
            >
              <Box
                sx={{
                  bgcolor: "#FFFFFF47",
                  backdropFilter: "blur(15px)",
                  borderRadius: "50%",
                  width: { xs: "60px", sm: "70px", md: "100px" },
                  height: { xs: "60px", sm: "70px", md: "100px" },
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexShrink: 0,
                  direction: "ltr",
                }}
              >
                <Typography
                  sx={{
                    fontSize: { xs: "16px", sm: "18px", md: "24px" },
                    fontWeight: "600",
                    fontFamily: isRTL ? "Noto Sans Hebrew" : "DM sans",
                    textAlign: "left",
                  }}
                >
                  {activeInvestors}+
                </Typography>
              </Box>
              <Box sx={{ textAlign: "left" }}>
                <Typography
                  sx={{
                    fontSize: { xs: "14px", sm: "16px", md: "24px" },
                    lineHeight: "1.2",
                    fontFamily: isRTL ? "Noto Sans Hebrew" : "DM sans",
                    fontWeight: isRTL ? "700" : "600",
                  }}
                >
                  {t("active")}
                </Typography>
                <Typography
                  sx={{
                    fontSize: { xs: "14px", sm: "16px", md: "24px" },
                    lineHeight: "1.2",
                    fontFamily: isRTL ? "Noto Sans Hebrew" : "DM sans",
                    fontWeight: isRTL ? "700" : "600",
                  }}
                >
                  {t("investors")}
                </Typography>
              </Box>
            </Box>

            {/* Successful Projects - Second Stat */}
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: isRTL ? "flex-end" : "flex-start",
                gap: { xs: "8px", sm: "10px", md: "12px" },
                // maxWidth: {
                //   xs: '40vw',
                //   md: isRTL ? '20%' :'45%',
                //   xl: '30%',
                // },
                scrollSnapAlign: downMd ? (isRTL ? "end" : "start") : "none",
                px: downMd ? 1 : 0,
                width: "100%",
              }}
            >
              <Box
                sx={{
                  bgcolor: "#FFFFFF47",
                  backdropFilter: "blur(15px)",
                  borderRadius: "50%",
                  width: { xs: "60px", sm: "70px", md: "100px" },
                  height: { xs: "60px", sm: "70px", md: "100px" },
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexShrink: 0,
                  direction: "ltr",
                }}
              >
                <Typography
                  sx={{
                    fontSize: { xs: "16px", sm: "18px", md: "24px" },
                    fontWeight: "700",
                    fontFamily: isRTL ? "Noto Sans Hebrew" : "DM sans",
                  }}
                >
                  {successfulProjects}+
                </Typography>
              </Box>
              <Box sx={{ textAlign: "left", width: "auto" }}>
                <Typography
                  sx={{
                    fontSize: { xs: "14px", sm: "16px", md: "24px" },
                    lineHeight: "1.2",
                    fontFamily: isRTL ? "Noto Sans Hebrew" : "DM sans",
                    fontWeight: isRTL ? "700" : "600",
                  }}
                >
                  {t("successful")}
                </Typography>
                <Typography
                  sx={{
                    fontSize: { xs: "14px", sm: "16px", md: "24px" },
                    lineHeight: "1.2",
                    fontFamily: isRTL ? "Noto Sans Hebrew" : "DM sans",
                    fontWeight: isRTL ? "700" : "600",
                  }}
                >
                  {t("projects")}
                </Typography>
              </Box>
            </Box>

            {/* In Real Estate Sales - Third Stat */}
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: isRTL ? "flex-end" : "flex-start",
                gap: { xs: "8px", sm: "10px", md: "12px" },
                scrollSnapAlign: downMd ? (isRTL ? "end" : "start") : "none",
                px: downMd ? 1 : 0,
                width: "100%",
              }}
            >
              <Box
                sx={{
                  bgcolor: "#FFFFFF47",
                  backdropFilter: "blur(15px)",
                  borderRadius: "50%",
                  width: { xs: "60px", sm: "70px", md: "100px" },
                  height: { xs: "60px", sm: "70px", md: "100px" },
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexShrink: 0,
                }}
              >
                <Typography
                  sx={{
                    fontSize: { xs: "16px", sm: "18px", md: "24px" },
                    fontWeight: "600",
                    direction: "ltr",
                    fontFamily: isRTL ? "Noto Sans Hebrew" : "DM sans",
                  }}
                >
                  {isRTL ? "₪" : "$"} {formattedSalesAmount}
                </Typography>
              </Box>
              <Box sx={{ textAlign: "left", width: "auto" }}>
                <Typography
                  sx={{
                    fontSize: { xs: "14px", sm: "16px", md: "24px" },
                    lineHeight: "1.2",
                    fontFamily: isRTL ? "Noto Sans Hebrew" : "DM sans",
                    fontWeight: isRTL ? "700" : "600",
                  }}
                >
                  {t("in_re")}
                </Typography>
                <Typography
                  sx={{
                    fontSize: { xs: "14px", sm: "16px", md: "24px" },
                    lineHeight: "1.2",
                    fontFamily: isRTL ? "Noto Sans Hebrew" : "DM sans",
                    fontWeight: isRTL ? "700" : "600",
                  }}
                >
                  {t("sales")}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>

      {/* Images Slider */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          overflowX: downMd ? "auto" : "auto",
          overflowY: "hidden",
          mx: { xs: "20px", md: "20px" },
          mt: "20px",
          gap: { xs: "10px", md: "20px" },
          scrollSnapType: downMd ? "x mandatory" : "none",
          scrollbarWidth: "none",
          "&::-webkit-scrollbar": { display: "none" },
          padding: downMd ? "0 10px" : "0",
          justifyContent: "space-between",
        }}
      >
        {/* House 1 */}
        <Box
          sx={{
            position: "relative",
            minWidth: "400px",
            scrollSnapAlign: downMd ? "start" : "none",
            borderRadius: "20px",
            overflow: "hidden",
            height: "400px",
            width: "33%",
          }}
        >
          <img
            src={houseOneImage}
            alt="House 1"
            style={{ width: "100%", height: "100%", objectFit: "cover" }}
          />
          <Box
            sx={{
              position: "absolute",
              bottom: 0,
              left: 0,
              top: 0,
              width: "100%",
              p: "30px",
              background:
                "linear-gradient(to bottom, rgba(0,0,0,0.7), rgba(0,0,0,0))",
              color: "white",
              textAlign: isRTL ? "right" : "left",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              boxSizing: "border-box",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                flexGrow: 1,
              }}
            >
              <Typography
                sx={{
                  fontSize: { xs: "22px", md: "26px" },
                  fontWeight: "500",
                  fontFamily: isRTL ? "Noto Sans Hebrew" : "DM sans",
                  lineHeight: "30px",
                }}
              >
                {t("edge")}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color: "#fff",
                background: "linear-gradient(to right, #034A73, #0A7992)",
                p: "10px 18px",
                cursor: "pointer",
                borderRadius: "24px",
                gap: "8px",
                flexDirection: isRTL ? "row-reverse" : "row",
                alignSelf: isRTL ? "flex-end" : "flex-start",
                padding: "16px 27.5px",
              }}
              onClick={() => navigate("/contact-us")}
            >
              <Typography
                sx={{
                  fontFamily: isRTL ? "Noto Sans Hebrew" : "DM Sans",
                  fontWeight: isRTL ? 400 : 500,
                  lineHeight: "16px",
                  fontSize: "16px",
                }}
              >
                {t("get_started")}
              </Typography>
            </Box>
          </Box>
        </Box>

        {/* House 2 */}
        <Box
          sx={{
            position: "relative",
            minWidth: "400px",
            scrollSnapAlign: downMd ? "start" : "none",
            borderRadius: "20px",
            overflow: "hidden",
            height: "400px",
            width: "33%",
            boxSizing: "border-box",
          }}
        >
          <img
            src={houseTwoImage}
            alt="House 2"
            style={{ width: "100%", height: "100%", objectFit: "cover" }}
          />
          <Box
            sx={{
              position: "absolute",
              bottom: 0,
              left: 0,
              top: 0,
              width: "100%",
              p: "30px",
              background:
                "linear-gradient(to bottom, rgba(0,0,0,0.7), rgba(0,0,0,0))",
              color: "white",
              textAlign: isRTL ? "right" : "left",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              boxSizing: "border-box",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                flexGrow: 1,
              }}
            >
              <Typography
                sx={{
                  fontSize: { xs: "22px", md: "26px" },
                  fontWeight: "500",
                  fontFamily: isRTL ? "Noto Sans Hebrew" : "DM sans",
                  lineHeight: "30px",
                }}
              >
                {t("team")}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color: "#fff",
                background: "linear-gradient(to right, #034A73, #0A7992)",
                p: "10px 18px",
                cursor: "pointer",
                borderRadius: "24px",
                gap: "8px",
                flexDirection: isRTL ? "row-reverse" : "row",
                alignSelf: isRTL ? "flex-end" : "flex-start",
                padding: "16px 27.5px",
              }}
              onClick={() => navigate("/contact-us")}
            >
              <Typography
                sx={{
                  fontFamily: isRTL ? "Noto Sans Hebrew" : "DM Sans",
                  fontWeight: isRTL ? 400 : 500,
                  lineHeight: "16px",
                  fontSize: "16px",
                }}
              >
                {t("get_started")}
              </Typography>
            </Box>
          </Box>
        </Box>

        {/* House 3 */}
        <Box
          sx={{
            position: "relative",
            minWidth: "400px",
            scrollSnapAlign: downMd ? "start" : "none",
            borderRadius: "20px",
            overflow: "hidden",
            height: "400px",
            width: "33%",
            boxSizing: "border-box",
          }}
        >
          <img
            src={houseThreeImage}
            alt="House 3"
            style={{ width: "100%", height: "100%", objectFit: "cover" }}
          />
          <Box
            sx={{
              position: "absolute",
              bottom: 0,
              left: 0,
              top: 0,
              width: "100%",
              p: "30px",
              background:
                "linear-gradient(to bottom, rgba(0,0,0,0.7), rgba(0,0,0,0))",
              color: "white",
              textAlign: isRTL ? "right" : "left",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              boxSizing: "border-box",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                flexGrow: 1,
              }}
            >
              <Typography
                sx={{
                  fontSize: { xs: "22px", md: "26px" },
                  fontWeight: "500",
                  fontFamily: isRTL ? "Noto Sans Hebrew" : "DM sans",
                  lineHeight: "30px",
                }}
              >
                {t("market")}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color: "#fff",
                background: "linear-gradient(to right, #034A73, #0A7992)",
                p: "10px 18px",
                cursor: "pointer",
                borderRadius: "24px",
                gap: "8px",
                flexDirection: isRTL ? "row-reverse" : "row",
                alignSelf: isRTL ? "flex-end" : "flex-start",
                padding: "16px 27.5px",
              }}
              onClick={() => navigate("/contact-us")}
            >
              <Typography
                sx={{
                  fontFamily: isRTL ? "Noto Sans Hebrew" : "DM Sans",
                  fontWeight: isRTL ? 400 : 500,
                  lineHeight: "16px",
                  fontSize: "16px",
                }}
              >
                {t("get_started")}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
}
