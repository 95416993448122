import { useMediaQuery } from "@mui/material";
import { skipToken } from "@reduxjs/toolkit/query";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useLocation, useSearchParams } from "react-router-dom";
import { AuthPopup } from "../components/_shared/AuthPopup";
import { Footer } from "../components/_shared/Footer";
import { FooterSocialMediaLine } from "../components/_shared/FooterSocialMediaLine";
import { Header } from "../components/_shared/Header";
import { Navbar, UserTypes } from "../components/_shared/Navbar";
import { NotificationSnackBar } from "../components/campaignBuilder/NotificationSnackBar";
import { FilterSideBar } from "../components/home/FilterSideBar";
import { GetInTouchBlock } from "../components/home/GetInTouchBlock";
import {
  DEFAULT_CAMPAIGN_STATUS,
  DEFAULT_FILTER,
  DEFAULT_SORT,
  DEFAULT_USER_ID,
} from "../components/portfolio/constants";
import { IFilterParams, ISortParams } from "../components/portfolio/interfaces";
import {
  buildSearchParamsFromGetPropertiesParams,
  convertToPaginationMeta,
  enrichParams,
  parseSearchParams,
} from "../components/portfolio/logic";
import { PortfolioMobileFilterContainer } from "../components/portfolio/content/layout/PortfolioMobileFilterContainer";
import { convertToProperties } from "../helpers/convert";
import { randomMediaGenerator } from "../helpers/fakeMedia";
import { useElementHeight } from "../hooks/useHeaderHeight";
import {
  IGetPropertiesParamsWithLang,
  useGetPropertiesWithParamsQuery,
} from "../store/api/propertyApiSlice";
import { setWishlistSnackbarClose } from "../store/features/wishlistSlice";
import { useAppDispatch } from "../store/store";
import { PortfolioDesktopFilterContainer } from "../components/portfolio/content/layout/PortfolioDesktopFilterContainer";
import { PropertiesContainer } from "../components/portfolio/content/layout/PropertiesContainer";
import { FixedMenu } from "../components/portfolio/content/layout/FixedMenu";
import { NotificationStatus } from "../components/campaignBuilder/content/steps/CampaignSetUpStep";
import { FooterDemo } from "../components/_shared/FooterDemo";

export const Portfolio = () => {
  const { i18n } = useTranslation();
  const dispatch = useAppDispatch();

  const [_, setSearchParams] = useSearchParams();
  const isFilterDesktop = useMediaQuery("(max-width: 1090px)");
  const user = localStorage.getItem("user") || sessionStorage.getItem("user");
  const location = useLocation();

  // Query state
  const [page, setPage] = useState<number>(1);
  const [sort, setSort] = useState<ISortParams>(DEFAULT_SORT);
  const [filters, setFilters] = useState<IFilterParams>(DEFAULT_FILTER);
  const [search, setSearch] = useState<string>("");
  const [campaignStatus, setCampaignStatus] = React.useState(
    DEFAULT_CAMPAIGN_STATUS
  );
  const [userId, setUserId] = React.useState(
    user && JSON.parse(user).userType === UserTypes.SELLER
      ? JSON.parse(user).id
      : DEFAULT_USER_ID
  );
  // Workaround for not setting state into URL before parsing
  const [initComplete, setInitComplete] = useState(false);

  // Sync/persist state back to Search Params
  useEffect(() => {
    if (!initComplete) return;
    setSearchParams(
      buildSearchParamsFromGetPropertiesParams({
        filters,
        page,
        search,
        sort,
        campaignStatus,
        userId,
      })
    );
  }, [initComplete, filters, page, search, sort, campaignStatus, userId]);

  // Init page state from Search Params
  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const params = parseSearchParams(searchParams, userId);

    //new format
    enrichParams(params).then((params) => {
      setPage(params.page);
      setSearch(params.search);
      setFilters(params.filters);
      setSort(params.sort);
      setCampaignStatus(params.campaignStatus);
      setUserId(params.userId);
      setInitComplete(true);
    });
    //new format
  }, []);

  // Query data
  const getPropertiesParams =
    useMemo<IGetPropertiesParamsWithLang | null>(() => {
      return {
        params: {
          page: 1,
          pageSize: (page * 20).toString(),
          sort: sort,
          filters: filters,
          search: search,
          campaignStatus: campaignStatus,
          userId: userId,
        },
        lang: i18n.language,
      };
    }, [page, sort, filters, search, i18n.language, campaignStatus, userId]);

  const { data, isLoading, refetch } = useGetPropertiesWithParamsQuery(
    getPropertiesParams ?? skipToken,
    {
      refetchOnMountOrArgChange: true,
    }
  );

  React.useEffect(() => {
    refetch();
  }, [location.key, refetch]);

  const fetchedProperties = useMemo(() => {
    return convertToProperties(randomMediaGenerator, data?.data);
  }, [data]);

  const paginationMeta = useMemo(() => {
    return convertToPaginationMeta(data?.meta?.pagination);
  }, [data]);

  const contentRef = React.useRef<any>(null);
  const [openSearchPanel, setOpenSearchPanel] = React.useState(false);

  const headerHeight = useElementHeight(contentRef, [openSearchPanel]);

  const { wishlistSnackbarOpen, snackBarLikeMessage } = useSelector(
    (state: any) => state.wishlist
  );

  const handleCloseLikeSnackBar = () => {
    dispatch(setWishlistSnackbarClose());
  };

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        position: "relative",
      }}
    >
      <>
        <div className="portfolio-header-sticky-wrapper" ref={contentRef}>
          <div
            style={{
              backgroundColor: "rgba(255, 255, 255, 1)",
            }}
          >
            <Header>
              <Navbar />
            </Header>
          </div>

          {isFilterDesktop ? (
            <PortfolioMobileFilterContainer
              filters={filters}
              onChangeFilters={setFilters}
              search={search}
              onChangeSearch={setSearch}
            />
          ) : (
            <PortfolioDesktopFilterContainer
              filters={filters}
              onChangeFilters={setFilters}
              search={search}
              onChangeSearch={setSearch}
              openSearchPanel={openSearchPanel}
              setOpenSearchPanel={setOpenSearchPanel}
            />
          )}
        </div>
        {isFilterDesktop && <FixedMenu />}

        <FilterSideBar
          filters={filters}
          onChangeFilters={setFilters}
          search={search}
          onChangeSearch={setSearch}
          initCampaingStatus={campaignStatus}
          onCampaingChange={setCampaignStatus}
          initUserId={userId}
          onUserIdChange={setUserId}
          showPortfolioFilters={true}
        />

        <PropertiesContainer
          filters={filters}
          allProperties={fetchedProperties}
          onPageChange={setPage}
          initSort={sort}
          onSortChange={setSort}
          initCampaingStatus={campaignStatus}
          onCampaingChange={setCampaignStatus}
          initUserId={userId}
          onUserIdChange={setUserId}
          paginationMeta={paginationMeta}
          isLoading={isLoading}
          headerHeight={headerHeight}
          search={search}
        />
        <AuthPopup />
        <GetInTouchBlock />
        {/* <Footer /> */}
        <FooterDemo />
        <FooterSocialMediaLine />
        <NotificationSnackBar
          open={wishlistSnackbarOpen}
          onClose={handleCloseLikeSnackBar}
          type={NotificationStatus.SUCCESSFULL}
          snackbarText={snackBarLikeMessage}
        />
      </>
    </div>
  );
};
