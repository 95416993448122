import { Snackbar } from "@mui/material";
import React from "react";
import { useLanguageTheme } from "../../hooks/useLanguageTheme";
import CheckIcon from "../../assets/campaignBuilder/check_icon.svg";
import ErrorIcon from "../../assets/campaignBuilder/error_notification.svg";
import { useTranslation } from "react-i18next";
import { NotificationStatus } from "./content/steps/CampaignSetUpStep";

export const NotificationSnackBar = ({
  snackbarText,
  onClose,
  type,
  open,
}: {
  snackbarText: string;
  onClose: any;
  type: any;
  open: boolean;
}) => {
  const langTheme = useLanguageTheme();
  const { t, i18n } = useTranslation(["campaignBuilder"]);
  const isRTL = i18n.dir() === "rtl";
  return (
    <Snackbar
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      ContentProps={{
        sx: {
          backgroundColor:
            type === NotificationStatus.SUCCESSFULL ? "#EDF7ED" : "#FDEDED",
          color:
            type === NotificationStatus.SUCCESSFULL ? "#1E4620" : "#5F2120",
          boxShadow: "none",
          fontFamily: langTheme.mainFont(),
          fontSize: "16px",
          fontWeight: 500,
          // justifyContent: isRTL ? "flex-end" : "flex-start",
          direction: i18n.dir(),
        },
      }}
      open={open}
      // open={true}
      autoHideDuration={6000}
      onClose={() => onClose(false)}
      message={
        <div
          style={{
            display: "flex",
            gap: "12px",
            // direction: i18n.dir(),
            // justifyContent: isRTL ? "flex-end" : "flex-start",
          }}
        >
          <img
            src={
              type === NotificationStatus.SUCCESSFULL ? CheckIcon : ErrorIcon
            }
          />
          <div
            style={{
              lineHeight: "24px",
            }}
          >
            {snackbarText}
          </div>
        </div>
      }
    />
  );
};
