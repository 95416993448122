import { IArticle } from "../interfaces";
import { WhiteMiddleArcitleCard } from "./WhiteMiddleArcitleCard";

export const whiteAdditionalCardStylesForGallery = {
  fontSize: "18px",
  lineHeight: "22px",
  padding: "20px 25px 25px 20px",
  minWidth: "316px",
  imgFlex: "1.1",
  height: "425px",
};

export const GalleryOfArticles = ({ articles }: { articles: IArticle[] }) => {
  return (
    <>
      {articles.map((article: any, index: number) => (
        <WhiteMiddleArcitleCard
          article={article}
          showClueContainer={false}
          descTrancateLength={100}
          key={index}
          styles={whiteAdditionalCardStylesForGallery}
        />
      ))}
    </>
  );
};
