export interface ITag {
  id: string;
  title: string;
}

export const DEFAULT_PAGE = 1;
export const ARTICLE_PAGE_SIZE = 10;

export const QUERY_PARAM_PAGE = "page";
export const QUERY_PARAM_SEARCH = "search";
export const QUERY_PARAM_PAGE_SIZE = "pageSize";
export const QUERY_PARAM_TAG = "tags";
export const PARAM_ENCODER_SEP = ",";

export interface IGetArticlesParams {
  page: number;
  pageSize?: string;
  search: string;
  tags: ITag[];
}

export const tagMap: TagMap = {
  "1": {
    id: "1",
    title: "news",
  },
  "2": {
    id: "2",
    title: "trends",
  },
  "3": {
    id: "3",
    title: "marketing",
  },
  "4": {
    id: "4",
    title: "sentom_solutions",
  },
  "5": {
    id: "5",
    title: "expert_tips",
  },
  "6": {
    id: "6",
    title: "investments",
  },
  "7": {
    id: "7",
    title: "law_tax",
  },
  "8": {
    id: "8",
    title: "finance",
  },
};

type FilterKey = string;
export type TagMap = { [key: FilterKey]: ITag };

export function getTagByIds(tagMap: TagMap, ids: FilterKey[]): ITag[] {
  const filters: ITag[] = [];

  for (const id of ids) {
    const filter = tagMap[id];
    if (filter) {
      filters.push(filter);
    }
  }

  return filters;
}

export function decodeTags(param: string | null): string[] {
  if (param === null) {
    return [];
  }

  return param.split(PARAM_ENCODER_SEP);
}

export function encodeParamArray(keys: string[]) {
  return keys.join(PARAM_ENCODER_SEP);
}

export function parseSearchParams(
  searchParams: URLSearchParams
): IGetArticlesParams {
  const pageParam = searchParams.get(QUERY_PARAM_PAGE);
  const searchParam = searchParams.get(QUERY_PARAM_SEARCH);
  const tagParam = searchParams.get(QUERY_PARAM_TAG);

  let tags = tagParam ? getTagByIds(tagMap, decodeTags(tagParam)) : [];

  return {
    page: Number(pageParam) || DEFAULT_PAGE,
    tags: tags,
    search: searchParam || "",
  };
}

export function buildSearchParamsFromGetArticlesParams(
  params: IGetArticlesParams
): URLSearchParams {
  const queryParams = new URLSearchParams();

  // queryParams.set(QUERY_PARAM_PAGE, "1");
  queryParams.set(QUERY_PARAM_PAGE, params.page.toString());
  queryParams.set(QUERY_PARAM_PAGE_SIZE, (params.page * 10).toString());
  // queryParams.set(QUERY_PARAM_PAGE_SIZE, ARTICLE_PAGE_SIZE.toString());

  if (params.search) {
    queryParams.set(QUERY_PARAM_SEARCH, params.search);
  } else {
    queryParams.delete(QUERY_PARAM_SEARCH);
  }

  let t = encodeParamArray(params.tags.map((item) => item.id));

  if (t) {
    queryParams.set(QUERY_PARAM_TAG, t);
  } else {
    queryParams.delete(QUERY_PARAM_TAG);
  }

  return queryParams;
}
