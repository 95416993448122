import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import LocationIcon from "../../../../assets/campaignBuilder/location_black.svg";
import UploadIcon from "../../../../assets/campaignBuilder/upload_blue.svg";
import Map, { MapRef, Marker } from "react-map-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import mapboxgl from "mapbox-gl";
import { useSelector } from "react-redux";
import LocationPinIcon from "../../../../assets/campaignBuilder/location_pin.svg";
import { Theme, useMediaQuery } from "@mui/material";
import i18n2 from "../../../../i18n";
import { REACT_APP_MAP_ACCESS_TOKEN } from "../../../../helpers/config";
import { PreviewMode } from "../../types";

if (REACT_APP_MAP_ACCESS_TOKEN) {
  mapboxgl.accessToken = REACT_APP_MAP_ACCESS_TOKEN;
}

if (mapboxgl.getRTLTextPluginStatus() === "unavailable") {
  mapboxgl.setRTLTextPlugin(
    "https://api.mapbox.com/mapbox-gl-js/plugins/mapbox-gl-rtl-text/v0.2.3/mapbox-gl-rtl-text.js",
    null,
    true
  );
}

export const LocationBlock = ({ isDraggable = false }) => {
  const { t } = useTranslation(["campaignBuilder"]);
  const mapRef = useRef<MapRef>(null);
  const [mapViewport, setMapViewport] = useState({
    latitude: 37.7749,
    longitude: -122.4194,
    zoom: 12,
  });

  const currentLanguage = i18n2.language;

  const [viewport, setViewport] = useState({
    latitude: 37.7749,
    longitude: -122.4194,
    zoom: 12,
  });

  const { propertyLocation } = useSelector(
    (state: any) => state.campaignBuilder
  );

  React.useEffect(() => {
    setViewport({
      latitude: propertyLocation.coordinates.latitude,
      longitude: propertyLocation.coordinates.longitude,
      zoom: 12,
    });
    setMapViewport({
      latitude: propertyLocation.coordinates.latitude,
      longitude: propertyLocation.coordinates.longitude,
      zoom: 12,
    });
  }, [propertyLocation]);

  const { previewMode } = useSelector(
    (state: any) => state.campaignBuilderSetup
  );
  const downMd = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));

  //

  const updateMapLanguage = (map: mapboxgl.Map) => {
    const language = currentLanguage;
    const languageField = "name_" + language;
    const style = map.getStyle();

    if (!style || !style.layers) {
      console.error("Map style or layers are not available");
      return;
    }
    style.layers.forEach(function (layer: mapboxgl.LayerSpecification) {
      if (
        layer.type === "symbol" &&
        layer.layout &&
        layer.layout["text-field"]
      ) {
        map.setLayoutProperty(layer.id, "text-field", [
          "coalesce",
          ["get", languageField],
          ["get", "name"],
        ]);
      }
    });
  };

  const onMapLoad = (event: mapboxgl.MapEvent) => {
    const map: mapboxgl.Map = event.target;
    updateMapLanguage(map);
  };

  useEffect(() => {
    if (mapRef.current) {
      const map = mapRef.current.getMap();
      onMapLoad({ target: map } as mapboxgl.MapEvent);
    }
  }, [currentLanguage]);

  return (
    <div>
      <div className="campaign-builder-main-location-header-container">
        <div className="campaign-builder-main-header">{t("location")}</div>
        <div className="campaign-builder-get-direction">
          <img src={UploadIcon} alt="Upload Icon" />
          <div>{t("get_directions")}</div>
        </div>
      </div>

      <div className="campaign-builder-main-location-name">
        <img src={LocationIcon} alt="Location Icon" />
        <div>
          {propertyLocation?.address ? propertyLocation?.address : t("adress")}
        </div>
      </div>

      <div>
        <Map
          ref={mapRef}
          onLoad={onMapLoad}
          {...mapViewport}
          style={{
            width: "100%",
            borderRadius: "20px",
            height:
              previewMode === PreviewMode.MOBILE || downMd ? "330px" : "450px",
          }}
          mapStyle="mapbox://styles/mapbox/streets-v12"
          mapboxAccessToken={REACT_APP_MAP_ACCESS_TOKEN}
          onMove={
            isDraggable
              ? (viewport) => setMapViewport(viewport.viewState)
              : () => {}
          }
        >
          <Marker longitude={viewport.longitude} latitude={viewport.latitude}>
            <img src={LocationPinIcon} alt="Location Pin Icon" />
          </Marker>
        </Map>
      </div>
    </div>
  );
};
