import { AuthWrapper } from "../components/_shared/AuthWrapper";
import { ForgotPasswordContent } from "../components/_shared/ForgotPasswordContent";

export enum ForgotPasswordStep {
  enterEmail = "enterEmail",
  confirmEmail = "confirmEmail",
  newPassword = "newPassword",
  done = "done",
}

export const ForgotPassword = () => {
  return (
    <AuthWrapper>
      <ForgotPasswordContent />
    </AuthWrapper>
  );
};
