import { Box } from "@mui/material";
import { Outlet } from "react-router-dom";
import { SideNavMenu } from "./SideNavMenu";

export const LayoutCampaignPageView = () => {
  return (
    <>
      <Box component="main">
        <Box>
          <SideNavMenu />
          <Outlet></Outlet>
        </Box>
      </Box>
    </>
  );
};
