import React, { useState, useEffect, useRef } from "react";
import { Autocomplete } from "@react-google-maps/api";
import { useTranslation } from "react-i18next";
import { useAppDispatch } from "../../store/store";
import { setPropertyLocation } from "../../store/features/campaignBuilderSlice";
import { useSelector } from "react-redux";
import { useLanguageTheme } from "../../hooks/useLanguageTheme";
import { useResetToDraft } from "../../hooks/useResetToDraft";

export const AutocompleteComponent = ({
  errors,
  setErrors,
}: {
  errors: any;
  setErrors: any;
}) => {
  const [autocomplete, setAutocomplete] = useState<any>(null);
  const [selectedPlace, setSelectedPlace] = useState<any>(null);
  const inputRef = useRef<any>(null);
  const onLoad = (autocompleteInstance: any) => {
    setAutocomplete(autocompleteInstance);
  };

  const resetToDraft = useResetToDraft();

  const langTheme = useLanguageTheme();

  const { t, i18n } = useTranslation(["portfolio"]);
  const dispatch = useAppDispatch();
  const { propertyLocation } = useSelector(
    (state: any) => state.campaignBuilder
  );
  const [value, setValue] = useState(propertyLocation?.address || "");

  useEffect(() => {
    setValue(propertyLocation?.address || "");
  }, [propertyLocation]);

  const extractCity = (place: any) => {
    if (!place.address_components) return "";
    for (const component of place.address_components) {
      if (component.types.includes("locality")) {
        return component.long_name;
      }
    }
    return "";
  };
  const onPlaceChanged = () => {
    if (autocomplete) {
      localStorage.setItem("onChangeCompanyBuilder", "1");
      const place = autocomplete.getPlace();

      if (place && place.formatted_address) {
        const latitude = place.geometry.location.lat();
        const longitude = place.geometry.location.lng();
        const city = extractCity(place);

        setSelectedPlace({ ...place, latitude, longitude, city });

        dispatch(
          setPropertyLocation({
            city,
            address: inputRef.current.value
              ? inputRef.current.value
              : place.formatted_address,
            coordinates: { latitude, longitude },
            id: "",
          })
        );

        resetToDraft();
        const newErrors = { ...errors };
        delete newErrors.location;
        setErrors(newErrors);
      }
    }
  };

  useEffect(() => {
    const direction = i18n.dir();
    const observer = new MutationObserver(() => {
      const pacItems = document.querySelectorAll(".pac-item");
      pacItems.forEach((item: any) => {
        item.style.textAlign = direction === "rtl" ? "right" : "left";
      });
    });

    observer.observe(document.body, {
      childList: true,
      subtree: true,
    });

    return () => {
      observer.disconnect();
    };
  }, [i18n.language]);

  return (
    <div>
      <div>
        <Autocomplete
          onLoad={onLoad}
          onPlaceChanged={onPlaceChanged}
          options={{
            componentRestrictions: { country: "il" },
          }}
        >
          <input
            type="text"
            ref={inputRef}
            className="test-location-builder"
            placeholder={t("location_placeholder")}
            style={{
              width: "100%",
              backgroundColor: "#e8eff2",
              padding: "15px 20px",
              boxSizing: "border-box",
              borderRadius: "16px",
              fontFamily: langTheme.mainFont(),
              border: errors.location
                ? "1px solid rgba(250, 99, 99, 1)"
                : "1px solid rgba(5, 57, 87, 0.46)",
            }}
            value={value}
            onChange={(e) => setValue(e.target.value)}
          />
        </Autocomplete>
      </div>
    </div>
  );
};
