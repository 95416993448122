import { Theme, useMediaQuery } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { useLanguageTheme } from "../../../hooks/useLanguageTheme";
import LinkArrowBlue from "../../../assets/icons/link_arrow_blue.svg";
import { useAppDispatch } from "../../../store/store";
import { setAuthPopupOpen } from "../../../store/features/common";
import { AuthPopupContentType } from "../../auth/types";
import { useAuth } from "../../../hooks/useAuth";
import { useNavigate } from "react-router-dom";

export const ExporeBlock = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { t } = useTranslation(["home"]);
  const { i18n } = useTranslation();
  const isRTL = i18n.language === "he";
  const langTheme = useLanguageTheme();
  const downMd = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));

  const isAuthenticated = useAuth();

  const userString =
    localStorage.getItem("user") || sessionStorage.getItem("user");
  const user = userString ? JSON.parse(userString) : null;
  const storedJwt =
    localStorage.getItem("jwt") || sessionStorage.getItem("jwt");
  const jwt = storedJwt ? storedJwt.replace(/"/g, "") : "";

  const handleGoToPortfolio = () => {
    if (!user || !user.id || !jwt) {
      console.error("User not logged in");
      dispatch(setAuthPopupOpen(AuthPopupContentType.SIGNUP));

      return;
    }

    if (isAuthenticated) {
      navigate("/portfolio");
    }
  };

  return (
    <div className="expore-block-container" onClick={handleGoToPortfolio}>
      <div className="expore-block-title-container">
        <div
          className="expore-block-title"
          style={{
            fontSize: downMd ? "20px" : "24px",
            maxWidth: downMd ? "189px" : "135px",
            fontFamily: langTheme.mainFont(),
            fontWeight: isRTL ? 700 : 500,
          }}
        >
          {t("explore_title")}
        </div>
        <div className="expore-block-arrow">
          <img src={LinkArrowBlue} />
        </div>
      </div>

      <div
        className="expore-block-desc"
        style={{
          fontFamily: langTheme.mainFont(),
          fontWeight: 400,
        }}
      >
        {t("explore_desc")}
      </div>
    </div>
  );
};
