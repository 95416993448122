import { SelectChangeEvent, Theme, useMediaQuery } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../../../store/store";
import {
  CampaignSideNavStep,
  Currency,
} from "../layout/CampaignBuilderSideNav";
import { CampaignBuilderSideNavHeader } from "../layout/CampaignBuilderSideNavHeader";
import { CampainBuilderNextBtn } from "../buttons/CampainBuilderNextBtn";
import { setCurrency } from "../../../../store/features/campaignBuilderSlice";
import { LangToggle } from "../../../_shared/LangToggle";
import { BackToMenuBtn } from "../buttons/BackToMenuBtn";
import { CampaignBuilderDurationSelect } from "../selects/CampaignBuilderDurationSelect";
import { CampaingBuilderSelect } from "../selects/CampaingBuilderSelect";
import { CampainBuilderBackBtn } from "../buttons/CampainBuilderBackBtn";

export const CampaignSettitngsStep = ({
  setCurrentStep,
  autosaveFunc = () => {},
  isDisabledSwitchLang = false,
}: {
  setCurrentStep: (step: CampaignSideNavStep) => void;
  autosaveFunc: any;
  isDisabledSwitchLang: boolean;
}) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation(["campaignBuilder"]);
  const { i18n } = useTranslation();
  const downMd = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));

  const { currency } = useSelector((state: any) => state.campaignBuilder);

  const handleChangeCurrency = (event: SelectChangeEvent<Currency>) => {
    localStorage.setItem("onChangeCompanyBuilder", "1");
    dispatch(setCurrency(event.target.value as Currency));
  };

  const handleNext = (step: CampaignSideNavStep) => {
    setCurrentStep(step);
  };

  return (
    <div className="campaign-builder-side-nav-wrapper">
      <CampaignBuilderSideNavHeader
        header="settings"
        leftBtn={
          <CampainBuilderBackBtn
            setCurrentStep={setCurrentStep}
            step={CampaignSideNavStep.campaignSetUp}
          />
        }
        rightBtn={
          <CampainBuilderNextBtn
            handleNext={handleNext}
            step={CampaignSideNavStep.mainInformation}
          />
        }
      />

      <div
        className="campaign-builder-sidenav-main-content-wrapper"
        style={{
          paddingTop: downMd ? "30px" : "40px",
          direction: i18n.dir(),
        }}
      >
        <div className="campaign-builder-sidenav-settings-fileds-container">
          <div className="campaign-builder-sidenav-settings-fileds-item">
            <div className="campaign-builder-sidenav-field-name">
              {t("currency")}
            </div>
            <CampaingBuilderSelect
              value={currency}
              onChangeValue={handleChangeCurrency}
              options={Object.values(Currency)}
            />
          </div>

          <div className="campaign-builder-sidenav-settings-fileds-item">
            <div className="campaign-builder-sidenav-field-name">
              {t("campaign_duration")}
            </div>
            <CampaignBuilderDurationSelect />
          </div>
        </div>

        <BackToMenuBtn setCurrentStep={setCurrentStep} />
      </div>
    </div>
  );
};
