import React from "react";
import { useTranslation } from "react-i18next";
import { useLanguageTheme } from "../../../../hooks/useLanguageTheme";

const CampaignStatus = {
  DRAFT: { label: "draft", color: "#67BAFF" },
  ARCHIVED: { label: "archived", color: "#AD53F7" },
  PUBLISH: { label: "publish", color: "#78D46E" },
  FINISHED: { label: "finished", color: "#FFB25A" },
};

type CampaignStatusKey = keyof typeof CampaignStatus;

export const CampaignStatusIndicator = ({ property }: { property: any }) => {
  const { t } = useTranslation(["portfolio"]);
  const langTheme = useLanguageTheme();

  const statusKey = property.campaignStatus?.toUpperCase() as CampaignStatusKey;
  const status = CampaignStatus[statusKey];
  
  if (status) {
    return (
      <div style={{ display: "flex", gap: "8px", alignItems: "center" }}>
        <div
          style={{
            width: "12px",
            height: "12px",
            borderRadius: "50%",
            backgroundColor: status.color,
          }}
        />
        <p
          style={{
            fontFamily: langTheme.mainFont(),
            margin: 0,
            fontSize: "16px",
            fontWeight: 500,
            lineHeight: "16px",
          }}
        >
          {t(status.label)}
        </p>
      </div>
    );
  }

  return null;
};
