import { useParams } from "react-router-dom";
import { useUpdatePropertyMutation } from "../store/api/campaignBuilderApiSlice";
import { useCallback } from "react";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../store/store";
import { setCampaignStatus } from "../store/features/campaignBuilderSlice";
import { CampaignCreatingStatus } from "../components/campaignBuilder/content/steps/CampaignSetUpStep";

export const useResetToDraft = () => {
  const dispatch = useAppDispatch();

  const { campaignStatus } = useSelector((state: any) => state.campaignBuilder);
  const [updateProperty] = useUpdatePropertyMutation();

  const { documentId } = useParams();

  const resetToDraft = useCallback(async () => {
    if (documentId && campaignStatus === CampaignCreatingStatus.PUBLISH) {
      try {
        const englishResponse = await updateProperty({
          propertyData: {
            campaignStatus: CampaignCreatingStatus.DRAFT,
          },
          documentId,
          language: "en",
        }).unwrap();

        const hebrewResponse = await updateProperty({
          propertyData: {
            campaignStatus: CampaignCreatingStatus.DRAFT,
          },
          documentId,
          language: "he",
        }).unwrap();

        const [englishResult, hebrewResult] = await Promise.all([
          englishResponse,
          hebrewResponse,
        ]);
        const bothSuccessful = !englishResult.error && !hebrewResult.error;

        if (bothSuccessful) {
          dispatch(setCampaignStatus(CampaignCreatingStatus.DRAFT));
        }
      } catch (error) {
        console.error("Error in updating property:", error);
      }
    }

  }, [documentId, campaignStatus]);

  return resetToDraft;
};
