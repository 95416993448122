import {
  InputAdornment,
  TextField,
  Typography
} from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import CrossIconBlue from "../../../../assets/icons/CrossBlueIcon.svg";
import SearchIcon from "../../../../assets/icons/search_icon.svg";
import { useLanguageTheme } from "../../../../hooks/useLanguageTheme";
import { LOCAL_TOOLS_ON } from "../../../../helpers/config";

export const PortfolioSearchContainer = ({
  openSearchPanel,
  searchValue,
  setSearchValue,
  onChangeSearch,
}: {
  openSearchPanel: boolean;
  searchValue: string;
  setSearchValue: React.Dispatch<React.SetStateAction<string>>;
  onChangeSearch: (search: string) => void;
}) => {
  const { t, i18n } = useTranslation(["home"]);
  const isRTL = i18n.dir() === "rtl";
  const langTheme = useLanguageTheme();

  const [selectedHint, setSelectedHint] = React.useState<string | null>(null);

  const hints = [
    t("apartments_in_tel_aviv"),
    t("sea_view"),
    t("villas_with_garden"),
  ];

  if (LOCAL_TOOLS_ON) {
    hints.push("duplex");
  }

  const handleHintClick = (hint: string) => {
    if (selectedHint === hint) {
      setSelectedHint(null);
      setSearchValue("");
    } else {
      setSelectedHint(hint);
      setSearchValue(hint);
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setSearchValue(newValue);

    if (!hints.includes(newValue)) {
      setSelectedHint(null);
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      const inputValue = (e.target as HTMLInputElement).value;
      onChangeSearch(inputValue);
    }
  };

  return (
    <div
      className="portfolio-search-container xl-width"
      style={{
        display: openSearchPanel ? "flex" : "none",
        width: "100%",
        flexDirection: "column",
        paddingBottom: "20px",
        marginTop: "23px",
        direction: i18n.dir(),
      }}
    >
      <TextField
        fullWidth
        value={searchValue}
        onChange={handleInputChange}
        placeholder={t("ask_ai_placeholder")}
        onKeyDown={handleKeyDown}
        inputProps={{
          maxLength: 100,
        }}
        sx={{
          backgroundColor: "rgba(240, 244, 247, 1)",
          borderRadius: "23px",
          fontFamily: langTheme.mainFont(),
          "& .MuiOutlinedInput-input": {
            fontFamily: langTheme.mainFont(),
            padding: "14.5px 22px",
          },
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              border: "none",
            },
          },
          "& .MuiInputBase-input::placeholder": {
            color: "rgba(0, 0, 0, 0.33)",
            opacity: 1,
            fontFamily: langTheme.mainFont(),
            fontWeight: isRTL ? 400 : 500,
          },
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment
              sx={{
                height: "100%",
                ml: 0,
                maxHeight: "none",
                borderRadius: "0 4px 4px 0",
                marginRight: "-14px",
                pl: "10px",
                pr: "10px",
                gap: "10px",
              }}
              position="end"
            >
              <img
                src={CrossIconBlue}
                onClick={() => {
                  onChangeSearch("");
                  setSearchValue("");
                  setSelectedHint(null);
                }}
                style={{
                  cursor: "pointer",
                }}
              />

              <div
                onClick={() => onChangeSearch(searchValue)}
                style={{
                  backgroundColor: "rgba(3, 74, 115, 1)",
                  width: "40px",
                  height: "40px",
                  borderRadius: "36px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  cursor: "pointer",
                }}
              >
                <img
                  src={SearchIcon}
                  style={{
                    width: "20px",
                    height: "20px",
                  }}
                />
              </div>
            </InputAdornment>
          ),
        }}
      />

      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          gap: "8px",
          marginTop: "12px",
          fontFamily: langTheme.mainFont(),
        }}
      >
        {hints.map((hint) => (
          <Typography
            key={hint}
            onClick={() => handleHintClick(hint)}
            sx={{
              fontFamily: langTheme.mainFont(),
              padding: "7.5px 12px",
              borderRadius: "20px",
              cursor: "pointer",
              fontSize: "14px",
              lineHeight: "14px",
              border: `1px solid ${
                selectedHint === hint ? "#646060a1" : "rgba(0, 0, 0, 0.11)"
              }`,
              fontWeight: 500,
            }}
          >
            {hint}
          </Typography>
        ))}
      </div>
    </div>
  );
};
