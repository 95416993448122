import React from "react";
import DoneIcon from "../../assets/icons/done.svg";
import { useTranslation } from "react-i18next";
import WhiteArrowLeft from "../../assets/icons/white_arrow_left.svg";
import { useLanguageTheme } from "../../hooks/useLanguageTheme";
import { useAppDispatch } from "../../store/store";
import {
  setAuthPopupOpen,
  setForgotPasswordStep,
} from "../../store/features/common";
import { AuthPopupContentType } from "./types";
import { ForgotPasswordStep } from "../../pages/ForgotPassword";

export const PasswordResetDoneStep = () => {
  const { t } = useTranslation(["signin"]);
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const isRTL = currentLanguage === "he";
  const langTheme = useLanguageTheme();
  const dispatch = useAppDispatch();

  const handleGoToLogin = () => {
    dispatch(setForgotPasswordStep(ForgotPasswordStep.enterEmail));
    dispatch(setAuthPopupOpen(AuthPopupContentType.SIGNIN));
  };

  return (
    <div className="forgot-p-container" style={{ flexDirection: "column" }}>
      <div className="forgot-p-lock-container">
        <img src={DoneIcon} />
      </div>

      <div
        className="forgot-p-title"
        style={{
          fontFamily: isRTL ? "Noto Sans Hebrew" : "Frank Ruhl Libre",
        }}
      >
        {t("all_done_title")}
      </div>
      <div
        className="forgot-p-desc"
        style={{
          fontFamily: langTheme.mainFont(),
        }}
      >
        {t("all_done_desc")}
      </div>

      <button
        onClick={handleGoToLogin}
        className="signin-form-inputs-signin-btn"
        style={{
          fontFamily: langTheme.mainFont(),
          fontWeight: isRTL ? 700 : 500,
          gap: "5px",
          display: "flex",
          justifyContent: "center",
          marginTop: 5,
        }}
      >
        <div>
          <img src={WhiteArrowLeft} />
        </div>
        <div>{t("back_to_login")}</div>
      </button>
    </div>
  );
};
