import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import { styled } from "@mui/material/styles";
import React from "react";
import { useSelector } from "react-redux";
import BackIcon from "../../../../assets/campaignBuilder/back_blue.svg";
import { useTranslation } from "react-i18next";
import { useAppDispatch } from "../../../../store/store";
import { useLanguageTheme } from "../../../../hooks/useLanguageTheme";
import { setPreviewMode } from "../../../../store/features/campaignBuilderSetup";
import { PreviewMode } from "../../types";
import {
  campaignBuilderOpenSelector,
  setCampaingBuilderSideBarOpen,
} from "../../../../store/features/common";

const Container = styled("div")({
  position: "relative",
  display: "flex",
  justifyContent: "center",
});

const Slider = styled("div")(({ theme }) => ({
  position: "absolute",
  width: "48%",
  backgroundColor: "#034A73",
  borderRadius: "40px",
  transition: "transform 0.5s ease",
  zIndex: 1,
  top: "6px",
  left: "6px",
  height: "calc(100% - 12px)",
}));

const StyledToggleButton = styled(ToggleButton)(({ theme }) => ({
  flex: 1,
  zIndex: 2,
  backgroundColor: "#8F8F9E33",
  borderRadius: "40px",
  border: "none",
  textTransform: "none",
  fontSize: "20px",
  lineHeight: "20px",
  fontWeight: 500,
  "&.Mui-selected": {
    color: "#FFFFFF",
  },
}));

export const PreviewModeSwiter = () => {
  const dispatch = useAppDispatch();
  const langTheme = useLanguageTheme();
  const { i18n, t } = useTranslation(["campaignBuilder"]);

  const { previewMode } = useSelector(
    (state: any) => state.campaignBuilderSetup
  );

  const handleModeChange = (
    event: React.MouseEvent<HTMLElement>,
    newMode: PreviewMode | null
  ) => {
    if (newMode !== null) {
      dispatch(setPreviewMode(newMode));
    }
  };
  const campaignBuilderOpen = useSelector(campaignBuilderOpenSelector);

  return (
    <div
      className="preview-mode-switcher-wrapper"
      style={{
        fontFamily: langTheme.mainFont(),
        direction: i18n.dir(),
        marginBottom: PreviewMode.MOBILE === previewMode ? "25px" : 0,
      }}
    >
      {!campaignBuilderOpen && (
        <div
          className="campaign-builder-light-blue-btn"
          onClick={() => {
            dispatch(setCampaingBuilderSideBarOpen());
            dispatch(setPreviewMode(PreviewMode.DESKTOP));
          }}
        >
          <img
            src={BackIcon}
            className="campaign-builder-sidenav-back-header-img"
            style={{
              transform: i18n.dir() === "rtl" ? "scaleX(-1)" : "scaleX(1)",
            }}
          />
          <div
            style={{
              fontFamily: langTheme.mainFont(),
            }}
          >
            {t("campaign_builder")}
          </div>
        </div>
      )}
      <Container>
        <ToggleButtonGroup
          value={previewMode}
          exclusive
          onChange={handleModeChange}
          className="preview-mode-switcher-toggle-btn"
          sx={{
            borderRadius: "40px",
            "& .MuiToggleButtonGroup-lastButton, & .MuiToggleButtonGroup-middleButton":
              {
                marginLeft: 0,
              },
          }}
        >
          <Slider
            style={{
              transform:
                previewMode === PreviewMode.DESKTOP
                  ? "translateX(0)"
                  : "translateX(100%)",
            }}
          />
          <StyledToggleButton
            value={PreviewMode.DESKTOP}
            sx={{
              fontFamily: langTheme.mainFont(),
            }}
          >
            {t("desktop")}
          </StyledToggleButton>
          <StyledToggleButton
            value={PreviewMode.MOBILE}
            sx={{
              fontFamily: langTheme.mainFont(),
            }}
          >
            {t("mobile")}
          </StyledToggleButton>
        </ToggleButtonGroup>
      </Container>
      <div className="empty-block100px"></div>
    </div>
  );
};
