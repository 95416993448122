import { Box, Dialog, DialogContent, IconButton } from "@mui/material";
import React from "react";
import { MediaType } from "./MediaUploadPanel";
import NextIcon from "../../assets/campaignBuilder/right_icon.svg";
import BackIcon from "../../assets/campaignBuilder/back_icon.svg";

export const MediaDialog = ({
  files,
  open,
  setOpen,
  mediaType,
}: {
  files: any;
  open: boolean;
  setOpen: any;
  mediaType: MediaType | null;
}) => {
  const [currentIndex, setCurrentIndex] = React.useState(0);

  const handleCloseGallery = () => {
    setOpen(false);
  };

  const handleNext = () => {
    setCurrentIndex((prev) => (prev + 1) % files.length);
  };

  const handlePrev = () => {
    setCurrentIndex((prev) => (prev - 1 + files.length) % files.length);
  };

  return (
    <Dialog
      open={open}
      onClose={handleCloseGallery}
      PaperProps={{
        sx: {
          borderRadius: "20px",
          backgroundColor: "white",
          position: "relative",
          width: "100%",
          maxWidth: "800px",
        },
      }}
      BackdropProps={{
        sx: { backgroundColor: "rgb(0 0 0 / 72%)" },
      }}
    >
      <DialogContent sx={{ p: 0, position: "relative", overflowY: "hidden" }}>
        {files.length > 0 && (
          <Box>
            {mediaType === "video" ? (
              <video
                width={"100%"}
                style={{
                  maxHeight: "500px",
                }}
                src={files[currentIndex]?.url}
                controls
              ></video>
            ) : (
              <>
                {files[currentIndex]?.ext === ".pdf" ? (
                  <embed
                    src={files[currentIndex]?.url}
                    type="application/pdf"
                    style={{
                      height: "90vh",
                      width: "100%",
                    }}
                  />
                ) : (
                  <img
                    src={files[currentIndex]?.url}
                    alt={`Image ${currentIndex + 1}`}
                    style={{
                      width: "100%",
                      // objectFit: "contain",
                      position: "relative",
                    }}
                  />
                )}
              </>
            )}

            {files.length > 1 && (
              <>
                <IconButton
                  onClick={handlePrev}
                  sx={{
                    position: "absolute",
                    left: 8,
                    top: "50%",
                    // transform: "translateY(-50%)",
                    backgroundColor: "#034A73BF",
                    color: "white",
                    "&:hover": { backgroundColor: "#02293fbf" },
                    width: "44px",
                    height: "44px",
                  }}
                >
                  <img src={BackIcon} />
                </IconButton>

                <IconButton
                  onClick={handleNext}
                  sx={{
                    position: "absolute",
                    right: 8,
                    top: "50%",
                    // transform: "translateY(-50%)",
                    backgroundColor: "#034A73BF",
                    color: "white",
                    "&:hover": { backgroundColor: "#02293fbf" },
                    width: "44px",
                    height: "44px",
                  }}
                >
                  <img src={NextIcon} />
                </IconButton>
              </>
            )}
          </Box>
        )}
      </DialogContent>
    </Dialog>
  );
};
