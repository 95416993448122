import { AuthPopup } from "../components/_shared/AuthPopup";
import { FooterDemo } from "../components/_shared/FooterDemo";
import { FooterSocialMediaLine } from "../components/_shared/FooterSocialMediaLine";
import { Header } from "../components/_shared/Header";
import { Navbar } from "../components/_shared/Navbar";
import { GetInTouchBlock } from "../components/home/GetInTouchBlock";
import { TermsContent } from "../components/terms/TermsContent";

export const Terms = () => {
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <>
        <div className="portfolio-header-sticky-wrapper">
          <div
            style={{
              backgroundColor: "rgba(255, 255, 255, 1)",
            }}
          >
            <Header>
              <Navbar />
            </Header>
          </div>
        </div>
        <TermsContent />
        <AuthPopup />
        <GetInTouchBlock />
        <FooterDemo />
        <FooterSocialMediaLine />
      </>
    </div>
  );
};
