import YellowArrow from "../../assets/icons/yellow_arrow.svg";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useLanguageTheme } from "../../hooks/useLanguageTheme";
import { useAppDispatch } from "../../store/store";
import { setAuthPopupOpen } from "../../store/features/common";
import { AuthPopupContentType } from "./types";

export const BackToLoginBtn = () => {
  const navigate = useNavigate();
  const { t } = useTranslation(["signin"]);
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const isRTL = currentLanguage === "he";
  const langTheme = useLanguageTheme();
  const dispatch = useAppDispatch();

  const navigateToLogin = () => {
    dispatch(setAuthPopupOpen(AuthPopupContentType.SIGNIN));
  };

  return (
    <div className="back-to-login-container" onClick={() => navigateToLogin()}>
      <img src={YellowArrow} />
      <div
        style={{
          fontFamily: langTheme.mainFont(),
          fontWeight: isRTL ? 700 : 500,
          color: "rgba(215, 161, 72, 1)",
          fontSize: "16px",
          letterSpacing: "-0.32px",
          lineHeight: "16px",
        }}
      >
        {t("back_to_login")}
      </div>
    </div>
  );
};
