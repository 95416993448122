import { AuthWrapper } from '../components/_shared/AuthWrapper';
import { SmsConfirmationContent } from '../components/auth/SmsConfirmationContent';

export const SmsConfirmation = () => {
  return (
    <AuthWrapper>
      <SmsConfirmationContent />
    </AuthWrapper>
  );
};
