import React from "react";
import PhoneIcon from "../../../../assets/campaignBuilder/phone.svg";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { campaignBuilderOpenSelector } from "../../../../store/features/common";

export const SpeakToAnAgentBtn = () => {
  const { t } = useTranslation(["campaignBuilder"]);
  const { selectedAgent } = useSelector((state: any) => state.campaignBuilder);
  const openNav = useSelector(campaignBuilderOpenSelector);

  const portfolioPageView = useSelector(
    (state: any) => state.campaignBuilderSetup.pageView
  );

  const isDisabled = !selectedAgent?.phone || (!portfolioPageView && openNav);

  const handleClick = (e: any) => {
    if (isDisabled) {
      e.preventDefault();
    }
  };

  return (
    <a
      href={isDisabled ? "#" : `tel:${selectedAgent?.phone}`}
      onClick={handleClick}
      className="reset-a"
      style={{
        cursor: isDisabled ? "auto" : "pointer",
      }}
    >
      <div className="speak-to-agent-btn">
        <img src={PhoneIcon} />
        <div>{t("speak_to_an_agent")}</div>
      </div>
    </a>
  );
};
