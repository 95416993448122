import { ITag } from "../../constants/articleData";
import { PAGE_SIZE } from "../../helpers/config";
import { apiSlice } from "./apiSlice";

export interface IGetArtcilesParams {
  page: number;
  search: string;
  pageSize?: string;
  tags: ITag[];
}

export interface IGetArticlesParamsWithLang {
  params: IGetArtcilesParams;
  lang: string;
}

function buildPaginationParams(
  params: URLSearchParams,
  page: number,
  pageSize?: string | undefined
) {
  params.set("pagination[page]", page.toString());
  params.set("pagination[pageSize]", pageSize ?? PAGE_SIZE);
}

function buildArticleList(
  params: URLSearchParams,
  tags: ITag[],
  andIndex: number,
  fieldName: string,
  currentLanguage: string
) {
  tags.forEach((tag, index) => {
    params.append(
      `filters[$and][${andIndex}][$or][${index}]${fieldName}[title]`,
      tag.title
    );
  });
}

function buildTagParams(params: URLSearchParams, tags: ITag[], lang: string) {
  let andIndex = 0;

  if (tags.length > 0) {
    buildArticleList(params, tags, andIndex, "[tags]", lang);
    andIndex++;
  }
}

function buildSearchParams(params: URLSearchParams, search: string) {
  const splitedSearch = search.split(" ");

  if (search) {
    for (let i = 0; i < splitedSearch.length; i++) {
      const term = splitedSearch[i];

      const trimmedTerm = term.trim();

      if (trimmedTerm.length > 0) {
        params.append(`filters[$or][${0}][title][$containsi]`, term);
      }
    }
  }
}

function CreateGetArticlesWithParamsURLQuery(
  params: IGetArticlesParamsWithLang
): string {
  const urlParams = new URLSearchParams();
  urlParams.append("populate", "*");
  urlParams.append("locale", params.lang);

  buildPaginationParams(urlParams, params.params.page, params.params?.pageSize);

  if (params.params.search) {
    buildSearchParams(urlParams, params.params.search);
  }

  buildTagParams(urlParams, params.params.tags, params.lang);
  return `/blog-posts?${urlParams.toString()}`;
}

export const articlesApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getArticlesWithParams: builder.query({
      query: (params: IGetArticlesParamsWithLang) => {
        return CreateGetArticlesWithParamsURLQuery(params);
      },
    }),
    getArticlesBySections: builder.query({
      query: (lang: string) => {
        return `/blog-posts/sections?locale=${lang}`;
      },
    }),
    getArticleByDocumentId: builder.query({
      query: ({ lang, documentId }: { lang: string; documentId: string }) => {
        return `/blog-posts/${documentId}?populate=*&locale=${lang}`;
      },
    }),
  }),
});

export const {
  useGetArticlesWithParamsQuery,
  useGetArticlesBySectionsQuery,
  useGetArticleByDocumentIdQuery,
} = articlesApiSlice;
