import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

export const AboutPropertyBlock = () => {
  const { t } = useTranslation(["campaignBuilder"]);
  const { aboutPropertyDesc } = useSelector(
    (state: any) => state.campaignBuilder
  );

  return (
    <div className="campaign-builder-about-property-container">
      <div className="campaign-builder-main-header">{t("about_property")}</div>
      <div className="campaign-builder-second-header">
        {aboutPropertyDesc ? aboutPropertyDesc : t("about_property_desc")}
      </div>
    </div>
  );
};
