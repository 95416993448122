import React, { useEffect } from "react";
import { Detail, Property } from "../../../../helpers/convert";
import { useTranslation } from "react-i18next";
import { Divider } from "@mui/material";
import { useLanguageTheme } from "../../../../hooks/useLanguageTheme";
import BathroomIcon from "../../../../assets/icons/bathroom.svg";
import BedroomIcon from "../../../../assets/icons/bedBlue.svg";
import SquareIcon from "../../../../assets/icons/square.svg";
import { useNavigate } from "react-router-dom";
import { formatPrice, getCurrencyIcon } from "../../../../helpers/dataConverts";
import { useAuth } from "../../../../hooks/useAuth";
import { setAuthPopupOpen } from "../../../../store/features/common";
import { useAppDispatch } from "../../../../store/store";
import { CampaignStatusIndicator } from "./CampaignStatusIndicator";
import {
  calculateDaysRemain,
  truncateStringWithEllipsis,
} from "../../../../helpers/formatter";
import { LikeDemo, LikeSize } from "../../../_shared/LikeDemo";
import { PropertyCardDaysLeftLabel } from "./PropertyCardDaysLeftLabel";
import { PropertyCardStamp } from "./PropertyCardStamp";
import { AuthPopupContentType } from "../../../auth/types";
import { UserTypes } from "../../../_shared/Navbar";

const propertyDetailsInit = {
  bedrooms: {
    name: "bedrooms",
    icon: <img src={BedroomIcon} />,
  },
  bathrooms: {
    name: "bathrooms",
    icon: <img src={BathroomIcon} />,
  },

  totalArea: {
    name: "livingArea",
    icon: <img src={SquareIcon} />,
  },
};

interface ConvertedDetail {
  [key: string]: { name: string; icon: any; quantity?: number };
}

export const PropertyCard = ({ property }: { property: Property }) => {
  const { t } = useTranslation(["portfolio"]);
  const { i18n } = useTranslation();
  const isRTL = i18n.language === "he";
  const langTheme = useLanguageTheme();
  const isAuthenticated = useAuth();
  const navigate = useNavigate();
  const [propertyDetails, setDetails] = React.useState<ConvertedDetail>({});
  const dispatch = useAppDispatch();

  const user = localStorage.getItem("user") || sessionStorage.getItem("user");

  const daysDifference = calculateDaysRemain(property.campaignCloseDate);

  const convertDetails = (input: Detail, details: ConvertedDetail) => {
    const result: ConvertedDetail = {};
    for (const key in input) {
      if (details[key]) {
        result[key] = { ...details[key], quantity: input[key] as number };
      }
    }

    return result;
  };

  useEffect(() => {
    const detailedPropertys = convertDetails(
      property.details,
      propertyDetailsInit
    );

    setDetails(detailedPropertys);
  }, [property]);

  return (
    <div
      className="property-card-container"
      style={{
        height: "100%",
        cursor: "pointer",
      }}
      onClick={
        isAuthenticated
          ? () => navigate(`/campaign-page-view-mode/${property.documentId}`)
          : () => dispatch(setAuthPopupOpen(AuthPopupContentType.SIGNUP))
      }
    >
      <div
        className="property-card-container-card"
        style={{
          background: `url(${property?.mainImage?.url}) center center / cover no-repeat`,
          transform: isRTL ? "scaleX(-1)" : "scaleX(1)",
          position: "relative",
        }}
      >
        {user &&
          JSON.parse(user).id === property.owner.id &&
          typeof property.numberOfDaysToCompletion !== "undefined" &&
          property.numberOfDaysToCompletion !== null &&
          daysDifference <= property.numberOfDaysToCompletion && (
            <PropertyCardDaysLeftLabel
              numberOfDaysToCompletion={daysDifference}
            />
          )}
      </div>

      <div
        className="property-card-additional-info"
        style={{
          position: "relative",
        }}
      >
        <PropertyCardStamp
          label={!property.propertyLabels ? null : property.propertyLabels[0]}
        />
        <div
          className={
            isAuthenticated
              ? "property-card-price-and-heart-container"
              : "property-card-price-and-heart-container-anonymous"
          }
          style={{
            direction: i18n.dir(),
          }}
        >
          {isAuthenticated ? (
            <div
              className="property-card-price"
              style={{
                fontFamily: langTheme.mainFont(),
                fontWeight: isRTL ? 600 : 700,
                direction: "ltr",
                display: "flex",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  fontWeight: isRTL ? 600 : 700,
                  fontFamily: langTheme.mainFont(),
                }}
              >
                {getCurrencyIcon(property.pricing.currency)}
              </div>
              <div
                style={{
                  fontFamily: "DM Sans",
                }}
              >
                {formatPrice(property.pricing.basePrice)}
              </div>
            </div>
          ) : (
            ""
          )}

          {user && JSON.parse(user).userType === UserTypes.SELLER && (
            <div
              style={{
                display: "flex",
                gap: "15px",
              }}
            >
              <CampaignStatusIndicator property={property} />
              <LikeDemo propertyId={property.id} likeSize={LikeSize.SMALL} />
            </div>
          )}
        </div>

        <div className="property-card-titles">
          <div
            style={{
              minHeight: "89px",
            }}
          >
            <div
              style={{
                fontFamily: isRTL ? "Noto Sans Hebrew" : "Frank Ruhl Libre",
                fontSize: "22px",
                fontWeight: isRTL ? 700 : 600,
                lineHeight: "26px",
                textTransform: "uppercase",
                marginBottom: "11px",
                marginTop: "2px",
                overflowWrap: "break-word",
                wordBreak: "break-word",
              }}
            >
              {truncateStringWithEllipsis(property.name, 35)}
            </div>

            <div
              className="property-card-titles-location"
              style={{
                fontFamily: langTheme.mainFont(),
                marginBottom: "10px",
              }}
            >
              {isAuthenticated
                ? property.location.address + "," + property.location.city
                : property.location.city}
            </div>
          </div>

          <Divider
            sx={{
              borderColor: "rgba(0, 0, 0, 0.09)",
            }}
          />

          <div
            className="property-card-details"
            style={{
              direction: i18n.dir(),
            }}
          >
            {Object.entries(propertyDetails).map(([key, detail]) => (
              <div
                key={key}
                className="property-card-detail"
                style={{
                  fontFamily: langTheme.mainFont(),
                }}
              >
                <div>{detail.icon}</div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "3px",
                  }}
                >
                  <div>{detail.quantity}</div>
                  <div>{t(detail.name)}</div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
