import { Theme, useMediaQuery } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useLanguageTheme } from "../../../hooks/useLanguageTheme";
import {
  whiteMustToReadCardStyles
} from "./AdditionalArticlesContainer";
import { MustReadMainCard } from "./MustReadMainCard";

import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { IArticle } from "../interfaces";
import { WhiteMiddleArcitleCard } from "./WhiteMiddleArcitleCard";

export const whiteTopCardMustToReadStylesSlider = {
  fontSize: "20px",
  lineHeight: "24px",
  padding: "20px 25px 35px 20px",
  minWidth: "300px",
  imgFlex: "1",
  height: "100%",
};

export const LearningCenterMustReadSection = ({
  articles,
}: {
  articles: IArticle[];
}) => {
  const { i18n, t } = useTranslation(["learningCenter"]);
  const langTheme = useLanguageTheme();
  const downMd = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));
  const downLg = useMediaQuery((theme: Theme) => theme.breakpoints.down("lg"));
  const is1100px = useMediaQuery("(max-width: 1100px)");

  const isRTL = i18n.dir() === "rtl";

  return (
    <div
      className="xl-width"
      style={{
        paddingBottom: downMd ? "90px" : "200px",
        height: "517px",
      }}
    >
      <div
        className="learning-center-must-to-read-section "
        style={{
          direction: i18n.dir(),
          fontFamily: langTheme.mainFont(),
          paddingBottom: "20px",
        }}
      >
        <div
          className="must-to-read-title"
          style={{
            fontFamily: isRTL ? "Noto Sans Hebrew" : "Frank Ruhl Libre",
            fontSize: downMd ? "24px" : "36px",
            lineHeight: downMd ? "28px" : "36px",
            marginBottom: downMd ? "15px" : "44px",
          }}
        >
          {t("must_read")}
        </div>

        {is1100px ? (
          <div className="must-to-read-title-swiper-slider">
            <Swiper
              pagination={{
                clickable: true,
              }}
              spaceBetween={10}
              modules={[Pagination]}
            >
              <SwiperSlide>
                <MustReadMainCard
                  article={articles[0]}
                  descTrancateLength={100}
                />
              </SwiperSlide>
              <SwiperSlide>
                <WhiteMiddleArcitleCard
                  styles={whiteTopCardMustToReadStylesSlider}
                  showClueContainer={true}
                  article={articles[1]}
                  descTrancateLength={175}
                />
              </SwiperSlide>
              <SwiperSlide>
                <WhiteMiddleArcitleCard
                  styles={whiteTopCardMustToReadStylesSlider}
                  showClueContainer={true}
                  article={articles[2]}
                  descTrancateLength={175}
                />
              </SwiperSlide>
            </Swiper>
          </div>
        ) : (
          <div className="must-to-read-article-section">
            <WhiteMiddleArcitleCard
              article={articles[0]}
              descTrancateLength={190}
              showClueContainer={false}
              styles={whiteMustToReadCardStyles}
            />
            <MustReadMainCard article={articles[1]} descTrancateLength={100} />

            <WhiteMiddleArcitleCard
              article={articles[2]}
              descTrancateLength={190}
              showClueContainer={false}
              styles={whiteMustToReadCardStyles}
            />
          </div>
        )}
      </div>
    </div>
  );
};
