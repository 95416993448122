import { ISignInData } from "../../components/_shared/SigninContent";
import { IResendSignupData } from "../../components/auth/EmailConfirmationContent";
import { IFinishSignupData } from "../../components/auth/FinishSignupContent";
import { IPasswordResetEnterEmailData } from "../../components/auth/PasswordResetEnterEmailStep";
import { IResetPasswordData } from "../../components/auth/PasswordResetNewPasswordStep";
import { ISignupData } from "../../components/auth/SignupContent";
import { apiSlice } from "../api/apiSlice";

export interface IResendSignupSmsData {
  phoneNumber: string;
}
export interface IVerifySmsData {
  smsCode: string;
  phoneNumber: string;
}

export interface IDemoLoginData {
  password: string;
}
export interface ICreateWishlistData {
  data: {
    users_permissions_user: number[];
    properties: number[];
  };
}
export interface IUpdateWishlistData {
  data: {
    properties: number[];
  };
}

export const authApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    signup: builder.mutation({
      query: (credentials: ISignupData) => ({
        url: "/auth/local/register",
        method: "POST",
        body: credentials,
      }),
    }),
    finishSignup: builder.mutation({
      query: (credentials: IFinishSignupData) => ({
        url: `/users/${JSON.parse(localStorage.getItem("user")!).id}`,
        method: "PUT",
        body: credentials,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")!}`,
        },
      }),
    }),
    signin: builder.mutation({
      query: (credentials: ISignInData) => ({
        url: "/auth/local",
        method: "POST",
        body: credentials,
      }),
    }),
    forgotPassword: builder.mutation({
      query: (credentials: IPasswordResetEnterEmailData) => ({
        url: "/auth/forgot-password",
        method: "POST",
        body: credentials,
      }),
    }),
    resetPassword: builder.mutation({
      query: (credentials: IResetPasswordData) => ({
        url: "/auth/reset-password",
        method: "POST",
        body: credentials,
      }),
    }),
    resendSignupEmail: builder.mutation({
      query: (credentials: IResendSignupData) => ({
        url: "/auth/send-email-confirmation",
        method: "POST",
        body: credentials,
      }),
    }),
    getUserWithPicture: builder.query({
      query: (params) => ({
        url: `/users/${params.id}?populate=profilePhoto`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${params.jwt}`,
        },
      }),
      keepUnusedDataFor: 0,
    }),
    smsSignup: builder.mutation({
      query: (credentials: ISignupData) => ({
        url: "/auth/register-with-sms",
        method: "POST",
        body: credentials,
      }),
    }),
    resendSignupSms: builder.mutation({
      query: (credentials: IResendSignupSmsData) => ({
        url: "/auth/resend-sms",
        method: "POST",
        body: credentials,
      }),
    }),
    verifySignupSms: builder.mutation({
      query: (credentials: IVerifySmsData) => ({
        url: "/auth/verify-sms",
        method: "POST",
        body: credentials,
      }),
    }),
    demoLogin: builder.mutation({
      query: (credentials: IDemoLoginData) => ({
        url: "/auth/front",
        method: "POST",
        body: credentials,
      }),
    }),
    updateUser: builder.mutation({
      query: (updateData) => ({
        url: `/users/${
          JSON.parse(
            localStorage.getItem("user") ||
              sessionStorage.getItem("user") ||
              "{}"
          ).id
        }`,
        method: "PUT",
        body: updateData,
        headers: {
          Authorization: `Bearer ${JSON.parse(
            localStorage.getItem("jwt") || sessionStorage.getItem("jwt") || "{}"
          )}`,
        },
      }),
    }),
    // createUserWishlist: builder.mutation({
    //   query: ({
    //     wishlistData,
    //     jwt,
    //   }: {
    //     wishlistData: ICreateWishlistData;
    //     jwt: string;
    //   }) => ({
    //     url: "/wishlists",
    //     method: "POST",
    //     body: wishlistData,
    //     headers: {
    //       Authorization: `Bearer ${jwt}`,
    //     },
    //   }),
    //   invalidatesTags: ["Wishlist"],
    // }),
    // updateUserWishlist: builder.mutation({
    //   query: ({
    //     wishlistId,
    //     updatedData,
    //     jwt,
    //   }: {
    //     wishlistId: number;
    //     updatedData: IUpdateWishlistData;
    //     jwt: string;
    //   }) => ({
    //     url: `/wishlists/${wishlistId}`,
    //     method: "PUT",
    //     body: updatedData,
    //     headers: {
    //       Authorization: `Bearer ${jwt}`,
    //     },
    //   }),
    //   invalidatesTags: ["Wishlist"],
    // }),
  }),
});

export const {
  useSignupMutation,
  useFinishSignupMutation,
  useSigninMutation,
  useForgotPasswordMutation,
  useResetPasswordMutation,
  useResendSignupEmailMutation,
  useGetUserWithPictureQuery,
  useSmsSignupMutation,
  useResendSignupSmsMutation,
  useVerifySignupSmsMutation,
  useDemoLoginMutation,
  useUpdateUserMutation,
  // useCreateUserWishlistMutation,
  // useUpdateUserWishlistMutation,
} = authApiSlice;
