import { Badge } from "@mui/material";
import { useTranslation } from "react-i18next";
import MapIcon from "../../../../assets/icons/map.svg";
import FilterIcon from "../../../../assets/icons/filters.svg";
import { useLanguageTheme } from "../../../../hooks/useLanguageTheme";
import { useAppDispatch } from "../../../../store/store";
import {
  setFilterSideBarOpen,
  setMapSideBarOpen,
} from "../../../../store/features/portfolioSlice";

export const FixedMenu = () => {
  const { t } = useTranslation(["portfolio"]);
  const langTheme = useLanguageTheme();
  const dispatch = useAppDispatch();

  return (
    <div
      style={{
        fontFamily: langTheme.mainFont(),
      }}
      className="see-map-and-filters-container"
    >
      <div
        className="see-map-and-filters-item"
        onClick={() => dispatch(setMapSideBarOpen())}
      >
        <div>{t("see_map")}</div>
        <div>
          <img src={MapIcon} />
        </div>
      </div>
      <div
        className="see-map-and-filters-item"
        onClick={() => dispatch(setFilterSideBarOpen())}
      >
        <div>{t("filters")}</div>
        <Badge
          variant="dot"
          sx={{
            "& .MuiBadge-dot": {
              backgroundColor: "rgba(215, 161, 72, 1)",
              right: "4px",
              top: "4px",
              width: "9px",
              height: "9px",
            },
          }}
        >
          <img src={FilterIcon} />
        </Badge>
      </div>
    </div>
  );
};
