import {
  InputAdornment,
  MenuItem,
  SelectChangeEvent,
  TextField,
  Theme,
  useMediaQuery,
} from "@mui/material";
import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Media } from "../../../../helpers/convert";
import { getCountryCode, trimCountryCode } from "../../../../helpers/formatter";
import { useLanguageTheme } from "../../../../hooks/useLanguageTheme";
import { useResetToDraft } from "../../../../hooks/useResetToDraft";
import {
  useCreateAgentMutation,
  useGetAgentsQuery,
  useLazyGetAgentsQuery,
  useUpdateAgentMutation,
} from "../../../../store/api/agentApiSlice";
import {
  removeAgentFileById,
  setAgentPhotoOpen,
  setAvatarFileSlice,
  setScheduleVewingOpen,
  setSelectedAgent,
  setSelectedUserId,
} from "../../../../store/features/campaignBuilderSlice";
import { useAppDispatch } from "../../../../store/store";
import { LangToggle } from "../../../_shared/LangToggle";
import { CampaignSideNavStep } from "../layout/CampaignBuilderSideNav";
import { CampaignBuilderSideNavHeader } from "../layout/CampaignBuilderSideNavHeader";
import { CampainBuilderMenuBtn } from "../buttons/CampainBuilderMenuBtn";
import { NotificationSnackBar } from "../../NotificationSnackBar";
import { ExpandMore } from "@mui/icons-material";
import { Button, Menu } from "@mui/material";
import { Collapse } from "@mui/material";
import ReplaceCross from "../../../../assets/campaignBuilder/agent_replace_cross.svg";
import { authTextFieldStyles } from "../../../../pages/Signup";
import { setCampaingBuilderSideBarClose } from "../../../../store/features/common";
import { PhoneSelect } from "../../../_shared/PhoneSelect";
import { CampaignBuilderSwitcher } from "../../CampaignBuilderSwitcher";
import { FilesLoader } from "../../FilesLoader";
import { textFieldStyles2 } from "./MainInformationStep";
import { NotificationStatus } from "./CampaignSetUpStep";
import {
  MAXIMUM_NUMBER_OF_AGENT_AVATAR,
  propertyImgAcceptFormat,
  urlRegex,
} from "../../constants";
import { BackToMenuBtn } from "../buttons/BackToMenuBtn";
import { FileDropZone } from "../layout/FileDropZone";
import { CampainBuilderBackBtn } from "../buttons/CampainBuilderBackBtn";

export const textFieldStyles3 = (
  fontFamily: string,
  isRtl = false,
  error?: any
) => ({
  backgroundColor: "rgba(255, 255, 255, 1)",
  borderRadius: isRtl ? "0 16px 16px 0" : "16px 0 0 16px",
  borderRight: isRtl
    ? 0
    : error
    ? "1px solid rgba(250, 99, 99, 1)"
    : "1px solid rgba(5, 57, 87, 0.46)",
  borderLeft: isRtl
    ? error
      ? "1px solid rgba(250, 99, 99, 1)"
      : "1px solid rgba(5, 57, 87, 0.46)"
    : 0,
  fontSize: "14px",
  boxSizing: "border-box",
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      border: "none",
      boxSizing: "border-box",
    },
  },
  "& .MuiInputBase-input::placeholder": {
    color: "rgb(112, 118, 135)",
    opacity: 1,
    fontWeight: 500,
    fontFamily: fontFamily,
  },
  "& .MuiInputBase-input": {
    color: "rgba(0, 0, 0, 1)",
    fontWeight: 500,
    fontFamily: fontFamily,
    paddingRight: isRtl ? "5px" : "14px",
    fontSize: "14px",
  },
  "& .MuiInputBase-input.MuiInputBase-inputMultiline": {
    padding: "0",
  },
});
export interface User {
  id: number;
  documentId: string;
  name: string;
  phone: string;
  email: string;
  createdAt: string;
  updatedAt: string;
  publishedAt: string;
  locale: string;
  photo: Media;
}

export const ContactInformation = ({
  setCurrentStep,
  setNewAgentAvatar,
  isAvatarLoading,
  autosaveFunc = () => {},
  isDisabledSwitchLang = false,
}: {
  setCurrentStep: (step: CampaignSideNavStep) => void;
  setNewAgentAvatar: React.Dispatch<React.SetStateAction<File[]>>;
  isAvatarLoading: boolean;
  autosaveFunc: any;
  isDisabledSwitchLang: boolean;
}) => {
  const dispatch = useAppDispatch();
  const { t, i18n } = useTranslation(["campaignBuilder"]);
  const isRTL = i18n.dir() === "rtl";

  const resetToDraft = useResetToDraft();
  const componentRef = useRef<any>(null);

  const downMd = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));
  const langTheme = useLanguageTheme();
  const [errors, setErrors] = React.useState<any>({});

  const [newAgentName, setNewAgentName] = React.useState("");
  const [newAgentPhone, setNewAgentPhone] = React.useState<string | undefined>(
    ""
  );
  const [newAgentLink, setNewAgentLink] = React.useState("");
  const [selectedCode, setSelectedCode] = React.useState<string | undefined>(
    "+972"
  );

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarText, setSnackbarText] = useState("");
  const [snackBarType, setSnackbarType] = useState("");

  const {
    selectedAgent,
    selectedUserId,
    agentAvatarSlice,
    language,
    scheduleVewingOpen,
    agentPhotOpen,
  } = useSelector((state: any) => state.campaignBuilder);

  const { data: agentsData } = useGetAgentsQuery({
    locale: "en",
  });

  const handleNewAgentNameChange = (event: any) => {
    const { value } = event.target;
    const newErrors = { ...errors };
    localStorage.setItem("onChangeCompanyBuilder", "1");
    if (!value.trim()) {
      newErrors.agentName = t("agent_name_required");
    } else {
      delete newErrors.agentName;
    }

    const updatedUserData = {
      ...selectedAgent,
      [i18n.language === "he" ? "nameHe" : "nameEn"]: value,
    };

    dispatch(setSelectedAgent(updatedUserData));
    resetToDraft();

    setErrors(newErrors);
  };

  const handleChangeScheduleVewingOpen = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    localStorage.setItem("onChangeCompanyBuilder", "1");
    dispatch(setScheduleVewingOpen(event.target.checked));
    resetToDraft();
  };

  const handleChangeAgentPhotoOpen = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    localStorage.setItem("onChangeCompanyBuilder", "1");
    dispatch(setAgentPhotoOpen(event.target.checked));
    resetToDraft();
  };

  const handlePhoneNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setNewAgentPhone(value);
    localStorage.setItem("onChangeCompanyBuilder", "1");
    const newErrors = { ...errors };
    const phoneRegex = /^\+?[1-9]\d{1,14}$/;
    const fullPhoneNumber = selectedCode + value;

    if (!value.trim()) {
      newErrors.phoneNumber = t("phone_number_required");
    } else if (!phoneRegex.test(fullPhoneNumber)) {
      newErrors.phoneNumber = t("invalid_phone_number_format");
    } else if (value.length < 7 || fullPhoneNumber.length > 15) {
      newErrors.phoneNumber = t("phone_number_length_invalid");
    } else {
      delete newErrors.phoneNumber;
    }

    const updatedUserData = {
      ...selectedAgent,
      phone: `${selectedCode}-${value}`,
    };
    dispatch(setSelectedAgent(updatedUserData as any));
    resetToDraft();

    setErrors(newErrors);
  };

  const handleCodeChange = (event: SelectChangeEvent<string>) => {
    localStorage.setItem("onChangeCompanyBuilder", "1");
    const value = event.target.value;
    setSelectedCode(value);
    resetToDraft();
  };

  const handleNewAgentLinkChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    localStorage.setItem("onChangeCompanyBuilder", "1");
    const { value } = event.target;
    setNewAgentLink(value);
    const newErrors = { ...errors };

    if (!value.trim()) {
      newErrors.link = t("link_required");
    } else if (!urlRegex.test(value)) {
      newErrors.link = t("invalid_url_format");
    } else {
      delete newErrors.link;
    }
    const updatedUserData = {
      ...selectedAgent,
      link: event.target.value,
    };
    dispatch(setSelectedAgent(updatedUserData as any));
    resetToDraft();

    setErrors(newErrors);
  };

  React.useEffect(() => {
    if (!selectedAgent) {
      return;
    }

    setNewAgentName(
      i18n.language === "en" ? selectedAgent.nameEn : selectedAgent.nameHe
    );
    setNewAgentLink(selectedAgent.link);
    setNewAgentPhone(trimCountryCode(selectedAgent?.phone));

    const code = getCountryCode(selectedAgent?.phone);

    setSelectedCode(code);
  }, [selectedAgent]);

  const validateForm = () => {
    const newErrors: any = {};

    // Validate agent name
    if (i18n.language === "he") {
      if (!selectedAgent.nameHe?.trim()) {
        newErrors.agentName = t("agent_name_required");
      }
    }

    if (i18n.language === "en") {
      if (!selectedAgent.nameEn?.trim()) {
        newErrors.agentName = t("agent_name_required");
      }
    }

    // Validate phone number
    if (!selectedAgent.phone?.trim()) {
      newErrors.phoneNumber = t("phone_number_required");
    }

    // Validate link
    if (!selectedAgent.link?.trim()) {
      newErrors.link = t("link_required");
    } else if (!urlRegex.test(selectedAgent.link)) {
      newErrors.link = t("invalid_url_format");
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const [createAgent] = useCreateAgentMutation();
  const [updateAgent] = useUpdateAgentMutation();
  const [triggerAgents] = useLazyGetAgentsQuery();

  const handleCreateAgent = async () => {
    const isValid = validateForm();
    if (!isValid) {
      return;
    }

    try {
      const agentData = {
        nameEn: selectedAgent.nameEn,
        nameHe: selectedAgent.nameHe,
        phone: selectedAgent.phone,
        email: "test@example.com",
        photo: agentAvatarSlice[0]?.id || selectedAgent?.photo?.id || [],
        link: selectedAgent.link,
      };

      const newAgent = await createAgent({
        agentData,
        locale: "en",
      }).unwrap();

      dispatch(setSelectedUserId(newAgent.data.id));
      triggerAgents({ locale: "en" });

      setOpenSnackbar(true);
      setSnackbarType(NotificationStatus.SUCCESSFULL);
      setSnackbarText(t("snackbarAgentSuccessfulCreated"));
    } catch (error) {
      console.error("Error creating agent: ", error);
    }
  };

  const handleUpdateAgent = async () => {
    const isValid = validateForm();
    if (!isValid) {
      return;
    }

    try {
      const agentData = {
        nameEn: selectedAgent.nameEn,
        nameHe: selectedAgent.nameHe,
        phone: selectedAgent.phone,
        email: "test@example.com",
        photo: agentAvatarSlice[0]?.id || selectedAgent?.photo?.id || [],
        link: selectedAgent.link,
      };

      await updateAgent({
        agentData,
        documentId: selectedAgent.documentId,
        locale: "en",
      }).unwrap();
      setOpenSnackbar(true);
      setSnackbarType(NotificationStatus.SUCCESSFULL);
      setSnackbarText(t("snackbarAgentSuccessfulUpdated"));

      triggerAgents({ locale: "en" });
    } catch (error) {
      console.error("Error creating agent: ", error);
    }
  };

  const handleRemoveAvatarFile = (fileId: number) => {
    dispatch(removeAgentFileById(fileId));
  };

  React.useEffect(() => {
    triggerAgents({ locale: "en" });
  }, [language, i18n.language]);

  const handleRemoveUserAvatar = (avatarId: number) => {
    dispatch(removeAgentFileById(avatarId));
    const updatedUserData = {
      ...selectedAgent,
      photo: null,
    };
    dispatch(setSelectedAgent(updatedUserData as any));
  };

  React.useEffect(() => {
    const updatedUserData = {
      ...selectedAgent,
    };
    if (agentAvatarSlice.length > 0 && agentAvatarSlice[0]) {
      updatedUserData.photo = agentAvatarSlice[0];
    }
    dispatch(setSelectedAgent(updatedUserData as any));
  }, [agentAvatarSlice]);

  const handleNext = (step: CampaignSideNavStep) => {
    setCurrentStep(step);
  };
  const dropdownRef = useRef<any>(null);
  const [openCustomDropdown, setOpenCustomDropdown] = useState(false);
  const [anchorEl, setAnchorEl] = useState<any>(null);

  const handleSelectAgent = (value: number | string) => {
    localStorage.setItem("onChangeCompanyBuilder", "1");
    if (value === "create-new") {
      dispatch(setSelectedUserId("create-new"));
      setNewAgentName("");
      setNewAgentPhone("");
      setNewAgentLink("");
      setNewAgentAvatar([]);
      dispatch(setAvatarFileSlice([]));

      dispatch(
        setSelectedAgent({
          id: null,
          nameEn: "",
          nameHe: "",
          phone: "",
          link: "",
          photo: null,
        })
      );
      resetToDraft();
    } else {
      dispatch(setSelectedUserId(value as number));

      const selectedAgentData =
        agentsData?.find((agent: User) => agent.id === value) || null;

      const code = getCountryCode(selectedAgentData.phone);

      setSelectedCode(code);
      setNewAgentName("");
      setNewAgentPhone("");
      setNewAgentAvatar([]);
      dispatch(setAvatarFileSlice([]));

      setErrors({});
      dispatch(setSelectedAgent(selectedAgentData));
      resetToDraft();
    }

    setOpenCustomDropdown((prev) => !prev);
  };

  return (
    <div className="campaign-builder-side-nav-wrapper">
      <CampaignBuilderSideNavHeader
        header="contact_information"
        leftBtn={
          <CampainBuilderBackBtn
            setCurrentStep={setCurrentStep}
            step={CampaignSideNavStep.aboutDeveloper}
          />
        }
        rightBtn={
          <CampainBuilderMenuBtn
            handleNext={handleNext}
            step={CampaignSideNavStep.campaignSetUp}
          />
        }
      />

      <div
        className="campaign-builder-sidenav-main-content-wrapper"
        style={{
          paddingTop: downMd ? "30px" : "40px",
          direction: i18n.dir(),
        }}
        ref={componentRef}
      >
        <div className="campaign-builder-sidenav-settings-fileds-container">
          <div className="campaign-builder-sidenav-settings-fileds-item">
            <div className="campaign-builder-sidenav-field-name">
              {t("agent_name")}
            </div>

            <Button
              variant="outlined"
              onClick={(event) => {
                if (event.detail === 0) return;
                setOpenCustomDropdown((prev) => !prev);
                setAnchorEl(event.currentTarget);
              }}
              disableRipple
              ref={dropdownRef}
              sx={{
                width: "100%",
                justifyContent: "space-between",
                textTransform: "none",
                borderRadius: "16px",
                height: 53,
                paddingLeft: 0,
                paddingRight: 0,
                backgroundColor: "#fff",
                borderColor: "#fff",
                color: "#000",
                zIndex: 14,
              }}
              endIcon={
                <ExpandMore
                  style={{
                    marginRight: isRTL ? "15px" : "20px",
                    marginLeft: isRTL ? "20px" : "15px",
                  }}
                />
              }
            >
              <TextField
                fullWidth
                placeholder={t("message_placeholder")}
                value={
                  selectedUserId === "create-new"
                    ? newAgentName
                    : i18n.language === "he"
                    ? selectedAgent?.nameHe
                    : selectedAgent?.nameEn || ""
                }
                onChange={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  handleNewAgentNameChange(e);
                }}
                onKeyDown={(e) => {
                  if (e.key === " ") {
                    e.stopPropagation();
                  }
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                }}
                sx={textFieldStyles3(langTheme.mainFont(), isRTL)}
                autoComplete="off"
                InputProps={{
                  endAdornment: (
                    <InputAdornment
                      sx={{
                        height: "100%",
                        ml: 0,
                        maxHeight: 50,
                        color: "rgba(0, 0, 0, 1)",
                        marginRight: "20px",
                        zIndex: 15,
                        backgroundColor: "transparent",
                      }}
                      position="start"
                    ></InputAdornment>
                  ),
                }}
              />
            </Button>

            <Menu
              anchorEl={anchorEl}
              open={openCustomDropdown}
              onClose={() => {
                setOpenCustomDropdown((prev) => !prev);
              }}
              PaperProps={{
                sx: {
                  width: dropdownRef?.current?.offsetWidth,
                  maxHeight: componentRef?.current?.offsetHeight - 110,
                  direction: i18n.dir(),
                },
              }}
            >
              <div>
                <MenuItem
                  onClick={() => handleSelectAgent("create-new")}
                  value="create-new"
                  sx={{
                    color: "rgba(0, 0, 0, 1)",
                    fontFamily: langTheme.mainFont(),
                    fontWeight: 500,
                    fontSize: "14px",
                  }}
                >
                  {t("create_new")}
                </MenuItem>

                {agentsData
                  ?.filter((option: any) => option.id !== selectedUserId)
                  .map((option: any) => (
                    <MenuItem
                      key={option.id}
                      value={option.id}
                      sx={{
                        color: "rgba(0, 0, 0, 1)",
                        fontFamily: langTheme.mainFont(),
                        fontWeight: 500,
                        fontSize: "14px",
                      }}
                      onClick={() => handleSelectAgent(option.id)}
                    >
                      {option.nameEn && i18n.language === "he"
                        ? option.nameHe === ""
                          ? option.nameEn
                          : option.nameHe
                        : option.nameEn === ""
                        ? option.nameHe
                        : option.nameEn}
                    </MenuItem>
                  ))}
              </div>
            </Menu>

            {errors.agentName && (
              <div className="campaign-builder-errors">{errors.agentName}</div>
            )}
          </div>

          <div className="campaign-builder-sidenav-settings-fileds-item">
            <div className="campaign-builder-sidenav-field-name">
              {t("agent_phone")}
            </div>

            <TextField
              type="tel"
              fullWidth
              required
              autoComplete="off"
              value={
                selectedUserId === "create-new"
                  ? newAgentPhone
                  : trimCountryCode(selectedAgent?.phone) || ""
              }
              onChange={(e: any) => handlePhoneNumberChange(e)}
              error={!!errors.phoneNumber}
              inputProps={{
                maxLength: 15,
              }}
              FormHelperTextProps={{
                sx: {
                  textAlign: isRTL ? "right" : "left",
                  fontFamily: langTheme.mainFont(),
                },
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <PhoneSelect
                      selectedCode={selectedCode}
                      handleCodeChange={handleCodeChange}
                    />
                  </InputAdornment>
                ),
              }}
              sx={(theme) => ({
                ...authTextFieldStyles.textField(
                  theme,
                  isRTL,
                  !!errors.phoneNumber
                ),
                input: {
                  textAlign: isRTL ? "right" : "left",
                  direction: i18n.dir(),
                },
              })}
            />

            {errors.phoneNumber && (
              <div className="campaign-builder-errors">
                {errors.phoneNumber}
              </div>
            )}
          </div>

          <div className="campaign-builder-sidenav-settings-fileds-item">
            <div className="campaign-builder-sidenav-field-name">
              {t("link_viewing")}
            </div>

            <TextField
              fullWidth
              sx={textFieldStyles2(langTheme.mainFont(), errors.link)}
              autoComplete="off"
              value={
                selectedUserId === "create-new"
                  ? newAgentLink
                  : selectedAgent?.link || ""
              }
              onChange={handleNewAgentLinkChange}
            />
            {errors.link && (
              <div className="campaign-builder-errors">{errors.link}</div>
            )}
          </div>

          <div className="campaign-builder-sidenav-settings-fileds-item">
            <div
              className="campaign-builder-sidenav-switcher-container"
              style={{
                marginBottom: selectedUserId === "create-new" ? "20px" : "0",
              }}
            >
              <div className="campaign-builder-sidenav-field-name">
                {t("agent_photo")}
              </div>
              <CampaignBuilderSwitcher
                open={agentPhotOpen}
                handleChangeOpen={handleChangeAgentPhotoOpen}
              />
            </div>

            <Collapse in={agentPhotOpen}>
              <>
                <FileDropZone
                  fileAcceptFormat={propertyImgAcceptFormat}
                  limit={MAXIMUM_NUMBER_OF_AGENT_AVATAR}
                  setFiles={setNewAgentAvatar}
                  placeholder={"placeholder_upload_property_agent_img"}
                />
                <div style={{ paddingTop: "20px" }}>
                  {selectedUserId === "create-new"
                    ? agentAvatarSlice.map((file: any, index: number) => (
                        <div
                          key={file.name + index}
                          className="drag-and-drop-file-container"
                        >
                          <div
                            style={{
                              position: "relative",
                              width: "fit-content",
                            }}
                          >
                            <img
                              onClick={() => handleRemoveAvatarFile(file.id)}
                              src={ReplaceCross}
                              style={{
                                right: isRTL ? "auto" : "-17px",
                                left: isRTL ? "-17px" : "auto",
                                position: "absolute",
                                cursor: "pointer",
                              }}
                              className="campaign-builder-sidenav-agent-photo-cross"
                            />
                            <div
                              style={{
                                background: `url(${file?.url}) center center / cover no-repeat`,
                                height: "111px",
                                width: "111px",
                                borderRadius: "55px",
                              }}
                              className="campaign-builder-sidenav-agent-photo-test img"
                            />
                          </div>
                        </div>
                      ))
                    : selectedAgent?.photo?.url && (
                        <div>
                          <div className="campaign-builder-sidenav-agent-photo">
                            <img
                              src={ReplaceCross}
                              onClick={() =>
                                handleRemoveUserAvatar(selectedAgent?.photo?.id)
                              }
                              style={{
                                right: isRTL ? "auto" : "-17px",
                                left: isRTL ? "-17px" : "auto",
                              }}
                              className="campaign-builder-sidenav-agent-photo-cross"
                            />
                            <div
                              style={{
                                background: `url(${selectedAgent?.photo?.url}) center center / cover no-repeat`,
                              }}
                              className="campaign-builder-sidenav-agent-photo img"
                            />
                          </div>
                        </div>
                      )}
                </div>
                <FilesLoader isLoading={isAvatarLoading} />
              </>
            </Collapse>
          </div>

          <div className="campaign-builder-sidenav-switcher-container">
            <div className="campaign-builder-sidenav-field-name">
              {t("schedule_viewing")}
            </div>
            <CampaignBuilderSwitcher
              open={scheduleVewingOpen}
              handleChangeOpen={handleChangeScheduleVewingOpen}
            />
          </div>

          <div className="campaign-builder-sidenav-create-agent-btn-wrapper">
            <div
              onClick={
                selectedUserId === "create-new"
                  ? handleCreateAgent
                  : handleUpdateAgent
              }
              className="campaign-builder-sidenav-create-agent-btn"
            >
              {selectedUserId === "create-new"
                ? t("create_agent")
                : t("update_agent")}
            </div>
          </div>
        </div>

        <div
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "space-between",
            gap: "20px",
          }}
        >
          <div style={{ flex: 1, maxWidth: "50%" }}>
            <BackToMenuBtn setCurrentStep={setCurrentStep} />
          </div>
          <div
            onClick={() => dispatch(setCampaingBuilderSideBarClose())}
            className="campaign-builder-sidenav-preview-btn"
            style={{
              flex: 1,
              maxWidth: "50%",
              textAlign: "center",
              marginTop: "20px",
            }}
          >
            {t("preview")}
          </div>
        </div>
      </div>

      <NotificationSnackBar
        snackbarText={snackbarText}
        onClose={() => setOpenSnackbar(false)}
        type={snackBarType}
        open={openSnackbar}
      />
    </div>
  );
};
