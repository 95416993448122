import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { formatPrice, getCurrencyIcon } from "../../../../helpers/dataConverts";
import Download from "../../../../assets/campaignBuilder/download.svg";
import { AgentInfoBlock } from "./AgentInfoBlock";
import { InvestmentHighlightsBlock } from "./InvestmentHighlightsBlock";
import { KeyFeturesBlock } from "./KeyFeturesBlock";
import { AboutPropertyBlock } from "./AboutPropertyBlock";
import { LocationBlock } from "./LocationBlock";
import { AboutDeveloperBlock } from "./AboutDeveloperBlock";
import { Theme, useMediaQuery } from "@mui/material";
import { MediaDialog } from "../../MediaDialog";
import { campaignBuilderOpenSelector } from "../../../../store/features/common";
import React from "react";
import { PropertyStatusBlock } from "./PropertyStatusBlock";
import { PreviewMode } from "../../types";

export const CampaignBuilderMainInfoSection = ({
  onlyView = false,
  investmentHighlightsRef,
  keyFeaturesRef,
  aboutPropertyRef,
  aboutDeveloperRef,
  contactInformationRef,
}: {
  onlyView: boolean;
  investmentHighlightsRef: any;
  keyFeaturesRef: any;
  aboutPropertyRef: any;
  aboutDeveloperRef: any;
  contactInformationRef: any;
}) => {
  const { t, i18n } = useTranslation(["campaignBuilder"]);

  const { previewMode } = useSelector(
    (state: any) => state.campaignBuilderSetup
  );
  const campaignBuilderOpen = useSelector(campaignBuilderOpenSelector);
  const downMd = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));
  const maxWidth1070px = useMediaQuery("(max-width: 1070px)");

  const {
    propertyTitle,
    currency,
    propertyPrice,
    propertyLocation,
    brochureFilesSlice,
  } = useSelector((state: any) => state.campaignBuilder);

  const [open, setOpen] = React.useState(false);

  const handleCloseDialog = () => {
    setOpen(false);
  };

  const handleOpen = (e: any) => {
    e.stopPropagation();
    if (brochureFilesSlice && brochureFilesSlice.length > 0) {
      setOpen(true);
    }
  };

  const portfolioPageView = useSelector(
    (state: any) => state.campaignBuilderSetup.pageView
  );
  return (
    <div className="campaign-builder-main-info-wrapper">
      <MediaDialog
        files={brochureFilesSlice}
        open={open}
        setOpen={handleCloseDialog}
        mediaType={"photos"}
      />
      <div
        className="campaign-builder-main-info"
        style={{
          direction: i18n.dir(),
        }}
      >
        <div className="campaign-builder-main-info-header">
          <div
            className="campaign-builder-main-info-title"
            style={{
              fontSize:
                previewMode === PreviewMode.MOBILE || downMd ? "24px" : "22px",
            }}
          >
            {propertyTitle ? propertyTitle : t("title_of_property_plug")}
          </div>
          <div
            className="campaign-builder-main-info-brochure-container"
            onClick={handleOpen}
          >
            <img src={Download} />
            <div>{t("brochure")}</div>
          </div>
        </div>
        <div className="campaign-builder-main-info-adress">
          {propertyLocation?.address ? propertyLocation?.address : t("adress")}
        </div>
        <div className="campaign-builder-main-info-price">
          {getCurrencyIcon(currency)} {formatPrice(propertyPrice)}
        </div>
        <PropertyStatusBlock />

        <div
          className="campaign-builder-main-info-divider"
          ref={investmentHighlightsRef}
        ></div>

        <InvestmentHighlightsBlock />
        <div
          className="campaign-builder-main-info-divider"
          ref={aboutPropertyRef}
        ></div>

        <AboutPropertyBlock />
        <div
          className="campaign-builder-main-info-divider"
          ref={keyFeaturesRef}
        ></div>

        <KeyFeturesBlock />
        <div className="campaign-builder-main-info-divider"></div>

        <LocationBlock isDraggable={true} />
        <div
          className="campaign-builder-main-info-divider"
          ref={aboutDeveloperRef}
        ></div>

        <AboutDeveloperBlock />
      </div>

      {maxWidth1070px ? (
        <></>
      ) : previewMode === PreviewMode.DESKTOP || campaignBuilderOpen ? (
        <div
          style={{
            minWidth: 300,
            width: 300,
            position: "relative",
            flexGrow: 1,
          }}
        >
          <div
            ref={contactInformationRef}
            style={{
              backgroundColor: "rgba(255, 255, 255, 1)",
              // position:
              //   !campaignBuilderOpen && !onlyView ? "relative" : "sticky",
              // top: !campaignBuilderOpen && !onlyView ? "auto" : "106px",
              position: !portfolioPageView ? "relative" : "sticky",
              top: !portfolioPageView ? "auto" : "106px",
              minWidth: 300,
              maxWidth: 300,
              maxHeight: 244,
              right: 0,
              transition: "top 0.2s ease-in-out",
            }}
          >
            <AgentInfoBlock />
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};
