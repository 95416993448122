import AddMediaIcon from "../../../../assets/campaignBuilder/add_media.svg";
import { useSelector } from "react-redux";
import { Theme, useMediaQuery } from "@mui/material";
import { Property } from "../../../../helpers/convert";
import { calculateDaysRemain } from "../../../../helpers/formatter";
import { PropertyCardDaysLeftLabelPageView } from "../../../_shared/PropertyCardDaysLeftLabelPageView";
import { LabelStampComponent } from "../../LabelStampComponent";
import { MediaUploadPanel } from "../../MediaUploadPanel";
import { PreviewMode } from "../../types";

export const CampaignBuilderMediaSection = ({
  property,
}: {
  property: Property;
}) => {
  const { labelBack, mainLabelOpen } = useSelector(
    (state: any) => state.campaignBuilder
  );
  const { previewMode } = useSelector(
    (state: any) => state.campaignBuilderSetup
  );
  const downMd = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));
  const maxWidth1220px = useMediaQuery("(max-width: 1220px)");

  const { imgFilesSlice } = useSelector((state: any) => state.campaignBuilder);
  const user = localStorage.getItem("user") || sessionStorage.getItem("user");
  const daysDifference = calculateDaysRemain(property?.campaignCloseDate);

  return (
    <div className="campaign-builder-media-wrapper">
      <div
        className="campaign-builder-media-main-container"
        style={{
          minHeight:
            previewMode === PreviewMode.MOBILE || downMd
              ? "325px"
              : maxWidth1220px
              ? "500px"
              : "700px",
        }}
      >
        {mainLabelOpen && <LabelStampComponent option={labelBack} />}

        {user &&
          JSON.parse(user).id === property?.owner?.id &&
          typeof property.numberOfDaysToCompletion !== "undefined" &&
          property.numberOfDaysToCompletion !== null &&
          daysDifference <= property?.numberOfDaysToCompletion && (
            <PropertyCardDaysLeftLabelPageView
              numberOfDaysToCompletion={daysDifference}
            />
          )}

        {previewMode === PreviewMode.MOBILE || downMd ? (
          imgFilesSlice[0]?.url ? (
            <div
              style={{
                background: `url(${imgFilesSlice[0]?.url}) center center / cover no-repeat`,
              }}
              className="campaign-builder-media-img-one"
            ></div>
          ) : (
            <div className="campaign-builder-media-img-one">
              <img src={AddMediaIcon} alt="Add Media" />
            </div>
          )
        ) : (
          <>
            {imgFilesSlice[0]?.url ? (
              <div
                style={{
                  background: `url(${imgFilesSlice[0]?.url}) center center / cover no-repeat`,
                }}
                className="campaign-builder-media-img-left"
              ></div>
            ) : (
              <div className="campaign-builder-media-img-left">
                <img src={AddMediaIcon} alt="Add Media" />
              </div>
            )}

            <div className="campaign-builder-media-img-right">
              {imgFilesSlice[1]?.url ? (
                <div
                  style={{
                    background: `url(${imgFilesSlice[1]?.url}) center center / cover no-repeat`,
                    borderTopRightRadius: "20px 20px",
                  }}
                  className="campaign-builder-media-img-item"
                ></div>
              ) : (
                <div
                  className="campaign-builder-media-img-item"
                  style={{
                    borderTopRightRadius: "20px 20px",
                  }}
                >
                  <img src={AddMediaIcon} alt="Add Media" />
                </div>
              )}

              {imgFilesSlice[2]?.url ? (
                <div
                  style={{
                    background: `url(${imgFilesSlice[2]?.url}) center center / cover no-repeat`,
                    borderBottomRightRadius: "20px 20px",
                  }}
                  className="campaign-builder-media-img-item"
                ></div>
              ) : (
                <div
                  className="campaign-builder-media-img-item"
                  style={{
                    borderBottomRightRadius: "20px 20px",
                  }}
                >
                  <img src={AddMediaIcon} alt="Add Media" />
                </div>
              )}
            </div>
          </>
        )}

        <MediaUploadPanel />
      </div>
    </div>
  );
};
