import { useTranslation } from "react-i18next";
import { useLanguageTheme } from "../../../../hooks/useLanguageTheme";
import { ILabel } from "../../../../helpers/convert";
import {
  LabelIconMap,
  LabelStamp,
} from "../../../../constants/campaignBuilderData";

export type IconKey =
  | "hot_price"
  | "premium"
  | "eco_friendly"
  | "alarm"
  | "diamond"
  | "sale";

export const PropertyCardStamp = ({ label }: { label: ILabel | null }) => {
  const { i18n } = useTranslation(["campaignBuilder"]);
  const langTheme = useLanguageTheme();
  const isRTL = i18n.dir() === "rtl";

  if (!label) return null;
  const IconComponent = LabelIconMap[label.iconKey];
  if (!IconComponent) return null;

  return (
    <div
      className="label-stamp-container"
      style={{
        top: "-17px",
        left: isRTL ? "auto" : "-9px",
        right: isRTL ? "-9px" : "auto",
      }}
    >
      <div
        style={{
          display: "flex",
          position: "absolute",
          gap: "7px",
          top: "8px",
          marginLeft: isRTL ? "auto" : "10px",
          marginRight: isRTL ? "10px" : "auto",
          zIndex: 2,
        }}
      >
        <IconComponent
          isSelected={false}
          width={17}
          height={19}
          backgroundColor={"white"}
          iconSelect={false}
        />
        <div
          style={{
            fontFamily: langTheme.mainFont(),
          }}
        >
          {i18n.language === "en" ? label?.labelNameEn : label?.labelNameHe}
        </div>
      </div>
      <div
        style={{
          transform: isRTL ? "scaleX(-1)" : " scaleX(1)",
        }}
      >
        <LabelStamp
          isSelected={true}
          width={135}
          height={46}
          backgroundColor={label?.backgroundColor}
          iconSelect={false}
        />
      </div>
    </div>
  );
};
