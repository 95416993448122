import React, { useEffect, useState } from "react";
import EmailChecked from "../../assets/icons/email_checked.svg";
import { useTranslation } from "react-i18next";
import YellowArrow from "../../assets/icons/yellow_arrow.svg";
import { useNavigate, useParams } from "react-router-dom";
import { Theme, useMediaQuery, Button, CircularProgress } from "@mui/material";
import { useLanguageTheme } from "../../hooks/useLanguageTheme";
import {
  useResendSignupEmailMutation,
  useResendSignupSmsMutation,
  useSmsSignupMutation,
} from "../../store/api/authApiSlice";
import { useAppDispatch } from "../../store/store";
import { setAuthPopupOpen } from "../../store/features/common";
import { AuthPopupContentType } from "./types";

export interface IResendSignupData {
  email: string;
}

export const EmailConfirmationContent = ({
  headerText,
}: {
  headerText: string;
}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation(["signin"]);
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const isRTL = currentLanguage === "he";
  const langTheme = useLanguageTheme();
  const downMd = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));
  const [isButtonDisabled, setIsButtonDisabled] = React.useState(false);
  const [countdown, setCountdown] = React.useState(10);

  const [signupSms] = useSmsSignupMutation();
  const [resendSms] = useResendSignupSmsMutation();
  const [isLoading, setIsLoading] = useState(false);

  const [resendEmailMutation] = useResendSignupEmailMutation();

  const registrationData = JSON.parse(
    localStorage.getItem("registrationData") || "{}"
  );
  const phoneNumber = registrationData.phoneNumber;
  const email = registrationData.email || "";

  useEffect(() => {
    let timer: any;
    if (isButtonDisabled && countdown > 0) {
      timer = setTimeout(() => setCountdown(countdown - 1), 1000);
    } else if (countdown === 0) {
      setIsButtonDisabled(false);
      setCountdown(60);
    }
    return () => clearTimeout(timer);
  }, [isButtonDisabled, countdown]);

  const resendEmail = async () => {
    if (!email) {
      return;
    }

    try {
      setIsButtonDisabled(true);
      setCountdown(60);
      await resendEmailMutation({
        email: email,
      }).unwrap();
    } catch (error) {
      console.log("log: ", error);
    }
  };

  async function handleSmsRedirect() {
    setIsLoading(true);
    try {
      const response = await signupSms(registrationData).unwrap();
      if (response.status === "unconfirmed") {
        await resendSms({ phoneNumber }).unwrap();
      }

      dispatch(setAuthPopupOpen(AuthPopupContentType.CONFIRM_SMS));
    } catch (error) {
      console.error("Error during SMS redirect:", error);
    } finally {
      setIsLoading(false);
    }
  }

  const handleBackToSignup = () => {
    dispatch(setAuthPopupOpen(AuthPopupContentType.SIGNUP));
  };

  return (
    <div
      className="email-confirmation-section"
      style={{
        direction: i18n.dir(),
        height: downMd ? "100vh" : "",
        position: "relative",
      }}
    >
      {isLoading && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(255, 255, 255, 0.8)",
            zIndex: 9999,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress size={50} color="primary" />
        </div>
      )}
      <div className="email-confirmation-email-checked-container">
        <img src={EmailChecked} alt="Email Checked icon" />
      </div>

      <div
        className="forgot-p-title"
        style={{
          fontFamily: isRTL ? "Noto Sans Hebrew" : "Frank Ruhl Libre",
        }}
      >
        {t(`${headerText}`)}
      </div>

      <div className="email-confirmation-please-confirm-container">
        <div className="email-confirmation-we-sent-container">
          <div
            className="email-confirmation-grey"
            style={{
              fontFamily: langTheme.mainFont(),
            }}
          >
            {t("we_sent_a_link_to")}
          </div>
          <span>&nbsp;</span>
          <div
            className="email-confirmation-blue"
            style={{
              fontFamily: langTheme.mainFont(),
            }}
          >
            {email}
          </div>
        </div>
        <div
          className="email-confirmation-grey"
          style={{
            fontFamily: langTheme.mainFont(),
          }}
        >
          {t("please_confirm_you_account")}
        </div>
      </div>

      <div className="email-confirmation-dont-receive-container">
        <div
          style={{
            fontFamily: langTheme.mainFont(),
            minWidth: "190px",
          }}
          className="email-confirmation-blue"
        >
          {t("dont_receive_the_email")}
        </div>
        <Button
          disabled={isButtonDisabled}
          onClick={() => resendEmail()}
          style={{
            fontFamily: langTheme.mainFont(),
            fontWeight: isRTL ? 600 : 500,
            color: "rgba(215, 161, 72, 1)",
            fontSize: "16px",
            letterSpacing: "-0.32px",
            lineHeight: "26px",
            cursor: "pointer",
            textTransform: "none",
            textAlign: "start",
            padding: 0,
            minWidth: isButtonDisabled ? "220px" : "auto",
            display: "block",
          }}
        >
          {isButtonDisabled
            ? `Resend Email in ${countdown} seconds`
            : t("click_to_resend")}
        </Button>
      </div>
      {phoneNumber.startsWith("+972") && (
        <div
          onClick={() => handleSmsRedirect()}
          style={{
            cursor: "pointer",
            fontFamily: langTheme.mainFont(),
            fontWeight: isRTL ? 600 : 500,
            color: "rgba(215, 161, 72, 1)",
            fontSize: "16px",
            letterSpacing: "-0.32px",
            lineHeight: "26px",
          }}
        >
          {t("sms_instead")}
        </div>
      )}

      <div
        className="email-confirmation-yellow email-confirmation-back-to-signup"
        style={{
          fontFamily: langTheme.mainFont(),
          direction: "ltr",
        }}
        onClick={handleBackToSignup}
      >
        <img src={YellowArrow} alt="yellow arrow" />
        <div>{t("back_to_sign_up")}</div>
      </div>
    </div>
  );
};
