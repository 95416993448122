import React from "react";
import Lock from "../../assets/icons/lock.svg";
import { useTranslation } from "react-i18next";
import {
  Box,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import Visibility from "../../assets/icons/visibility.svg";
import VisibilityOff from "../../assets/icons/visibility_off.svg";
import { BackToLoginBtn } from "./BackToLoginBtn";
import { ResetPasswordBtn } from "./ResetPasswordBtn";
import { PasswordCheck } from "./PasswordCheck";
import { useLanguageTheme } from "../../hooks/useLanguageTheme";
import { useResetPasswordMutation } from "../../store/api/authApiSlice";
import { useForm } from "react-hook-form";
import { FormContainer } from "react-hook-form-mui";
import { ISignupError } from "../../pages/Signup";
import { useSearchParams } from "react-router-dom";

export interface IResetPasswordData {
  password: string;
  passwordConfirmation: string;
  code: string;
}

export const PasswordResetNewPasswordStep = ({
  onChangeStep,
}: {
  onChangeStep: () => void;
}) => {
  const { t } = useTranslation(["signin"]);
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const isRTL = currentLanguage === "he";
  const langTheme = useLanguageTheme();

  const [password, setPassword] = React.useState("");
  const [confirmPassword, setConfirmPassword] = React.useState("");
  const [showPassword, setShowPassword] = React.useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);
  const [errors, setErrors] = React.useState<Partial<ISignupError>>({});
  const [submitError, setSubmitError] = React.useState("");
  const [_, setSearchParams] = useSearchParams();

  const handleTogglePasswordVisibility = () => {
    setShowPassword((prev) => !prev);
  };

  const handleToggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword((prev) => !prev);
  };

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const [resetPassword] = useResetPasswordMutation();

  const queryParams = new URLSearchParams(window.location.search);
  const code = queryParams.get("code");

  const onSubmit = async (data: IResetPasswordData) => {
    const newErrors: Partial<ISignupError> = {};

    if (!password.trim()) {
      newErrors.password = t("password_required");
    }

    if (!confirmPassword.trim()) {
      newErrors.confirmPassword = t("password_required");
    }

    if (
      password.trim() &&
      confirmPassword.trim() &&
      password !== confirmPassword
    ) {
      newErrors.confirmPassword = t("password_does_not_match");
    }

    setErrors(newErrors);

    if (Object.keys(newErrors).length === 0 && code) {
      try {
        const response = await resetPassword({
          password: password,
          passwordConfirmation: confirmPassword,
          code: code,
        }).unwrap();

        const queryParams = new URLSearchParams(window.location.search);
        queryParams.delete("code");

        setSearchParams(queryParams);

        onChangeStep();
      } catch (err) {
        setSubmitError("Something went wrong");
        console.log("log: ", err);
      }
    }
  };

  const formContext = useForm<IResetPasswordData>({
    defaultValues: {
      password: "",
      passwordConfirmation: "",
    },
    reValidateMode: "onChange",
    shouldUseNativeValidation: false,
    mode: "onSubmit",
  });

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setPassword(value);

    const newErrors = { ...errors };
    if (!value.trim()) {
      newErrors.password = t("password_required");
    } else {
      delete newErrors.password;
    }
    setErrors(newErrors);
  };

  const handleConfirmPasswordChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = e.target.value;
    setConfirmPassword(value);

    // Clear or set errors for confirmPassword
    const newErrors = { ...errors };

    if (!value.trim()) {
      newErrors.confirmPassword = t("password_required");
    } else if (password.trim() && value.trim() && password !== value) {
      newErrors.confirmPassword = t("password_does_not_match");
    } else {
      delete newErrors.confirmPassword;
    }
    setErrors(newErrors);
  };

  return (
    <div className="forgot-p-container" style={{ flexDirection: "column" }}>
      <div className="forgot-p-lock-container">
        <img src={Lock} />
      </div>

      <div
        className="forgot-p-title"
        style={{
          fontFamily: isRTL ? "Noto Sans Hebrew" : "Frank Ruhl Libre",
        }}
      >
        {t("set_an_new_password_title")}
      </div>
      <div
        className="forgot-p-desc"
        style={{
          fontFamily: langTheme.mainFont(),
        }}
      >
        {t("set_a_new_password_desc")}
      </div>

      <div style={{ width: "100%", direction: i18n.dir() }}>
        <FormContainer
          formContext={formContext}
          onSuccess={(data) => onSubmit(data)}
        >
          <div
            className="signin-form-inputs-label"
            style={{
              fontFamily: langTheme.mainFont(),
            }}
          >
            {t("password")}
          </div>
          <TextField
            type={showPassword ? "text" : "password"}
            fullWidth
            required
            placeholder="Enter password"
            value={password}
            onChange={handlePasswordChange}
            error={!!errors.password}
            // helperText={errors.password || " "}
            inputProps={{
              maxLength: 64,
            }}
            FormHelperTextProps={{
              sx: {
                textAlign: isRTL ? "right" : "left",
                fontFamily: langTheme.mainFont(),
              },
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={handleTogglePasswordVisibility}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? (
                      <img
                        src={VisibilityOff}
                        style={{
                          width: "24px",
                          height: "24px",
                          marginRight: "8px",
                        }}
                      />
                    ) : (
                      <img
                        src={Visibility}
                        style={{
                          width: "24px",
                          height: "24px",
                          marginRight: "8px",
                        }}
                      />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            sx={{
              "& .MuiOutlinedInput-root": {
                border: "1px solid rgba(224, 229, 242, 1)",
                borderRadius: "16px",
              },
              "& .MuiOutlinedInput-root.Mui-focused": {
                border: "1px solid rgba(129, 154, 221, 1)",
              },
              "& .MuiOutlinedInput-input": {
                fontSize: "14px",
                color: "rgba(43, 54, 116, 1)",
                fontFamily: "DM Sans",
                fontWeight: 500,
                lineHeight: "14px",
                letterSpacing: "-0.32px",
              },
              "& .MuiOutlinedInput-input::placeholder": {
                color: "rgba(163, 174, 208, 1)",
                opacity: 1,
              },
              "& .MuiOutlinedInput-input:focus::placeholder": {
                opacity: 0,
              },
              "& .MuiOutlinedInput-notchedOutline": {
                border: "none",
              },
            }}
          />
          <PasswordCheck password={password} />
          <div
            className="signin-form-inputs-label"
            style={{
              fontFamily: langTheme.mainFont(),
            }}
          >
            {t("confirm_password")}
          </div>
          <TextField
            type={showConfirmPassword ? "text" : "password"}
            fullWidth
            required
            placeholder={t("confirm_password")}
            value={confirmPassword}
            error={!!errors.confirmPassword}
            helperText={errors.confirmPassword || " "}
            onChange={handleConfirmPasswordChange}
            inputProps={{
              maxLength: 64,
            }}
            FormHelperTextProps={{
              sx: {
                textAlign: isRTL ? "right" : "left",
                fontFamily: langTheme.mainFont(),
              },
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={handleToggleConfirmPasswordVisibility}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showConfirmPassword ? (
                      <img
                        src={VisibilityOff}
                        style={{
                          width: "24px",
                          height: "24px",
                          marginRight: "8px",
                        }}
                      />
                    ) : (
                      <img
                        src={Visibility}
                        style={{
                          width: "24px",
                          height: "24px",
                          marginRight: "8px",
                        }}
                      />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            sx={{
              "& .MuiOutlinedInput-root": {
                border: "1px solid rgba(224, 229, 242, 1)",
                borderRadius: "16px",
              },
              "& .MuiOutlinedInput-root.Mui-focused": {
                border: "1px solid rgba(129, 154, 221, 1)",
              },
              "& .MuiOutlinedInput-input": {
                fontSize: "14px",
                color: "rgba(43, 54, 116, 1)",
                fontFamily: "DM Sans",
                fontWeight: 500,
                lineHeight: "14px",
                letterSpacing: "-0.32px",
              },
              "& .MuiOutlinedInput-input::placeholder": {
                color: "rgba(163, 174, 208, 1)",
                opacity: 1,
              },
              "& .MuiOutlinedInput-input:focus::placeholder": {
                opacity: 0,
              },
              "& .MuiOutlinedInput-notchedOutline": {
                border: "none",
              },
            }}
          />
        </FormContainer>
      </div>

      {submitError && (
        <div
          className="submit-error"
          style={{
            fontFamily: langTheme.mainFont(),
            direction: i18n.dir(),
            width: "100%",
          }}
        >
          {submitError}
        </div>
      )}
      <ResetPasswordBtn onSubmit={onSubmit} />
      <BackToLoginBtn />
    </div>
  );
};
