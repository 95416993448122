import { useTranslation } from "react-i18next";
import { Theme, useMediaQuery } from "@mui/material";
import { useNavigate } from "react-router-dom";
import DefaultArticleImg from "../../../assets/learningCenter/building.png";
import { useLanguageTheme } from "../../../hooks/useLanguageTheme";
import { IArticle } from "../interfaces";
import { formatDateWithMonthByLocale } from "../../../helpers/formatter";

export const MainBlueArticleCard = ({ article }: { article: IArticle }) => {
  const { i18n, t } = useTranslation(["learningCenter"]);
  const isRTL = i18n.dir() === "rtl";
  const langTheme = useLanguageTheme();
  const downMd = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));
  const navigate = useNavigate();

  return (
    <div
      className="main-blue-acticle-section-wrapper"
      style={{
        direction: i18n.dir(),
        fontFamily: langTheme.mainFont(),
        flexDirection: downMd ? "column" : "row",
      }}
      onClick={() => navigate(`/learning-center/${article.documentId}`)}
    >
      <div
        style={{
          transform: isRTL ? "scaleX(-1)" : " scaleX(1)",
          background: `url(${
            !article?.mediaContent || article?.mediaContent?.length === 0
              ? DefaultArticleImg
              : article?.mediaContent[0]?.url
          }) center center / cover no-repeat`,
        }}
        className="main-blue-acticle-section-main-img"
      ></div>
      <div
        className="main-blue-acticle-section-info-wrapper"
        style={{
          padding: downMd ? "15px 15px 35px 15px" : "30px",
        }}
      >
        <div>
          <div className="main-blue-acticle-clue-container">
            {article?.tags.map((tag, index) => (
              <div className="clue-grey" key={index}>
                {t(tag.title)}
              </div>
            ))}
          </div>
          <div
            className="main-blue-acticle-section-title"
            style={{
              fontSize: downMd ? "18px" : "24px",
              fontFamily: isRTL ? "Noto Sans Hebrew" : "Frank Ruhl Libre",
              marginTop: downMd ? "10px" : "15px",
              marginBottom: downMd ? "10px" : "15px",
              lineHeight: downMd ? "22px" : "28px",
              maxWidth: downMd ? "90%" : "362px",
            }}
          >
            {article?.title}
          </div>
          <div
            className="main-blue-acticle-section-desc"
            style={{
              fontFamily: langTheme.mainFont(),
              maxWidth: downMd ? "90%" : "362px",
            }}
          >
            {article?.shortDescription}
          </div>
        </div>

        <div className="main-blue-acticle-additional-info-wrapper">
          <div>
            {formatDateWithMonthByLocale(
              article?.createdAt,
              isRTL ? "he-IL" : "en-US"
            )}
          </div>
          <div className="main-blue-acticle-additional-info-line"></div>
          <div>
            {article?.minutesToRead} {t("min_read")}
          </div>
        </div>
      </div>
    </div>
  );
};
