import React, {useState} from "react";
import {contextMenu, Item, ItemParams, Menu, Separator, Submenu} from "react-contexify";
import material from "../../../assets/campaignBuilder/ai-chat/material.svg";
import pencil from "../../../assets/campaignBuilder/ai-chat/pencil.svg";
import sammarize from "../../../assets/campaignBuilder/ai-chat/sammarize.svg";
import textLonger from "../../../assets/campaignBuilder/ai-chat/textLonger.svg";
import simplify from "../../../assets/campaignBuilder/ai-chat/simplify.svg";
import formal from "../../../assets/campaignBuilder/ai-chat/formal.svg";
import engagine from "../../../assets/campaignBuilder/ai-chat/engagine.svg";
import casual from "../../../assets/campaignBuilder/ai-chat/casual.svg";
import simplytone from "../../../assets/campaignBuilder/ai-chat/simplytone.svg";
import "react-contexify/dist/ReactContexify.css";
import cut from "../../../assets/campaignBuilder/ai-chat/cut.svg";
import {useTranslation} from "react-i18next";
import axios from "axios";
import {API_API_BASE_URL} from "../../../helpers/api";
import {ThreeDots} from "react-loader-spinner";
import {
    setAboutDeveloper,
    setAboutPropertyDesc,
    setPropertyDescription,
    setPropertyTitle
} from "../../../store/features/campaignBuilderSlice";
import {useAppDispatch} from "../../../store/store";

interface AiContextMenuProps {
    menuID?: string;
}

const AiContextMenu: React.FC<AiContextMenuProps> = ({menuID = 'aiFieldContextMenu'}) => {
    const dispatch = useAppDispatch();
    const { i18n, t } = useTranslation(["aiChart"]);
    const currentLanguage: string = i18n.language;

    const [isLoading, setIsLoading] = useState(false);
    const [currentAction, setCurrentAction] = useState('');

    const actions: { title: string; slug: string; logo: string; action: { task: string; prompt: string } }[] = [
        {
            title: currentLanguage === 'en' ?  (t("translate_english")) : (t("translate_hebrew")),
            slug: 'translate',
            logo: material,
            action: {
                task: "Translate",
                prompt: currentLanguage === 'en' ? 'to English' : 'to Hebrew'
            }
        },
        {
            
    
            title: (t("summarize")),
            slug: 'summarize',
            logo: sammarize,
            action: {
                task: "Summarize",
                prompt: ""
            }
        },
        {
         
            title: (t("shorten")),
            slug: 'shorten',
            logo: cut,
            action: {
                task: "Shorten",
                prompt: ""
            }
        },
        {
           
            title: (t("make_longer")),
            slug: 'make_longer',
            logo: textLonger,
            action: {
                task: "Make Longer",
                prompt: ""
            }
        },
        {
            title: (t("simplify")),
            slug: 'simplify',
            logo: simplify,
            action: {
                task: "Simplify",
                prompt: ""
            }
        }
    ];

    const actionsChangeTone: { title: string; slug: string; logo: string; action: { task: string; prompt: string } }[] = [
        {
            
            title:  (t("change_tone_engaging")),
            slug: 'change_tone_engaging',
            logo: engagine,
            action: {
                task: "Change Tone",
                prompt: "make it more engaging"
            }
        },
        {
            
            title:(t("change_tone_formal")),
            slug: 'change_tone_formal',
            logo: formal,
            action: {
                task: "Change Tone",
                prompt: "make it more formal"
            }
        },
        {
            title: (t("change_tone_casual")),
            slug: 'change_tone_casual',
            logo: casual,
            action: {
                task: "Change Tone",
                prompt: "make it more casual"
            }
        }
    ];

    const applyAction = (action: { task: string; prompt: string }, actionSlug: string, key: string, value: any) => {
        setIsLoading(true);
        setCurrentAction(actionSlug);

        axios.post(API_API_BASE_URL + "/analysis", {
            form: {
                value: value
            },
            instruction: {
                task: action.task || "Nothing",
                prompt: action.prompt || ''
            }
        }).then(response => {
            const result = response.data;
            const responseValue = result?.form?.value || value;

            if (key === 'propertyTitle') {
                dispatch(setPropertyTitle(responseValue));
            }
            if (key === 'propertyDescription') {
                dispatch(setPropertyDescription(responseValue));
            }
            if (key === 'aboutPropertyDesc') {
                dispatch(setAboutPropertyDesc(responseValue));
            }
            if (key === 'aboutDeveloper') {
                dispatch(setAboutDeveloper(responseValue));
            }
        }).catch(error => {
            console.error('Failed to analyze campaign:', error);
        }).finally(() => {
            setIsLoading(false);

            contextMenu.hideAll()
        })
    }




  return (
      <Menu id={menuID} className="custom-context-menu">
          {actions?.map((item: { title: string; slug: string; logo: string; action: { task: string; prompt: string } }, index: number) => (
              <Item
                  key={item.slug}
                  onClick={({props, data, event, triggerEvent}: ItemParams) => {
                      applyAction(item.action, item.slug, props?.key, props?.value);
                  }}
                  closeOnClick={false}>
                  {(isLoading && currentAction === item.slug)
                      ? (
                          <ThreeDots
                              visible={true}
                              height="20"
                              width="20"
                              color="#034a73"
                              radius="9"
                              ariaLabel="three-dots-loading"
                              wrapperStyle={{}}
                              wrapperClass=""
                          />
                      )
                      : (
                          <img src={item?.logo} width={20} height={20} alt=""/>
                  )}
                  <span style={{margin: "0px 5px"}}>{item?.title}</span>
              </Item>
          ))}
          <Submenu label={
              <>
                  <img src={simplytone} width={20} height={20} alt=""/>
                  
                  <span style={{margin: "0px 5px"}}>{t("change_Tone")} </span>
              </>
          }>
              {actionsChangeTone?.map((item: { title: string; slug: string; logo: string; action: { task: string; prompt: string } }, index: number) => (
                  <Item
                      key={item.slug}
                      onClick={({props, data, event, triggerEvent}: ItemParams) => {
                          applyAction(item.action, item.slug, props?.key, props?.value);
                      }}
                      closeOnClick={false}>
                      {(isLoading && currentAction === item.slug)
                          ? (
                              <ThreeDots
                                  visible={true}
                                  height="20"
                                  width="20"
                                  color="#034a73"
                                  radius="9"
                                  ariaLabel="three-dots-loading"
                                  wrapperStyle={{}}
                                  wrapperClass=""
                              />
                          )
                          : (
                              <img src={item?.logo} width={20} height={20} alt=""/>
                          )}
                      <span style={{margin: "0px 5px"}}>{item?.title}</span>
                  </Item>
              ))}
          </Submenu>
      </Menu>
  );
};

export default AiContextMenu;
