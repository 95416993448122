import { Theme, useMediaQuery } from "@mui/material";
import { ContactUsInfoBlock } from "./ContactUsInfoBlock";
import { useTranslation } from "react-i18next";
import { ContactUsFormContainer } from "./ContactUsFormContainer";
import { NotificationSnackBar } from "../../../campaignBuilder/NotificationSnackBar";
import { useSelector } from "react-redux";
import {
  feedbackFormSnackMessageSelector,
  feedbackFormSnackOpenSelector,
  feedbackFormSnackTypeSelector,
  setContactUsFormSnackBarClose,
} from "../../../../store/features/contactUsSlice";
import { useAppDispatch } from "../../../../store/store";

export const ContactUsMainContainer = () => {
  const downMd = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));
  const { i18n } = useTranslation("contactUs");
  const dispatch = useAppDispatch();

  const feedbackFormSnackOpen = useSelector(feedbackFormSnackOpenSelector);
  const feedbackFormSnackMessage = useSelector(
    feedbackFormSnackMessageSelector
  );
  const feedbackFormSnackType = useSelector(feedbackFormSnackTypeSelector);

  return (
    <div
      className="xl-width"
      style={{
        paddingTop: downMd ? "35px" : "100px",
        direction: i18n.dir(),
      }}
    >
      <div className="contact-us-main-container-wrapper">
        <ContactUsInfoBlock />
        <ContactUsFormContainer />
      </div>

      <div
        className="contact-us-divider"
        style={{
          marginTop: downMd ? "65px" : "100px",
        }}
      />
      <NotificationSnackBar
        open={feedbackFormSnackOpen}
        onClose={() => dispatch(setContactUsFormSnackBarClose())}
        type={feedbackFormSnackType}
        snackbarText={feedbackFormSnackMessage}
      />
    </div>
  );
};
