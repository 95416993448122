import {
  locationFilters,
  statusFilters,
  typeFilters,
} from "../../constants/propertyData";
import { IPriceRange } from "./interfaces";

export const DEFAULT_PAGE = 1;

export const DEFAULT_SORT_FIELD = "created_at";
export const DEFAULT_SORT_DIRECTION = "desc";
export const DEFAULT_SORT = {
  direction: DEFAULT_SORT_DIRECTION,
  field: DEFAULT_SORT_FIELD,
};
export const DEFAULT_CAMPAIGN_STATUS = "all";
export const DEFAULT_USER_ID = "all";

export const DEFAULT_PRICE_RANGE_START = 0;
export const DEFAULT_PRICE_RANGE_END = 100_000_000;
export const DEFAULT_PRICE_RANGE: IPriceRange = {
  start: DEFAULT_PRICE_RANGE_START,
  end: DEFAULT_PRICE_RANGE_END,
};

export const DEFAULT_FILTER = {
  locations: [],
  statuses: [
    {
      key: "0",
      name: { en: 'Pre-Sale ("On Paper")', he: "לפני מכירה (״על הנייר״)" },
      translationKey: "pre_sale_on_paper",
    },
    {
      key: "1",
      name: { en: "Under Construction", he: "בקרוב בבנייה" },
      translationKey: "under_construction",
    },
    {
      key: "2",
      name: { en: "Ready for Occupancy", he: "מוכן לאכלוס" },
      translationKey: "ready_for_occupancy",
    },
    {
      key: "3",
      name: { en: "Immediate Availability", he: "זמינות מיידית" },
      translationKey: "immediate_availability",
    },
    {
      key: "4",
      name: { en: "Coming Soon", he: "בקרוב" },
      translationKey: "coming_soon",
    },
  ],
  types: [
    {
      key: "0",
      name: { en: "Penthouse", he: "פנטהאוז" },
      translationKey: "penthouse",
    },
    {
      key: "1",
      name: { en: "Garden Apartment", he: "דירת גן" },
      translationKey: "garden_apartment",
    },
    {
      key: "2",
      name: { en: "Duplex", he: "דופלקס" },
      translationKey: "duplex",
    },
    {
      key: "3",
      name: { en: "Triplex", he: "טריפלקס" },
      translationKey: "triplex",
    },
    {
      key: "4",
      name: { en: "Villa/House", he: "וילה/בית" },
      translationKey: "villa_house",
    },
    {
      key: "5",
      name: { en: "Townhouse", he: "בית עירוני" },
      translationKey: "townhouse",
    },
    {
      key: "6",
      name: { en: "Rooftop Apartment", he: "דירת גג" },
      translationKey: "rooftop_apartment",
    },
    {
      key: "7",
      name: { en: "Studio Apartment (Premium)", he: "דירת סטודיו (פרימיום)" },
      translationKey: "studio_apartment_premium",
    },
    {
      key: "8",
      name: { en: "Office Space", he: "שטח משרדים" },
      translationKey: "office_space",
    },
    {
      key: "9",
      name: { en: "Retail Space", he: "שטח מסחרי" },
      translationKey: "retail_space",
    },
    {
      key: "10",
      name: { en: "Mixed-Use Development", he: "פיתוח משולב" },
      translationKey: "mixed_use_development",
    },
    {
      key: "11",
      name: { en: "Commercial Building", he: "בניין מסחרי" },
      translationKey: "commercial_building",
    },
    {
      key: "12",
      name: { en: "Business Center", he: "מרכז עסקים" },
      translationKey: "business_center",
    },
    {
      key: "13",
      name: { en: "Luxury Apartment", he: "דירת יוקרה" },
      translationKey: "luxury_apartment",
    },
    {
      key: "14",
      name: { en: "Luxury Tower", he: "מגדל יוקרה" },
      translationKey: "luxury_tower",
    },
  ],
  priceRange: DEFAULT_PRICE_RANGE,
};

export const ALL_FILTERS = {
  locations: locationFilters,
  statuses: statusFilters,
  types: typeFilters,
  priceRange: DEFAULT_PRICE_RANGE,
};

export const QUERY_PARAM_PAGE = "page";
export const QUERY_PARAM_SEARCH = "search";
export const QUERY_PARAM_PAGE_SIZE = "pageSize";
export const QUERY_PARAM_SORT_FIELD = "sortField";
export const QUERY_PARAM_SORT_DIRECTION = "sortDir";
export const QUERY_PARAM_FILTER_LOCATION = "location";
export const QUERY_PARAM_FILTER_PROPERTY_TYPES = "propertyTypes";
export const QUERY_PARAM_FILTER_PRICE_RANGE_START = "rangeStart";
export const QUERY_PARAM_FILTER_PRICE_RANGE_END = "rangeEnd";
export const QUERY_PARAM_FILTER_STATUSES = "statuses";
export const QUERY_PARAM_FILTER_CAMPAIGN_STATUS = "campaignStatus";
export const QUERY_PARAM_FILTER_USER_ID = "userId";

export const ALL = "all";

export const PARAM_ENCODER_SEP = ",";
