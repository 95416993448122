import {
  Box,
  Button,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React from "react";
import house1 from "../../assets/sellWithUs/footer_house1.png";
import house2 from "../../assets/sellWithUs/footer_house2.png";
import house3 from "../../assets/sellWithUs/footer_house3.png";
import kid from "../../assets/sellWithUs/kid.png";
import meeting from "../../assets/sellWithUs/meeting.png";
import woman from "../../assets/sellWithUs/footer_woman.png";
import { useTranslation } from "react-i18next";
import Arrow from "../../assets/icons/arrow.svg";
import i18n from "../../i18n";
import { useNavigate } from "react-router-dom";

export default function GetInTouchFooter() {
  const { t } = useTranslation(["sellWithUs"]);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(670));
  const isRTL = i18n.language === "he";
  const navigate = useNavigate();
  const downMd = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));

  const maxWidth900px = useMediaQuery(theme.breakpoints.down(900));
  const maxWidth870px = useMediaQuery(theme.breakpoints.down(870));
  const maxWidth650px = useMediaQuery(theme.breakpoints.down(650));

  const desktopImages = [
    { src: house1, alt: "Property 1" },
    { src: meeting, alt: "Team meeting" },
    { src: house3, alt: "Property 3" },
    { src: house2, alt: "Property 2" },
    { src: kid, alt: "Person walking" },
    { src: woman, alt: "Person with phone" },
  ];

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        backgroundColor: "#034A73",
        marginTop: isMobile ? "30px" : "100px",
      }}
    >
      <div
        className="xl-width"
        style={{
          direction: i18n.dir(),
        }}
      >
        <Box
          sx={{
            bgcolor: "#034A73",
            px: isMobile ? "20px" : "20px",
            display: "flex",
            gap: "20px",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          {/* Left Side: Text and Button */}
          <Box
            sx={{
              textAlign: isRTL ? "right" : "left",
              color: "#fff",
              m: "38px 0",
              maxWidth: maxWidth650px ? "460px" : "415px",
            }}
          >
            <Typography
              variant="body2"
              sx={{
                fontSize: "18px",
                fontWeight: 600,
                fontFamily: isRTL ? "Noto Sans Hebrew" : "Frank Ruhl Libre",
                color: "#FCBC5C",
              }}
            >
              {t("get_in_touch")}
            </Typography>

            <Typography
              variant="h2"
              sx={{
                fontWeight: 600,
                fontSize: maxWidth900px ? "24px" : "34px",
                textTransform: "uppercase",
                fontFamily: isRTL ? "Noto Sans Hebrew" : "Frank Ruhl Libre",
                mb: isMobile ? "15px" : "",
              }}
            >
              {t("lets_start")}
            </Typography>

            <Typography
              variant="body1"
              sx={{
                fontSize: "18px",
                mb: maxWidth870px ? "20px" : "25px",
                fontFamily: isRTL ? "Noto Sans Hebrew" : "DM sans",
                lineHeight: downMd ? "20px" : "22px",
                color: "#FFFFFFCC",
              }}
            >
              {t("list")}
            </Typography>
            <Button
              variant="contained"
              endIcon={
                <img
                  src={Arrow}
                  alt="Arrow"
                  style={{
                    width: "16px",
                    height: "16px",
                    transform: isRTL ? "scaleX(-1)" : "",
                  }}
                />
              }
              sx={{
                background: "linear-gradient(to right, #D7A148, #E0CD6A)",
                color: "#fff",
                textTransform: "none",
                fontSize: "1rem",
                fontWeight: 500,
                fontFamily: isRTL ? "Noto Sans Hebrew" : "DM sans",
                borderRadius: "50px",
                px: isMobile ? 2 : 4,
                py: 1.5,
                display: "flex",
                maxHeight: { md: "auto", xl: "48px" },
                direction: i18n.dir(),
              }}
              onClick={() => navigate("/contact-us")}
            >
              <Typography
                sx={{
                  mr: isRTL ? 0 : "1px",
                  ml: isRTL ? "10px" : 0,
                  fontFamily: isRTL ? "Noto Sans Hebrew" : "DM sans",
                }}
              >
                {t("lets_get")}
              </Typography>
            </Button>
          </Box>

          {/* Right Side: Image Collage */}

          <Box
            sx={{
              display: "flex",
              height: "100%",
              gap: "40px",
            }}
          >
            <Box
              sx={{
                display: maxWidth650px ? "none" : "flex",

                gap: "20px",
                flexDirection: "column",
                height: "100%",
              }}
            >
              {desktopImages.slice(0, 3).map((image, index) => {
                return (
                  <img
                    key={index}
                    src={image.src}
                    alt={image.alt}
                    style={{
                      height: "100%",
                      maxWidth: "239px",
                      width: "100%",
                    }}
                  />
                );
              })}
            </Box>
            <Box
              sx={{
                display: maxWidth870px ? "none" : "flex",
                gap: "20px",
                flexDirection: "column",
                height: "100%",
              }}
            >
              {desktopImages.slice(3, 6).map((image, index) => {
                return (
                  <img
                    key={index}
                    src={image.src}
                    alt={image.alt}
                    style={{
                      height: "100%",
                      maxWidth: "239px",
                      width: "100%",
                    }}
                  />
                );
              })}
            </Box>
          </Box>
        </Box>
      </div>
    </div>
  );
}
