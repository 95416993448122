import { Avatar } from "@mui/material";
import { useSelector } from "react-redux";
import { ScheduleAViewingBtn } from "../layout/ScheduleAViewingBtn";
import { SpeakToAnAgentBtn } from "../buttons/SpeakToAnAgentBtn";
import { useTranslation } from "react-i18next";
import { useLanguageTheme } from "../../../../hooks/useLanguageTheme";

export const AgentInfoBlock = () => {
  const { i18n, t } = useTranslation(["campaignBuilder"]);
  const langTheme = useLanguageTheme();

  const {
    selectedAgent,
    selectedUserId,
    agentAvatarSlice,
    scheduleVewingOpen,
    agentPhotOpen,
  } = useSelector((state: any) => state.campaignBuilder);

  const shouldShowPhoto =
    agentPhotOpen && (selectedAgent?.photo?.url || agentAvatarSlice[0]?.url);

  const agentInitial = selectedAgent?.name
    ? selectedAgent.name.charAt(0).toUpperCase()
    : "";

  return (
    <div
      className="campaign-builder-agent-info-block"
      style={{
        direction: i18n.dir(),
      }}
    >
      <div className="campaign-builder-agent-info-block-name">
        {selectedUserId == "create-new" ? (
          <Avatar
            src={
              shouldShowPhoto
                ? agentAvatarSlice[0]?.url || selectedAgent?.photo?.url
                : null
            }
            sx={{ width: 47, height: 47 }}
          >
            {!shouldShowPhoto && agentInitial}
          </Avatar>
        ) : (
          <Avatar
            src={shouldShowPhoto ? selectedAgent?.photo?.url : null}
            sx={{ width: 47, height: 47 }}
          >
            {!shouldShowPhoto && agentInitial}
          </Avatar>
        )}

        <div
          style={{
            fontFamily: langTheme.mainFont(),
          }}
        >
          <div className="campaign-builder-agent-info-block-agent-name">
            {i18n.language === "he"
              ? selectedAgent?.nameHe
              : selectedAgent?.nameEn}
          </div>
          <div className="campaign-builder-agent-info-block-agent-role">
            {t("agent")}
          </div>
        </div>
      </div>
      <div className="campaign-builder-agent-info-block-btns">
        {scheduleVewingOpen && <ScheduleAViewingBtn />}
        <SpeakToAnAgentBtn />
      </div>
    </div>
  );
};
