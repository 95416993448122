import React, { useMemo } from "react";
import sendIcon from "../../../assets/campaignBuilder/ai-chat/sendImage.svg";
import pinIcon from "../../../assets/campaignBuilder/ai-chat/pin.svg";
import voiceIcon from "../../../assets/campaignBuilder/ai-chat/voice.svg";
import ButtonItem from "./ButtonItem";
import { useTranslation } from "react-i18next";
import { textFieldStyles } from "../content/steps/MainInformationStep";
import { TextField } from "@mui/material";

interface AiFooterProps {
    setPromt: (prompt: string) => void;
    setSubmit: (value: string) => void;
    setApplyBtn: (value: string) => void;
    setOpenFooterTopButton: (value: boolean) => void;
    openFooterTopButton: boolean;
    prompt: string;
    allData?: any;
    applyBtn: any;
    chatArray: any;
    openQuickActions: any;
    currentsection:any;
}

const AiFooter: React.FC<AiFooterProps> = ({
    setPromt,
    setSubmit,
    openFooterTopButton,
    prompt,
    allData,
    setApplyBtn,
    applyBtn,
    chatArray,
    openQuickActions,
    setOpenFooterTopButton,
    currentsection
}) => {
    const { i18n } = useTranslation(["campaignBuilder"]);
    const isRTL = i18n.dir() === "rtl";
    const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter' && !e.shiftKey && prompt?.length > 0) {
            setSubmit('send');
        }
        if (e.key === 'Enter' && e.shiftKey) {
            return; 
        }
    };
    const { t } = useTranslation(["aiChart"]);
    const isInvitationApplicable = useMemo(() => {
        if (allData?.invalidated?.length > 0) {
            return allData?.invalidated?.filter((item: any) => [
                'propertyTitle',
                'propertyDescription',
                'propertyPrice',
                'propertyLocation',
                'aboutPropertyDesc',
                'aboutDeveloper'
            ].map(el => el.toLowerCase()).includes(item.toLowerCase()))?.length > 0;
        }

        return false;
    }, [allData]);

    return (
        <div className="ai_footer">
            {(isInvitationApplicable && openFooterTopButton) ? <ButtonItem
                openFooterTopButton={openFooterTopButton}
                applyBtn={applyBtn}
                setApplyBtn={(value: string) => {
                    setApplyBtn(value.toString());
                    applyBtn === 'Apply' && setOpenFooterTopButton(false)
                }
                }
            /> :   <p className="currentsection_text">{currentsection}</p>}
            {(chatArray.length > 1 && !openQuickActions) && <button className={`quick_btn ${isRTL ? 'left' : 'right'} `} onClick={(e) => {
                setApplyBtn("quick_btn");
            }}>
                {t("quick_actions")}
            </button>
            }
            <div className="footer_item">
                <textarea
                    className="prompt_search"
                    placeholder={t("refine_prompt")}
                    autoComplete="off"
                    value={prompt}
                    onKeyDown={(e: React.KeyboardEvent<HTMLTextAreaElement>) => {
                        if (e.key === 'Enter' && !e.shiftKey && prompt?.length > 0) {
                            setSubmit('send');
                        }
                        if (e.key === 'Enter' && e.shiftKey) {
                            return;
                        }
                    }}
                    onChange={(e) => setPromt(e.target.value)}
                    style={{
                        width: '100%',
                        minHeight: '40px',
                        maxHeight: '120px',
                        border: 'none',
                        outline: 'none',
                        padding: '0px',
                        resize: 'none',
                        overflow: 'auto',
                        scrollbarWidth: 'none',
                        msOverflowStyle: 'none',
                    }}
                />
                <div>
                    <button className="send" onClick={(e) => {
                        e.preventDefault();
                        if (prompt?.length > 0) {
                            setSubmit('send');
                        }
                    }}>
                        <img src={sendIcon} alt="" />
                    </button>
                </div>

            </div>
        </div>
    );
};

export default AiFooter;

