import React from "react";
import HeroImg from "../../../../assets/learningCenter/heroImgLearningCenter.png";
import { useTranslation } from "react-i18next";
import { Theme, Typography, useMediaQuery } from "@mui/material";
import { useLanguageTheme } from "../../../../hooks/useLanguageTheme";

export const ContactUsHeroImg = () => {
  const { i18n, t } = useTranslation("contactUs");
  const downMd = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));
  const isRTL = i18n.dir() === "rtl";
  const langTheme = useLanguageTheme();

  return (
    <div
      className="contact-us-hero-img-wrapper"
      style={{
        direction: i18n.dir(),
        height: downMd ? "375px" : "518px",
        background: `url(${HeroImg}) center center / cover no-repeat`,
        fontFamily: langTheme.mainFont(),
      }}
    >
      <div className="background-shadow">
        <div
          className="contact-us-hero-img-info-wrapper"
          style={{
            gap: downMd ? "5px" : "12px",
            paddingBottom: downMd ? "35px" : "50px",
            width: "100%",
            maxWidth: "1440px",
          }}
        >
          <Typography
            className="contact-us-hero-img-info-wrapper-title"
            variant="h1"
            sx={{
              fontFamily: isRTL ? "Noto Sans Hebrew" : "Frank Ruhl Libre",
              lineHeight: downMd ? "28px" : "46px",
            }}
          >
            {t("contact_us_title")}
          </Typography>
          <div
            className="contact-us-hero-img-info-wrapper-desc"
            style={{
              fontFamily: langTheme.mainFont(),
            }}
          >
            {t("contact_us_desc")}
          </div>
        </div>
      </div>
    </div>
  );
};
