import { useState, useEffect } from "react";
import { useGetUserWithPictureQuery } from "../store/api/authApiSlice";
import { useSelector } from "react-redux";
import {
  setSignupWithGoogleFalse,
  signupWithGoogleSelector,
} from "../store/features/common";
import { useAppDispatch } from "../store/store";

export function useUserProfile() {
  const dispatch = useAppDispatch();

  const [profile, setProfile] = useState(null);
  const jwt = (
    localStorage.getItem("jwt") || sessionStorage.getItem("jwt")
  )?.replace(/"/g, "");
  const userString =
    localStorage.getItem("user") || sessionStorage.getItem("user");
  const user = userString ? JSON.parse(userString) : null;

  const signupWithGoogle = useSelector(signupWithGoogleSelector);
  const {
    data: userWithPicture,
    isLoading,
    error,
    refetch,
  } = useGetUserWithPictureQuery(
    {
      id: user?.id,
      jwt,
    },
    {
      skip: !(jwt && user?.id),
      refetchOnMountOrArgChange: true,
    }
  );

  useEffect(() => {
    if (userWithPicture) {
      setProfile(userWithPicture);
    } else {
      setProfile(null);
    }
  }, [userWithPicture]);

  useEffect(() => {
    if (signupWithGoogle) {
      refetch();
      dispatch(setSignupWithGoogleFalse());
    }
  }, [signupWithGoogle]);

  if (isLoading) return { profile: null, isLoading: true, error: null };
  if (error) return { profile: null, isLoading: false, error: error };
  return { profile, isLoading: false, error: null };
}
