import { Box, Theme, Typography, useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import builder from "../../assets/sellWithUs/builder.png";
import builderHebrew from "../../assets/sellWithUs/builderHe.png";
import crm from "../../assets/sellWithUs/crm.png";
import { useLanguageTheme } from "../../hooks/useLanguageTheme";
import i18n from "../../i18n";
import { useNavigate } from "react-router-dom";

export default function OurTools() {
  const { t } = useTranslation(["sellWithUs"]);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(800));
  const currentLanguage = i18n.language;
  const langTheme = useLanguageTheme();
  const isRTL = currentLanguage === "he";
  const downLg = useMediaQuery((theme: Theme) => theme.breakpoints.down("lg"));
  const navigate = useNavigate();

  const panels = [
    {
      image: isRTL ? builderHebrew : builder,
      title: t("builder"),
      description: t("builder_text"),
    },
    {
      image: crm,
      title: t("crm"),
      description: t("crm_text"),
    },
  ];

  return (
    <Box
      sx={{
        zIndex: 2,
        mx: isMobile ? "20px" : "20px",
        direction: isRTL ? "rtl" : "ltr",
        marginTop: isMobile ? "50px" : "150px",
      }}
    >
      {panels.map((panel, index) => (
        <Box
          key={index}
          sx={{
            bgcolor: "#E8EFF2A1",
            display: "flex",
            mt: isMobile ? "25px" : "50px",
            pt: isMobile ? "15px" : "50px",
            px: isMobile ? "15px" : "28px",
            // pb: isMobile ? '15px' : '50px',
            flexDirection: isMobile
              ? "column"
              : isRTL
              ? index === 0
                ? "row-reverse"
                : "row"
              : index === 0
              ? "row"
              : "row-reverse",
            //   xs: 'column',
            //   sm: isRTL
            //     ? index === 0
            //       ? 'row-reverse'
            //       : 'row'
            //     : index === 0
            //     ? 'row'
            //     : 'row-reverse',
            // }
            position: "relative",
            overflow: "hidden",
            borderRadius: "25px",
            justifyContent: "space-between",
            alignItems: isMobile ? "center" : "center",
          }}
        >
          <Box
            sx={{
              zIndex: 2,
              maxWidth: isMobile ? "100%" : "40%",
              textAlign: isRTL ? "right" : index === 0 ? "start" : "end",
              mb: isMobile ? "20px" : 0,
              pb: isMobile ? "15px" : "50px",
            }}
          >
            <Typography
              sx={{
                color: "#034A73",
                fontFamily: isRTL ? "Noto Sans Hebrew" : "DM Sans",
                fontSize: isMobile ? "16px" : "18px",
                fontWeight: 600,
              }}
            >
              {t("tools")}
            </Typography>
            <Typography
              sx={{
                fontFamily: isRTL ? "Noto Sans Hebrew" : "Frank Ruhl Libre",
                fontSize: isMobile ? "28px" : "36px",
                textTransform: "uppercase",
                fontWeight: 600,
              }}
            >
              {panel.title}
            </Typography>
            <Typography
              sx={{
                fontFamily: isRTL ? "Noto Sans Hebrew" : "DM Sans",
                fontSize: isMobile ? "14px" : "16px",
                fontWeight: 400,
              }}
            >
              {panel.description}
            </Typography>
            <Box
              sx={{
                display: "flex",
                mt: "30px",
                gap: "10px",
                justifyContent: isRTL
                  ? "flex-start"
                  : index === 0
                  ? "flex-start"
                  : "flex-end",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  color: "#fff",
                  background: "linear-gradient(to right, #034A73, #0A7992)",
                  p: "10px 15px",
                  cursor: "pointer",
                  borderRadius: "24px",
                  "&:hover": {
                    background: "linear-gradient(to right, #034A73, #0A7992)",
                  },
                  width: "115px",
                  height: "25px",
                  textAlign: "center",
                }}
                onClick={() => navigate("/contact-us")}
              >
                <Typography
                  sx={{
                    fontFamily: isRTL
                      ? "Noto Sans Hebrew"
                      : langTheme.mainFont(),
                    fontWeight: isRTL ? 400 : 500,
                    lineHeight: "16px",
                    fontSize: downLg ? "14px" : "16px",
                  }}
                >
                  {t("try_now")}
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              position: "relative",
              display: "flex",
              maxWidth: isMobile ? "100%" : "60%",
              justifyContent: isMobile ? "center" : "flex-start",
              alignSelf: { sm: "center", md: "flex-end" },
            }}
          >
            <Box
              sx={{
                bgcolor: "#FCBC5C",
                width: isMobile ? "60%" : "70%",
                height: { xs: "120%", md: "140%", xl: "140%" },
                transform: `rotate(${index === 0 ? "-9deg" : "9deg"})`,
                borderRadius: "10%",
                position: "absolute",
                zIndex: 1,
                left: isMobile
                  ? isRTL
                    ? index === 0
                      ? "25%"
                      : "14%"
                    : index === 0
                    ? "25%"
                    : "14%"
                  : index === 0
                  ? "28%"
                  : "0%",
                // right: index === 1 ? '30%' : isMobile ? '10%' : 0,
                right: isRTL ? (isMobile ? (index === 0 ? "" : "") : "") : "",
                top: isMobile ? 8 : { md: 10, lg: 20 },
                overflow: "hidden",
              }}
            />
            <Box
              sx={{
                maxHeight: isMobile ? "200px" : "350",
                zIndex: 2,
                padding: 0,
                m: 0,
                width: isMobile ? "70%" : "100%",
                left: isMobile ? 0 : isRTL ? 10 : 70,
                justifyContent: isRTL
                  ? index === 0
                    ? "flex-start"
                    : "flex-end"
                  : index === 0
                  ? "flex-end"
                  : "flex-start",
                display: "flex",
              }}
            >
              <img
                src={panel.image}
                alt={panel.title}
                style={{
                  width: isMobile ? "120%" : "90%",
                  overflow: "hidden",
                  height: isMobile ? "120%" : "110%",
                  position: "relative",
                }}
              />
            </Box>
          </Box>
        </Box>
      ))}
    </Box>
  );
}
