import { RootState } from "../store";
import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AuthPopupContentType } from "../../components/auth/types";
import { ForgotPasswordStep } from "../../pages/ForgotPassword";

interface ISideNavState {
  sideNavOpen: boolean;
  authPopupOpen: boolean;
  campaignBuilderSideBarOpen: boolean;
  authPopupContentType: AuthPopupContentType | null;
  forgotPasswordStep: ForgotPasswordStep;
  signupWithGoogle: boolean;
}

const initialState: ISideNavState = {
  sideNavOpen: false,
  authPopupOpen: false,
  campaignBuilderSideBarOpen: true,
  authPopupContentType: null,
  forgotPasswordStep: ForgotPasswordStep.enterEmail,
  signupWithGoogle: false,
};

export const sideNavOpenSlice = createSlice({
  name: "sideNav",
  initialState,
  reducers: {
    setOpenSideNav(state) {
      state.sideNavOpen = true;
    },
    setCloseSideNav(state) {
      state.sideNavOpen = false;
    },
    setAuthPopupOpen(state, action: PayloadAction<AuthPopupContentType>) {
      state.authPopupOpen = true;
      state.authPopupContentType = action.payload;
    },
    setAuthPopupClose(state) {
      state.authPopupOpen = false;
      state.authPopupContentType = null;
    },
    setCampaingBuilderSideBarOpen(state) {
      state.campaignBuilderSideBarOpen = true;
    },
    setCampaingBuilderSideBarClose(state) {
      state.campaignBuilderSideBarOpen = false;
    },
    setForgotPasswordStep(state, action) {
      state.forgotPasswordStep = action.payload;
    },
    setSignupWithGoogle(state) {
      state.signupWithGoogle = true;
    },
    setSignupWithGoogleFalse(state) {
      state.signupWithGoogle = false;
    },
  },
});

const sideNavOpenState = (state: RootState) => state.sideNav;

export const sideNavOpenSelector = createSelector(
  sideNavOpenState,
  (s) => s.sideNavOpen
);

export const authPopupOpenSelector = createSelector(
  sideNavOpenState,
  (s) => s.authPopupOpen
);

export const campaignBuilderOpenSelector = createSelector(
  sideNavOpenState,
  (s) => s.campaignBuilderSideBarOpen
);

export const authPopupContentTypeSelector = createSelector(
  sideNavOpenState,
  (s) => s.authPopupContentType
);

export const forgotPasswordStepSelector = createSelector(
  sideNavOpenState,
  (s) => s.forgotPasswordStep
);

export const signupWithGoogleSelector = createSelector(
  sideNavOpenState,
  (s) => s.authPopupContentType
);

export const {
  setOpenSideNav,
  setCloseSideNav,
  setAuthPopupOpen,
  setAuthPopupClose,
  setCampaingBuilderSideBarOpen,
  setCampaingBuilderSideBarClose,
  setForgotPasswordStep,
  setSignupWithGoogle,
  setSignupWithGoogleFalse,
} = sideNavOpenSlice.actions;

export default sideNavOpenSlice.reducer;
