import React from "react";
import { useTranslation } from "react-i18next";
import { useLanguageTheme } from "../../../../hooks/useLanguageTheme";

export const PortfolioFilterItemWrapper = ({
  children,
  name,
}: {
  children: React.ReactNode;
  name: string;
}) => {
  const { t, i18n } = useTranslation(["portfolio"]);
  const isRTL = i18n.dir() === "rtl";
  const langTheme = useLanguageTheme();

  return (
    <div className="potfolio-filter-container">
      <div
        className="porfolio-filter-name"
        style={{
          fontFamily: langTheme.mainFont(),
          fontWeight: isRTL ? 400 : 500,
        }}
      >
        {t(name)}
      </div>
      {children}
    </div>
  );
};
