import { useState, useEffect } from "react";
import // useCreateUserWishlistMutation,
// useUpdateUserWishlistMutation,
"../store/api/authApiSlice";
import { IInitialProperty } from "../types/initialProperty";
import { useGetUserWishlistQuery } from "../store/api/wishlistApiSlice";

export function useWishlistProperties() {
  const [properties, setProperties] = useState<IInitialProperty[]>([]);
  const [propertyIds, setPropertyIds] = useState<number[]>([]);
  // const [createWishlist] = useCreateUserWishlistMutation();
  // const [updateWishlist] = useUpdateUserWishlistMutation();
  const jwt = (
    localStorage.getItem("jwt") || sessionStorage.getItem("jwt")
  )?.replace(/"/g, "");
  const userString =
    localStorage.getItem("user") || sessionStorage.getItem("user");
  const user = userString ? JSON.parse(userString) : null;

  // const {
  //   data: wishlist,
  //   isLoading,
  //   error,
  // } = useGetUserWishlistQuery(
  //   {
  //     userId: user?.id,
  //     jwt,
  //   },
  //   {
  //     skip: !(jwt && user?.id),
  //   }
  // );

  const wishlist = {
    data: {
      properties: [
        {
          id: 11252,
          documentId: "yuqulqhd0lz88xgmsyiwt8lz",
          name: "",
          description: "",
          propertyType: "penthouse",
          propertyStatus: "under_construction",
          pricing: {
            currency: "nis",
            basePrice: "0",
          },
          details: {
            galleryOpen: true,
            agentPhotOpen: true,
            mainLabelOpen: true,
            mapSectionOpen: true,
            videoSectionOpen: true,
            scheduleVewingOpen: true,
            floorPlanSectionOpen: true,
          },
          location: {
            id: "",
            city: "",
            address: "",
            coordinates: {
              latitude: null,
              longitude: null,
            },
          },
          createdAt: "2025-03-10T14:28:21.384Z",
          updatedAt: "2025-03-10T14:28:51.140Z",
          publishedAt: "2025-03-10T14:28:51.128Z",
          createdById: null,
          updatedById: null,
          locale: "en",
          labels: {
            id: "3",
            icon_key: "alarm",
            labelName: "alarm",
            backgroundColor: "#034A73",
          },
          developer: "",
          campaignCloseDate: "2025-06-10",
          campaignArchiveDate: null,
          campaignStatus: "draft",
          aboutProperty: "",
          mediaContent: [],
        },
      ],
    },
  };

  // useEffect(() => {
  //   if (wishlist?.data) {
  //     const fetchedProperties = wishlist?.data?.properties;
  //     setProperties(fetchedProperties);
  //     setPropertyIds(
  //       fetchedProperties?.map((property: IInitialProperty) => property?.id)
  //     );
  //   }
  // }, [wishlist]);

  function checkIsPropertyInWishlist(propertyId: number) {
    return propertyIds.includes(propertyId);
  }

  async function togglePropertyInWishlist(propertyId: number) {
    try {
      if (!user.id || !jwt) {
        console.error("User not logged in");
        return;
      }
      let updatedProperties: number[] = [];

      // if (!wishlist?.data?.id) {
      //   updatedProperties = [propertyId];
      //   const data = {
      //     data: {
      //       users_permissions_user: [user.id],
      //       properties: updatedProperties,
      //     },
      //   };
      //   await createWishlist({ wishlistData: data, jwt });
      //   setPropertyIds(() => updatedProperties);
      // } else {
      //   updatedProperties = checkIsPropertyInWishlist(propertyId)
      //     ? propertyIds.filter((id) => id !== propertyId)
      //     : [...propertyIds, propertyId];
      //   const data = {
      //     data: {
      //       properties: updatedProperties,
      //     },
      //   };
      //   await updateWishlist({
      //     jwt,
      //     wishlistId: wishlist.data.documentId,
      //     updatedData: data,
      //   });
      // }
      setPropertyIds(() => updatedProperties);
      setProperties(
        updatedProperties
          .map((id) => properties.find((p) => p.id === id))
          .filter((property) => property !== undefined) as IInitialProperty[]
      );
    } catch (err) {
      console.error("Error updating wishlist:", err);
    }
  }

  async function deletePropertiesFromWishlist(leftPropertiesIds: number[]) {
    try {
      if (!user.id || !jwt) {
        console.error("User not logged in");
        return;
      }
      const data = {
        data: {
          properties: leftPropertiesIds,
        },
      };
      // await updateWishlist({
      //   jwt,
      //   wishlistId: wishlist.data.documentId,
      //   updatedData: data,
      // });
      setPropertyIds(() => leftPropertiesIds);
      setProperties(
        leftPropertiesIds
          .map((id) => properties.find((p) => p.id === id))
          .filter((property) => property !== undefined) as IInitialProperty[]
      );
    } catch (err) {
      console.error("Error updating wishlist:", err);
    }
  }

  // if (isLoading) {
  //   return { wishlistProperties: null, isLoading: true, error: null };
  // }
  // if (error) {
  //   return { wishlistProperties: null, isLoading: false, error: error };
  // }

  return {
    properties,
    wishlist,
    isLoading: false,
    error: null,
    checkIsPropertyInWishlist,
    togglePropertyInWishlist,
    deletePropertiesFromWishlist,
  };
}
