import React from "react";
import { useLanguageTheme } from "../../hooks/useLanguageTheme";

export const TermsContent = () => {
  const langTheme = useLanguageTheme();

  return (
    <div
      style={{
        padding: "50px 20px",
        fontFamily: langTheme.mainFont(),
      }}
    >
      <div>
        Lorem, ipsum dolor sit amet consectetur adipisicing elit. Omnis
        consequuntur reprehenderit deleniti animi amet, sapiente, optio, fugit
        aspernatur nemo explicabo veritatis neque laboriosam molestias vitae
        consectetur quaerat tenetur reiciendis quas provident iusto quia qui
        fugiat tempore quam. Accusamus iste esse, sit a sed dolorum, natus
        quaerat consequuntur sapiente tempora neque ratione commodi voluptatem
        exercitationem? Cumque at similique possimus cum. Amet inventore
        exercitationem at ipsum, fuga commodi veritatis illo similique
        architecto. Maxime ipsum facilis, assumenda aliquid corporis ab
        asperiores nostrum reiciendis sunt eos laudantium unde reprehenderit,
        qui dignissimos, sapiente beatae sequi impedit tempora omnis at
        exercitationem error. Debitis ea ad a pariatur libero aperiam
        aspernatur, nemo magnam consequatur tempora, harum eaque necessitatibus
        beatae odit reiciendis deleniti laborum veniam illum dolores cupiditate
        numquam similique? Minima sint eaque eius odio, corrupti cum perferendis
        nihil voluptates harum alias nisi, placeat qui. Vel perspiciatis
        repudiandae quasi soluta, earum eum facilis corrupti, et tenetur nisi,
        exercitationem sit error unde quas molestiae sapiente maiores eius.
        Nemo, maiores asperiores assumenda, aspernatur animi impedit dolores
        inventore excepturi itaque possimus harum vel amet voluptatum ducimus
        adipisci in, error dolor laudantium tempora laborum nobis veritatis
        ipsum? Doloribus nulla ipsa esse quis in beatae dolore assumenda iste
        qui nihil omnis enim ratione minus, id ex, voluptatem voluptatibus
        quisquam? Doloremque accusantium, tempora, impedit voluptatibus expedita
        consequuntur temporibus, dolor delectus dolore alias ea officia. Quo
        fuga exercitationem autem provident praesentium voluptates sit quod
        placeat dignissimos, odit laboriosam, qui, amet et quidem nobis?
        Suscipit nemo voluptatibus sit at? Consequuntur magnam odit beatae dicta
        eum impedit, adipisci vitae, accusamus, perspiciatis assumenda odio
        necessitatibus quasi corporis culpa ullam. Debitis consequuntur ut
        molestias quaerat consectetur maiores eaque repudiandae, aut rerum iusto
        provident error nisi excepturi dolore veniam culpa saepe quas. Ad,
        optio, fugit ipsam saepe quos tenetur cum dicta, omnis maxime
        repellendus consequatur minus ducimus. Nostrum quisquam dignissimos
        soluta! Quos a aliquam dolorem libero hic dolorum vero. Officia ut
        eveniet ducimus veritatis quibusdam vitae quisquam eius vel iste,
        tenetur minus nostrum recusandae harum. Iure eum ipsum quidem officia
        nulla adipisci architecto saepe veritatis eos possimus aspernatur,
        voluptates dolor aliquam maiores vitae, ex, nemo magnam. Placeat
        adipisci facilis omnis amet laudantium deleniti. Neque, exercitationem
        nulla praesentium eos nostrum, optio, natus minus rerum nihil nam ad?
        Voluptas magni veritatis nostrum accusantium vel asperiores natus.
        Molestiae numquam veniam quibusdam alias porro corrupti cum, neque
        architecto iure consequuntur consequatur aspernatur fugit quisquam
        perferendis, dignissimos ex? Recusandae, odit repudiandae consequatur
        nisi molestiae nihil, fuga, excepturi veniam dolorem asperiores expedita
        maiores quaerat. Quas amet suscipit impedit? Mollitia dicta facilis vero
        quas dolorem accusamus eveniet veritatis, est placeat quam reprehenderit
        beatae nam dolore voluptatibus labore exercitationem itaque. Fuga
        adipisci, aliquid repellendus quidem modi facere possimus similique
        rerum enim doloribus et odio esse earum fugit ratione provident,
        necessitatibus a maiores animi sint nemo? Quia totam repudiandae cumque!
        Omnis distinctio numquam veritatis sint unde minima eos ad veniam,
        ullam, repellendus sequi minus dolorem obcaecati praesentium! Temporibus
        ipsam officiis similique facere et recusandae deserunt hic,
        reprehenderit quia iste quo doloribus in voluptates animi molestiae
        tempora iure blanditiis tempore!
      </div>
      <br />
      <div>
        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Accusamus
        repellendus, modi dolore voluptates quas dignissimos voluptatum magnam
        fugiat alias ipsum odio molestiae magni natus? Fugit inventore ipsam,
        eligendi repellat consectetur cum maiores earum sint optio itaque in
        consequuntur quia natus nemo veniam repellendus? Ipsa corrupti quo
        eveniet, expedita a ipsum numquam soluta, similique esse voluptatum
        tempora modi nesciunt commodi pariatur iste sapiente tempore dignissimos
        aperiam mollitia voluptates, quos maiores! Tenetur voluptatem, adipisci
        praesentium tempore voluptates suscipit nostrum, quo corrupti illo, fuga
        nam architecto. Est beatae consectetur dignissimos cum obcaecati
        mollitia possimus quia corporis vero dolorem, quasi aliquid, ducimus
        quas ut quisquam vitae natus explicabo. Vitae inventore, magnam tempora
        iusto sit alias quasi saepe quaerat nobis ipsum quisquam modi expedita
        facere quam, optio nisi? Quod porro aliquam labore neque, cum magnam
        commodi accusamus officia deserunt, eaque maxime velit voluptate
        doloremque maiores dicta eos omnis adipisci, magni impedit dolorum
        ducimus voluptates soluta. Ad, maiores incidunt molestiae eum impedit
        alias enim neque repellendus distinctio eos adipisci quisquam et nisi
        voluptas sit soluta odit eius ab laborum iure omnis libero repellat
        ratione. Ipsum a soluta nostrum totam, facilis doloribus beatae
        accusamus minus pariatur est sunt placeat repellendus asperiores sequi
        velit laudantium illo aperiam esse recusandae quam ab tenetur harum
        corporis ipsam. Eligendi mollitia, at totam facere fugiat error? Facere
        vero, et totam modi odit iure nulla, rerum adipisci neque quas
        voluptate, tempore corrupti sequi error eaque praesentium omnis dicta
        laudantium deserunt? Voluptatem, magni eligendi. Voluptas hic
        repudiandae id provident, vel iusto aliquam veniam illo saepe! Sapiente
        ab animi excepturi error nobis. Autem voluptas eum pariatur voluptatum
        quas deleniti unde possimus laboriosam expedita suscipit esse rerum
        architecto quisquam consequatur adipisci, odio quidem aliquid quibusdam,
        facilis distinctio. Sed, omnis alias repellendus temporibus quisquam
        quaerat doloremque ad sunt debitis eius id iste quam deleniti corrupti
        excepturi tempore, atque animi dolorum earum, nostrum consequuntur?
        Porro temporibus perferendis, tempore optio dolore at, repellendus
        dolorem eligendi laboriosam expedita hic modi sapiente deserunt id
        dignissimos? Nam tenetur atque nisi adipisci ut natus incidunt
        exercitationem nemo fugit quae assumenda officia, vel soluta consectetur
        placeat tempora non aliquam qui nobis labore architecto dolores eveniet,
        hic magnam? Similique alias minima in adipisci ratione, distinctio
        voluptatem impedit quis voluptatibus voluptatum nulla blanditiis dolorem
        pariatur ab a obcaecati reiciendis ex sapiente quibusdam. Minus
        suscipit, corporis optio quidem expedita aspernatur impedit recusandae
        illo, a dolore eligendi! Voluptate ut dignissimos dolore voluptates
        minus illo libero? Iusto, rem quis.
      </div>
      <br />

      <div>
        Lorem ipsum dolor sit amet consectetur, adipisicing elit. Ipsum debitis
        aut eaque nulla eos necessitatibus nisi facere. Libero, perferendis.
        Aliquid laudantium illum explicabo quas, magnam fuga minus corporis aut,
        laborum temporibus rem atque. Illo magni provident quam ea debitis in
        repellendus eligendi eos nemo animi consectetur at alias voluptate,
        aliquid voluptas. Dolores quo ducimus voluptatum nobis, obcaecati iusto,
        molestias in omnis, tempora eaque consequuntur! Adipisci aspernatur
        veniam molestiae fugiat non maiores, voluptatem sint maxime possimus
        fugit nisi voluptate vero quas quisquam ullam ratione illum numquam!
        Tenetur iusto quod eveniet nihil neque officia perferendis enim minima
        minus impedit. Vel libero laborum qui commodi ex, nesciunt maxime
        deserunt error et nostrum. Eveniet amet laudantium magnam repudiandae
        delectus, animi nam voluptas officia corporis hic odio sed. Qui neque
        vero, ullam tenetur ab aperiam nam ipsa maiores sapiente iure voluptas,
        hic quam id delectus praesentium tempore fuga impedit, dolorem adipisci
        consequuntur obcaecati libero itaque laborum provident? Cupiditate dicta
        alias, amet accusamus tempora cum rerum voluptas accusantium? Minima
        veniam officia id impedit. Dignissimos veritatis architecto non quae
        error iste dicta placeat corporis at nobis, asperiores fugit dolores
        facilis ratione quam exercitationem soluta nostrum minus laboriosam?
        Ipsam, mollitia natus. Recusandae et, rem repudiandae doloremque at
        quaerat ducimus explicabo dicta harum mollitia obcaecati rerum,
        laudantium vero velit autem iure error, fugit possimus? Eos eligendi
        dolorum aspernatur minus a illo facere voluptatem consequuntur
        laboriosam! Ipsum quas magni vero officiis ducimus? Esse, sint? Rem,
        dolores sequi similique iste debitis vero fugiat qui beatae magni unde
        doloribus odit illo error at quo minus nihil eum quisquam. Enim nesciunt
        voluptate commodi vel modi ullam sequi rerum ex saepe. Aliquid ea
        reiciendis repellat cupiditate totam labore accusantium, exercitationem
        autem animi? Quam quia, alias pariatur quaerat sequi, beatae eius libero
        repellat voluptates, voluptas quos illum? Quam ducimus a quia impedit
        voluptate quibusdam, officiis ipsum repudiandae ipsa similique, non
        eligendi molestiae nihil nobis aut adipisci beatae cumque quos,
        reprehenderit voluptates veritatis accusamus delectus alias! Architecto
        temporibus necessitatibus quis amet recusandae? Eligendi autem deserunt
        ea ipsum aperiam est explicabo atque accusantium laboriosam eum impedit
        aut repudiandae natus amet cum fugit, eaque sint incidunt at suscipit
        esse corporis itaque odit. Provident maxime ab totam soluta quo itaque.
        Saepe velit quia suscipit itaque tenetur quas. Id similique dolorem
        voluptatibus cupiditate facere quas, qui aperiam voluptatem alias
        obcaecati explicabo eaque labore, quia perspiciatis tenetur excepturi
        non! At ut cum accusantium quo quasi, repudiandae fugiat blanditiis,
        voluptatibus maxime cumque sint pariatur consectetur temporibus amet hic
        qui reprehenderit maiores sit. Quis quia illo blanditiis ipsa
        praesentium ullam dignissimos ex a officiis voluptas quasi nihil placeat
        obcaecati, modi rem quam non consequuntur. Fugiat, esse, excepturi
        ducimus eos vitae quibusdam illo corporis voluptas aperiam doloremque
        quisquam pariatur natus voluptatem aut accusamus earum minus at est
        facere quis molestiae libero consectetur ut fuga? Iure consequatur cum
        expedita assumenda, porro sequi perspiciatis! Et ipsa porro iste
        laboriosam quo illo ad alias nisi distinctio dolorem architecto quam
        accusantium adipisci aspernatur atque ipsam quos quod delectus magnam,
        numquam dolorum dolore! Optio obcaecati aperiam nesciunt ad expedita?
      </div>

      <br />

      <div>
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Quibusdam
        aperiam aut exercitationem fuga ullam ea enim doloremque perspiciatis
        sint, quis optio similique sunt aliquam magni et obcaecati, nobis ipsam
        nam dolores laborum. Fugiat corporis rerum sequi, asperiores officiis
        nam ipsum nisi illo quaerat delectus. Et veniam praesentium, nemo
        dignissimos, voluptatum voluptate earum tempore minus aperiam molestias
        alias minima fuga suscipit corrupti reiciendis eveniet sapiente dolores
        officia. Reprehenderit natus architecto molestias optio accusantium
        minus quidem, odio ab laborum nobis aliquam nostrum consectetur autem
        quis amet, labore blanditiis tenetur? Ipsum aliquid, adipisci enim
        accusantium perferendis error ea nihil soluta veniam quisquam nulla,
        sed, reiciendis sit! Voluptatibus, neque praesentium molestiae nisi,
        reiciendis nesciunt qui repellendus, possimus eaque aut sapiente rem ut
        ipsa dolore! Repellendus, provident accusantium? Quo doloribus itaque
        quibusdam dicta a facere. Incidunt iusto non repellendus, quae
        recusandae, alias porro maiores nesciunt ratione qui sed fuga, fugiat
        quisquam. Reprehenderit nam quod dignissimos, minus culpa alias illo
        soluta debitis dolores distinctio aliquam ipsa excepturi adipisci, eius
        iste, quaerat omnis accusamus accusantium nostrum exercitationem?
        Accusantium aut minima in nisi vel deserunt assumenda error quidem minus
        accusamus, sunt sit corrupti sequi? Ea debitis sint dolorem, fugit
        corrupti, natus velit ipsa magni provident sequi laboriosam sit?
      </div>
    </div>
  );
};
