import React, { useState } from 'react';
import { Typography, Box, Button, Stack, Divider } from '@mui/material';
import { formatPrice } from '../../helpers/dataConverts';
import { useTranslation } from 'react-i18next';
import { useLanguageTheme } from '../../hooks/useLanguageTheme';
import convertDetails from '../../helpers/convertDetails';
import CheckBox from '../../assets/icons/check_white.svg';

const PropertyCard = ({
  property,
  isListLayout,
  onRemoveProperty,
  isMobile,
  isSelected,
}: {
  property: any;
  isListLayout: boolean;
  onRemoveProperty: (propertyId: number) => void;
  isMobile: boolean;
  isSelected: boolean;
}) => {
  const { t, i18n } = useTranslation(['profile']);
  const isRTL = i18n.dir() === 'rtl';
  const langTheme = useLanguageTheme();
  const propertyDetails = convertDetails(property);
  const [isOpenCheckbox, setIsOpenCheckbox] = useState(true);
  let mainImage;
  for (let media of property.mediaContent) {
    if (media?.formats?.large?.url) {
      mainImage = media.formats.large.url;
      break;
    } else {
      mainImage = media?.url
    }
  }
  return isListLayout && !isMobile ? (
    <>
      {isSelected && (
        <Box
          sx={{
            width: '20px',
            height: '20px',
            border: '1px solid #E1E4F1',
            borderRadius: '5px',
            alignSelf: 'center',
            background: isSelected ? '#034A73' : '#F6F7FF',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {isSelected && <img src={CheckBox} alt="checked"></img>}
        </Box>
      )}
      <Box
        sx={{
          display: 'flex',
          flexDirection: isRTL ? 'row-reverse' : 'row',
          justifyContent: 'space-between',
          gap: 0,
          alignItems: 'center',
          width: '100%',
          heigth: '100%',
        }}
      >
        <Box
          id="left"
          sx={{
            width: '60%',
            gap: '10px',
            display: 'flex',
            flexDirection: isRTL ? 'row-reverse' : 'row',
            justifyContent: 'flex-start',
          }}
        >
          <Box
            id="image"
            sx={{
              position: 'relative',
              maxHeight: '250px',
              maxWidth: '300px',
            }}
          >
            <img
              src={mainImage ? mainImage : ''}
              alt=""
              width={'90%'}
              height="95%"
              style={{
                borderRadius: 20,
              }}
            />
            {/* <PropertyCardStamp inWishlist={true} /> */}
            {/* <div
              style={{ position: 'absolute', top: 5, right: 45 }}
              onClick={() => onRemoveProperty(property.id)}
            >
              <Like propertyId={property.id} />
            </div> */}
          </Box>

          <Box
            id="text"
            sx={{
              px: 1,
              width: '50%', //{ md: 300, sm: 200, xs: 100 },
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
            }}
          >
            <Box>
              <Typography
                variant="body1"
                fontWeight={500}
                gutterBottom
                sx={{ color: '#D7A148', fontFamily: 'DM Sans' }}
              >
                {t(property.propertyType)}
              </Typography>
              <Typography
                variant="body1"
                fontWeight={500}
                sx={{ mb: 1, fontFamily: 'DM Sans' }}
                color="#2B3674"
              >
                {property?.name}
              </Typography>
              <Typography variant="body2" color="#828BA7" sx={{ mb: 2 }}>
                {property?.location?.address}, {property?.location?.city}
              </Typography>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                sx={{ mb: 2 }}
              >
                <Typography
                  variant="h6"
                  sx={{ fontFamily: 'DM Sans', color: '#2B3674' }}
                >
                  ${formatPrice(property?.pricing?.basePrice)}
                </Typography>
              </Stack>
            </Box>

            <Box
              id="amenities"
              sx={{
                alignSelf: 'flex-start',
                height: '60px',
              }}
            >
              <Stack
                direction="row"
                spacing={1}
                sx={{
                  flexWrap: 'no-wrap',
                  justifyContent: 'flex-start',
                  mb: 2,
                }}
              >
                {Object.entries(propertyDetails).map(([key, detail]) => (
                  <div
                    key={key}
                    className="property-card-detail"
                    style={{
                      fontFamily: langTheme.mainFont(),
                      marginLeft: '3px',
                      columnGap: '3px',
                    }}
                  >
                    <div>{detail?.icon}</div>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '3px',
                      }}
                    >
                      <div>{detail?.quantity}</div>
                      <div>{t(detail.name)}</div>
                    </div>
                  </div>
                ))}
              </Stack>
            </Box>
          </Box>
        </Box>
        <Box
          id="buttons"
          sx={{
            width: '30%',
            // height: 'auto',
            display: 'flex',
            flexDirection: 'column',
            gap: '10px',
            justifyContent: 'center',
            alignItems: 'center',
            ml: '5px',
          }}
        >
          <Button
            fullWidth
            variant="contained"
            size="large"
            sx={{
              textTransform: 'none',
              fontWeight: 600,
              color: '#fff',
              '&:hover': { backgroundColor: '#023952' },
              borderRadius: '24px',
              px: 3,
              py: 1.5,
              background: 'linear-gradient(180deg, #034A73 0%, #497690 100%)',
              boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
            }}
          >
            {t('scheduleViewing')}
          </Button>
          <Button
            fullWidth
            variant="outlined"
            size="large"
            sx={{
              textTransform: 'none',
              fontWeight: 600,
              borderColor: '#034A73',
              color: '#034A73',
              '&:hover': { borderColor: '#023952' },
              borderRadius: '24px',
              px: 3,
              py: 1.5,
            }}
          >
            {t('speakToAgent')}
          </Button>
        </Box>
      </Box>
      <Divider sx={{ mt: 2 }} />
    </>
  ) : (
    <Box
      sx={{
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column',
        direction: isRTL ? 'rtl' : 'ltr',
        border: isSelected ? '3px solid #D7A148' : '',
        borderRadius: isSelected ? '23px' : '',
        height: '100%',
      }}
    >
      <Box
        sx={{
          position: 'relative',
          width: '100%',
        }}
      >
        <img
          src={mainImage ? mainImage : ''}
          alt=""
          width="100%"
          height="250px"
          style={{
            borderRadius: 20,
          }}
        />
        {/* <PropertyCardStamp inWishlist={true} /> */}
        {/* <div
          style={{ position: 'absolute', top: 5, right: 5 }}
          onClick={() => onRemoveProperty(property.id)}
        >
          <Like propertyId={property.id} />
        </div> */}
      </Box>
      <Box
        sx={{
          px: 1,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
      >
        <Box
          sx={{
            height: '100%',
          }}
        >
          <Typography
            variant="body1"
            fontWeight={500}
            gutterBottom
            sx={{ color: '#D7A148', fontFamily: 'DM Sans' }}
          >
            {t(property.propertyType)}
          </Typography>
          <Typography
            variant="body1"
            fontWeight={500}
            sx={{ mb: 1, fontFamily: 'DM Sans' }}
            color="#2B3674"
          >
            {property?.name}
          </Typography>
          <Typography variant="body2" color="#828BA7" sx={{ mb: 2 }}>
            {property?.location?.address}, {property?.location?.city}
          </Typography>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{ mb: 2 }}
          >
            <Typography
              variant="h6"
              sx={{ fontFamily: 'DM Sans', color: '#2B3674' }}
            >
              ${formatPrice(property?.pricing?.basePrice)}
            </Typography>
          </Stack>
        </Box>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Box
            id="amenities"
            sx={{
              alignSelf: 'flex-start',
              height: { lg: '100px', xl: '60px' },
              mb: { lg: '20px', xl: '0' },
            }}
          >
            <Stack
              direction="row"
              spacing={1}
              sx={{
                flexWrap: 'wrap',
                justifyContent: 'flex-start',
                mb: 2,
              }}
            >
              {Object.entries(propertyDetails).map(([key, detail]) => (
                <div
                  key={key}
                  className="property-card-detail"
                  style={{
                    fontFamily: langTheme.mainFont(),
                    marginLeft: '3px',
                    columnGap: '3px',
                  }}
                >
                  <div>{detail?.icon}</div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: '3px',
                    }}
                  >
                    <div>{detail?.quantity}</div>
                    <div>{t(detail.name)}</div>
                  </div>
                </div>
              ))}
            </Stack>
          </Box>
          <Box
            id="buttons"
            sx={{
              // mt: 2,
              width: '100%',
            }}
          >
            <Stack
              direction={'column'}
              spacing={1}
              width="100%"
              sx={{
                justifyContent: 'flex-start',
                mt: '5px',
              }}
            >
              <Button
                fullWidth
                variant="contained"
                size="large"
                sx={{
                  width: '100%',
                  height: '40%',
                  textTransform: 'none',
                  fontWeight: 600,
                  color: '#fff',
                  '&:hover': { backgroundColor: '#023952' },
                  borderRadius: '24px',
                  px: 3,
                  py: 1.5,
                  background:
                    'linear-gradient(180deg, #034A73 0%, #497690 100%)',
                  boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
                }}
              >
                {t('scheduleViewing')}
              </Button>
              <Button
                fullWidth
                variant="outlined"
                size="large"
                sx={{
                  width: '100%',
                  height: '40%',
                  textTransform: 'none',
                  fontWeight: 600,
                  borderColor: '#034A73',
                  color: '#034A73',
                  '&:hover': { borderColor: '#023952' },
                  borderRadius: '24px',
                  px: 3,
                  py: 1.5,
                }}
              >
                {t('speakToAgent')}
              </Button>
            </Stack>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default PropertyCard;
