import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { setFilterSideBarClose } from "../../store/features/portfolioSlice";
import { useAppDispatch } from "../../store/store";
import { FilterSideBarSelect } from "./FilterSideBarSelect";

import {
  campaignStatusOptions,
  propertyByUserOptions,
  statusFilterMap,
  typeFilterMap,
} from "../../constants/propertyData";
import { useLanguageTheme } from "../../hooks/useLanguageTheme";
import { OneGoogleLocationSearch } from "../_shared/OneGoogleLocationSearch";
import { PriceRangeSlider } from "../_shared/PriceRangeSlider";
import { CampaignStatusSelect } from "../portfolio/content/filters/CampaignStatusSelect";
import { BelongToUserSelect } from "../portfolio/content/filters/BelongToUserSelect";
import { IFilter, IFilterParams, ILocationFilter, IPriceRange } from "../portfolio/interfaces";

const clearIconStyles = {
  top: "29%",
  right: "10px",
};

const mobileSortStyles = {
  width: "100%",
  backgroundColor: "white",
  border: "1px solid rgba(224, 229, 242, 1)",
  height: "auto",
  borderRadius: "16px",
  fontSize: "14px",
  padding: "13.5px 20px",
};

export const BuyTabPanelContent = ({
  filters,
  onChangeFilters,
  setSearchValue,
  priceRangeValue,
  setPriceRangeValue,
  locationValues,
  setLocationValues,
  statusValues,
  setStatusValues,
  typeValues,
  setTypeValues,
  initCampaingStatus,
  onCampaingChange,
  initUserId,
  onUserIdChange,
  showPortfolioFilters,
}: {
  filters: IFilterParams;
  onChangeFilters: any;
  setSearchValue: React.Dispatch<React.SetStateAction<string>>;
  priceRangeValue: IPriceRange;
  setPriceRangeValue: React.Dispatch<React.SetStateAction<IPriceRange>>;
  locationValues: ILocationFilter[];
  setLocationValues: React.Dispatch<React.SetStateAction<ILocationFilter[]>>;
  statusValues: IFilter[];
  setStatusValues: React.Dispatch<React.SetStateAction<IFilter[]>>;
  typeValues: IFilter[];
  setTypeValues: React.Dispatch<React.SetStateAction<IFilter[]>>;
  initCampaingStatus: any;
  onCampaingChange: any;
  initUserId: any;
  onUserIdChange: any;
  showPortfolioFilters: boolean;
}) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation(["home"]);
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const isRTL = currentLanguage === "he";
  const langTheme = useLanguageTheme();

  const submitFilters = useCallback(() => {
    onChangeFilters({
      locations: locationValues,
      statuses: statusValues,
      types: typeValues,
      priceRange: priceRangeValue,
    });
    dispatch(setFilterSideBarClose());
  }, [locationValues, statusValues, typeValues, priceRangeValue]);

  return (
    <div className="filter-side-bar-filters-container">
      <div>
        <span
          className="filter-side-bar-filters-title"
          style={{
            fontFamily: langTheme.mainFont(),
          }}
        >
          {t("location")}
        </span>

        <OneGoogleLocationSearch
          selectedLocation={locationValues}
          onChange={setLocationValues}
          className={"mobile-google-search-input"}
          clearIcon={true}
          clearIconStyles={clearIconStyles}
        />
      </div>

      <div>
        <span
          className="filter-side-bar-filters-title"
          style={{
            fontFamily: langTheme.mainFont(),
          }}
        >
          {t("property_type")}
        </span>
        <FilterSideBarSelect
          filterMap={typeFilterMap}
          selectedFilters={typeValues}
          onChange={setTypeValues}
          truncateLength={40}
        />
      </div>

      <div>
        <span
          className="filter-side-bar-filters-title"
          style={{
            fontFamily: langTheme.mainFont(),
          }}
        >
          {t("price_range")}
        </span>
        <div
          style={{
            padding: "0 10px",
          }}
        >
          <PriceRangeSlider
            priceRangeValue={priceRangeValue}
            setPriceRangeValue={setPriceRangeValue}
            styles={{ color: "rgba(0, 0, 0, 0.63)" }}
          />
        </div>
      </div>

      <div>
        <span
          className="filter-side-bar-filters-title"
          style={{
            fontFamily: langTheme.mainFont(),
          }}
        >
          {t("status")}
        </span>
        <FilterSideBarSelect
          filterMap={statusFilterMap}
          selectedFilters={statusValues}
          onChange={setStatusValues}
          truncateLength={40}
        />
      </div>

      {showPortfolioFilters && (
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            gap: "20px",
            marginTop: "20px",
          }}
        >
          <BelongToUserSelect
            initSort={initUserId}
            onSortChange={onUserIdChange}
            options={propertyByUserOptions}
            styles={mobileSortStyles}
          />
          <CampaignStatusSelect
            initSort={initCampaingStatus}
            onSortChange={onCampaingChange}
            options={campaignStatusOptions}
            styles={mobileSortStyles}
          />
        </div>
      )}

      <button
        onClick={submitFilters}
        className="filter-side-bar-search-btn"
        style={{
          fontFamily: langTheme.mainFont(),
          cursor: "pointer",
        }}
      >
        {t("search")}
      </button>
    </div>
  );
};
