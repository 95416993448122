import React, { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import {
  InputAdornment,
  SelectChangeEvent,
  TextField,
  Theme,
  useMediaQuery,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { FormContainer } from "react-hook-form-mui";
import { useNavigate } from "react-router-dom";
import { authTextFieldStyles, ISignupError } from "../../pages/Signup";
import { PhoneSelect } from "../_shared/PhoneSelect";
import { useAppDispatch } from "../../store/store";
import { useFinishSignupMutation } from "../../store/api/authApiSlice";
import { UserType, UserTypeSelect } from "./UserTypeSelect";
import { useLanguageTheme } from "../../hooks/useLanguageTheme";
import { BackBtnAuth } from "./BackBtnAuth";
import CheckIcon from "../../assets/icons/check_blue.svg";
import {
  setAuthPopupOpen,
  setSignupWithGoogle,
} from "../../store/features/common";
import { AuthPopupContentType } from "./types";

export interface IFinishSignupData {
  fullName: string;
  username: string;
  userType: string;
  phoneNumber: string;
}

export const FinishSignupContent = ({
  additionalDescription,
  isPopup,
}: {
  additionalDescription: string;
  isPopup: boolean;
}) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { t, i18n } = useTranslation(["signin"]);
  const isRTL = i18n.dir() === "rtl";
  const langTheme = useLanguageTheme();
  const downSm = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));

  const [finishSignup] = useFinishSignupMutation();

  const [fullName, setFullName] = React.useState("");
  const [phoneNumber, setPhoneNumber] = React.useState("");
  const [userType, setUserType] = React.useState<UserType>(UserType.Buyer);
  const [selectedCode, setSelectedCode] = React.useState("+972");
  const [errors, setErrors] = React.useState<Partial<ISignupError>>({});
  const hasError = useMemo(() => Object.keys(errors).length !== 0, [errors]);
  const [submitError, setSubmitError] = React.useState("");

  const handleCodeChange = (event: SelectChangeEvent<string>) => {
    setSelectedCode(event.target.value);
  };

  const onSubmit = async (data: IFinishSignupData) => {
    const newErrors: Partial<ISignupError> = {};

    // Validate Full Name
    if (!fullName.trim()) {
      newErrors.fullName = t("full_name_required");
    }

    // Validate Phone Number
    const phoneRegex = /^\+?[1-9]\d{1,14}$/;

    if (!phoneNumber.trim()) {
      newErrors.phoneNumber = t("phone_number_required");
    } else if (!phoneRegex.test(phoneNumber)) {
      newErrors.phoneNumber = t("invalid_phone_number_format");
    }

    setErrors(newErrors);

    if (Object.keys(newErrors).length === 0) {
      try {
        const response = await finishSignup({
          fullName: fullName,
          username: crypto.randomUUID(),
          userType: userType,
          phoneNumber: `${selectedCode} ${phoneNumber}`,
        }).unwrap();
        if (response.provider === "google") {
          navigate("/home");
          localStorage.setItem("user", JSON.stringify(response));
          dispatch(setSignupWithGoogle());
          dispatch(setAuthPopupOpen(AuthPopupContentType.WELCOME_ON_BOARD));
        } else {
          navigate("/home");
          dispatch(setAuthPopupOpen(AuthPopupContentType.SIGNUP));
        }
      } catch (err) {
        console.log("log:err ", err);
        setSubmitError(t("registration.error.unableToComplete"));
      }
    }
  };

  const formContext = useForm<IFinishSignupData>({
    defaultValues: {
      fullName: "",
      username: "",
      userType: "",
      phoneNumber: "",
    },
    reValidateMode: "onChange",
    shouldUseNativeValidation: false,
    mode: "onSubmit",
  });

  const handleFullNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setFullName(value);

    const newErrors = { ...errors };

    if (!value.trim()) {
      newErrors.fullName = t("full_name_required");
    } else {
      delete newErrors.fullName;
    }

    setErrors(newErrors);
  };

  const handlePhoneNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setPhoneNumber(value);

    const newErrors = { ...errors };

    const phoneRegex = /^\+?[1-9]\d{1,14}$/;

    const fullPhoneNumber = selectedCode + value;

    if (!value.trim()) {
      newErrors.phoneNumber = t("phone_number_required");
    } else if (!phoneRegex.test(fullPhoneNumber)) {
      newErrors.phoneNumber = t("invalid_phone_number_format");
    } else if (value.length < 7 || fullPhoneNumber.length > 15) {
      newErrors.phoneNumber = t("phone_number_length_invalid");
    } else {
      delete newErrors.phoneNumber;
    }

    setErrors(newErrors);
  };

  return (
    <div
      className="signin-form-section"
      style={{
        paddingTop: isPopup ? 0 : "50px",
        paddingBottom: isPopup ? 0 : "50px",
        padding: isPopup ? "" : "45px 20px",
      }}
    >
      <div
        className="signin-form-container"
        style={{
          direction: i18n.dir(),
        }}
      >
        <div className="forgot-p-check-wrapper" style={{}}>
          <div className="forgot-p-check-container">
            <img src={CheckIcon} />
          </div>
        </div>
        {!isPopup && <BackBtnAuth />}
        <div
          className="signin-form-title-container"
          style={{
            direction: i18n.dir(),
            flexDirection: "column",
          }}
        >
          <div
            className="signin-form-title-signin"
            style={{
              fontFamily: isRTL ? "Noto Sans Hebrew" : "Frank Ruhl Libre",
              width: "100%",
            }}
          >
            {t("google_success")}
          </div>

          <div
            className="signin-form-title-welcom"
            style={{
              fontFamily: langTheme.mainFont(),
              marginTop: "15px",
              marginBottom: "30px",
              width: "100%",
            }}
          >
            {t("continue_header")}
          </div>
        </div>
        {additionalDescription && (
          <div
            className="signup-additional-desc"
            style={{
              fontSize: downSm ? "14px" : "16px",
              fontFamily: langTheme.mainFont(),
            }}
          >
            {t(additionalDescription)}
          </div>
        )}

        <FormContainer
          formContext={formContext}
          onSuccess={(data) => onSubmit(data)}
        >
          <div
            className="signin-form-inputs-label"
            style={{
              fontFamily: langTheme.mainFont(),
            }}
          >
            {t("user_type")}
          </div>

          <UserTypeSelect userType={userType} setUserType={setUserType} />

          <div
            className="signin-form-inputs-label"
            style={{
              fontFamily: langTheme.mainFont(),
              marginTop: "5px",
            }}
          >
            {t("full_name")}
          </div>
          <TextField
            type="text"
            name="fullName"
            value={fullName}
            onChange={handleFullNameChange}
            fullWidth
            required
            inputProps={{
              maxLength: 100,
            }}
            placeholder={t("full_name_example")}
            error={!!errors.fullName}
            helperText={errors.fullName || " "}
            className={`form-input-wrapper ${!!errors.fullName ? "error" : ""}`}
            sx={(theme) =>
              authTextFieldStyles.textField(theme, isRTL, !!errors.fullName)
            }
            FormHelperTextProps={{
              sx: {
                textAlign: isRTL ? "right" : "left",
                fontFamily: langTheme.mainFont(),
              },
            }}
          />

          <div
            className="signin-form-inputs-label"
            style={{
              fontFamily: langTheme.mainFont(),
              marginTop: "5px",
            }}
          >
            {t("phone_number")}
          </div>

          <TextField
            type="tel"
            fullWidth
            required
            value={phoneNumber}
            onChange={handlePhoneNumberChange}
            error={!!errors.phoneNumber}
            helperText={errors.phoneNumber || " "}
            inputProps={{
              maxLength: 15,
            }}
            FormHelperTextProps={{
              sx: {
                textAlign: isRTL ? "right" : "left",
                fontFamily: langTheme.mainFont(),
              },
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <PhoneSelect
                    selectedCode={selectedCode}
                    handleCodeChange={handleCodeChange}
                  />
                </InputAdornment>
              ),
            }}
            sx={(theme) => ({
              ...authTextFieldStyles.textField(
                theme,
                isRTL,
                !!errors.phoneNumber
              ),
              input: {
                textAlign: isRTL ? "right" : "left",
                direction: i18n.dir(),
              },
            })}
          />

          <div
            className="sign-up-term-policy"
            style={{
              marginTop: "5px",
            }}
          ></div>

          {submitError && (
            <div
              className="submit-error"
              style={{
                fontFamily: langTheme.mainFont(),
                direction: i18n.dir(),
              }}
            >
              {submitError}
            </div>
          )}

          <button
            type="submit"
            className="signin-form-inputs-signin-btn"
            style={{
              fontFamily: langTheme.mainFont(),
              fontWeight: isRTL ? 700 : 500,
            }}
            disabled={hasError}
          >
            {t("continue")}
          </button>
        </FormContainer>
      </div>
    </div>
  );
};
