import { Box, Button, Theme, Typography, useMediaQuery } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import CircularProgress from "@mui/material/CircularProgress";
import { useUpdateUserMutation } from "../../store/api/authApiSlice";

export enum Language {
  ENGLISH = "en",
  HEBREW = "he",
}
export const LanguageDetails = {
  [Language.ENGLISH]: { name: "עב", dir: "ltr" },
  [Language.HEBREW]: { name: "En", dir: "rtl" },
} as const;

export const LangToggle = ({
  autosaveFunc = async () => {},
  isDisabledSwitchLang,
  color,
  fontSize,
  className,
}: {
  autosaveFunc?: any;
  isDisabledSwitchLang?: boolean;
  color?: string;
  fontSize?: string;
  className?: string;
}) => {
  const [updateUser] = useUpdateUserMutation();
  const { i18n } = useTranslation();
  const [selectedLang, setSelectedLang] = React.useState<Language>(
    (localStorage.getItem("lang") as Language) || Language.ENGLISH
  );

  const downLg = useMediaQuery((theme: Theme) => theme.breakpoints.down("lg"));

  const handleLanguageToggle = async () => {
    try {
      const newLang =
        selectedLang === Language.ENGLISH ? Language.HEBREW : Language.ENGLISH;
      await autosaveFunc();
      await setSelectedLang(newLang);
      await i18n.changeLanguage(newLang);
      await localStorage.setItem("lang", newLang);
      const userString = await (localStorage.getItem("user") ||
        sessionStorage.getItem("user"));
      const user = await (userString ? JSON.parse(userString) : null);

      if (user) {
        await updateUser({
          details: { ...user?.details, language: newLang },
        }).unwrap();
      }
    } catch (error: any) {
      console.log(error.data.error);
    }
  };

  React.useEffect(() => {
    const savedLang = localStorage.getItem("lang") as Language;
    if (savedLang && Object.values(Language).includes(savedLang)) {
      setSelectedLang(savedLang);
      i18n.changeLanguage(savedLang);
    }
  }, [i18n]);

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: isDisabledSwitchLang ? "12px 13px 10px 13px " : "13px 11px",
      }}
      className={className}
      onClick={isDisabledSwitchLang ? () => {} : () => handleLanguageToggle()}
    >
      <Typography
        sx={{
          color: color ? color : "black",
          fontFamily: "Noto Sans Hebrew",
          fontWeight: 500,
          fontSize: fontSize ? fontSize : downLg ? "14px" : "16px",
          lineHeight: "20px",
        }}
      >
        {isDisabledSwitchLang ? (
          <CircularProgress
            size={20}
            sx={{
              color: "white",
            }}
          />
        ) : (
          LanguageDetails[selectedLang]?.name
        )}
      </Typography>
    </div>
  );
};
