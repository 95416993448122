import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import house from "../../assets/sellWithUs/house.png";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import i18n from "../../i18n";
import { useGetFaqsQuery } from "../../store/api/sellWithUsApiSlice";

interface IFaq {
  question: string;
  answer: string;
}

export default function Questions() {
  const { t } = useTranslation(["sellWithUs"]);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(650));
  const [expanded, setExpanded] = useState<number | false>(0);
  const isRTL = i18n.language === "he";
  const { data: fetchedFaqs } = useGetFaqsQuery(i18n.language);

  const [faqs, setFaqs] = useState<IFaq[]>([]);

  useEffect(() => {
    if (fetchedFaqs) {
      setFaqs(fetchedFaqs.data);
    }
  }, [fetchedFaqs]);

  const handleAccordionChange =
    (panel: number) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: isMobile ? "column" : "row",
        gap: 4,
        alignItems: "flex-start",
        justifyContent: "space-between",
        p: 0,
        direction: isRTL ? "rtl" : "ltr",
        mx: isMobile ? "20px" : "20px",
        marginTop: "85px",
      }}
    >
      {/* Text and Image Block */}
      <Box
        sx={{
          maxWidth: isMobile ? "100%" : "40%",
          textAlign: isRTL ? "right" : "left",
          justifyContent: isRTL ? "flex-end" : "flex-start",
          order: isRTL ? 1 : 0,
          display: "flex",
          flexDirection: "column",
        }}
      >
        {isMobile ? (
          <Typography
            sx={{
              fontWeight: 600,
              fontSize: "24px",
              color: "#000",
              textTransform: "uppercase",
              mb: 2,
              fontFamily: isRTL ? "Noto Sans Hebrew" : "Frank Ruhl Libre",
              textAlign: isRTL ? "flex-start" : "flex-end",
              width: "90%",
            }}
          >
            {t("top_questions")}
          </Typography>
        ) : (
          <Box>
            <Typography
              sx={{
                fontWeight: 600,
                fontSize: { sm: "24px", lg: "36px" },
                color: "#000",
                textTransform: "uppercase",
                mb: "77px",
                fontFamily: isRTL ? "Noto Sans Hebrew" : "Frank Ruhl Libre",
                textAlign: isRTL ? "right" : "left",
                width: isRTL ? "75%" : "100%",
              }}
            >
              {t("top_questions")}
            </Typography>
          </Box>
        )}

        {/* Image */}
        {isMobile && (
          <Box
            sx={{
              display: "flex",
              justifyContent: isRTL ? "flex-end" : "flex-start",
              my: 2,
              position: "relative",
            }}
          >
            <img
              src={house}
              alt="Modern house"
              style={{
                width: "100%",
                maxWidth: "250px", // Smaller image on mobile
                height: "auto",
                objectFit: "cover",
              }}
            />
            <Box
              sx={{
                bgcolor: "#00000026",
                width: "287px",
                height: "72px",
                position: "absolute",
                bottom: "-5%",
                left: isRTL ? "-15%" : "-5%",
                transform: "rotate(2.91deg)",
                borderRadius: "50%",
                zIndex: -1,
                filter: "blur(10px)",
              }}
            ></Box>
          </Box>
        )}

        {!isMobile && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              position: "relative",
              px: isRTL ? 0 : "30px",
            }}
          >
            <img
              src={house}
              alt="Modern house"
              style={{
                width: "100%",
                maxWidth: "312px",
                height: "auto",
                objectFit: "cover",
              }}
            />
            <Box
              sx={{
                bgcolor: "#00000026",
                width: "287px",
                height: "72px",
                position: "absolute",
                bottom: "-5%",
                left: isRTL ? "10%" : "0%",
                transform: "rotate(2.91deg)",
                borderRadius: "50%",
                zIndex: -1,
                filter: "blur(10px)",
              }}
            ></Box>
          </Box>
        )}
      </Box>

      {/* FAQ Block */}
      <Box
        sx={{
          maxWidth: isMobile ? "100%" : "50%",
          alignSelf: "flex-start",
          order: isRTL ? 1 : 0,
        }}
      >
        {faqs?.map((faq, index) => (
          <Accordion
            key={index}
            expanded={expanded === index}
            onChange={handleAccordionChange(index)}
            sx={{
              boxShadow: "none",
              borderBottom: isMobile
                ? "1px solid #e0e0e0"
                : index === faqs.length - 1
                ? "none"
                : "1px solid #e0e0e0",
              "&:before": {
                display: "none",
              },
            }}
            aria-label={`FAQ: ${faq?.question}`}
          >
            <AccordionSummary
              expandIcon={
                <ExpandMoreIcon
                  sx={{
                    color: expanded === index ? "#D7A148" : "#666",
                  }}
                />
              }
              sx={{
                p: 0,
                "& .MuiAccordionSummary-content": {
                  margin: "12px 0",
                },
              }}
            >
              <Typography
                variant="h6"
                sx={{
                  fontWeight: 500,
                  fontSize: "22px",
                  color: "#000",
                  fontFamily: isRTL ? "Noto Sans Hebrew" : "DM Sans",
                }}
              >
                {faq?.question}
              </Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ p: 0, pb: 2 }}>
              <Typography
                variant="body1"
                sx={{
                  fontSize: "16px",
                  color: "#0000009E",
                  fontFamily: isRTL ? "Noto Sans Hebrew" : "DM Sans",
                  fontWeight: 400,
                }}
              >
                {faq?.answer}
              </Typography>
            </AccordionDetails>
          </Accordion>
        ))}
      </Box>
    </Box>
  );
}
