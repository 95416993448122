import { configureStore } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import { apiSlice } from "./api/apiSlice";
import sideNav from "./features/common";
import portfolio from "./features/portfolioSlice";
import campaignBuilder from "./features/campaignBuilderSlice";
import campaignBuilderSetup from "./features/campaignBuilderSetup";
import auth from "./features/authSlice";
import wishlist from "./features/wishlistSlice";
import contactUs from "./features/contactUsSlice";

export const store = configureStore({
  reducer: {
    [apiSlice.reducerPath]: apiSlice.reducer,
    sideNav,
    portfolio,
    campaignBuilder,
    auth,
    campaignBuilderSetup,
    wishlist,
    contactUs,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(apiSlice.middleware),
  devTools: true,
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();
