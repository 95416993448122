import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Header } from "../components/_shared/Header";
import { Navbar } from "../components/_shared/Navbar";
import { Footer } from "../components/_shared/Footer";
import { CampaignCompareProperties } from "../components/campaignBuilder/CampaignCompareProperties";
import { CampaignCalculator } from "../components/campaignBuilder/CampaignCalculator";
import { GetInTouchBlock } from "../components/home/GetInTouchBlock";
import { FooterSocialMediaLine } from "../components/_shared/FooterSocialMediaLine";
import { extractMediaProperties } from "../helpers/campaignConvert";
import { convertDateToTimePeriods } from "../helpers/formatter";
import { useGetAgentsQuery } from "../store/api/agentApiSlice";
import {
  useLazyGetPropertyQuery,
  IGetPropertiesParamsWithLang,
  useGetPropertiesWithParamsQuery,
} from "../store/api/propertyApiSlice";
import {
  setAboutDeveloper,
  setAboutPropertyDesc,
  setBrochureFileSlice,
  setCampaignStatus,
  setCountOfTimePeriods,
  setCurrency,
  setFloorPlanSlice,
  setHightlightsBack,
  setImgFilesSlice,
  setKeyFeatureBack,
  setLabelBack,
  setPropertyDescription,
  setPropertyLocation,
  setPropertyPrice,
  setPropertyStatus,
  setPropertyTitle,
  setPropertyType,
  setSelectedAgent,
  setSelectedUserId,
  setTimePeriod,
  setVideoFileSlice,
} from "../store/features/campaignBuilderSlice";
import { useAppDispatch } from "../store/store";
import { skipToken } from "@reduxjs/toolkit/query";
import { convertToProperties } from "../helpers/convert";
import { randomMediaGenerator } from "../helpers/fakeMedia";
import { FilterMap, locationFilterMap } from "../constants/propertyData";
import { useUserProfile } from "../hooks/useUserProfile";
import { NotificationSnackBar } from "../components/campaignBuilder/NotificationSnackBar";
import { setWishlistSnackbarClose } from "../store/features/wishlistSlice";
import {
  DEFAULT_FILTER,
  DEFAULT_SORT,
} from "../components/portfolio/constants";
import { ILocationFilter } from "../components/portfolio/interfaces";
import { CampainBuilderMainContainer } from "../components/campaignBuilder/content/blocks/CampainBuilderMainContainer";
import { NotificationStatus } from "../components/campaignBuilder/content/steps/CampaignSetUpStep";
import { setPageView } from "../store/features/campaignBuilderSetup";

export enum BuilderMode {
  CREATE = 0,
  EDIT = 1,
}

export const CampaignPageViewMode = () => {
  const dispatch = useAppDispatch();
  const { i18n } = useTranslation(["campaignBuilder"]);
  const currentLanguage = i18n.language;
  const { documentId } = useParams();
  const [triggerGetProperty, { data: propertyData }] =
    useLazyGetPropertyQuery();
  const { data: agentsData } = useGetAgentsQuery({ locale: currentLanguage });
  const navigate = useNavigate();

  const { error } = useUserProfile();
  useEffect(() => {
    const user = localStorage.getItem("user") || sessionStorage.getItem("user");
    const jwt = localStorage.getItem("jwt") || sessionStorage.getItem("jwt");
    if (error || !jwt || !user) {
      localStorage.removeItem("jwt");
      localStorage.removeItem("user");
      sessionStorage.removeItem("jwt");
      sessionStorage.removeItem("user");
      if (window.location.pathname === "/home") {
        window.location.reload();
      } else {
        navigate("/home");
      }
    }
  }, [error]);

  const { selectedUserId, language } = useSelector(
    (state: any) => state.campaignBuilder
  );

  React.useEffect(() => {
    if (documentId) {
      dispatch(setImgFilesSlice([]));
      dispatch(setFloorPlanSlice([]));
      dispatch(setVideoFileSlice([]));
      dispatch(setBrochureFileSlice([]));

      triggerGetProperty({
        documentId: documentId,
        locale: language.slice(0, 2) ?? "en",
      });
    } else {
    }
  }, []);

  React.useEffect(() => {
    if (documentId) {
      triggerGetProperty({
        documentId: documentId,
        locale: i18n.language,
      });
    }
  }, [i18n.language]);

  const findCityByName = (
    cities: FilterMap,
    searchTerm: string,
    location: any
  ): any | undefined => {
    const city = Object.values(cities).find(
      (city) => city.name.en === searchTerm || city.name.he === searchTerm
    ) as ILocationFilter;

    if (city) {
      return {
        key: city.key,
        name: city.name,
        placeName: city.name,
        city: location.city,
        custom_adress: location.address,
        translationKey: city.translationKey,
        longitude: location.coordinates.longitude,
        latitude: location.coordinates.latitude,
      };
    }

    return undefined;
  };

  const getPropertiesParams =
    useMemo<IGetPropertiesParamsWithLang | null>(() => {
      return {
        params: {
          page: 1,
          pageSize: "4",
          sort: DEFAULT_SORT,
          filters: {
            ...DEFAULT_FILTER,
            locations: propertyData?.location?.city
              ? [
                  findCityByName(
                    locationFilterMap,
                    propertyData?.location?.city,
                    propertyData?.location
                  ) ?? {
                    key: "0",
                    name: { en: "Tel Aviv", he: "תל אביב" },
                    placeName: { en: "Tel Aviv", he: "תל אביב" },
                    translationKey: "tel_aviv",
                    longitude: 34.7818,
                    latitude: 32.0853,
                  },
                ]
              : [],
          },
          campaignStatus: "all",
          userId: "all",
          search: "",
        },
        lang: i18n.language,
      };
    }, [propertyData]);

  const { data, isLoading } = useGetPropertiesWithParamsQuery(
    getPropertiesParams ?? skipToken
  );

  useEffect(() => {
    if (propertyData) {
      const { countOfTimePeriodsValue, timePeriodValue } =
        convertDateToTimePeriods(propertyData.campaignCloseDate);

      dispatch(setCountOfTimePeriods(countOfTimePeriodsValue));
      dispatch(setTimePeriod(timePeriodValue));
      dispatch(setPropertyTitle(propertyData.name));
      dispatch(setPropertyDescription(propertyData.description));
      dispatch(setPropertyPrice(String(propertyData.pricing.basePrice)));
      dispatch(setCurrency(propertyData.pricing.currency));
      dispatch(setCurrency(propertyData.pricing.currency));
      dispatch(setPropertyStatus(propertyData.propertyStatus));
      dispatch(setPropertyType(propertyData.propertyType));
      dispatch(setAboutPropertyDesc(propertyData.aboutProperty));
      dispatch(setAboutDeveloper(propertyData.developer));
      dispatch(setKeyFeatureBack(propertyData.key_features));
      dispatch(setHightlightsBack(propertyData.investment_highlights));
      dispatch(setSelectedUserId(propertyData.agent?.id));
      dispatch(setLabelBack(propertyData.propertyLabels[0]));
      dispatch(setCampaignStatus(propertyData.campaignStatus));
      dispatch(
        setPropertyLocation({
          city: propertyData.location.city,
          address: propertyData.location.address,
          coordinates: {
            latitude: propertyData.location.coordinates.latitude,
            longitude: propertyData.location.coordinates.longitude,
          },
          id: propertyData.location.id,
        })
      );

      const extractedImgFiles = extractMediaProperties(
        propertyData.mediaContent
      );
      dispatch(setImgFilesSlice(extractedImgFiles));

      const extractedFloorPlan = extractMediaProperties(propertyData.floorplan);
      dispatch(setFloorPlanSlice(extractedFloorPlan));

      const extractedVideo = extractMediaProperties(propertyData.video);
      dispatch(setVideoFileSlice(extractedVideo));

      const extractedBrocure = extractMediaProperties(propertyData.brochure);
      dispatch(setBrochureFileSlice(extractedBrocure));
    }
  }, [propertyData]);

  React.useEffect(() => {
    if (agentsData && agentsData.length > 0) {
      const selectedAgent = agentsData.find(
        (agent: any) => agent.id === selectedUserId
      );

      if (selectedAgent) {
        dispatch(setSelectedAgent(selectedAgent));
      } else {
        if (selectedUserId === "create-new") {
          dispatch(setSelectedUserId("create-new"));
        } else {
          dispatch(setSelectedAgent(agentsData[0]));
          dispatch(setSelectedUserId(agentsData[0]?.id));
        }
      }
    }
  }, [agentsData, selectedUserId, dispatch]);

  //wishlist
  const { wishlistSnackbarOpen, snackBarLikeMessage } = useSelector(
    (state: any) => state.wishlist
  );
  const handleCloseLikeSnackBar = () => {
    dispatch(setWishlistSnackbarClose());
  };
  //wishlist

  React.useEffect(() => {
    dispatch(setPageView(true));
  }, []);

  return (
    <div>
      <div className="portfolio-header-sticky-wrapper">
        <div
          style={{
            backgroundColor: "rgba(255, 255, 255, 1)",
          }}
        >
          <Header>
            <Navbar />
          </Header>
        </div>
      </div>
      <div
        style={{
          width: "100%",
          marginRight: 0,
          marginLeft: 0,
        }}
      >
        <CampainBuilderMainContainer
          onlyView={true}
          documentId={propertyData?.documentId}
          propertyId={propertyData?.id}
          property={propertyData}
        />
      </div>

      {!isLoading ? (
        <CampaignCompareProperties
          data={convertToProperties(randomMediaGenerator, data?.data)}
          triggerGetProperty={triggerGetProperty}
          language={language}
          city={propertyData?.location?.city}
        />
      ) : (
        ""
      )}

      {!isLoading ? <CampaignCalculator /> : ""}
      <GetInTouchBlock />
      <Footer />
      <FooterSocialMediaLine />
      <NotificationSnackBar
        open={wishlistSnackbarOpen}
        onClose={handleCloseLikeSnackBar}
        type={NotificationStatus.SUCCESSFULL}
        snackbarText={snackBarLikeMessage}
      />
    </div>
  );
};
