import { styled, Theme, Typography, useMediaQuery } from "@mui/material";
import Tooltip, { tooltipClasses, TooltipProps } from "@mui/material/Tooltip";
import { useEffect, useState } from "react";
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from "react-beautiful-dnd";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import DraggableIcon from "../../../../assets/campaignBuilder/draggable_icon.svg";
import InfoIcon from "../../../../assets/icons/info_icon_key.svg";
import { KeyFeatureIconMapBlue } from "../../../../constants/campaignBuilderData";
import { useLanguageTheme } from "../../../../hooks/useLanguageTheme";
import { useAppDispatch } from "../../../../store/store";
import { LangToggle } from "../../../_shared/LangToggle";
import { CampaignSideNavStep } from "../layout/CampaignBuilderSideNav";
import { CampaignBuilderSideNavHeader } from "../layout/CampaignBuilderSideNavHeader";
import { CampainBuilderNextBtn } from "../buttons/CampainBuilderNextBtn";
import { HighlightSelect } from "../selects/HighlightSelect";
import { useResetToDraft } from "../../../../hooks/useResetToDraft";
import { BackToMenuBtn } from "../buttons/BackToMenuBtn";
import { CampainBuilderBackBtn } from "../buttons/CampainBuilderBackBtn";

export interface IKeyFeature {
  id: string;
  selectId: string;
  labelName: string;
  icon_key: string;
  selectedToCard: boolean;
}

const reorder = (
  list: string[],
  startIndex: number,
  endIndex: number
): string[] => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

export const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "rgba(255, 255, 255, 0.85)",
    color: "rgba(0, 0, 0, 0.87)",
    fontSize: 14,
    maxWidth: 180,
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: "rgba(255, 255, 255, 0.85)",
  },
}));
export const KeyFeature = ({
  setCurrentStep,
  keyfeatureOptionsState,
  selectedFeature,
  setSelectedFeature,
  setFeatureForEditId,
  autosaveFunc = () => {},
  isDisabledSwitchLang = false,
}: {
  setCurrentStep: (step: CampaignSideNavStep) => void;
  keyfeatureOptionsState: any;
  selectedFeature: any;
  setSelectedFeature: any;
  setFeatureForEditId: any;
  autosaveFunc: any;
  isDisabledSwitchLang: boolean;
}) => {
  const { documentId } = useParams();
  const { t, i18n } = useTranslation(["campaignBuilder"]);
  const isRTL = i18n.dir() === "rtl";
  const downMd = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));
  const langTheme = useLanguageTheme();
  const [selectedKeyFeturesIds, setSelectedKeyFeturesIds] = useState<any[]>([]);
  const dispatch = useAppDispatch();
  const { keyFeaturesBack } = useSelector(
    (state: any) => state.campaignBuilder
  );
  const resetToDraft = useResetToDraft();

  const handleNext = (step: CampaignSideNavStep) => {
    setCurrentStep(step);
  };

  useEffect(() => {
    if (selectedFeature) {
      setSelectedKeyFeturesIds(selectedFeature.map((el: any) => el.id));
    }
  }, [selectedFeature, keyFeaturesBack]);

  useEffect(() => {
    if (
      !documentId &&
      selectedFeature &&
      Array.isArray(selectedFeature) &&
      selectedFeature.length === 0
    ) {
      dispatch(
        setSelectedFeature(
          keyfeatureOptionsState
            .filter((el: any) => el.type === "system")
            .slice(0, 4)
        )
      );
    }
  }, [keyfeatureOptionsState]);

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }
    const reorderedIds = reorder(
      selectedKeyFeturesIds,
      result.source.index,
      result.destination.index
    );
    dispatch(
      setSelectedFeature(
        reorderedIds.map((id) =>
          keyFeaturesBack.find((item: any) => item.id === id)
        )
      )
    );
    resetToDraft();
    setSelectedKeyFeturesIds(reorderedIds);
  };

  return (
    <div className="campaign-builder-side-nav-wrapper">
      <CampaignBuilderSideNavHeader
        header="key_features"
        leftBtn={
          <CampainBuilderBackBtn
            setCurrentStep={setCurrentStep}
            step={CampaignSideNavStep.aboutProperty}
          />
        }
        nearHeaderComponent={
          <LightTooltip
            title={
              <Typography
                sx={{
                  fontSize: "14px",
                  color: "black",
                  padding: "8px",
                  fontFamily: langTheme.mainFont(),
                }}
              >
                {t("keyFeaturesTooltip")}
              </Typography>
            }
            arrow
          >
            <img src={InfoIcon} alt="" />
          </LightTooltip>
        }
        rightBtn={
          <CampainBuilderNextBtn
            handleNext={handleNext}
            step={CampaignSideNavStep.aboutDeveloper}
          />
        }
      />

      <div
        className="campaign-builder-sidenav-main-content-wrapper"
        style={{
          paddingTop: downMd ? "30px" : "40px",
          direction: i18n.dir(),
        }}
      >
        <div>
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="keyFeatureList" type="group">
              {(provided) => (
                <div ref={provided.innerRef} {...provided.droppableProps}>
                  {selectedKeyFeturesIds && selectedKeyFeturesIds.length > 0
                    ? selectedKeyFeturesIds.map(
                        (selectedId: string, index: number) => {
                          return (
                            <Draggable
                              key={selectedId.toString()}
                              draggableId={selectedId.toString()}
                              index={index}
                            >
                              {(provided) => (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  className="campaign-builder-sidenav-settings-fileds-item"
                                  style={{
                                    ...provided.draggableProps.style,
                                    display: "flex",
                                    alignItems: "center",
                                    marginBottom: "20px",
                                    gap: "17.5px",
                                  }}
                                >
                                  <img
                                    {...provided.dragHandleProps}
                                    src={DraggableIcon}
                                    alt="Drag Handle"
                                    style={{
                                      cursor: "grab",
                                    }}
                                  />
                                  <div className="campaign-builder-sidenav-settings-fileds-item">
                                    <div
                                      style={{ position: "relative" }}
                                      onClick={() => {
                                        localStorage.setItem(
                                          "editKeyFeatureHighlightId",
                                          selectedId.toString()
                                        );
                                        setCurrentStep(
                                          CampaignSideNavStep.addaNewFeature
                                        );
                                      }}
                                    >
                                      {selectedFeature.find(
                                        (el: any) => el.id === selectedId
                                      )?.details &&
                                      selectedFeature.find(
                                        (el: any) => el.id === selectedId
                                      ).details[documentId ?? ""] ? (
                                        <div
                                          style={{
                                            backgroundColor: "rgba(0,0,0,0.2)",
                                            position: "absolute",
                                            maxWidth: "360px",
                                            width: "100%",
                                            height: "100%",
                                            top: 0,
                                            zIndex: 10,
                                            borderRadius: 16,
                                          }}
                                        />
                                      ) : (
                                        ""
                                      )}
                                      <HighlightSelect
                                        value={selectedId}
                                        onChangeValue={(newId: any) => {}}
                                        disabled={true}
                                        options={selectedFeature}
                                        iconMap={KeyFeatureIconMapBlue}
                                        selectedOptions={selectedKeyFeturesIds}
                                      />
                                    </div>
                                  </div>
                                </div>
                              )}
                            </Draggable>
                          );
                        }
                      )
                    : ""}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </div>

        <div
          style={{
            backgroundColor: "rgba(5, 57, 87, 1)",
            position: "sticky",
            bottom: 0,
            zIndex: 11,
            display: "flex",
            gap: "15px",
          }}
        >
          <div style={{ flex: 1, maxWidth: "50%" }}>
            <BackToMenuBtn setCurrentStep={setCurrentStep} />
          </div>

          <div
            onClick={() => {
              setFeatureForEditId(0);
              localStorage.setItem("editKeyFeatureHighlightId", "0");
              dispatch(
                setSelectedFeature([
                  ...selectedFeature,
                  {
                    id: 0,
                    iconKey: "stair",
                    labelNameHe: "",
                    labelNameEn: "",
                  },
                ])
              );
              setCurrentStep(CampaignSideNavStep.addaNewFeature);
            }}
            style={{
              marginTop: "20px",
              flex: 1,
              maxWidth: "50%",
            }}
            className="campaign-builder-sidenav-preview-btn"
          >
            <span>{t("add_new_feature")}</span>
          </div>
        </div>
      </div>
    </div>
  );
};
