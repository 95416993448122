import { Theme, useMediaQuery } from "@mui/material";
import { useTranslation } from "react-i18next";
import React from "react";
import { useGetArticlesBySectionsQuery } from "../../store/api/articlesApiSlice";
import { IArticle } from "../learningCenter/interfaces";
import { AdditionalArticlesContainer } from "../learningCenter/content/AdditionalArticlesContainer";

export const RelatedArticlesSection = () => {
  const { i18n, t } = useTranslation(["learningCenter"]);
  const downMd = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));
  const isRTL = i18n.dir() === "rtl";
  
  const { data } = useGetArticlesBySectionsQuery(i18n.language);

  const [topArticles, setTopArticles] = React.useState<IArticle[]>([]);

  React.useEffect(() => {
    if (data) {
      setTopArticles(data.data.newestResults);
    }
  }, [data]);

  return (
    <div>
      <div
        className="related-arctiles-title xl-width"
        style={{
          fontFamily: isRTL ? "Noto Sans Hebrew" : "Frank Ruhl Libre",
          fontSize: downMd ? "24px" : "36px",
          lineHeight: downMd ? "24px" : "36px",
          marginBottom: downMd ? "25px" : "50px",
          marginTop: downMd ? "15px" : "50px",
        }}
      >
        {t("related_articles")}
      </div>
      <AdditionalArticlesContainer articles={topArticles}/>
    </div>
  );
};
