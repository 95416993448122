export enum AuthPopupContentType {
  SIGNIN = "SIGNIN",
  SIGNUP = "SIGNUP",
  FORGOT_PASSWORD = "FORGOT_PASSWORD",
  CHOOSE_VERIFICATION = "CHOOSE_VERIFICATION",
  CONFIRM_EMAIL = "CONFIRM_EMAIL",
  CONFIRM_SMS = "CONFIRM_SMS",
  FINISH_SIGNUP_WITH_GOOGLE = "FINISH_SIGNUP_WITH_GOOGLE",
  WELCOME_ON_BOARD = "WELCOME_ON_BOARD"
}
