import { InputAdornment, TextField } from "@mui/material";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import SearchIcon from "../../../../assets/icons/search_icon.svg";
import { PortfilioMobileFilterItem } from "./PortfilioMobileFilterItem";
import {
  locationFilterMap,
  statusFilterMap,
  typeFilterMap,
} from "../../../../constants/propertyData";
import { formatPrice } from "../../../../helpers/formatter";
import { useLanguageTheme } from "../../../../hooks/useLanguageTheme";
import { IFilterParams } from "../../interfaces";
import { LocationMobileFilterItem } from "../filters/LocationMobileFilterItem";

export const PortfolioMobileFilterContainer = ({
  filters,
  onChangeFilters,
  search,
  onChangeSearch,
}: {
  filters: IFilterParams;
  onChangeFilters: any;
  search: string;
  onChangeSearch: (search: string) => void;
}) => {
  const { t, i18n } = useTranslation(["portfolio"]);
  const isRTL = i18n.dir() === "rtl";
  const currentLanguage = i18n.language;
  const langTheme = useLanguageTheme();

  const [searchValue, setSearchValue] = React.useState(search);
  const [priceRangeValue, setPriceRangeValue] = React.useState(
    filters.priceRange
  );
  const [locationValues, setLocationValues] = React.useState(filters.locations);
  const [statusValues, setStatusValues] = React.useState(filters.statuses);
  const [typeValues, setTypeValues] = React.useState(filters.types);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setSearchValue(newValue);
  };

  React.useEffect(() => {
    setSearchValue(search);
    setLocationValues(filters.locations);
    setTypeValues(filters.types);
    setStatusValues(filters.statuses);
    setPriceRangeValue(filters.priceRange);
  }, [filters, search]);

  useEffect(() => {
    onChangeFilters({
      locations: locationValues,
      statuses: statusValues,
      types: typeValues,
      priceRange: priceRangeValue,
    });
  }, [locationValues, statusValues, typeValues]);

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      const inputValue = (e.target as HTMLInputElement).value;
      onChangeSearch(inputValue);
    }
  };

  return (
    <div className="portfolio-mobile-filter-container">
      <div className="portfolio-mobile-filter-search-container">
        <TextField
          fullWidth
          value={searchValue}
          onChange={handleInputChange}
          placeholder={t("AI_search")}
          onKeyDown={handleKeyDown}
          inputProps={{
            maxLength: 300,
          }}
          sx={{
            backgroundColor: "rgba(240, 244, 247, 1)",
            borderRadius: "23px",

            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                border: "none",
              },
            },
            "& .MuiInputBase-input::placeholder": {
              color: "rgba(0, 0, 0, 0.33)",
              opacity: 1,
              fontFamily: langTheme.mainFont(),
              fontWeight: isRTL ? 400 : 500,
            },
            "& .MuiInputBase-input": {
              paddingY: "14.5px",
              fontFamily: langTheme.mainFont(),
            },
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment
                onClick={() => onChangeSearch(searchValue)}
                sx={{
                  height: "100%",
                  ml: 0,
                  maxHeight: "none",
                  borderRadius: "0 4px 4px 0",
                  marginRight: "-14px",
                  pl: "5px",
                  pr: "13px",
                  cursor: "pointer",
                }}
                position="end"
              >
                <div className="portfolio-mobile-search-container-icon">
                  <img src={SearchIcon} />
                </div>
              </InputAdornment>
            ),
          }}
        />
      </div>

      <div className="portfolio-mobile-filters-container">
        {/* <PortfilioMobileFilterItem
          filterMap={locationFilterMap}
          selectedFilters={locationValues}
          onChange={setLocationValues}
          truncateLength={15}
        /> */}

        <LocationMobileFilterItem
          filterMap={locationFilterMap}
          selectedFilters={locationValues}
          onChange={setLocationValues}
          truncateLength={15}
        />

        <PortfilioMobileFilterItem
          filterMap={typeFilterMap}
          selectedFilters={typeValues}
          onChange={setTypeValues}
          truncateLength={15}
        />

        {priceRangeValue && (
          <div
            className="portfolio-mobile-filter-item"
            style={{
              fontFamily: langTheme.mainFont(),
              fontWeight: isRTL ? 400 : 500,
            }}
          >
            <div>{isRTL ? "₪" : "$"}</div>
            <div>{`${formatPrice(priceRangeValue.start)}-${formatPrice(
              priceRangeValue.end
            )}`}</div>
          </div>
        )}
        <PortfilioMobileFilterItem
          filterMap={statusFilterMap}
          selectedFilters={statusValues}
          onChange={setStatusValues}
          truncateLength={15}
        />
      </div>
    </div>
  );
};
