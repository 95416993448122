import { Box, Drawer, Theme, Typography, useMediaQuery } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import {
  setAuthPopupOpen,
  setCloseSideNav,
  sideNavOpenSelector,
} from "../../store/features/common";
import { useAppDispatch } from "../../store/store";
import Close_Icon from "../../assets/icons/close_icon.svg";
import logo from "../../assets/logo.png";
import { useTranslation } from "react-i18next";
import Arrow from "../../assets/icons/arrow.svg";
import { NavLink, useNavigate } from "react-router-dom";
import { LangToggle } from "./LangToggle";
import { useLanguageTheme } from "../../hooks/useLanguageTheme";
import { useAuth } from "../../hooks/useAuth";
import { logOut } from "../../store/features/authSlice";
import { ProfileSelect } from "./profileSelect";
import { AuthPopupContentType } from "../auth/types";

export const SideNavMenu = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const openSideNav = useSelector(sideNavOpenSelector);
  const { t } = useTranslation(["navbar"]);
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const isRTL = currentLanguage === "he";
  const langTheme = useLanguageTheme();
  const isAuthenticated = useAuth();
  const downLg = useMediaQuery((theme: Theme) => theme.breakpoints.down("lg"));

  const handleNavigateToSignIn = () => {
    dispatch(setCloseSideNav());
    dispatch(setAuthPopupOpen(AuthPopupContentType.SIGNIN));
  };

  const navItems = [
    { title: t("home"), path: "/home" },
    { title: t("portfolio"), path: "/portfolio" },
    { title: t("sell_with_us"), path: "/sell-with-us" },
    { title: t("learning_center"), path: "/learning-center" },
  ];

  const content = (
    <div style={{ padding: "20px" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "20px",
        }}
      >
        <img src={logo} style={{ width: "62px" }} />
        <img
          src={Close_Icon}
          onClick={() => dispatch(setCloseSideNav())}
          style={{ cursor: "pointer" }}
        />
      </div>

      <Box
        onClick={() => navigate("/contact-us")}
        sx={{
          display: "inline-flex",
          alignItems: "baseline",
          color: "#fff",
          backgroundColor: "#034A73",
          p: "15px 33px 15px 33px",
          cursor: "pointer",
          borderRadius: "24px",
          marginBottom: "20px",
          "&:hover": {
            background: "linear-gradient(to right, #034A73, #0A7992)",
          },
        }}
      >
        <Typography
          sx={{
            mr: isRTL ? "0" : "12px",
            ml: isRTL ? "12px" : "0",
            fontFamily: langTheme.mainFont(),
            fontWeight: isRTL ? 600 : 500,
            lineHeight: "16px",
          }}
        >
          {t("contact_us")}
        </Typography>
        <img
          src={Arrow}
          alt="arrow"
          style={{
            transform: isRTL ? "scaleX(-1)" : " scaleX(1)",
          }}
        />
      </Box>
      <div style={{ display: "flex", flexDirection: "column" }}>
        {navItems.map((item, index) => (
          <Box key={index} component="span" my={2}>
            <NavLink
              to={item.path}
              style={({ isActive }) => ({
                textDecoration: "none",
                color: isActive ? "#000" : "#00000087",
                fontFamily: langTheme.mainFont(),
                fontWeight: isRTL ? 400 : 500,
                cursor: "pointer",
              })}
              onClick={() => dispatch(setCloseSideNav())}
            >
              {item.title}
            </NavLink>
          </Box>
        ))}
      </div>
      <hr
        style={{
          border: "none",
          height: "1px",
          backgroundColor: "rgba(0, 0, 0, 0.09",
        }}
      />
      <div
        style={{
          marginTop: "20px",
        }}
      >
        {isAuthenticated ? "" : <LangToggle />}
      </div>
      <Typography
        onClick={!isAuthenticated ? handleNavigateToSignIn : () => {}}
        sx={{
          marginTop: "32px",

          fontSize: downLg ? "14px" : "16px",
          color: "#000",
          fontFamily: langTheme.mainFont(),
          fontWeight: isRTL ? 400 : 500,
          cursor: "pointer",
          height: "47px",
        }}
      >
        {isAuthenticated ? <ProfileSelect /> : t("sign_in")}
      </Typography>
    </div>
  );

  return (
    <Drawer
      transitionDuration={400}
      anchor="top"
      open={openSideNav}
      onClose={() => dispatch(setCloseSideNav())}
      PaperProps={{
        sx: {
          backgroundColor: "rgba(255, 255, 255, 1)",
          width: "100%",
          direction: i18n.dir(),
        },
      }}
      BackdropProps={{
        sx: {
          backgroundColor: "transparent",
        },
      }}
      sx={{ zIndex: (theme) => theme.zIndex.appBar + 100 }}
      variant="temporary"
    >
      {content}
    </Drawer>
  );
};
