import React from "react";

interface UserProps {
  message: string;
}

export const User:React.FC<UserProps> = ({ message }) => {


  return (
    <div
      className="user_message"
    >
      <div className="user_message_first_child">
        <p>{message}</p>
      </div>

    </div>
  );
};

