import React, { FC, useEffect, useState } from "react";
import pencil from "../../../assets/campaignBuilder/ai-chat/pencil.svg";
import cut from "../../../assets/campaignBuilder/ai-chat/cut.svg";
import material from "../../../assets/campaignBuilder/ai-chat/material.svg";
import {useTranslation} from "react-i18next";
import {ThreeDots} from "react-loader-spinner";
import textLonger from "../../../assets/campaignBuilder/ai-chat/textLonger.svg";

interface QuickActionsProps {
    isLoading: boolean;
    currentAction: string;
    onActionClick?: (title: string, action: { task: string; prompt: string }, actionSlug: string) => void;
    currentStep:any;
    chatArray:any;
    currentsection:any;
    langageChangeformData:any;
}

export const QuickActions: FC<QuickActionsProps> = ({ isLoading = false, currentAction = '', onActionClick = null,currentStep, chatArray,currentsection,langageChangeformData }) => {
    const { i18n } = useTranslation(["campaignBuilder"]);
    const currentLanguage: string = i18n.language;
    const { t  } = useTranslation(["aiChart"]);
  

    const actionBtn: { title: string; slug: string; logo: string; action: { task: string; prompt: string } }[] = [

        {
            title: currentLanguage === 'en' ?  (t("translate_english")) : (t("translate_hebrew")),
            slug: 'translate',
            logo: material,
            action: {
                task: "Translate",
                prompt: currentLanguage === 'en' ? 'to English' : 'to Hebrew'
            }
        },

        {
            title: (t("rewrite_formal")),
            slug: 'rewrite_formal',
            logo: pencil,
            action: {
                task: "Rephrase",
                prompt: "make it more formal"
            }
        },
        {
            title: (t("rewrite_engaging")),
            slug: 'rewrite_engaging',
            logo: pencil,
            action: {
                task: "Rephrase",
                prompt: "make it more engaging"
            }
        },
        {
           
            title: (t("shorten")),
            slug: 'shorten',
            logo: cut,
            action: {
                task: "Shorten",
                prompt: ""
            }
        },
        {
           
            title: (t("make_longer")),
            slug: 'make_longer',
            logo: textLonger,
            action: {
                task: "Make Longer",
                prompt: ""
            }
        },
    ];
   


    return (
        <div className="quick_actions" ref={el => {
            if (chatArray.length > 1 && el) {
                el.scrollIntoView({ behavior: 'smooth', block: 'start' });
            }
        }}>
            <h4> {currentsection} {t("quick_actions")} </h4>
            {actionBtn?.map((item: { title: string; slug: string; logo: string; action: { task: string; prompt: string } }, index: number) => {
             
                if (index === 0 && Object?.values(langageChangeformData)?.every(value => !value)) {
                    return null;
                }
                
                return (
                    <button
                        key={index}
                        className="quick_actions_btn"
                        onClick={() => {
                            if (onActionClick) {
                                onActionClick(item.title, item.action, item.slug);
                            }
                        }}>
                        {(isLoading && currentAction === item.slug)
                            ? (
                                <ThreeDots
                                    visible={true}
                                    height="24"
                                    width="24"
                                    color="#034a73"
                                    radius="9"
                                    ariaLabel="three-dots-loading"
                                    wrapperStyle={{}}
                                    wrapperClass=""
                                />
                            )
                            : (
                                <img src={item?.logo} width={24} height={24} alt=""/>
                            )}
                        <p>{item?.title}</p>
                    </button>
                );
            })}
        </div>
    );
};
