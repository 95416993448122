import { Box, Theme, useMediaQuery } from "@mui/material";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useLanguageTheme } from "../../hooks/useLanguageTheme";
import { LOCAL_TOOLS_ON } from "../../helpers/config";

export enum UserTypes {
  SELLER = "Seller",
  BUYER = "Buyer",
}

export const Navbar = ({ disabled = false }) => {
  const { t } = useTranslation(["navbar"]);
  const { i18n } = useTranslation();
  const langTheme = useLanguageTheme();
  const currentLanguage = i18n.language;
  const isRTL = currentLanguage === "he";
  const user = localStorage.getItem("user") || sessionStorage.getItem("user");
  const downLg = useMediaQuery((theme: Theme) => theme.breakpoints.down("lg"));

  const navItems = [
    { title: t("home"), path: "/home" },
    { title: t("portfolio"), path: "/portfolio", onlyForSellers: true },
    { title: t("sell_with_us"), path: "/sell-with-us", disabled: false },
    { title: t("learning_center"), path: "/learning-center", disabled: false },
    // { title: t("crm"), path: "/crm", onlyForSellers: true, disabled: true },
  ];

  if (LOCAL_TOOLS_ON) {
    navItems.push({
      title: "Campaign Page Builder",
      path: "/campaign-page-builder",
    });
  }

  return (
    <div
      style={{
        gap: downLg ? "15px" : "32px",
        display: "flex",
        direction: i18n.dir(),
      }}
    >
      {(user && JSON.parse(user).userType === UserTypes.SELLER
        ? navItems
        : navItems.filter((el) => !el?.onlyForSellers)
      ).map((item, index) => (
        <Box key={index} component="span">
          {disabled ? (
            <Box
              style={{
                textDecoration: "none",
                color: "#00000087",
                fontWeight: isRTL ? 400 : 500,
                fontFamily: langTheme.mainFont(),
                cursor: "pointer",
                fontSize: downLg ? "13px" : "16px",
              }}
            >
              {item.title}
            </Box>
          ) : (
            <NavLink
              to={item.path}
              aria-disabled={true}
              style={({ isActive }) => ({
                textDecoration: "none",
                color: isActive ? "#000" : "#00000087",
                fontWeight: isRTL ? 400 : 500,
                fontFamily: langTheme.mainFont(),
                cursor: "pointer",
                fontSize: downLg ? "14px" : "16px",
                pointerEvents: item.disabled ? "none" : "auto",
              })}
            >
              {item.title}
            </NavLink>
          )}
        </Box>
      ))}
    </div>
  );
};
