import React from "react";
import { useTranslation } from "react-i18next";

interface ButtonItemProps {
  openFooterTopButton: boolean;
  setApplyBtn: (value: string) => void;
  applyBtn:any
}

const ButtonItem = ({ openFooterTopButton, setApplyBtn,applyBtn }: ButtonItemProps) => {
       const { t, i18n } = useTranslation(["aiChart"]);
       const buttonText = [
        {title:(t("apply")),
          label:'Apply'
        },
        {title: (t("preview")),
          label:'Preview'
        },
        {title:(t("cancel")),
          label: 'Cancel'
        }
        ];
    return (
        <div className="button_item">
           {buttonText.map((text, index) => {
        if (text.label === 'Preview' && applyBtn === 'Preview') {
          return (
            <button
              style={{
                backgroundColor: '#F8FAFF',
                color: '#000'
              }}
              key={index}
              onClick={() => { setApplyBtn('Preview Cancel') }}
            >
               {t("preview_Cancel")}
            </button>
          );
        }

        if (applyBtn !== 'Preview' || text.label !== 'Preview') {
          return (
            <button
              style={{
                backgroundColor: text.label === 'Apply' ? '#034A73' : '#F8FAFF',
                color: text.label === 'Apply' ? '#fff' : '#000'
              }}
              key={index}
              onClick={() => { setApplyBtn(text.label) }}
            >
              {text.title}
            </button>
          );
        }

        return null;
      })}
        </div>
    );
};

export default ButtonItem;


