import { MenuItem, Select, SelectChangeEvent } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as SelectArrow } from "../../../../assets/icons/select_arrow_black.svg";
import { useLanguageTheme } from "../../../../hooks/useLanguageTheme";
import i18n from "../../../../i18n";
import { ISortOption, ISortParams } from "../../interfaces";

export const BelongToUserSelect = ({
  options,
  initSort,
  onSortChange,
  styles,
}: {
  options: any[];
  initSort: ISortParams;
  onSortChange: (sortParams: ISortParams) => void;
  styles: any;
}) => {
  const currentLanguage = i18n.language;
  const langTheme = useLanguageTheme();
  const isRTL = currentLanguage === "he";
  const { t } = useTranslation(["portfolio"]);
  const user = localStorage.getItem("user") || sessionStorage.getItem("user");

  const initialSelectedOption = React.useMemo(() => {
    const matchedOption = options.find(
      (option) => option.nameParam === initSort
    );

    return matchedOption || options[0];
  }, [initSort, options]);

  const [selectedOption, setSelectedOption] = React.useState<ISortOption>(
    initialSelectedOption
  );

  React.useEffect(() => {
    setSelectedOption(initialSelectedOption);
  }, [initialSelectedOption]);

  const handleChangeOption = (event: SelectChangeEvent<string>) => {
    const selected = options.find(
      (option) => option.id.toString() === event.target.value
    );

    if (!selected) {
      return;
    }

    setSelectedOption(selected);
    if (selected.nameParam === "all") {
      onSortChange(selected.nameParam);
    } else {
      if (!user) {
        console.error("User data not found");
        return;
      }

      try {
        const userData = JSON.parse(user);
        if (userData && userData.id) {
          onSortChange(userData.id);
        } else {
          console.error("Invalid user data structure");
        }
      } catch (error) {
        console.error("Error parsing user data:", error);
      }
    }
  };

  return (
    <Select
      fullWidth
      MenuProps={{
        slotProps: {
          paper: {
            sx: {
              background: "rgba(255, 255, 255, 1)",
            },
          },
        },
      }}
      sx={{
        direction: i18n.dir(),
        boxSizing: "border-box",
        backgroundColor: styles.backgroundColor,
        borderRadius: styles.borderRadius,
        width: styles.width,
        fontWeight: 500,
        color: "rgba(0, 0, 0, 1)",
        fontFamily: langTheme.mainFont(),
        border: styles.border,
        height: styles.height,
        "& .MuiSelect-select": {
          padding: styles.padding,
          fontSize: styles.fontSize,
        },
        "& .MuiSelect-icon": {
          top: "calc(50% - 0.2em)",
          right: isRTL ? "auto" : "20px",
          left: isRTL ? "20px" : "auto",
        },
        "& .MuiOutlinedInput-notchedOutline": {
          border: "none",
        },
        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
          border: "none",
        },
        "& .MuiOutlinedInput-input": {
          paddingRight: 0,
        },
      }}
      value={selectedOption.id.toString()}
      onChange={handleChangeOption}
      IconComponent={SelectArrow}
    >
      {options.map((option: ISortOption, index: number) => (
        <MenuItem
          value={option.id.toString()}
          key={option.id}
          sx={{
            color: "rgba(0, 0, 0, 1)",
            fontFamily: langTheme.mainFont(),
            fontWeight: 500,
            fontSize: "14px",
            direction: i18n.dir(),
            "&:hover": {
              backgroundColor: "#acc3d954",
            },
          }}
        >
          {t(option.translationKey)}
        </MenuItem>
      ))}
    </Select>
  );
};
