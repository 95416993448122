import React from "react";
import closeIcon from "../../../assets/campaignBuilder/ai-chat/flowbite_close-outline.svg";
import HeartIcon from "../../../assets/campaignBuilder/ai-chat/sticky.svg";
import { Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";

interface AiHeaderProps {
  aiToggle: Dispatch<SetStateAction<boolean>>;
}
export const AiHeader: React.FC<AiHeaderProps> = ({ aiToggle }) => {
    const { t, i18n } = useTranslation(["aiChart"]);

  return (
    <div
      className="ai_header" 
    >
       
        <h2> {t("ai_assistant")}</h2>
        <div className="icon_div">
        {/* <img src={HeartIcon}/>  */}
        <img style={{cursor:'pointer'}} onClick={() => aiToggle(prev => !prev)} src={closeIcon}/> 
        </div>
   
    </div>
  );
};

