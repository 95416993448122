import React from "react";
import { useTranslation } from "react-i18next";
import { Box, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { useLanguageTheme } from "../../../../hooks/useLanguageTheme";
import { ReactComponent as SelectArrow } from "../../../../assets/campaignBuilder/select_arror.svg";
import { selectStyles } from "./CampaingBuilderSelect";
import {
  LabelIconKey,
  LabelIconMap,
} from "../../../../constants/campaignBuilderData";

export interface Option {
  id: string;
  iconKey: LabelIconKey;
  backgroundColor: string;
  labelNameEn: string;
  labelNameHe: string;
  labelName: string;
}

interface LabelSelectProps {
  value: string;
  onChangeValue: (value: string) => void;
  options: Option[];
}

export const LabelSelect: React.FC<LabelSelectProps> = ({
  value,
  onChangeValue,
  options,
}) => {
  const { t } = useTranslation(["campaignBuilder"]);
  const langTheme = useLanguageTheme();
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const isRTL = currentLanguage === "he";

  const filteredOptions =
    options && Array.isArray(options)
      ? options.filter((el) => el.id !== value)
      : options;

  const menuSx =
    filteredOptions.length === 0
      ? { display: "none" }
      : { background: "rgba(232, 239, 242, 1)" };

  const handleChangeLabel = (event: SelectChangeEvent<string>) => {
    onChangeValue(event.target.value as string);
    localStorage.setItem("onChangeCompanyBuilder", "1");
  };

  return (
    <Select
      value={value}
      onChange={handleChangeLabel}
      sx={selectStyles(langTheme.mainFont(), isRTL)}
      MenuProps={{
        PaperProps: {
          sx: { ...menuSx, direction: i18n.dir() },
        },
      }}
      IconComponent={SelectArrow}
      renderValue={(id) => {
        const option = options.find((opt) => opt.id === id);
        if (!option) return null;

        const IconComponent = LabelIconMap[option.iconKey];

        return (
          <Box sx={{ display: "flex", alignItems: "center", gap: "7px" }}>
            {IconComponent && (
              <IconComponent
                isSelected={false}
                width={17}
                height={19}
                backgroundColor={option.backgroundColor}
                iconSelect={false}
              />
            )}
            <div>
              {option[i18n.language === "he" ? "labelNameHe" : "labelNameEn"]}
            </div>
          </Box>
        );
      }}
    >
      {filteredOptions.length > 0 &&
        filteredOptions.map((option, index) => {
          const IconComponent = LabelIconMap[option.iconKey];

          return (
            <MenuItem
              key={`${option.id}-${index}`}
              value={option.id}
              sx={{
                color: "rgba(0, 0, 0, 1)",
                fontFamily: langTheme.mainFont(),
                fontWeight: 500,
                fontSize: "14px",
                gap: "7px",
              }}
            >
              {IconComponent && (
                <IconComponent
                  isSelected={false}
                  width={17}
                  height={19}
                  backgroundColor={option.backgroundColor}
                  iconSelect={false}
                />
              )}
              <div>
                {option[i18n.language === "he" ? "labelNameHe" : "labelNameEn"]}
              </div>
            </MenuItem>
          );
        })}
    </Select>
  );
};
