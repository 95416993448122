import React from "react";
import { useAppDispatch } from "../../store/store";
import { Box, Stack, Theme, Typography, useMediaQuery } from "@mui/material";
import { setOpenSideNav, setAuthPopupOpen } from "../../store/features/common";
import Burger_Menu from "../../assets/icons/burger_menu.svg";
import logo from "../../assets/logo.png";
import Arrow from "../../assets/icons/arrow.svg";
import { LangToggle } from "./LangToggle";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useLanguageTheme } from "../../hooks/useLanguageTheme";
import { useAuth } from "../../hooks/useAuth";
import { ProfileSelect } from "./profileSelect";
import { AuthPopupContentType } from "../auth/types";

const TOP_NAV_HEIGHTUpMd = 106;
const TOP_NAV_HEIGHTDownMd = 81;

export const Header = ({
  children,
  disabled = false,
  isDisplayBack = false,
}: {
  children: React.ReactNode;
  disabled?: boolean;
  isDisplayBack?: boolean;
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation(["navbar"]);
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const langTheme = useLanguageTheme();
  const isAuthenticated = useAuth();

  const isRTL = currentLanguage === "he";
  const downMd = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));
  const downLg = useMediaQuery((theme: Theme) => theme.breakpoints.down("lg"));
  const isXXL = useMediaQuery("(min-width: 2360px)");

  const dispatch = useAppDispatch();

  const handleNavigateToSignIn = () => {
    dispatch(setAuthPopupOpen(AuthPopupContentType.SIGNIN));
  };

  return (
    <div
      style={
        isDisplayBack
          ? {
              display: "flex",
              justifyContent: "center",
              maxWidth: "1340px",
              width: "100%",
              margin: "0 auto",
            }
          : {
              width: "100%",
              display: "flex",
              justifyContent: "center",
            }
      }
    >
      <Box
        component="header"
        sx={{
          backgroundColor: "rgba(255, 255, 255, 1)",
          mx: isXXL ? "100px" : "20px",
        }}
        className="xxl-width"
      >
        <Stack
          alignItems="center"
          direction="row"
          justifyContent="space-between"
          sx={{
            minHeight: downMd ? TOP_NAV_HEIGHTDownMd : TOP_NAV_HEIGHTUpMd,
          }}
        >
          <div
            style={{
              maxWidth: "300px",
            }}
          >
            <img
              src={logo}
              onClick={disabled ? () => {} : () => navigate("/home")}
              style={{
                width: downMd ? "97px" : downLg ? "97px" : "132px",
                cursor: "pointer",
              }}
              alt="logo"
            />
          </div>

          {downMd && (
            <Box display={"flex"} alignItems={"center"}>
              <img
                src={Burger_Menu}
                onClick={
                  disabled
                    ? () => {}
                    : () => {
                        dispatch(setOpenSideNav());
                      }
                }
                alt="burger_menu"
                style={{ marginRight: "20px", cursor: "pointer" }}
              />
            </Box>
          )}

          {!downMd && (
            <Box display={"flex"} justifyContent={"space-between"} mt={"5px"}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  borderRadius: 12,
                }}
              >
                {children}
              </Box>
            </Box>
          )}

          {!downMd && (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: downLg ? "7px" : "25px",
              }}
            >
              {isAuthenticated  ? "" : <LangToggle />}
              <Typography
                onClick={
                  disabled
                    ? () => {}
                    : !isAuthenticated
                    ? handleNavigateToSignIn
                    : () => {}
                }
                sx={{
                  fontSize: downLg ? "14px" : "16px",
                  color: "#000",
                  fontFamily: langTheme.mainFont(),
                  fontWeight: isRTL ? 400 : 500,
                  cursor: "pointer",
                }}
              >
                {isAuthenticated ? (
                  <ProfileSelect disabled={disabled} />
                ) : (
                  t("sign_in")
                )}
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  color: "#fff",
                  backgroundColor: "#034A73",
                  p: downLg ? "15px 25px 15px 25px" : "15px 33px 15px 33px",
                  cursor: disabled ? "auto" : "pointer",
                  borderRadius: "24px",
                  "&:hover": {
                    background: "linear-gradient(to right, #034A73, #0A7992)",
                  },
                  direction: i18n.dir(),
                }}
                onClick={disabled ? () => {} : () => navigate("/contact-us")}
              >
                <Typography
                  sx={{
                    mr: isRTL ? "0" : "12px",
                    ml: isRTL ? "12px" : "0",
                    fontFamily: langTheme.mainFont(),
                    fontWeight: isRTL ? 400 : 500,
                    lineHeight: "16px",
                    fontSize: downLg ? "14px" : "16px",
                  }}
                >
                  {t("contact_us")}
                </Typography>
                <img
                  src={Arrow}
                  alt="arrow"
                  style={{
                    transform: isRTL ? "scaleX(-1)" : " scaleX(1)",
                  }}
                />
              </Box>
            </Box>
          )}
        </Stack>
      </Box>
    </div>
  );
};
