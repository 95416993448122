import React from "react";
import { CampaignSideNavStep } from "../layout/CampaignBuilderSideNav";
import { CampaignBuilderSideNavHeader } from "../layout/CampaignBuilderSideNavHeader";
import { CampainBuilderNextBtn } from "../buttons/CampainBuilderNextBtn";
import { Theme, Typography, useMediaQuery } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useAppDispatch } from "../../../../store/store";
import { useSelector } from "react-redux";
import { ExtractedMediaData } from "../../../../helpers/campaignConvert";
import ReplaceBlack from "../../../../assets/campaignBuilder/replace.svg";
import { truncateString } from "../../../../helpers/formatter";
import {
  removeBrochureFileById,
  removeImgFileById,
  setImgFilesSlice,
} from "../../../../store/features/campaignBuilderSlice";
import Paperclips from "../../../../assets/campaignBuilder/paperclips.svg";
import ReplaceCrossWhite from "../../../../assets/campaignBuilder/white_replace_cross.svg";
import { FilesLoader } from "../../FilesLoader";
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
} from "react-beautiful-dnd";
import { LightTooltip } from "./KeyFeature";
import InfoIcon from "../../../../assets/icons/info_icon_key.svg";
import { useLanguageTheme } from "../../../../hooks/useLanguageTheme";
import { useResetToDraft } from "../../../../hooks/useResetToDraft";
import { LangToggle } from "../../../_shared/LangToggle";
import {
  brochureAcceptFormat,
  MAXIMUM_NUMBER_OF_BROCHURE,
  MAXIMUM_NUMBER_OF_PROPERTY_IMG,
  propertyImgAcceptFormat,
} from "../../constants";
import { BackToMenuBtn } from "../buttons/BackToMenuBtn";
import { FileDropZone } from "../layout/FileDropZone";
import { CampainBuilderBackBtn } from "../buttons/CampainBuilderBackBtn";

export type ImageFormatMap = {
  [key: string]: string[];
};

const reorder = (
  list: ExtractedMediaData[],
  startIndex: number,
  endIndex: number
): ExtractedMediaData[] => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

export const CampaignMediaStep = ({
  setCurrentStep,
  setImgFiles,
  setBrochureFiles,
  isImgsLoading,
  isBrochureLoading,
  autosaveFunc = () => {},
  isDisabledSwitchLang = false,
}: {
  setCurrentStep: (step: CampaignSideNavStep) => void;
  setImgFiles: any;
  setBrochureFiles: React.Dispatch<React.SetStateAction<File[]>>;
  isImgsLoading: boolean;
  isBrochureLoading: boolean;
  autosaveFunc: any;
  isDisabledSwitchLang: boolean;
}) => {
  const dispatch = useAppDispatch();
  const { t, i18n } = useTranslation(["campaignBuilder"]);
  const isRTL = i18n.dir() === "rtl";
  const downMd = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));
  const langTheme = useLanguageTheme();

  const resetToDraft = useResetToDraft();

  const handleNext = (step: CampaignSideNavStep) => {
    //TODO add validations
    setCurrentStep(step);
  };

  const { imgFilesSlice, brochureFilesSlice } = useSelector(
    (state: any) => state.campaignBuilder
  );

  const handleRemoveImgFile = (fileId: number) => {
    dispatch(removeImgFileById(fileId));
    resetToDraft();
  };

  const handleRemoveBrochureFile = (fileId: number) => {
    dispatch(removeBrochureFileById(fileId));
    resetToDraft();
  };

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) return;

    const reordered = reorder(
      imgFilesSlice,
      result.source.index,
      result.destination.index
    );

    dispatch(setImgFilesSlice(reordered));
    resetToDraft();
  };

  return (
    <div className="campaign-builder-side-nav-wrapper">
      <CampaignBuilderSideNavHeader
        header="media"
        leftBtn={
          <CampainBuilderBackBtn
            setCurrentStep={setCurrentStep}
            step={CampaignSideNavStep.mainInformation}
          />
        }
        rightBtn={
          <CampainBuilderNextBtn
            handleNext={handleNext}
            step={CampaignSideNavStep.labels}
          />
        }
      />

      <div
        className="campaign-builder-sidenav-main-content-wrapper"
        style={{
          paddingTop: downMd ? "30px" : "40px",
          direction: i18n.dir(),
        }}
      >
        <div className="campaign-builder-sidenav-settings-fileds-container">
          <div className="campaign-builder-sidenav-settings-fileds-item">
            <div>
              <div
                className="campaign-builder-sidenav-field-name-main"
                style={{
                  display: "flex",
                  gap: "12px",
                }}
              >
                <div>{t("property_images")}</div>
                <LightTooltip
                  title={
                    <Typography
                      sx={{
                        fontSize: "14px",
                        color: "black",
                        padding: "8px",
                        fontFamily: langTheme.mainFont(),
                      }}
                    >
                      {t("imgsTooltip")}
                    </Typography>
                  }
                  arrow
                >
                  <img src={InfoIcon} alt="" />
                </LightTooltip>
              </div>
            </div>

            <FileDropZone
              fileAcceptFormat={propertyImgAcceptFormat}
              limit={MAXIMUM_NUMBER_OF_PROPERTY_IMG}
              setFiles={setImgFiles}
              placeholder={"placeholder_upload_property_img"}
              maxFileSizeMB={10}
            />

            {imgFilesSlice.length > 0 && (
              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="images">
                  {(provided) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.droppableProps}
                      style={{
                        display: "flex",
                        gap: "23px",
                        flexWrap: "wrap",
                        padding: "25px 0 0 0",
                      }}
                    >
                      {imgFilesSlice.map(
                        (item: ExtractedMediaData, index: number) => (
                          <Draggable
                            key={item.id.toString()}
                            draggableId={item.id.toString()}
                            index={index}
                          >
                            {(provided) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                className="drag-and-drop-file-container"
                              >
                                <div className="drag-and-drop-file-container-img-container">
                                  <div
                                    style={{
                                      background: `url(${item.url}) center center / cover no-repeat`,
                                      width: "190px",
                                      height: "110px",
                                    }}
                                  ></div>
                                  <div
                                    style={{
                                      right: isRTL ? "auto" : "-8px",
                                      left: isRTL ? "-8px" : "auto",
                                    }}
                                    className="replace-cross-wrapper"
                                    onClick={() => handleRemoveImgFile(item.id)}
                                  >
                                    <img src={ReplaceBlack} alt="Remove" />
                                  </div>
                                </div>
                                <div className="drag-and-drop-file-name">
                                  {truncateString(item.name, 25)}
                                </div>
                              </div>
                            )}
                          </Draggable>
                        )
                      )}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            )}

            <FilesLoader isLoading={isImgsLoading} />
          </div>

          <div className="campaign-builder-sidenav-settings-fileds-item">
            <div className="campaign-builder-sidenav-field-name-main">
              {t("attachments")}
            </div>

            <FileDropZone
              fileAcceptFormat={brochureAcceptFormat}
              limit={MAXIMUM_NUMBER_OF_BROCHURE}
              setFiles={setBrochureFiles}
              placeholder={"placeholder_upload_property_brochure"}
              maxFileSizeMB={25}
            />
            <div
              style={{
                paddingTop: "20px",
              }}
            >
              {brochureFilesSlice.map((file: any, index: number) => (
                <div key={file.name + index}>
                  <div className="drag-and-drop-file-container-pdf-container">
                    <img src={Paperclips} />
                    <div
                      className="drag-and-drop-pdf-name"
                      style={{
                        margin: isRTL ? "0 5px 0 15px" : "0 15px 0 5px",
                      }}
                    >
                      {truncateString(file.name, 50)}
                    </div>
                    <img
                      src={ReplaceCrossWhite}
                      onClick={() => handleRemoveBrochureFile(file.id)}
                      style={{
                        cursor: "pointer",
                      }}
                    />
                  </div>
                </div>
              ))}
            </div>
            <FilesLoader isLoading={isBrochureLoading} />
          </div>
        </div>
        <BackToMenuBtn setCurrentStep={setCurrentStep} />
      </div>
    </div>
  );
};
