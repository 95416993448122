import { Theme, useMediaQuery } from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import AboutDeveloperIcon from "../../../../assets/campaignBuilder/about_developer.svg";
import AboutPropertyIcon from "../../../../assets/campaignBuilder/about_property.svg";
import ContactIcon from "../../../../assets/campaignBuilder/contact.svg";
import InvestmentHighlightsIcon from "../../../../assets/campaignBuilder/investment_highlights.svg";
import KeyFeatureIcon from "../../../../assets/campaignBuilder/key_features.svg";
import LabelIcon from "../../../../assets/campaignBuilder/label.svg";
import MainInfoIcon from "../../../../assets/campaignBuilder/main_info.svg";
import MediaIcon from "../../../../assets/campaignBuilder/media.svg";
import SettingsIcon from "../../../../assets/campaignBuilder/settings.svg";
import {
  MIN_ABOUT_DEVELOPER_LENGTH_EN,
  MIN_ABOUT_DEVELOPER_LENGTH_HE,
  MIN_ABOUT_PROPERTY_LENGTH_EN,
  MIN_ABOUT_PROPERTY_LENGTH_HE,
} from "../../constants";
import {
  setCampaignStatus,
  setSelectedUserId,
} from "../../../../store/features/campaignBuilderSlice";
import {
  convertDataToCreateProperty,
  extractIds,
} from "../../../../helpers/campaignConvert";
import { CampaignBuilderSideNavHeader } from "../layout/CampaignBuilderSideNavHeader";
import { LangToggle } from "../../../_shared/LangToggle";
import { CampaignBuilderSideNavCloseBtn } from "../buttons/CampaignBuilderSideNavCloseBtn";
import { CampaignSetupItem } from "../layout/CamaignSetupItem";
import { CampaignSideNavStep } from "../layout/CampaignBuilderSideNav";
import { NotificationSnackBar } from "../../NotificationSnackBar";
import { useAppDispatch } from "../../../../store/store";
import {
  useCreatePropertyMutation,
  useUpdatePropertyMutation,
} from "../../../../store/api/campaignBuilderApiSlice";
import {
  useCreateAgentMutation,
  useGetAgentsQuery,
  useUpdateAgentMutation,
} from "../../../../store/api/agentApiSlice";
import { useLazyGetPropertyQuery } from "../../../../store/api/propertyApiSlice";
import { IPropertyLocation } from "../../CampaignBuilderLocationSearch";
import { CampaingBuilderGoToPrevPageBtn } from "../buttons/CampaingBuilderGoToPrevPageBtn";

export enum CampaignCreatingStatus {
  PUBLISH = "publish",
  DRAFT = "draft",
  ARCHIVED = "archived",
  FINISHED = "finished",
}

export enum NotificationStatus {
  SUCCESSFULL = "successfull",
  ERROR = "error",
}

export const CampaignSetUpStep = ({
  setCurrentStep,
  currentStep,
  allFieldsErrors,
  setAllFieldsErrors,
  setHasPublish,
  autosaveFunc = () => {},
  isDisabledSwitchLang = false,
}: {
  setCurrentStep: (step: CampaignSideNavStep) => void;
  currentStep: any;
  allFieldsErrors: any;
  setAllFieldsErrors: any;
  setHasPublish: any;
  autosaveFunc: any;
  isDisabledSwitchLang: boolean;
}) => {
  const { t, i18n } = useTranslation(["campaignBuilder"]);
  const downMd = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));
  const { documentId } = useParams();
  const user = localStorage.getItem("user") || sessionStorage.getItem("user");
  const dispatch = useAppDispatch();
  const isRTL = i18n.dir() === "rtl";

  const {
    language,
    countOfTimePeriods,
    timePeriod,
    propertyTitle,
    propertyDescription,
    propertyStatus,
    currency,
    propertyPrice,
    propertyLocation,
    propertyType,
    aboutPropertyDesc,
    aboutDeveloper,
    keyFeaturesBack,
    hightlightsBack,
    selectedUserId,
    labelBack,
    imgFilesSlice,
    brochureFilesSlice,
    floorPlanFilesSlice,
    videoFilesSlice,
    campaignStatus,
    mainLabelOpen,
    floorPlanSectionOpen,
    mapSectionOpen,
    videoSectionOpen,
    scheduleVewingOpen,
    agentPhotOpen,
    galleryOpen,
    selectedAgent,
    agentAvatarSlice,
  } = useSelector((state: any) => state.campaignBuilder);

  const [createProperty] = useCreatePropertyMutation();
  const [updateProperty] = useUpdatePropertyMutation();
  const navigate = useNavigate();
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarText, setSnackbarText] = useState("");
  const [snackBarType, setSnackbarType] = useState("");

  const [triggerGetProperty, { data: propertyDataFromApi }] =
    useLazyGetPropertyQuery();

  const [createAgent] = useCreateAgentMutation();
  const [updateAgent] = useUpdateAgentMutation();

  const { data: agentsData } = useGetAgentsQuery({
    locale: "en",
  });

  const validateFieldsAndNotify = (
    imgIds: number[],
    propertyTitle: string,
    propertyDescription: string,
    propertyLocation: IPropertyLocation,
    propertyPrice: string,
    aboutPropertyDesc: string,
    aboutDeveloper: string
  ): boolean => {
    const errors: any = {};

    if (
      propertyTitle.trim().length <= 0 ||
      propertyDescription.trim().length <= 0 ||
      propertyLocation.address.length <= 0 ||
      Number(propertyPrice) <= 0
    ) {
      errors.main_information = "main_information";
      setAllFieldsErrors(errors);
      setOpenSnackbar(true);
      setSnackbarType(NotificationStatus.ERROR);
      setSnackbarText(t("snackbar_main_info"));
      return false;
    }

    if (imgIds.length < 3) {
      errors.media = "media";
      setAllFieldsErrors(errors);
      setOpenSnackbar(true);
      setSnackbarType(NotificationStatus.ERROR);
      setSnackbarText(t("snackbarNotEnoughtImg"));
      return false;
    }

    if (
      aboutPropertyDesc.trim().length <= 0 ||
      aboutPropertyDesc.trim().length <
        (isRTL ? MIN_ABOUT_PROPERTY_LENGTH_HE : MIN_ABOUT_PROPERTY_LENGTH_EN)
    ) {
      errors.about_property = "about_property";
      setAllFieldsErrors(errors);
      setOpenSnackbar(true);
      setSnackbarType(NotificationStatus.ERROR);
      setSnackbarText(t("snackbar_about_property_info"));
      return false;
    }

    if (
      aboutDeveloper.trim().length <= 0 ||
      aboutDeveloper.trim().length <
        (isRTL ? MIN_ABOUT_DEVELOPER_LENGTH_HE : MIN_ABOUT_DEVELOPER_LENGTH_EN)
    ) {
      errors.about_developer = "about_developer";
      setAllFieldsErrors(errors);
      setOpenSnackbar(true);
      setSnackbarType(NotificationStatus.ERROR);
      setSnackbarText(t("snackbar_about_developer_info"));
      return false;
    }

    setAllFieldsErrors({});
    return true;
  };

  const handleCreateCampaign = async (
    campaignStatus: CampaignCreatingStatus
  ) => {
    setHasPublish(true);
    try {
      let imgIds = extractIds(imgFilesSlice);
      let brochureIds = extractIds(brochureFilesSlice);
      let floorplanIds = extractIds(floorPlanFilesSlice);
      let videoIds = extractIds(videoFilesSlice);

      if (CampaignCreatingStatus.PUBLISH === campaignStatus) {
        if (
          !validateFieldsAndNotify(
            imgIds,
            propertyTitle,
            propertyDescription,
            propertyLocation,
            propertyPrice,
            aboutPropertyDesc,
            aboutDeveloper
          )
        ) {
          return;
        }
      }

      let agentId = selectedUserId;
      if (selectedUserId === "create-new") {
        const agentData = {
          nameEn: selectedAgent.nameEn,
          nameHe: selectedAgent.nameHe,
          phone: selectedAgent.phone,
          email: "test@example.com",
          photo: agentAvatarSlice[0]?.id || selectedAgent?.photo?.id || [],
          link: selectedAgent.link,
        };

        const agentResponse = await createAgent({
          agentData,
          locale: "en",
        }).unwrap();

        agentId = agentResponse.data.id;

        dispatch(setSelectedUserId(agentResponse.data.id));
      } else if (selectedUserId === 0) {
        if (agentsData && agentsData.length > 0) {
          agentId = agentsData[0].id;
        }
      } else {
        const agentData = {
          nameEn: selectedAgent.nameEn,
          nameHe: selectedAgent.nameHe,
          phone: selectedAgent.phone,
          email: "test@example.com",
          photo: agentAvatarSlice[0]?.id || selectedAgent?.photo?.id || [],
          link: selectedAgent.link,
        };
        await updateAgent({
          agentData,
          documentId: selectedAgent.documentId,
          locale: "en",
        }).unwrap();
      }

      const propertyData = convertDataToCreateProperty(
        countOfTimePeriods,
        timePeriod,
        propertyTitle,
        propertyDescription,
        propertyStatus,
        currency,
        propertyPrice,
        propertyLocation,
        propertyType,
        aboutPropertyDesc,
        aboutDeveloper,
        keyFeaturesBack.map((el: any) => el?.id),
        hightlightsBack.map((el: any) => el?.id),
        agentId,
        [labelBack.id],
        imgIds,
        brochureIds,
        floorplanIds,
        videoIds,
        campaignStatus,
        mainLabelOpen,
        floorPlanSectionOpen,
        mapSectionOpen,
        videoSectionOpen,
        scheduleVewingOpen,
        agentPhotOpen,
        galleryOpen,
        user
      );

      const response = await createProperty({
        propertyData,
        language: language.slice(0, 2),
      }).unwrap();
      await updateProperty({
        propertyData: {
          ...propertyData,
          name: "",
          description: "",
          developer: "",
          aboutProperty: "",
          location: {
            city: "",
            address: "",
            coordinates: {
              latitude: null,
              longitude: null,
            },
            id: "",
          },
        },
        documentId: response.data.documentId,
        language: language.slice(0, 2) === "en" ? "he" : "en",
      }).unwrap();
      navigate("/portfolio");
      setOpenSnackbar(true);
      setSnackbarType(NotificationStatus.SUCCESSFULL);
      localStorage.setItem("onChangeCompanyBuilder", "0");
      setSnackbarText(t("snackbarSuccessfulCreated"));
    } catch (error: any) {
      console.log("log: ", error);
      if (
        error.data.error.message ===
        "1 relation(s) of type plugin::users-permissions.user associated with this entity do not exist"
      ) {
        localStorage.removeItem("jwt");
        localStorage.removeItem("user");
        sessionStorage.removeItem("jwt");
        sessionStorage.removeItem("user");
        if (window.location.pathname === "/home") {
          window.location.reload();
        } else {
          navigate("/home");
        }
      }
    }
  };
  const handleUpdateCampaign = async (
    campaignStatus: CampaignCreatingStatus
  ) => {
    try {
      let agentId = selectedUserId;
      if (selectedUserId === "create-new") {
        const agentData = {
          nameEn: selectedAgent.nameEn,
          nameHe: selectedAgent.nameHe,
          phone: selectedAgent.phone,
          email: "test@example.com",
          photo: agentAvatarSlice[0]?.id || selectedAgent?.photo?.id || [],
          link: selectedAgent.link,
        };

        const agentResponse = await createAgent({
          agentData,
          locale: "en",
        }).unwrap();

        agentId = agentResponse.data.id;

        dispatch(setSelectedUserId(agentResponse.data.id));
      } else if (selectedUserId === 0) {
        if (agentsData && agentsData.length > 0) {
          agentId = agentsData[0].id;
        }
      } else {
        const agentData = {
          nameEn: selectedAgent.nameEn,
          nameHe: selectedAgent.nameHe,
          phone: selectedAgent.phone,
          email: "test@example.com",
          photo: agentAvatarSlice[0]?.id || selectedAgent?.photo?.id || [],
          link: selectedAgent.link,
        };
        await updateAgent({
          agentData,
          documentId: selectedAgent.documentId,
          locale: "en",
        }).unwrap();
      }

      let imgIds = extractIds(imgFilesSlice);
      let brochureIds = extractIds(brochureFilesSlice);
      let floorplanIds = extractIds(floorPlanFilesSlice);
      let videoIds = extractIds(videoFilesSlice);

      if (CampaignCreatingStatus.PUBLISH === campaignStatus) {
        setHasPublish(true);

        if (
          !validateFieldsAndNotify(
            imgIds,
            propertyTitle,
            propertyDescription,
            propertyLocation,
            propertyPrice,
            aboutPropertyDesc,
            aboutDeveloper
          )
        ) {
          return;
        }
      }

      const propertyData = convertDataToCreateProperty(
        countOfTimePeriods,
        timePeriod,
        propertyTitle,
        propertyDescription,
        propertyStatus,
        currency,
        propertyPrice,
        propertyLocation,
        propertyType,
        aboutPropertyDesc,
        aboutDeveloper,
        keyFeaturesBack.map((el: any) => el?.id),
        hightlightsBack.map((el: any) => el?.id),
        agentId,
        [labelBack.id],
        imgIds,
        brochureIds,
        floorplanIds,
        videoIds,
        campaignStatus,
        mainLabelOpen,
        floorPlanSectionOpen,
        mapSectionOpen,
        videoSectionOpen,
        scheduleVewingOpen,
        agentPhotOpen,
        galleryOpen,
        user
      );

      await updateProperty({
        propertyData,
        documentId: documentId,
        language: i18n.language,
      }).unwrap();
      const dataForDiffLang = await triggerGetProperty({
        documentId: documentId ?? "",
        locale: i18n.language === "en" ? "he" : "en",
      });
      await updateProperty({
        propertyData: {
          ...propertyData,
          name: dataForDiffLang?.data?.name,
          description: dataForDiffLang?.data?.description,
          developer: dataForDiffLang?.data?.developer,
          aboutProperty: dataForDiffLang?.data?.aboutProperty,
          location: dataForDiffLang?.data.location,
        },
        documentId: documentId,
        language: i18n.language === "en" ? "he" : "en",
      }).unwrap();
      setOpenSnackbar(true);
      setSnackbarType(NotificationStatus.SUCCESSFULL);
      localStorage.setItem("onChangeCompanyBuilder", "0");
      setSnackbarText(t("snackbarSuccessfulUpdated"));
      dispatch(setCampaignStatus(CampaignCreatingStatus.PUBLISH));
    } catch (error: any) {
      console.log("log: ", error);
      if (
        error.data.error.message ===
        "1 relation(s) of type plugin::users-permissions.user associated with this entity do not exist"
      ) {
        localStorage.removeItem("jwt");
        localStorage.removeItem("user");
        sessionStorage.removeItem("jwt");
        sessionStorage.removeItem("user");
        if (window.location.pathname === "/home") {
          window.location.reload();
        } else {
          navigate("/home");
        }
      }
    }
  };

  React.useEffect(() => {
    const newErrors = { ...allFieldsErrors };

    if (imgFilesSlice.length >= 3) {
      delete newErrors.media;
    }
    setAllFieldsErrors(newErrors);
  }, [imgFilesSlice]);

  return (
    <div className="campaign-builder-side-nav-wrapper">
      <CampaignBuilderSideNavHeader
        header="campaign_set_up"
        leftBtn={
          <div
            style={{
              width: "24px",
            }}
          ></div>
        }
        rightBtn={<CampaignBuilderSideNavCloseBtn />}
      />
      <div
        className="campaign-builder-sidenav-main-content-wrapper"
        style={{
          height: "100%",
        }}
      >
        <div
          className="campaign-builder-sidenav-main-content-set-up"
          style={{
            paddingTop: downMd ? "30px" : "40px",
            height: "100%",
          }}
        >
          <CampaignSetupItem
            title="settings"
            icon={SettingsIcon}
            handleOpenStep={() => setCurrentStep(CampaignSideNavStep.settings)}
          />
          <CampaignSetupItem
            title="main_information"
            icon={MainInfoIcon}
            handleOpenStep={() =>
              setCurrentStep(CampaignSideNavStep.mainInformation)
            }
            errors={allFieldsErrors}
          />
          <CampaignSetupItem
            title="media"
            icon={MediaIcon}
            handleOpenStep={() => setCurrentStep(CampaignSideNavStep.media)}
            errors={allFieldsErrors}
          />
          <CampaignSetupItem
            title="labels"
            icon={LabelIcon}
            handleOpenStep={() => setCurrentStep(CampaignSideNavStep.labels)}
          />
          <CampaignSetupItem
            title="investment_highlights"
            icon={InvestmentHighlightsIcon}
            handleOpenStep={() => {
              setCurrentStep(CampaignSideNavStep.investmentHighlights);
              if (!documentId) {
                autosaveFunc();
              }
            }}
          />
          <CampaignSetupItem
            title="about_property"
            icon={AboutPropertyIcon}
            handleOpenStep={() =>
              setCurrentStep(CampaignSideNavStep.aboutProperty)
            }
            errors={allFieldsErrors}
          />
          <CampaignSetupItem
            title="key_features"
            icon={KeyFeatureIcon}
            handleOpenStep={() => {
              setCurrentStep(CampaignSideNavStep.keyFeatures);
              if (!documentId) {
                autosaveFunc();
              }
            }}
          />
          <CampaignSetupItem
            title="about_developer"
            icon={AboutDeveloperIcon}
            handleOpenStep={() =>
              setCurrentStep(CampaignSideNavStep.aboutDeveloper)
            }
            errors={allFieldsErrors}
          />
          <CampaignSetupItem
            title="contact_information"
            icon={ContactIcon}
            handleOpenStep={() =>
              setCurrentStep(CampaignSideNavStep.contactInformation)
            }
          />
        </div>

        <div className="campaign-builder-sidenav-footer-btns-container">
          {documentId ? (
            <>
              {campaignStatus === CampaignCreatingStatus.DRAFT ? (
                <>
                  <div
                    className="campaign-builder-sidenav-white-btn"
                    onClick={() =>
                      handleUpdateCampaign(CampaignCreatingStatus.PUBLISH)
                    }
                  >
                    <div>{t("publish_the_campaign")}</div>
                  </div>

                  <div
                    className="campaign-builder-sidenav-blue-btn"
                    onClick={() =>
                      handleUpdateCampaign(CampaignCreatingStatus.DRAFT)
                    }
                  >
                    <div>{t("save_as_draft")}</div>
                  </div>
                </>
              ) : campaignStatus === CampaignCreatingStatus.FINISHED ? (
                <>
                  <div
                    className="campaign-builder-sidenav-white-btn"
                    onClick={() =>
                      handleUpdateCampaign(CampaignCreatingStatus.PUBLISH)
                    }
                  >
                    <div>{t("resume_the_campaign")}</div>
                  </div>

                  <div
                    className="campaign-builder-sidenav-blue-btn"
                    onClick={() =>
                      handleUpdateCampaign(CampaignCreatingStatus.ARCHIVED)
                    }
                  >
                    <div>{t("archive_the_campaign_move")}</div>
                  </div>
                </>
              ) : campaignStatus === CampaignCreatingStatus.ARCHIVED ? (
                <>
                  <div
                    className="campaign-builder-sidenav-white-btn"
                    onClick={() =>
                      handleUpdateCampaign(CampaignCreatingStatus.PUBLISH)
                    }
                  >
                    <div>{t("resume_the_campaign")}</div>
                  </div>
                </>
              ) : (
                <>
                  <div
                    className="campaign-builder-sidenav-white-btn"
                    onClick={() =>
                      handleUpdateCampaign(CampaignCreatingStatus.PUBLISH)
                    }
                  >
                    <div>{t("update_the_campaign")}</div>
                  </div>

                  <div
                    className="campaign-builder-sidenav-blue-btn"
                    onClick={() =>
                      handleUpdateCampaign(CampaignCreatingStatus.DRAFT)
                    }
                  >
                    <div>{t("save_as_draft")}</div>
                  </div>
                </>
              )}
            </>
          ) : (
            <>
              <div
                className="campaign-builder-sidenav-white-btn"
                onClick={() =>
                  handleCreateCampaign(CampaignCreatingStatus.PUBLISH)
                }
              >
                <div>{t("publish_the_campaign")}</div>
              </div>

              <div
                className="campaign-builder-sidenav-blue-btn"
                onClick={() =>
                  handleCreateCampaign(CampaignCreatingStatus.DRAFT)
                }
              >
                <div>{t("save_as_draft")}</div>
              </div>
            </>
          )}
        </div>
      </div>
      <NotificationSnackBar
        snackbarText={snackbarText}
        onClose={() => setOpenSnackbar(false)}
        type={snackBarType}
        open={openSnackbar}
      />
    </div>
  );
};
