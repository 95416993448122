import { Autocomplete } from "@react-google-maps/api";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import CrossBlue from "../../assets/campaignBuilder/cross_blue.svg";
import { useLanguageTheme } from "../../hooks/useLanguageTheme";
import { useAppDispatch } from "../../store/store";

export const OneGoogleLocationSearch = ({
  selectedLocation,
  onChange,
  className,
  clearIcon,
  clearIconStyles,
}: {
  selectedLocation: any;
  onChange: any;
  className: string;
  clearIcon: boolean;
  clearIconStyles?: any;
}) => {
  const { t, i18n } = useTranslation(["portfolio"]);
  const isRTL = i18n.dir() === "rtl";
  const [autocomplete, setAutocomplete] = useState<any>(null);
  const [selectedPlace, setSelectedPlace] = useState<any>(null);
  const inputRef = useRef<any>(null);

  const onLoad = (autocompleteInstance: any) => {
    setAutocomplete(autocompleteInstance);
  };
  const langTheme = useLanguageTheme();

  const dispatch = useAppDispatch();

  const [value, setValue] = useState("");

  useEffect(() => {
    if (selectedLocation && selectedLocation.length > 0) {
      setValue(selectedLocation[0].custom_adress);
    } else {
      setValue("");
    }
  }, [selectedLocation]);

  const extractCity = (place: any) => {
    if (!place.address_components) return "";
    for (const component of place.address_components) {
      if (component.types.includes("locality")) {
        return component.long_name;
      }
    }
    return "";
  };

  const onPlaceChanged = () => {
    if (autocomplete) {
      const place = autocomplete.getPlace();

      if (place && place.formatted_address) {
        const latitude = place.geometry.location.lat();
        const longitude = place.geometry.location.lng();
        const city = extractCity(place);

        setSelectedPlace({ ...place, latitude, longitude, city });

        onChange([
          {
            ...place,
            latitude,
            longitude,
            city,
            custom_adress: inputRef.current.value,
          },
        ]);

        setValue(inputRef.current.value);
      }
    }
  };

  const handleClear = () => {
    setValue("");
    onChange([]);
  };

  useEffect(() => {
    const direction = i18n.dir();
    const observer = new MutationObserver(() => {
      const pacItems = document.querySelectorAll(".pac-item");
      pacItems.forEach((item: any) => {
        item.style.textAlign = direction === "rtl" ? "right" : "left";
      });
    });

    observer.observe(document.body, {
      childList: true,
      subtree: true,
    });

    return () => {
      observer.disconnect();
    };
  }, [i18n.language]);

  if (typeof window.google === "undefined") {
    return (
      <div>
        <input
          type="text"
          ref={inputRef}
          className={className}
          placeholder={t("location_placeholder")}
          style={{
            fontFamily: langTheme.mainFont(),
          }}
          value={value}
          onChange={(e) => setValue(e.target.value)}
        />
      </div>
    );
  }

  return (
    <div className="location-search-absolute">
      <Autocomplete
        onLoad={onLoad}
        onPlaceChanged={onPlaceChanged}
        options={{
          componentRestrictions: { country: "il" },
        }}
      >
        <input
          type="text"
          ref={inputRef}
          className={className}
          placeholder={t("location_placeholder")}
          style={{
            fontFamily: langTheme.mainFont(),
          }}
          value={value}
          onChange={(e) => setValue(e.target.value)}
        />
      </Autocomplete>
      {clearIcon && (
        <div
          onClick={handleClear}
          className="clear-location"
          style={{
            ...clearIconStyles,
            left: isRTL ? "15px" : "auto",
            right: isRTL ? "auto" : "15px",
          }}
        >
          <img src={CrossBlue} />
        </div>
      )}
    </div>
  );
};
