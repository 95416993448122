import { Dispatch, SetStateAction, useEffect, useRef } from "react";
import { Theme, useMediaQuery } from "@mui/material";
import { useTranslation } from "react-i18next";
import { IConvertedArticleContent } from "../../helpers/convert";

export const ArticleMainContent = ({
  items,
  setActiveItemId,
}: {
  items: IConvertedArticleContent[];
  setActiveItemId: Dispatch<SetStateAction<string | null>>;
}) => {
  const { i18n, t } = useTranslation(["learningCenter"]);
  const downMd = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));
  const downLg = useMediaQuery((theme: Theme) => theme.breakpoints.down("lg"));

  const isRTL = i18n.dir() === "rtl";
  const containerRef = useRef<any>();

  useEffect(() => {
    if (!containerRef.current) return;

    const observerOptions = {
      threshold: 0,
      rootMargin: "-50% 0px -50% 0px",
    };

    const handleIntersect = (entries: IntersectionObserverEntry[]) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setActiveItemId(entry.target.id);
        }
      });
    };

    const observer = new IntersectionObserver(handleIntersect, observerOptions);
    const sections = containerRef.current.querySelectorAll(".article-section");

    sections.forEach((section: Element) => observer.observe(section));

    return () => {
      sections.forEach((section: Element) => observer.unobserve(section));
    };
  }, [setActiveItemId, items]);

  return (
    <div
      ref={containerRef}
      className="article-main-content"
      style={{
        marginRight: isRTL ? "0" : !downLg ? "120px" : "50px",
        marginLeft: isRTL ? (!downLg ? "120px" : "50px") : "0",
        paddingTop: downMd ? "35px" : "50px",
        direction: i18n.dir(),
      }}
    >
      {items.map((item: IConvertedArticleContent) => (
        <div
          key={item.id}
          id={item.id}
          style={{
            marginBottom: downMd ? "35px" : "50px",
          }}
          className="article-section"
        >
          <div
            className="article-chapter"
            style={{
              fontSize: downMd ? "20px" : "24px",
              marginBottom: downMd ? "15px" : "25px",
              lineHeight: downMd ? "24px" : "22px",
            }}
          >
            {item.title}
          </div>
          {Array.isArray(item.content) &&
            item.content.map((el: any, index: number) => (
              <div key={index}>
                <div
                  className="article-content"
                  key={`${item.id}-content-${index}`}
                >
                  {el}
                </div>
                <br />
              </div>
            ))}
        </div>
      ))}
    </div>
  );
};
