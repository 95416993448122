import { Box, Drawer, Tab, Tabs } from "@mui/material";
import { useTranslation } from "react-i18next";
import React from "react";
import PropTypes from "prop-types";
import Sparkles from "../../assets/icons/sparkles.svg";
import { BuyTabPanelContent } from "./BuyTabPanelContent";
import { AskAITabPanelContent } from "./AskAITabPanelContent";
import { useAppDispatch } from "../../store/store";
import {
  filterSideBarOpenSelector,
  setFilterSideBarClose,
} from "../../store/features/portfolioSlice";
import { useSelector } from "react-redux";
import { useLanguageTheme } from "../../hooks/useLanguageTheme";
import { DEFAULT_PRICE_RANGE } from "../portfolio/constants";
import { IFilterParams } from "../portfolio/interfaces";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ px: "20px", height: "100%" }}>
          <Box height={"100%"}>{children}</Box>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index: number) {
  return {
    id: `tab-${index}`,
    "aria-controls": `tabpanel-${index}`,
  };
}

export const FilterSideBar = ({
  filters,
  onChangeFilters,
  search,
  onChangeSearch,
  initCampaingStatus,
  onCampaingChange,
  initUserId,
  onUserIdChange,
  showPortfolioFilters
}: {
  filters: IFilterParams;
  onChangeFilters: any;
  search: string;
  onChangeSearch: (search: string) => void;
  initCampaingStatus?: any;
  onCampaingChange?: any;
  initUserId?: any;
  onUserIdChange?: any;
  showPortfolioFilters: boolean
}) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation(["home"]);
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const isRTL = currentLanguage === "he";
  const langTheme = useLanguageTheme();

  const filterSideBarOpen = useSelector(filterSideBarOpenSelector);

  const [selectedTab, setSelectedTab] = React.useState(0);
  const [searchValue, setSearchValue] = React.useState(search);

  const [priceRangeValue, setPriceRangeValue] = React.useState(
    filters.priceRange
  );
  const [locationValues, setLocationValues] = React.useState(filters.locations);
  const [statusValues, setStatusValues] = React.useState(filters.statuses);
  const [typeValues, setTypeValues] = React.useState(filters.types);

  const handleChangeSelectedTab = (
    event: React.SyntheticEvent,
    newValue: number
  ) => {
    setSelectedTab(newValue);
  };

  const handleClear = () => {
    setSearchValue("");
    setLocationValues([]);
    setStatusValues([]);
    setTypeValues([]);
    setPriceRangeValue(DEFAULT_PRICE_RANGE);
  };

  React.useEffect(() => {
    setSearchValue(search);
    setLocationValues(filters.locations);
    setTypeValues(filters.types);
    setStatusValues(filters.statuses);
    setPriceRangeValue(filters.priceRange);
  }, [filters, search]);

  return (
    <Drawer
      sx={{
        "& .MuiPaper-root": {
          borderRadius: "18px 18px 0 0",
          height: "630px",
        },
      }}
      anchor={"bottom"}
      open={filterSideBarOpen}
      onClose={() => dispatch(setFilterSideBarClose())}
    >
      <div
        className="filter-side-bar-container "
        style={{
          paddingTop: "18px",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <div
            className="filter-side-bar-line"
            style={{
              width: "160px",
              height: "4px",
              backgroundColor: "rgba(0, 0, 0, 0.17)",
              borderRadius: "3px",
            }}
          ></div>
        </div>
        <div
          className="filter-side-title-menu"
          style={{
            flexDirection: isRTL ? "row-reverse" : "row",
          }}
        >
          <span
            style={{
              fontWeight: 600,
              fontFamily: langTheme.mainFont(),
            }}
          >
            {t("filters")}
          </span>
          <span
            onClick={handleClear}
            style={{
              fontWeight: isRTL ? 700 : 500,
              fontFamily: langTheme.mainFont(),
              color: "rgba(3, 74, 115, 1)",
              cursor: "pointer",
            }}
          >
            {t("clear")}
          </span>
        </div>

        <div
          style={{
            direction: i18n.dir(),
            height: selectedTab === 2 ? "100%" : "auto",
          }}
        >
          <Tabs
            value={selectedTab}
            onChange={handleChangeSelectedTab}
            sx={{
              "& .MuiTabs-indicator": {
                backgroundColor: "rgba(215, 161, 72, 1)",
              },
              "& .MuiTabs-scroller": {
                borderBottom: "1px solid rgba(0, 0, 0, 0.09)",
              },
              display: "flex",
              alignItems: "center",
            }}
            orientation="horizontal"
          >
            <Tab
              label={t("buy")}
              sx={{
                textTransform: "none",
                color:
                  selectedTab === 0 ? "rgba(0, 0, 0, 1)" : "rgba(0, 0, 0, 0.5)",
                fontWeight: 500,
                "&.Mui-selected": {
                  color: "rgba(0, 0, 0, 1)",
                },
                p: 0,
                mb: "10px",
                minHeight: "fit-content",
                minWidth: "fit-content",
                mr: isRTL ? "20px" : "35px",
                ml: isRTL ? "35px" : "20px",
                fontSize: "16px",
              }}
            />
            <Tab
              label=<div
                style={{
                  display: "flex",
                  flexDirection: isRTL ? "row-reverse" : "row",
                  alignItems: "center",
                  gap: "6px",
                }}
              >
                <img src={Sparkles} />
                <div
                  style={{
                    marginTop: 0,
                    marginBottom: 0,
                    fontSize: "16px",
                  }}
                >
                  {t("search")}
                </div>
              </div>
              sx={{
                textTransform: "none",
                color:
                  selectedTab === 2
                    ? "rgba(0, 0, 0, 1)"
                    : "rgba(104, 191, 210, 1)",
                fontWeight: 500,
                p: 0,
                mb: "10px",
                minHeight: "fit-content",
                minWidth: "fit-content",
                mr: isRTL ? 0 : "35px",
                ml: isRTL ? "35px" : "0",
                "&.Mui-selected": {
                  color: "rgba(0, 0, 0, 1)",
                },
              }}
            />
          </Tabs>

          <TabPanel value={selectedTab} index={0}>
            <BuyTabPanelContent
              filters={filters}
              onChangeFilters={onChangeFilters}
              setSearchValue={setSearchValue}
              priceRangeValue={priceRangeValue}
              setPriceRangeValue={setPriceRangeValue}
              locationValues={locationValues}
              setLocationValues={setLocationValues}
              statusValues={statusValues}
              setStatusValues={setStatusValues}
              typeValues={typeValues}
              setTypeValues={setTypeValues}
              initCampaingStatus={initCampaingStatus}
              onCampaingChange={onCampaingChange}
              initUserId={initUserId}
              onUserIdChange={onUserIdChange}
              showPortfolioFilters={showPortfolioFilters}
            />
          </TabPanel>

          <TabPanel value={selectedTab} index={1}>
            <AskAITabPanelContent
              searchValue={searchValue}
              setSearchValue={setSearchValue}
              onSearchChange={onChangeSearch}
            />
          </TabPanel>
        </div>
      </div>
    </Drawer>
  );
};
