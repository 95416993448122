import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import DefaultArticleImg from "../../../assets/learningCenter/building.png";
import { useLanguageTheme } from "../../../hooks/useLanguageTheme";
import { truncateStringWithEllipsis, formatDateWithMonthByLocale } from "../../../helpers/formatter";
import { IArticle } from "../interfaces";
import { IWhiteTopCardStyles } from "./TopArticleSection";

export const WhiteMiddleArcitleCard = ({
  article,
  styles,
  showClueContainer,
  descTrancateLength,
}: {
  article: IArticle;
  styles: IWhiteTopCardStyles;
  showClueContainer: boolean;
  descTrancateLength: number;
}) => {
  const { i18n, t } = useTranslation(["learningCenter"]);
  const langTheme = useLanguageTheme();
  const isRTL = i18n.dir() === "rtl";
  const navigate = useNavigate();

  return (
    <div
      className="white-middle-article-card"
      style={{
        direction: i18n.dir(),
        fontFamily: langTheme.mainFont(),
        minWidth: styles.minWidth,
        height: styles.height,
      }}
      onClick={() => navigate(`/learning-center/${article.documentId}`)}
    >
      <div
        style={{
          transform: isRTL ? "scaleX(-1)" : " scaleX(1)",
          background: `url(${
            !article?.mediaContent || article?.mediaContent?.length === 0
              ? DefaultArticleImg
              : article?.mediaContent[0]?.url
          }) center center / cover no-repeat`,
          flex: styles.imgFlex,
        }}
        className="white-middle-article-card-img"
      ></div>
      <div className="white-middle-article-card-info-wrapper" style={{
        padding: styles.padding
      }}>
        <div>
          {showClueContainer && (
            <div className="main-blue-acticle-clue-container">
              {article?.tags.map((tag, index) => (
                <div className="clue-blue" key={index}>
                  {t(tag.title)}
                </div>
              ))}
            </div>
          )}

          <div
            className="white-article-card-title"
            style={{
              fontSize: styles.fontSize,
              lineHeight: styles.lineHeight,
              fontFamily: isRTL ? "Noto Sans Hebrew" : "Frank Ruhl Libre",
            }}
            lang={i18n.language}
          >
            {article?.title}
          </div>
          <div className="white-article-card-desc">
            {truncateStringWithEllipsis(
              article?.shortDescription,
              descTrancateLength
            )}
          </div>
        </div>

        <div className="main-white-acticle-additional-info-wrapper">
          <div>
            {formatDateWithMonthByLocale(
              article?.createdAt,
              isRTL ? "he-IL" : "en-US"
            )}
          </div>
          <div className="main-white-acticle-additional-info-line"></div>
          <div>
            {article?.minutesToRead} {t("min_read")}
          </div>
        </div>
      </div>
    </div>
  );
};
