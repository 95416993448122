import { FetchBaseQueryMeta } from "@reduxjs/toolkit/query";
import {
  IContactUsUserData,
  IGetContactData,
  SendContactFormParams,
} from "../../components/contactUs/interfaces";
import { apiSlice } from "./apiSlice";

export const contactUsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getContactUsInfo: builder.query<IGetContactData, string>({
      query: (lang: string) => {
        return `/contact-info?locale=${lang}`;
      },
    }),

    sendContactUsForm: builder.mutation<any, SendContactFormParams>({
        query: ({ credentials, lang }) => ({
          url: `/feedback-forms?locale=${lang}`,
          method: "POST",
          body: { data: credentials },
        }),
        transformResponse: (response, meta:FetchBaseQueryMeta) => {
          return {
            data: response,
            status: meta?.response?.status
          };
        },
      }),
  }),
});

export const { useGetContactUsInfoQuery, useSendContactUsFormMutation } =
  contactUsApiSlice;
