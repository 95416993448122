import { useAppDispatch } from "../../../../store/store";
import CloseIcon from "../../../../assets/icons/builderClose.svg";
import { setCampaingBuilderSideBarClose } from "../../../../store/features/common";
import { useTranslation } from "react-i18next";

export const CampaignBuilderSideNavCloseBtn = () => {
  const dispatch = useAppDispatch();
  const { i18n } = useTranslation();

  return (
    <div
      onClick={() => dispatch(setCampaingBuilderSideBarClose())}
      className="campaign-builder-sidenav-close"
      style={{
        transform: i18n.dir() === "rtl" ? "scaleX(-1)" : "scaleX(1)",
      }}
    >
      <img src={CloseIcon} />
    </div>
  );
};
