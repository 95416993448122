import React, { Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";
import FingerPrint from "../../assets/icons/fingerprint.svg";
import { TextField } from "@mui/material";
import { BackToLoginBtn } from "./BackToLoginBtn";
import { authTextFieldStyles, ISignupError } from "../../pages/Signup";
import { useLanguageTheme } from "../../hooks/useLanguageTheme";
import { FormContainer, useForm } from "react-hook-form-mui";
import { useForgotPasswordMutation } from "../../store/api/authApiSlice";

export interface IPasswordResetEnterEmailData {
  email: string;
}

export const PasswordResetEnterEmailStep = ({
  onChangeStep,
  email,
  setEmail,
}: {
  onChangeStep: () => void;
  email: string;
  setEmail: Dispatch<SetStateAction<string>>;
}) => {
  const { t } = useTranslation(["signin"]);
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const isRTL = currentLanguage === "he";
  const langTheme = useLanguageTheme();

  const [errors, setErrors] = React.useState<Partial<ISignupError>>({});
  const [submitError, setSubmitError] = React.useState("");

  const [forgotpassword] = useForgotPasswordMutation();

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setEmail(value);

    const newErrors = { ...errors };

    if (!value.trim()) {
      newErrors.email = t("email_required");
    } else if (!/\S+@\S+\.\S+/.test(value)) {
      newErrors.email = t("email_invalid");
    } else {
      delete newErrors.email;
    }
    setErrors(newErrors);
  };

  const formContext = useForm<IPasswordResetEnterEmailData>({
    defaultValues: {
      email: "",
    },
    reValidateMode: "onChange",
    shouldUseNativeValidation: false,
    mode: "onSubmit",
  });

  const onSubmit = async (data: IPasswordResetEnterEmailData) => {
    const newErrors: Partial<ISignupError> = {};

    if (!email.trim()) {
      newErrors.email = t("email_required");
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      newErrors.email = t("email_invalid");
    }

    setErrors(newErrors);

    if (Object.keys(newErrors).length === 0) {
      try {
        const response = await forgotpassword({
          email: email,
        });

        if (response.data.ok === true) {
          onChangeStep();
        }
      } catch (error) {
        console.log("log: ", error);
        setSubmitError(t("registration.error.unableToComplete"));
      }
    }
  };

  return (
    <div className="forgot-p-container" style={{ flexDirection: "column" }}>
      <div className="forgot-p-fingerprint-container">
        <img src={FingerPrint} />
      </div>

      <div
        className="forgot-p-title"
        style={{
          fontFamily: isRTL ? "Noto Sans Hebrew" : "Frank Ruhl Libre",
        }}
      >
        {t("forgot_password")}
      </div>

      <div
        className="forgot-p-desc"
        style={{
          fontFamily: langTheme.mainFont(),
        }}
      >
        {t("forgot_p_no_worries")}
      </div>

      <div
        style={{
          width: "100%",
          direction: i18n.dir(),
        }}
      >
        <FormContainer formContext={formContext}>
          <div
            className="signin-form-inputs-label"
            style={{
              fontFamily: langTheme.mainFont(),
            }}
          >
            {t("email")}
          </div>
          <TextField
            type="email"
            fullWidth
            required
            value={email}
            onChange={handleEmailChange}
            error={!!errors.email}
            helperText={errors.email || " "}
            placeholder={t("email_placeholder")}
            FormHelperTextProps={{
              sx: {
                textAlign: isRTL ? "right" : "left",
                fontFamily: langTheme.mainFont(),
              },
            }}
            inputProps={{
              maxLength: 254,
            }}
            sx={(theme) => authTextFieldStyles.textField(theme, isRTL, false)}
          />

          {submitError && (
            <div
              className="submit-error"
              style={{
                fontFamily: langTheme.mainFont(),
                direction: i18n.dir(),
              }}
            >
              {submitError}
            </div>
          )}

          <button
            onClick={formContext.handleSubmit(onSubmit)}
            type="button"
            className="signin-form-inputs-signin-btn"
            style={{
              fontFamily: langTheme.mainFont(),
              fontWeight: isRTL ? 700 : 500,
            }}
          >
            {t("reset_password")}
          </button>
        </FormContainer>
      </div>

      <BackToLoginBtn />
    </div>
  );
};
