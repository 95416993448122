import React, { useState } from 'react';
import { Wishlist } from '../components/profile/Wishlist';
import { Box, useMediaQuery, useTheme, IconButton, Divider } from '@mui/material';
import SidebarProfile from '../components/profile/SideBarProfile';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../hooks/useAuth';
import BurgerIcon from '../assets/icons/burger_menu.svg'

function Profile() {
  const navigate = useNavigate();
  const isAuthenticated = useAuth();
  if (!isAuthenticated) {
    navigate('/signin');
  }

  const [selectedOption, setSelectedOption] = useState('wishlist');
  const [mobileOpen, setMobileOpen] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleOptionChange = (option: string) => {
    setSelectedOption(option);
  };

  const renderRightComponent = () => {
    switch (selectedOption) {
      case 'personal_details':
        return '';
      case 'wishlist':
        return <Wishlist isMobile={isMobile} />;
      case 'account_settings':
        return '';
      case 'security':
        return '';
      case 'meetings':
        return '';
      case 'notifications':
        return '';
      case 'ai_assistant':
        return '';
      default:
        return <Wishlist isMobile={isMobile} />;
    }
  };

  return (
    <>
      {isMobile && (
        <Box
          display="flex"
          justifyContent="space-between"
          sx={{
            width: '100%',
            p: 0,
            height: '80px',
            // boxShadow: '0 4px 6px -4px rgba(0, 0, 0, 0.2);',
          }}
        >
          <Box
            sx={{
              p: 1,
              mb: 1,
              cursor: 'pointer',
              width: '80%',
            }}
            onClick={() => navigate('/home')}
          >
            <img
              src="logo.png"
              alt="Logo"
              style={{ width: '100px', padding: 0 }}
            />
          </Box>
          <Box>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{
                position: 'fixed',
                top: 16,
                right: 16,
                zIndex: theme.zIndex.appBar + 1,
                bgcolor: '#FFFFFF',
              }}
            >
              <img src={BurgerIcon} alt="" />
            </IconButton>
          </Box>
          <Divider />
        </Box>
      )}
      <Box
        sx={{
          display: 'flex',
          backgroundColor: '#F5F7FF',
          width: '100%',
          m: 0,
          p:0
        }}
      >
        <Box
          sx={{
            display: { xs: 'none', sm: 'block' },
            width: { sm: '240px' },
          }}
        >
          <SidebarProfile
            onOptionChange={handleOptionChange}
            selectedOption={selectedOption}
            mobileOpen={mobileOpen}
            handleDrawerToggle={handleDrawerToggle}
            isMobile={isMobile}
          />
        </Box>
        <Box
          sx={{
            pt: isMobile ? 0 : 3,
            pr: 0,
            pl: { xs: 0, sm: '0' },
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
            {renderRightComponent()}
        </Box>
      </Box>
    </>
  );
}

export default Profile;
