import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { NotificationStatus } from "../../components/campaignBuilder/content/steps/CampaignSetUpStep";

export interface ICampaingBuilderSliceState {
  feedbackFormSnackOpen: boolean;
  feedbackFormSnackMessage: string;
  feedbackFormSnackType: NotificationStatus | null;
}

const initialState: ICampaingBuilderSliceState = {
  feedbackFormSnackOpen: false,
  feedbackFormSnackMessage: "",
  feedbackFormSnackType: null,
};

const contactUsSlice = createSlice({
  name: "contactUs",
  initialState,
  reducers: {
    setContactUsFormSnackBarOpen(state, action) {
      state.feedbackFormSnackOpen = true;
      state.feedbackFormSnackMessage = action.payload.message;
      state.feedbackFormSnackType = action.payload.type;
    },
    setContactUsFormSnackBarClose(state) {
      state.feedbackFormSnackOpen = false;
      state.feedbackFormSnackMessage = "";
      state.feedbackFormSnackType = null;
    },
  },
});

const contactUsState = (state: RootState) => state.contactUs;

export const feedbackFormSnackOpenSelector = createSelector(
  contactUsState,
  (s) => s.feedbackFormSnackOpen
);

export const feedbackFormSnackMessageSelector = createSelector(
  contactUsState,
  (s) => s.feedbackFormSnackMessage
);

export const feedbackFormSnackTypeSelector = createSelector(
  contactUsState,
  (s) => s.feedbackFormSnackType
);

export const { setContactUsFormSnackBarOpen, setContactUsFormSnackBarClose } =
  contactUsSlice.actions;

export default contactUsSlice.reducer;
