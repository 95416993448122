import React from "react";
import CalendarIcon from "../../../../assets/campaignBuilder/calendar.svg";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { campaignBuilderOpenSelector } from "../../../../store/features/common";
import { urlRegex } from "../../constants";

export const ScheduleAViewingBtn = () => {
  const { t } = useTranslation(["campaignBuilder"]);
  const { selectedAgent } = useSelector((state: any) => state.campaignBuilder);
  const openNav = useSelector(campaignBuilderOpenSelector);

  const portfolioPageView = useSelector(
    (state: any) => state.campaignBuilderSetup.pageView
  );

  const isValidUrl = selectedAgent?.link && urlRegex.test(selectedAgent.link);

  const isDisabled = !isValidUrl || (!portfolioPageView && openNav);

  const handleOpenLink = () => {
    if (!isDisabled) {
      window.open(selectedAgent.link, "_blank");
    }
  };

  return (
    <div
      className="schedule-a-viewing-btn"
      onClick={handleOpenLink}
      style={{
        cursor: isDisabled ? "auto" : "pointer",
      }}
    >
      <img src={CalendarIcon} />
      <div>{t("schedule_a_viewing")}</div>
    </div>
  );
};
