import React, { useEffect } from 'react';
import BathroomIcon from '../assets/icons/bathroom.svg';
import BedroomIcon from '../assets/icons/bedBlue.svg';
import SquareIcon from '../assets/icons/square.svg';
import { Detail, Property } from './convert';

export default function ConvertDetails(property: Property) {
  const propertyDetailsInit = {
    bedrooms: {
      name: 'bedrooms',
      icon: <img src={BedroomIcon} alt="bedrooms" />,
    },
    bathrooms: {
      name: 'bathrooms',
      icon: <img src={BathroomIcon} alt="bathrooms" />,
    },
    totalArea: {
      name: 'livingArea',
      icon: <img src={SquareIcon} alt="livingArea" />,
    },
  };

  interface ConvertedDetail {
    [key: string]: { name: string; icon: any; quantity?: number };
  }
  const [propertyDetails, setDetails] = React.useState<ConvertedDetail>({});
  const convertDetails = (input: Detail, details: ConvertedDetail) => {
    const result: ConvertedDetail = {};
    for (const key in input) {
      if (details[key]) {
        result[key] = { ...details[key], quantity: input[key] as number };
      }
    }
    return result;
  };

  useEffect(() => {
    const detailedPropertys = convertDetails(
      property.details,
      propertyDetailsInit,
    );
    setDetails(detailedPropertys);
  }, [property.details]);

  return propertyDetails;
}
