import { Box, Theme, useMediaQuery } from "@mui/material";
import { useTranslation } from "react-i18next";
import { IArticle } from "../interfaces";
import { WhiteMiddleArcitleCard } from "./WhiteMiddleArcitleCard";
import { Swiper, SwiperSlide } from "swiper/react";
import { Mousewheel, Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";
import "swiper/css/mousewheel";
import "swiper/css/effect-fade";

export const whiteAdditionalCardStyles = {
  fontSize: "18px",
  lineHeight: "22px",
  padding: "20px 25px 32px 20px",
  minWidth: "330px",
  // minWidth: "275px",
  imgFlex: "1.1",
  height: "100%",
};

export const whiteMustToReadCardStyles = {
  fontSize: "18px",
  lineHeight: "22px",
  padding: "20px 25px 35px 20px",
  minWidth: "300px",
  imgFlex: "1.1",
  height: "100%",
};

export const AdditionalArticlesContainer = ({
  articles,
}: {
  articles: IArticle[];
}) => {
  const downMd = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));
  const { i18n } = useTranslation(["learningCenter"]);
  const isRTL = i18n.dir() === "rtl";

  return (
    <div
      className="xl-width"
      style={{
        boxSizing: "border-box",
        display: "flex",
        direction: isRTL ? "rtl" : "ltr",
      }}
    >
      <Box
        sx={{
          width: `calc(100vw - ${downMd ? "20px" : "80px"})`,
          maxWidth: "1440px",
          flex: "1",
          padding: "0 20px",
          boxSizing: "border-box",
        }}
      >
        <Swiper
          modules={[Mousewheel, Pagination]}
          mousewheel={{
            forceToAxis: true,
          }}
          slidesPerView={"auto"}
          spaceBetween={25}
          speed={800}
          loop={false}
          pagination={{
            clickable: true,
            dynamicBullets: true,
          }}
          className={`additional-articles-swiper ${
            isRTL ? "additional-articles-rtl" : "additional-articles-ltr"
          } `}
          style={
            {
              marginBottom: downMd ? "50px" : "150px",
              paddingBottom: "55px",
              "--swiper-pagination-bullet-size": "10px",
              "--swiper-pagination-bullet-inactive-color":
                "rgba(3, 74, 115, 0.59)",
              "--swiper-pagination-color": "rgba(3, 74, 115, 1)",
              "--swiper-pagination-bullet-inactive-opacity": "1",
              "--swiper-pagination-bullet-horizontal-gap": "6px",
            } as React.CSSProperties
          }
        >
          {articles.map((article, index: number) => (
            <SwiperSlide key={index}>
              <WhiteMiddleArcitleCard
                article={article}
                styles={whiteAdditionalCardStyles}
                showClueContainer={false}
                descTrancateLength={100}
              />
            </SwiperSlide>
          ))}
          <div className="swiper-pagination"></div>
        </Swiper>
      </Box>
    </div>
  );
};
