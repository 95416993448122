import { apiSlice } from "./apiSlice";

export interface IHightlightParams {
  userId: string;
}

const buildHightlightParam = (
  params: URLSearchParams,
  paramName: string,
  paramValue: string,
  andIndex: number
) => {
  params.set(`filters[$or][${andIndex}][${paramName}]`, paramValue);
};

const CreateGetHightlightWithParamsURLQuery = (
  params: IHightlightParams
): string => {
  const urlParams = new URLSearchParams();
  buildHightlightParam(urlParams, "type", "system", 0);
  buildHightlightParam(urlParams, "authorId", params.userId, 1);
  urlParams.set("pagination[pageSize]", "999");
  return `/investment-highlights?${urlParams.toString()}`;
};

const CreateGetKeyFeaturesWithParamsURLQuery = (
  params: IHightlightParams
): string => {
  const urlParams = new URLSearchParams();
  buildHightlightParam(urlParams, "type", "system", 0);
  buildHightlightParam(urlParams, "authorId", params.userId, 1);
  urlParams.set("pagination[pageSize]", "999");
  return `/key-features?${urlParams.toString()}`;
};

const CreateGetLabelsWithParamsURLQuery = (
  params: IHightlightParams
): string => {
  const urlParams = new URLSearchParams();
  buildHightlightParam(urlParams, "type", "system", 0);
  buildHightlightParam(urlParams, "authorId", params.userId, 1);
  urlParams.set("pagination[pageSize]", "999");
  return `/labels?${urlParams.toString()}`;
};

const CreateGetHightlightByUserURLQuery = (
  params: IHightlightParams
): string => {
  const urlParams = new URLSearchParams();
  buildHightlightParam(urlParams, "authorId", params.userId, 1);
  urlParams.set("pagination[pageSize]", "999");
  return `/investment-highlights?${urlParams.toString()}`;
};

export const campaignBuilderApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    createProperty: builder.mutation({
      query: ({ propertyData, language }) => ({
        headers: {
          "Content-Type": "application/json; charset=utf-8",
        },
        url: `/properties?locale=${language}`,
        method: "POST",
        body: JSON.stringify({ data: propertyData }),
      }),
    }),
    updateProperty: builder.mutation({
      query: ({ propertyData, documentId, language }) => ({
        headers: {
          "Content-Type": "application/json; charset=utf-8",
        },
        url: `/properties/${documentId}?locale=${language}`,
        method: "PUT",
        body: JSON.stringify({ data: propertyData }),
      }),
    }),
    createInvestmentHightLight: builder.mutation({
      query: ({ optionData, language }) => ({
        headers: {
          "Content-Type": "application/json; charset=utf-8",
        },
        url: `/investment-highlights`,
        method: "POST",
        body: JSON.stringify({ data: optionData }),
      }),
    }),
    updateInvestmentHightLight: builder.mutation({
      query: ({ optionData, language, documentId }) => ({
        headers: {
          "Content-Type": "application/json; charset=utf-8",
        },
        url: `/investment-highlights/${documentId}`,
        method: "PUT",
        body: JSON.stringify({ data: optionData }),
      }),
    }),
    getHightLightsWithParams: builder.query({
      query: (params: IHightlightParams) => {
        return CreateGetHightlightWithParamsURLQuery(params);
      },
    }),
    getUsersHightLights: builder.query({
      query: (params: IHightlightParams) => {
        return CreateGetHightlightByUserURLQuery(params);
      },
    }),
    deleteInvestmentHighlight: builder.mutation({
      query: ({ hightlightDocumentId }) => ({
        headers: {
          "Content-Type": "application/json; charset=utf-8",
        },
        url: `/investment-highlights/${hightlightDocumentId}`,
        method: "DELETE",
      }),
    }),
    getKeyFeaturesWithParams: builder.query({
      query: (params: IHightlightParams) => {
        return CreateGetKeyFeaturesWithParamsURLQuery(params);
      },
    }),
    createKeyFeature: builder.mutation({
      query: ({ optionData, language }) => ({
        headers: {
          "Content-Type": "application/json; charset=utf-8",
        },
        url: `/key-features`,
        method: "POST",
        body: JSON.stringify({ data: optionData }),
      }),
    }),
    updateKeyFeature: builder.mutation({
      query: ({ optionData, language, documentId }) => ({
        headers: {
          "Content-Type": "application/json; charset=utf-8",
        },
        url: `/key-features/${documentId}`,
        method: "PUT",
        body: JSON.stringify({ data: optionData }),
      }),
    }),
    deleteKeyFeature: builder.mutation({
      query: ({ hightlightDocumentId }) => ({
        headers: {
          "Content-Type": "application/json; charset=utf-8",
        },
        url: `/key-features/${hightlightDocumentId}`,
        method: "DELETE",
      }),
    }),

    getLabelsWithParams: builder.query({
      query: (params: IHightlightParams) => {
        return CreateGetLabelsWithParamsURLQuery(params);
      },
    }),

    createLabel: builder.mutation({
      query: ({ optionData, language }) => ({
        headers: {
          "Content-Type": "application/json; charset=utf-8",
        },
        url: `/labels`,
        method: "POST",
        body: JSON.stringify({ data: optionData }),
      }),
    }),
    updateLabel: builder.mutation({
      query: ({ optionData, language, documentId }) => ({
        headers: {
          "Content-Type": "application/json; charset=utf-8",
        },
        url: `/labels/${documentId}`,
        method: "PUT",
        body: JSON.stringify({ data: optionData }),
      }),
    }),
    deleteLabel: builder.mutation({
      query: ({ hightlightDocumentId }) => ({
        headers: {
          "Content-Type": "application/json; charset=utf-8",
        },
        url: `/labels/${hightlightDocumentId}`,
        method: "DELETE",
      }),
    }),
  }),
});

export const {
  useCreatePropertyMutation,
  useUpdatePropertyMutation,
  useGetHightLightsWithParamsQuery,
  useGetKeyFeaturesWithParamsQuery,
  useGetLabelsWithParamsQuery,
  useCreateInvestmentHightLightMutation,
  useUpdateInvestmentHightLightMutation,
  useCreateKeyFeatureMutation,
  useUpdateKeyFeatureMutation,
  useCreateLabelMutation,
  useUpdateLabelMutation,
  useLazyGetHightLightsWithParamsQuery,
  useLazyGetKeyFeaturesWithParamsQuery,
  useLazyGetLabelsWithParamsQuery,
  useLazyGetUsersHightLightsQuery,
  useGetUsersHightLightsQuery,
  useDeleteInvestmentHighlightMutation,
} = campaignBuilderApiSlice;
