import { Box } from "@mui/material";
import Clock from "../../../../assets/icons/clock.svg";
import { useTranslation } from "react-i18next";
import { useLanguageTheme } from "../../../../hooks/useLanguageTheme";

export function PropertyCardDaysLeftLabel({
  numberOfDaysToCompletion,
}: {
  numberOfDaysToCompletion: number;
}) {
  const { t } = useTranslation(["portfolio"]);
  const langTheme = useLanguageTheme();

  return (
    <Box
      className="day-remain-label-property-card"
      sx={{
        fontFamily: langTheme.mainFont(),
        left: "-9px",
      }}
    >
      {numberOfDaysToCompletion < 0 ? (
        <div>{t("the_campaign_is_closed")}</div>
      ) : (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "5px",
          }}
        >
          <img src={Clock} alt="Clock Icon" />
          <div>
            {numberOfDaysToCompletion} {t("days_remain")}
          </div>
        </Box>
      )}
    </Box>
  );
}
