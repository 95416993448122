import {
  Button,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  Theme,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import CalculatorImg from "../../assets/calculatorImg.png";
import { useLanguageTheme } from "../../hooks/useLanguageTheme";

export const CampaignCalculator = () => {
  const { t, i18n } = useTranslation(["campaignView"]);
  const langTheme = useLanguageTheme();
  const [value, setValue] = useState("Annually");
  const currentLanguage = i18n.language;
  const isRTL = currentLanguage === "he";
  const downMd = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));
  const maxWidth1070px = useMediaQuery("(max-width: 1070px)");

  const handleValueChange = useCallback((e: SelectChangeEvent<string>) => {
    setValue(e.target.value);
  }, []);

  const [contribution, setContribution] = useState<number | string>("");

  const handleContributionChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setContribution(event.target.value);
  };

  const [yearsToGrow, setYearsToGrow] = useState<number | string>("");

  const handleYearsToGrowChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setYearsToGrow(event.target.value);
  };
  return (
    <div
      style={{
        direction: i18n.dir(),
        padding: "0 20px",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <div
        className="campaign-view-mode-calculator-wrapper xl-width"
        style={{
          fontFamily: langTheme.mainFont(),
          marginBottom: downMd ? "70px" : "130px",
          padding: downMd ? "38px 20px " : "42px 50px",
        }}
      >
        <div style={{ display: "flex", gap: 50 }}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: 25,
              flexGrow: 1,
            }}
          >
            <div className="campaign-view-mode-calculator-title-and-desc-container">
              <Typography
                variant="h4"
                sx={{
                  fontFamily: langTheme.mainFont(),
                }}
                className="campaign-view-mode-calculator-invest-calc"
              >
                {t("investCalc")}
              </Typography>
              <p
                className="campaign-view-mode-calculator-desc"
                style={{
                  direction: i18n.dir(),
                  fontSize: downMd ? "24px" : "34px",
                  lineHeight: downMd ? "28px" : "46px",
                  fontFamily: isRTL ? "Noto Sans Hebrew" : "Frank Ruhl Libre",
                }}
              >
                {t("investConf")}
              </p>
            </div>
            <div
              style={{
                display: "grid",
                gridTemplateColumns: downMd ? "1fr" : "1fr 1fr",
                gap: 25,
              }}
            >
              <div style={{ display: "flex", flexDirection: "column", gap: 5 }}>
                <p
                  style={{
                    direction: i18n.dir(),
                  }}
                  className="campaign-view-mode-calculator-filed-title"
                >
                  {t("startAmount")}
                </p>
                <OutlinedInput
                  type="number"
                  onChange={() => {}}
                  startAdornment={
                    <InputAdornment
                      position="start"
                      sx={{
                        mr: isRTL ? "20px" : "auto",
                      }}
                    >
                      <Typography sx={{ fontFamily: langTheme.mainFont() }}>
                        ₪
                      </Typography>
                    </InputAdornment>
                  }
                  sx={{
                    borderRadius: "16px",
                    fontFamily: langTheme.mainFont(),
                    backgroundColor: "white",
                    "& input[type=number]": {
                      MozAppearance: "textfield",
                      WebkitAppearance: "none",
                      appearance: "textfield",
                    },
                    "& input[type=number]::-webkit-outer-spin-button, & input[type=number]::-webkit-inner-spin-button":
                      {
                        WebkitAppearance: "none",
                        margin: 0,
                      },
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#dce4ea",
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#b0bec5",
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#90a4ae",
                    },
                    direction: i18n.dir(),
                  }}
                />
              </div>
              <div style={{ display: "flex", flexDirection: "column", gap: 5 }}>
                <p
                  style={{
                    direction: i18n.dir(),
                  }}
                  className="campaign-view-mode-calculator-filed-title"
                >
                  {t("rate")}
                </p>
                <OutlinedInput
                  type="number"
                  onChange={() => {}}
                  endAdornment={
                    <InputAdornment
                      position="end"
                      sx={{
                        ml: isRTL ? "20px" : "auto",
                      }}
                    >
                      <Typography sx={{ fontFamily: langTheme.mainFont() }}>
                        %
                      </Typography>
                    </InputAdornment>
                  }
                  sx={{
                    borderRadius: "16px",
                    fontFamily: langTheme.mainFont(),
                    backgroundColor: "white",
                    "& input[type=number]": {
                      MozAppearance: "textfield",
                      WebkitAppearance: "none",
                      appearance: "textfield",
                    },
                    "& input[type=number]::-webkit-outer-spin-button, & input[type=number]::-webkit-inner-spin-button":
                      {
                        WebkitAppearance: "none",
                        margin: 0,
                      },
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#dce4ea",
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#b0bec5",
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#90a4ae",
                    },
                    direction: i18n.dir(),
                  }}
                  inputProps={{
                    style: {
                      fontFamily: langTheme.mainFont(),
                    },
                  }}
                />
              </div>
              <div style={{ display: "flex", flexDirection: "column", gap: 5 }}>
                <p
                  style={{
                    direction: i18n.dir(),
                  }}
                  className="campaign-view-mode-calculator-filed-title"
                >
                  {t("contribution")}
                </p>
                <OutlinedInput
                  type="number"
                  value={contribution}
                  onChange={handleContributionChange}
                  startAdornment={
                    <InputAdornment
                      position="start"
                      sx={{
                        mr: isRTL ? "20px" : "auto",
                        fontFamily: langTheme.mainFont(),
                      }}
                    >
                      <Typography sx={{ fontFamily: langTheme.mainFont() }}>
                        ₪
                      </Typography>
                    </InputAdornment>
                  }
                  sx={{
                    fontFamily: langTheme.mainFont(),
                    borderRadius: "16px",
                    backgroundColor: "white",
                    "& input[type=number]": {
                      MozAppearance: "textfield",
                      WebkitAppearance: "none",
                      appearance: "textfield",
                    },
                    "& input[type=number]::-webkit-outer-spin-button, & input[type=number]::-webkit-inner-spin-button":
                      {
                        WebkitAppearance: "none",
                        margin: 0,
                      },
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#dce4ea",
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#b0bec5",
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#90a4ae",
                    },
                    direction: i18n.dir(),
                  }}
                />
              </div>
              <div style={{ display: "flex", flexDirection: "column", gap: 5 }}>
                <p
                  style={{
                    direction: i18n.dir(),
                  }}
                  className="campaign-view-mode-calculator-filed-title"
                >
                  {t("grow")}
                </p>
                <OutlinedInput
                  type="number"
                  value={yearsToGrow}
                  onChange={handleYearsToGrowChange}
                  sx={{
                    borderRadius: "16px",
                    backgroundColor: "white",
                    fontFamily: langTheme.mainFont(),
                    "& input[type=number]": {
                      MozAppearance: "textfield",
                      WebkitAppearance: "none",
                      appearance: "textfield",
                    },
                    "& input[type=number]::-webkit-outer-spin-button, & input[type=number]::-webkit-inner-spin-button":
                      {
                        WebkitAppearance: "none",
                        margin: 0,
                      },
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#dce4ea",
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#b0bec5",
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#90a4ae",
                    },
                    direction: i18n.dir(),
                  }}
                />
              </div>
              <div style={{ display: "flex", flexDirection: "column", gap: 5 }}>
                <p
                  style={{
                    direction: i18n.dir(),
                  }}
                  className="campaign-view-mode-calculator-filed-title"
                >
                  {t("compound")}
                </p>
                <Select
                  value={value}
                  onChange={handleValueChange}
                  displayEmpty
                  sx={{
                    fontFamily: langTheme.mainFont(),
                    borderRadius: "16px",
                    backgroundColor: "white",
                    padding: "10px",
                    width: "100%",
                    "& .MuiSelect-select": { padding: "6.5px" },
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#dce4ea",
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#b0bec5",
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#90a4ae",
                    },
                    direction: i18n.dir(),
                  }}
                  MenuProps={{
                    PaperProps: {
                      sx: {
                        "& .MuiMenuItem-root": {
                          fontFamily: langTheme.mainFont(),
                          direction: i18n.dir(),
                        },
                      },
                    },
                  }}
                >
                  <MenuItem value="Annually">{t("annually")}</MenuItem>
                  <MenuItem value="Quarterly">{t("quarterly")}</MenuItem>
                  <MenuItem value="Monthly">{t("monthly")}</MenuItem>
                </Select>
              </div>
              <Button
                variant="contained"
                className="calculator-btn"
                onClick={() => {}}
                sx={{
                  fontFamily: langTheme.mainFont(),
                }}
              >
                {t("calculate")}
              </Button>
            </div>
            <div
              style={{
                display: "flex",
                gap: 10,
                alignItems: "center",
                direction: i18n.dir(),
              }}
            >
              <p className="calculator-final-sum">{t("worth")}</p>
              <p className="calculator-final-sum-blue">{"₪3,653,410"}</p>
            </div>
          </div>
          {!maxWidth1070px && (
            <img
              src={CalculatorImg}
              alt="calc-img"
              className="campaign-view-mode-calculator-img"
            />
          )}
        </div>
      </div>
    </div>
  );
};
