import React, { useEffect, useState } from 'react';
import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Box,
  ListItemButton,
  Typography,
  Avatar,
} from '@mui/material';
import SettingsIcon from '../../assets/icons/settings.svg';
import HeartIcon from '../../assets/icons/outline_heart.svg';
import UserIcon from '../../assets/icons/user-profile.svg';
import SecurityIcon from '../../assets/icons/security.svg';
import CalendarIcon from '../../assets/icons/calendar.svg';
import NotificationsIcon from '../../assets/icons/notifications.svg';
import AssistantIcon from '../../assets/icons/ai.svg';
import LogoutIcon from '../../assets/icons/logout.svg';
import { useUserProfile } from '../../hooks/useUserProfile';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

interface SidebarProfileProps {
  onOptionChange: (option: string) => void;
  selectedOption: string;
  mobileOpen: boolean;
  handleDrawerToggle: () => void;
  isMobile: boolean;
}

const SidebarProfile: React.FC<SidebarProfileProps> = ({
  onOptionChange,
  selectedOption,
  isMobile,
  mobileOpen,
  handleDrawerToggle,
}) => {
  const navigate = useNavigate();
  
  const handleLogOut = () => {
    localStorage.removeItem('jwt');
    localStorage.removeItem('user');
    sessionStorage.removeItem('jwt');
    sessionStorage.removeItem('user');
    navigate('/signin');
  };

  const {
    profile,
    isLoading,
    error,
  }: { profile: any; isLoading: any; error: any } = useUserProfile();
  const profilePicture = profile?.profilePhoto?.formats?.thumbnail?.url;
  const [fullName, setFullName] = useState('');
  const [userType, setUserType] = useState('');
  const { t, i18n } = useTranslation(['profile']);
  const isRTL = i18n.dir() === 'rtl';

  useEffect(() => {
    if (profile && profile.fullName && profile.userType) {
      setFullName(profile.fullName);
      setUserType(profile.userType);
    }
  }, [profile]);

  if (isLoading) {
    return <div>{t('loading')}</div>;
  }
  if (error) {
    return <div>{t('error')}</div>;
  }

  const handleListItemClick = (text: string) => {
    onOptionChange(text);
    if (isMobile) {
      handleDrawerToggle();
    }
  };

  return (
    <Drawer
      variant={isMobile ? 'temporary' : 'permanent'}
      open={isMobile ? mobileOpen : true}
      onClose={handleDrawerToggle}
      ModalProps={{
        keepMounted: true,
      }}
      sx={{
        width: '240px',
        flexShrink: 0,
        [`& .MuiDrawer-paper`]: {
          width: '240px',
          boxSizing: 'border-box',
          borderRight: 'none',
          overflowY: 'hidden',
          direction: isRTL ? 'rtl' : 'ltr',
          textAlign: isRTL ? 'right' : 'left',
          backgroundColor: '#F5F7FF',
          fontFamily: 'DM-Sans',
        },
      }}
    >
      <Box
        sx={{
          p: 3,
          cursor: 'pointer',
          width: '80%',
          direction: isRTL ? 'rtl' : 'row',
        }}
        onClick={() => navigate('/home')}
      >
        <img src="logo.png" alt="Logo" style={{ width: '100px', padding: 0 }} />
      </Box>
      <Box
        sx={{
          px: 2,
          mb: 1,
        }}
      >
        <Typography
          sx={{
            color: 'text.secondary',
            display: 'block',
            mb: 0.5,
            fontWeight: 600,
            fontSize: '16px',
          }}
        >
          {t('account')}
        </Typography>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 1.5,
            mb: 1,
          }}
        >
          <Avatar
            src={profilePicture}
            sx={{
              width: 48,
              height: 48,
              bgcolor: '#E8F4F8',
              color: '#034A736E',
              fontSize: '1.2rem',
            }}
          >
            {!profilePicture && fullName.charAt(0).toUpperCase()}
          </Avatar>
          <Box>
            <Typography variant="body2" fontWeight={600} lineHeight={1.2}>
              {fullName}
            </Typography>
            <Typography
              lineHeight={1.2}
              sx={{
                fontSize: '16px',
                fontFamily: 'DM-Sans',
                fontWeight: 500,
                color: 'rgba(163, 174, 208, 1)',
              }}
            >
              {t(userType)}
            </Typography>
          </Box>
        </Box>
      </Box>

      <Box sx={{ px: 2, mb: 0.5 }}>
        <Typography
          sx={{
            display: 'block',
            mb: 0.5,
            color: 'rgba(43, 54, 116, 0.75)',
            fontWeight: 600,
            fontSize: '16px',
            fontFamily: 'DM-Sans',
          }}
        >
          {t('general')}
        </Typography>
        <List disablePadding dense>
          {[
            { icon: UserIcon, text: 'personal_details' },
            { icon: HeartIcon, text: 'wishlist' },
            { icon: SettingsIcon, text: 'account_settings' },
          ].map((item) => (
            <ListItem key={item.text} disablePadding sx={{ mb: 0.25 }}>
              <ListItemButton
                sx={{
                  borderRadius: 1,
                  px: 1,
                  py: '4px',
                }}
                onClick={() => handleListItemClick(item.text)}
                selected={selectedOption === item.text}
              >
                <ListItemIcon
                  sx={{
                    minWidth: '32px',
                  }}
                >
                  <img src={item.icon} alt={item.text} style={{ width: 18 }} />
                </ListItemIcon>
                <ListItemText
                  primary={t(item.text)}
                  primaryTypographyProps={{
                    variant: 'body2',
                    sx: {
                      lineHeight: 1.25,
                      textAlign: isRTL ? 'right' : 'left',
                    },
                  }}
                />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Box>

      <Box sx={{ px: 2, mb: 0.5, direction: isRTL ? 'row-reverse' : 'row' }}>
        <Typography
          sx={{
            color: 'rgba(43, 54, 116, 0.75)',
            fontWeight: 600,
            fontSize: '16px',
            fontFamily: 'DM-Sans',
            display: 'block',
            mb: 0.5,
          }}
        >
          {t('communication')}
        </Typography>
        <List disablePadding dense>
          {[
            { icon: SecurityIcon, text: 'security' },
            { icon: CalendarIcon, text: 'meetings' },
            { icon: NotificationsIcon, text: 'notifications' },
          ].map((item) => (
            <ListItem key={item.text} disablePadding sx={{ mb: 0.25 }}>
              <ListItemButton
                sx={{
                  borderRadius: 1,
                  px: 1,
                  py: '4px',
                }}
                onClick={() => handleListItemClick(item.text)}
                selected={selectedOption === item.text}
              >
                <ListItemIcon
                  sx={{
                    minWidth: '32px',
                  }}
                >
                  <img src={item.icon} alt={item.text} style={{ width: 18 }} />
                </ListItemIcon>
                <ListItemText
                  primary={t(item.text)}
                  primaryTypographyProps={{
                    variant: 'body2',
                    sx: {
                      lineHeight: 1.25,
                      textAlign: isRTL ? 'right' : 'left',
                    },
                  }}
                />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Box>

      <Box sx={{ px: 2, direction: isRTL ? 'rtl' : 'ltr' }}>
        <Typography
          sx={{
            color: 'rgba(43, 54, 116, 0.75)',
            fontWeight: 600,
            fontSize: '16px',
            fontFamily: 'DM-Sans',
            display: 'block',
            mb: 0.5,
          }}
        >
          {t('other')}
        </Typography>
        <List disablePadding dense>
          {[
            { icon: AssistantIcon, text: 'ai_assistant' },
            { icon: LogoutIcon, text: 'logout', onClick: handleLogOut },
          ].map((item) => (
            <ListItem key={item.text} disablePadding sx={{ mb: 0.25 }}>
              <ListItemButton
                sx={{
                  borderRadius: 1,
                  px: 1,
                  py: '4px',
                }}
                onClick={item.onClick || (() => handleListItemClick(item.text))}
                selected={selectedOption === item.text}
              >
                <ListItemIcon
                  sx={{
                    minWidth: '32px',
                  }}
                >
                  <img src={item.icon} alt={item.text} style={{ width: 18 }} />
                </ListItemIcon>
                <ListItemText
                  primary={t(item.text)}
                  primaryTypographyProps={{
                    variant: 'body2',
                    sx: {
                      lineHeight: 1.25,
                      textAlign: isRTL ? 'right' : 'left',
                    },
                  }}
                />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Box>
    </Drawer>
  );
};

export default SidebarProfile;
