import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import woman from "../../assets/sellWithUs/woman.png";
import i18n from "../../i18n";
import { useGetTransparentProcessStagesQuery } from "../../store/api/sellWithUsApiSlice";

interface IStage {
  title: string;
  description: string;
  image: { url: string };
}

export default function TransparentProcess() {
  const { t } = useTranslation(["sellWithUs"]);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const isMid = useMediaQuery(theme.breakpoints.down(1400));
  const isRTL = i18n.language === "he";
  const [selectedStage, setSelectedStage] = useState(0);
  const { data: fetchedStages } = useGetTransparentProcessStagesQuery(
    i18n.language
  );
  const [stages, setStages] = useState<IStage[]>([]);

  const timelineSliderRef = useRef<HTMLDivElement>(null);

  const GAP = 10;

  useEffect(() => {
    if (fetchedStages) {
      setStages(fetchedStages?.data);
    }
  }, [fetchedStages]);

  useEffect(() => {
    const slider = timelineSliderRef.current;
    if (!slider || !isMobile) return;

    const handleScrollEnd = () => {
      const sliderRect = slider.getBoundingClientRect();
      const stageElements = slider.children as HTMLCollectionOf<HTMLElement>;
      let closestStage = 0;
      let minDistance = Infinity;

      // Find the stage closest to the start edge
      Array.from(stageElements).forEach((element, index) => {
        const elementRect = element.getBoundingClientRect();
        const distance = isRTL
          ? Math.abs(sliderRect.right - elementRect.right)
          : Math.abs(elementRect.left - sliderRect.left);
        // console.log(`Stage ${index}: distance = ${distance}`, 'minDistance',minDistance);
        // console.log(elementRect.right, sliderRect.right, elementRect.left, sliderRect.left);

        if (distance < minDistance) {
          // console.log('yes')
          minDistance = distance;
          closestStage = index;
        }
      });

      // Override to select last stage if scrolled to the end
      const threshold = 20; // Small threshold in pixels to account for minor offsets
      if (isRTL) {
        if (
          slider.scrollLeft >=
          slider.scrollWidth - sliderRect.width - threshold
        ) {
          // console.log(1)
          closestStage = stages?.length - 1;
        }
        // console.log(slider.scrollLeft, '>=',slider.scrollWidth, sliderRect.width, threshold);
      } else {
        if (
          slider.scrollLeft + sliderRect.width >=
          slider.scrollWidth - threshold
        ) {
          closestStage = stages?.length - 1;
          // console.log(2)
        }
      }

      // console.log('Selected stage:', closestStage);
      setSelectedStage(closestStage);
    };

    slider.addEventListener("scrollend", handleScrollEnd);
    return () => slider.removeEventListener("scrollend", handleScrollEnd);
  }, [isMobile, isRTL, stages?.length]);

  return (
    <Box
      sx={{
        p: isMobile ? 0 : 0,
        pb: 0,
        direction: isRTL ? "rtl" : "ltr",
        maxWidth: "100%",
        overflowX: "hidden",
        mx: isMobile ? "20px" : "20px",
        marginTop: isMobile ? "50px" : "150px",
      }}
    >
      {/* Title */}
      <Box
        sx={{
          display: "flex",
          fontFamily: isRTL ? "Noto Sans Hebrew" : "Frank Ruhl Libre",
          fontWeight: 600,
          fontSize: isMobile ? "24px" : "36px",
          justifyContent: "flex-start",
          height: { xs: "36px", md: "46px" },
          mb: "5px",
        }}
      >
        <Typography
          variant="h2"
          sx={{
            textTransform: "uppercase",
            [isRTL ? "ml" : "mr"]: 1,
            px: 1,
            bgcolor: "#E8EFF2A1",
          }}
        >
          {t("transparent")}
        </Typography>
        <Typography variant="h2" sx={{ textTransform: "uppercase", mb: 4 }}>
          {t("process")}
        </Typography>
      </Box>

      {/* Timeline */}
      {isMobile ? (
        <Box
          ref={timelineSliderRef}
          sx={{
            display: "flex",
            flexDirection: "row",
            overflowX: "auto",
            overflowY: "hidden",
            gap: `${GAP}px`,
            mb: 4,
            scrollSnapType: "x mandatory",
            scrollbarWidth: "none",
            "&::-webkit-scrollbar": { display: "none" },
            padding: "0 10px",
            scrollBehavior: "smooth",
            direction: isRTL ? "rtl" : "ltr",
          }}
        >
          {stages?.map((stage, index) => {
            const displayIndex = index;
            const isChecked = index <= selectedStage;

            return (
              <Box
                key={index}
                onClick={() => setSelectedStage(index)}
                sx={{
                  flex: "0 0 auto",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  position: "relative",
                  textAlign: isRTL ? "right" : "left",
                  cursor: "pointer",
                  scrollSnapAlign: "start",
                  "&:hover": { opacity: 0.8 },
                  minWidth: "250px",
                  minHeight: "100px",
                }}
              >
                <Typography
                  variant="h6"
                  sx={{
                    color: isChecked ? "#FCBC5C" : "#888888",
                    fontWeight: 700,
                    mb: 1,
                    fontSize: "14px",
                    fontFamily: "DM Sans",
                  }}
                >
                  {String(displayIndex + 1).padStart(2, "0")}
                </Typography>
                <Typography
                  variant="body2"
                  sx={{
                    color: "#000000",
                    fontWeight: 500,
                    fontSize: "0.8rem",
                    lineHeight: "22px",
                    mb: 3,
                    textAlign: isRTL ? "right" : "left",
                    fontFamily: isRTL
                      ? "Noto Sans Hebrew"
                      : '"DM Sans", sans-serif',
                  }}
                >
                  {stage?.title || "N/A"}
                </Typography>
                {/* Circle */}
                <Box
                  sx={{
                    position: "absolute",
                    top: "70px",
                    [isRTL ? "right" : "left"]: 0,
                    width: "10px",
                    height: "10px",
                    borderRadius: "50%",
                    backgroundColor: isChecked ? "#FCBC5C" : "#D3D3D3",
                    zIndex: 2,
                  }}
                />
                {/* Line to next stage */}
                <Box
                  sx={{
                    position: "absolute",
                    top: "74px",
                    [isRTL ? "right" : "left"]: isRTL ? "0" : "5px",
                    width:
                      index === stages?.length - 1
                        ? "255px"
                        : `calc(250px + ${GAP}px)`,
                    height: "2px",
                    backgroundColor: isChecked ? "#FCBC5C" : "#D3D3D3",
                    zIndex: 1,
                  }}
                />
              </Box>
            );
          })}
        </Box>
      ) : (
        // Desktop Timeline
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "flex-start",
            position: "relative",
            mb: 4,
            maxWidth: "100%",
          }}
        >
          {stages?.map((stage, index) => {
            const displayIndex = index;
            const isChecked = index <= selectedStage;

            return (
              <Box
                key={index}
                onClick={() => setSelectedStage(index)}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  position: "relative",
                  textAlign: isRTL ? "right" : "left",
                  zIndex: 2,
                  cursor: "pointer",
                  "&:hover": { opacity: 0.8 },
                  width: "100%",
                }}
              >
                <Typography
                  variant="h6"
                  sx={{
                    color: isChecked ? "#FCBC5C" : "#888888",
                    fontWeight: 700,
                    mb: 1,
                    fontSize: "20px",
                    fontFamily: "DM Sans",
                  }}
                >
                  {String(displayIndex + 1).padStart(2, "0")}
                </Typography>
                <Typography
                  variant="body2"
                  sx={{
                    color: "#000000",
                    fontWeight: 500,
                    fontSize: "18px",
                    lineHeight: "22px",
                    mb: 3,
                    fontFamily: isRTL
                      ? "Noto Sans Hebrew"
                      : '"DM Sans", sans-serif',
                    width: "90%",
                  }}
                >
                  {stage?.title || "N/A"}
                </Typography>
                <Box
                  sx={{
                    position: "absolute",
                    top: isMid ? (isRTL ? "115px" : "146px") : "95px",
                    [isRTL ? "right" : "left"]: 0,
                    width: "10px",
                    height: "10px",
                    borderRadius: "50%",
                    backgroundColor: isChecked ? "#FCBC5C" : "#D3D3D3",
                    zIndex: 2,
                  }}
                />
                <Box
                  sx={{
                    position: "absolute",
                    top: isMid ? (isRTL ? "120px" : "150px") : "100px",
                    [isRTL ? "right" : "left"]: "5px",
                    width: "100%", //'calc(100% - 5px)',
                    height: "2px",
                    backgroundColor: isChecked ? "#FCBC5C" : "#D3D3D3",
                    zIndex: 1,
                  }}
                />
              </Box>
            );
          })}
        </Box>
      )}

      {/* Image and Description */}
      <Box
        sx={{
          display: "flex",
          flexDirection: isMobile ? "column" : isRTL ? "row-reverse" : "row",
          gap: 4,
          alignItems: "center",
          flexWrap: "wrap",
          mt: "36px",
          width: "100%",
        }}
      >
        <Box
          sx={{
            flex: isMobile ? "none" : 1,
            width: "100%",
            display: "flex",
            justifyContent: isMobile ? "center" : "flex-end",
            alignItems: "center",
            order: isRTL && !isMobile ? 1 : 2,
          }}
        >
          <img
            src={stages[selectedStage]?.image?.url || woman}
            alt={stages[selectedStage]?.title || "Stage Image"}
            style={{
              width: "auto",
              maxWidth: "100%",
              maxHeight: isMobile ? "300px" : "400px",
              objectFit: "cover",
              borderRadius: "10px",
            }}
          />
        </Box>
        <Box
          sx={{
            flex: isMobile ? "none" : 1,
            width: isMobile ? "100%" : "50%",
            order: isRTL && !isMobile ? 2 : 1,
          }}
        >
          <Typography
            variant="body1"
            sx={{
              color: "#666",
              lineHeight: 1.6,
              fontSize: isMobile ? "0.9rem" : "18px",
              textAlign: isRTL ? "right" : "left",
              fontFamily: isRTL ? "Noto Sans Hebrew" : '"DM Sans", sans-serif',
              width: "100%",
            }}
          >
            {stages[selectedStage]?.description || "N/A"}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}
