import React from "react";
import { useTranslation } from "react-i18next";
import { MenuItem, Select } from "@mui/material";
import { useLanguageTheme } from "../../../../hooks/useLanguageTheme";
import { ReactComponent as SelectArrow } from "../../../../assets/campaignBuilder/select_arror.svg";

export const selectStyles = (
  fontFamily: string,
  isRTL: boolean,
  error?: any
) => ({
  width: "100%",
  fontSize: "14px",
  fontFamily: fontFamily,
  fontWeight: 500,
  backgroundColor: "rgba(232, 239, 242, 1)",
  borderRadius: "16px",
  "& .MuiOutlinedInput-notchedOutline": {
    border: "none",
  },
  "&:hover .MuiOutlinedInput-notchedOutline": {
    border: "none",
  },

  "& .MuiSelect-icon": {
    top: "calc(50% - 0.2em)",
    right: isRTL ? "auto" : "25px",
    left: isRTL ? "25px" : "auto",
  },
  "& .MuiSelect-select": {
    paddingY: "13.5px",
    display: "flex",
    alignItems: "center",
    paddingRight: isRTL ? "24px" : "24px",
    paddingLeft: isRTL ? "24px" : "20px",
  },
});
export const CampaingBuilderSelect = ({
  value,
  onChangeValue,
  options,
}: {
  value: any;
  onChangeValue: (value: any) => void;
  options: any;
}) => {
  const { t } = useTranslation(["campaignBuilder"]);
  const langTheme = useLanguageTheme();
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const isRTL = currentLanguage === "he";

  return (
    <Select
      value={value}
      onChange={onChangeValue}
      sx={selectStyles(langTheme.mainFont(), isRTL)}
      MenuProps={{
        slotProps: {
          paper: {
            sx: {
              direction: i18n.dir(),
              background: "rgba(232, 239, 242, 1)",
            },
          },
        },
      }}
      IconComponent={SelectArrow}
    >
      {options.map((option: string) => (
        <MenuItem
          key={option}
          value={option}
          sx={{
            color: "rgba(0, 0, 0, 1)",
            fontFamily: langTheme.mainFont(),
            fontWeight: 500,
            fontSize: "14px",
          }}
        >
          {t(option)}
        </MenuItem>
      ))}
    </Select>
  );
};
