export const {
  REACT_APP_BACKEND_URL,
  REACT_APP_LOCAL_TOOLS_ON,
  REACT_APP_MAP_ACCESS_TOKEN,
  REACT_APP_GOOGLE_CLIENT_ID,
  REACT_APP_GOOGLE_API_KEY,
  REACT_APP_AI_API_URL
} = process.env;

export let LOCAL_TOOLS_ON = false;
if (REACT_APP_LOCAL_TOOLS_ON) {
  LOCAL_TOOLS_ON = true;
}

export const PAGE_SIZE = "10";
