import { IGetWishlistWithLang } from "../../pages/WishlistDemo";
import { apiSlice } from "../api/apiSlice";
import {
  buildCampaignStatusParams,
  buildFilterParams,
  buildPaginationParams,
  buildSearchParams,
  buildSortParams,
} from "./propertyApiSlice";

export interface ICreateWishlistData {
  data: {
    users_permissions_user: number[];
    properties: number[];
  };
}
export interface IUpdateWishlistData {
  data: {
    properties: number[];
  };
}

export function buildUserIdParam(params: URLSearchParams, userId: string) {
  if (userId === "all") {
    params.set("all", "true");
  }

  const user = localStorage.getItem("user") || sessionStorage.getItem("user");
  if (user) {
    params.set("owner", JSON.parse(user).id);
  } else {
    return;
  }
}

function CreateGetUserWishlistURLQuery(params: IGetWishlistWithLang): string {
  const urlParams = new URLSearchParams();
  urlParams.append("locale", params.lang);

  buildPaginationParams(urlParams, params.params.page, params.params?.pageSize);
  buildSortParams(urlParams, params.params.sort);
  buildCampaignStatusParams(urlParams, params.params.campaignStatus);
  buildUserIdParam(urlParams, params.params.userId);

  if (params.params.search) {
    buildSearchParams(urlParams, params.params.search);
  } else {
    buildFilterParams(urlParams, params.params.filters, params.lang);
  }

  return `wishlists/properties?${urlParams.toString()}`;
}

export const authApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getUserWishlist: builder.query({
      query: (params: IGetWishlistWithLang) => {
        const url = CreateGetUserWishlistURLQuery(params);
        return {
          url,
          method: "GET",
          headers: {
            Authorization: `Bearer ${params.params.jwt}`,
          },
        };
      },
      providesTags: ["Wishlist"],
    }),

    getUserWishlistWithoutParams: builder.query({
      query: (params: IGetWishlistWithLang) => {
        return {
          url: `wishlists/properties?owner=${params.params.userId}&all=true`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${params.params.jwt}`,
          },
        };
      },
      providesTags: ["Wishlist"],
    }),
    createUserWishlist: builder.mutation({
      query: ({
        wishlistData,
        jwt,
      }: {
        wishlistData: ICreateWishlistData;
        jwt: string;
      }) => ({
        url: "/wishlists",
        method: "POST",
        body: wishlistData,
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
      }),
      invalidatesTags: ["Wishlist"],
    }),
    updateUserWishlist: builder.mutation({
      query: ({
        wishlistId,
        updatedData,
        jwt,
      }: {
        wishlistId: number;
        updatedData: IUpdateWishlistData;
        jwt: string;
      }) => ({
        url: `/wishlists/${wishlistId}`,
        method: "PUT",
        body: updatedData,
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
      }),
      invalidatesTags: ["Wishlist"],
    }),
  }),
});

export const {
  useGetUserWishlistQuery,
  useGetUserWishlistWithoutParamsQuery,
  useCreateUserWishlistMutation,
  useUpdateUserWishlistMutation,
  useLazyGetUserWishlistQuery,
} = authApiSlice;
