import React from "react";
import { useTranslation } from "react-i18next";
import { useLanguageTheme } from "../../hooks/useLanguageTheme";
import { Theme, useMediaQuery } from "@mui/material";
import logo from "../../assets/logo.png";
import { useNavigate } from "react-router-dom";
import { INavItem } from "./interfaces";
import { useAppDispatch } from "../../store/store";
import { setAuthPopupOpen } from "../../store/features/common";
import { AuthPopupContentType } from "../auth/types";
import { useAuth } from "../../hooks/useAuth";
import { UserTypes } from "./Navbar";

export const FooterDemo = () => {
  const { t, i18n } = useTranslation(["navbar"]);
  const isRTL = i18n.dir() === "rtl";

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const langTheme = useLanguageTheme();

  const downMd = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));
  const isXXL = useMediaQuery("(min-width: 2360px)");

  const isAuthenticated = useAuth();

  const storedUser =
    localStorage.getItem("user") || sessionStorage.getItem("user");
  const user = storedUser ? JSON.parse(storedUser) : null;

  const navItems: INavItem[] = [
    { title: t("portfolio"), path: "/portfolio", onlyForSellers: true },
    { title: t("contact_us"), path: "/contact-us", onlyForSellers: false },
    { title: t("sell_with_us"), path: "/sell-with-us", onlyForSellers: false },
    {
      title: t("wishlist"),
      path: "/wishlist",
      onlyForSellers: true,
    },
    {
      title: t("learning_center"),
      path: "/learning-center",
      onlyForSellers: false,
    },
    { title: t("term_and_use_policy"), path: "/terms", onlyForSellers: false },
  ];

  const filteredNavItems = navItems.filter((item) => {
    if (!isAuthenticated || !user) {
      return true;
    }
    if (user.userType === UserTypes.SELLER) {
      return true;
    }
    return !item.onlyForSellers;
  });

  const handleGoToPage = (item: INavItem): void => {
    if (!isAuthenticated) {
      if (item.title === t("portfolio") || item.title === t("wishlist")) {
        dispatch(setAuthPopupOpen(AuthPopupContentType.SIGNUP));
        return;
      }
      navigate(item.path);
      return;
    }
    navigate(item.path);
  };

  return (
    <div className="xxl-width">
      <div
        style={{
          boxShadow: "border-box",
          paddingLeft: isXXL ? "100px" : "20px",
          paddingRight: isXXL ? "100px" : "20px",
          paddingTop: downMd ? "35px" : "57px",
          paddingBottom: downMd ? "35px" : "57px",
          display: "flex",
          justifyContent: "space-between",
          flexDirection: downMd ? "column" : isRTL ? "row-reverse" : "row",
        }}
      >
        <div>
          <div
            className="footer-items-wrapper"
            style={{
              direction: i18n.dir(),
              fontFamily: langTheme.mainFont(),
              gridTemplateColumns: downMd ? "repeat(2, 1fr)" : "repeat(3, 1fr)",
            }}
          >
            {filteredNavItems.map((item, index) => (
              <div
                key={index}
                className="footer-container-nav-item"
                onClick={() => handleGoToPage(item)}
              >
                <div>{item.title}</div>
              </div>
            ))}
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginTop: downMd ? "40px" : "30px",
              direction: i18n.dir(),
            }}
          >
            <div
              style={{
                marginTop: "24px",
              }}
            >
              <img src={logo} style={{ width: "130px" }} />
            </div>
            {downMd && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "5px",
                  direction: i18n.dir(),
                }}
              >
                <div
                  style={{
                    fontFamily: langTheme.mainFont(),
                    fontWeight: 400,
                    color: "rgba(0, 0, 0, 0.62)",
                  }}
                >
                  {t("email_address")}
                </div>
                <div
                  style={{
                    color: "rgba(0, 0, 0, 1)",
                    fontFamily: langTheme.mainFont(),
                    fontWeight: 500,
                  }}
                >
                  avidor@ioteratech.com
                </div>
              </div>
            )}
          </div>
        </div>

        {!downMd && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "5px",
              direction: i18n.dir(),
            }}
          >
            <div
              style={{
                fontFamily: langTheme.mainFont(),
                fontWeight: 400,
                color: "rgba(0, 0, 0, 0.62)",
              }}
            >
              {t("email_address")}
            </div>
            <div
              style={{
                color: "rgba(0, 0, 0, 1)",
                fontFamily: langTheme.mainFont(),
                fontWeight: 500,
              }}
            >
              avidor@ioteratech.com
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
