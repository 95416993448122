import { Button, Theme, useMediaQuery } from "@mui/material";
import DoneIcon from "../../assets/icons/done.svg";
import { useTranslation } from "react-i18next";
import { useLanguageTheme } from "../../hooks/useLanguageTheme";
import { useAppDispatch } from "../../store/store";
import { setAuthPopupClose } from "../../store/features/common";

export const WelcomeOnBoardContent = () => {
  const { t, i18n } = useTranslation(["signin"]);
  const isRTL = i18n.dir() === "rtl";

  const dispatch = useAppDispatch();

  const downMd = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));
  const langTheme = useLanguageTheme();

  return (
    <div
      className="signin-form-section"
      style={{
        height: downMd ? "100vh" : "",
        flexDirection: "column",
        minHeight: "470px",
      }}
    >
      <div className="welcome-on-board-done-icon">
        <img src={DoneIcon} />
      </div>

      <div
        className="signin-form-title-signin"
        style={{
          fontFamily: isRTL ? "Noto Sans Hebrew" : "Frank Ruhl Libre",
          marginTop: "35px",
        }}
      >
        {t("welcome_on_board")}
      </div>
      <div
        className="signup-additional-desc"
        style={{
          fontFamily: langTheme.mainFont(),
        }}
      >
        {t("welcome_on_board_desc")}
      </div>
      <Button
        onClick={() => dispatch(setAuthPopupClose())}
        type="submit"
        variant="contained"
        className="contact-us-submit-btn"
        sx={{
          textTransform: "capitalize",
          backgroundColor: "rgba(3, 74, 115, 1)",
          borderRadius: "16px",
          padding: "13px",
          fontSize: "16px",
          fontWeight: 500,
          fontFamily: langTheme.mainFont(),
          marginTop: downMd ? "20px" : "35px",
        }}
      >
        {t("continue")}
      </Button>
    </div>
  );
};
