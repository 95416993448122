import React from "react";
import { LabelIconMap, LabelStamp } from "../../constants/campaignBuilderData";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { ILabel } from "../../helpers/convert";
export type IconKey =
  | "hot_price"
  | "premium"
  | "eco_friendly"
  | "alarm"
  | "diamond"
  | "sale";

interface Option {
  authorId: null;
  backgroundColor: string;
  createdAt: string;
  details: null;
  documentId: string;
  iconKey: IconKey;
  id: number;
  labelNameEn: string;
  labelNameHe: string;
  locale: string;
  publishedAt: string;
  type: string;
  updatedAt: string;
}

export const LabelStampComponent = ({ option }: { option: Option }) => {
  const { t, i18n } = useTranslation(["campaignBuilder"]);

  if (!option) return null;
  const IconComponent = LabelIconMap[option.iconKey];
  if (!IconComponent) return null;
  return (
    <div
      className="label-stamp-container"
      style={{
        left: "-9px",
      }}
    >
      <div
        style={{
          display: "flex",
          position: "absolute",
          top: "8px",
          gap: "7px",
          marginLeft: "10px",
        }}
      >
        <IconComponent
          isSelected={false}
          width={17}
          height={19}
          backgroundColor={"white"}
          iconSelect={false}
        />
        <div>
          {t(i18n.language === "en" ? option?.labelNameEn : option.labelNameHe)}
        </div>
      </div>
      <LabelStamp
        isSelected={true}
        width={135}
        height={46}
        backgroundColor={option?.backgroundColor}
        iconSelect={false}
      />
    </div>
  );
};
