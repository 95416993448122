import React, { useEffect, useMemo } from "react";
import { FormContainer, useForm } from "react-hook-form-mui";
import { UserType } from "../../../auth/UserTypeSelect";
import { IContactUsUserData } from "../../interfaces";
import { useTranslation } from "react-i18next";
import { useLanguageTheme } from "../../../../hooks/useLanguageTheme";
import {
  Button,
  Checkbox,
  InputAdornment,
  SelectChangeEvent,
  TextField,
  Theme,
  useMediaQuery,
} from "@mui/material";
import { authTextFieldStyles } from "../../../../pages/Signup";
import { UserTypeCheckboxSelect } from "./UserTypeCheckboxSelect";
import { PhoneSelect } from "../../../_shared/PhoneSelect";
import { termPolicyCheckboxStyles } from "../../../auth/SignupContent";
import { useSendContactUsFormMutation } from "../../../../store/api/contactUsApiSlice";
import { useAppDispatch } from "../../../../store/store";
import { setContactUsFormSnackBarOpen } from "../../../../store/features/contactUsSlice";
import { NotificationStatus } from "../../../campaignBuilder/content/steps/CampaignSetUpStep";
import {
  MAX_COMPANY_NAME_LENG,
  MAX_FULL_NAME_LENG,
  MAX_MESSAGE_LENGTH,
  MAX_PHONE_NUMBER_LENGTH,
  MAX_WEBSITE_LENGTH,
  MIN_PHONE_NUMBER_LENGTH,
} from "../../constants";
import { isValidUrl } from "../../logic";

export const ContactUsFormContainer = () => {
  const { t, i18n } = useTranslation(["contactUs"]);
  const dispatch = useAppDispatch();

  const isRTL = i18n.dir() === "rtl";
  const downMd = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));

  const langTheme = useLanguageTheme();

  const [fullName, setFullName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [userType, setUserType] = React.useState<UserType>(UserType.Buyer);

  const [companyName, setCompanyName] = React.useState("");

  const [phoneNumber, setPhoneNumber] = React.useState("");
  const [selectedCode, setSelectedCode] = React.useState("+972");

  const [companyWebSite, setCompanyWebSite] = React.useState("");
  const [message, setMessage] = React.useState("");

  const [termPolicyChecked, setTermPolicyChecked] = React.useState(false);
  const [isSubmited, setIsSubmited] = React.useState(false);
  const [errors, setErrors] = React.useState<Partial<any>>({});

  const [contactUsSendForm] = useSendContactUsFormMutation();

  const resetLocalState = () => {
    setFullName("");
    setEmail("");
    setUserType(UserType.Buyer);
    setCompanyName("");
    setPhoneNumber("");
    setSelectedCode("+972");
    setCompanyWebSite("");
    setMessage("");
    setTermPolicyChecked(false);
    setIsSubmited(false);
    setErrors({});
  };

  const onSubmit = async (data: IContactUsUserData) => {
    setIsSubmited(true);

    const newErrors: Partial<any> = {};

    if (!fullName.trim()) {
      newErrors.fullName = "full_name_required";
    }

    if (!email.trim()) {
      newErrors.email = "email_required";
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      newErrors.email = "email_invalid";
    }

    let formattedPhoneNumber = phoneNumber;
    if (selectedCode === "+972" && phoneNumber.startsWith("0")) {
      formattedPhoneNumber = phoneNumber.substring(1);
    }

    const phoneRegex = /^\+?[1-9]\d{1,14}$/;
    const fullPhoneNumber = selectedCode + formattedPhoneNumber;

    if (!phoneNumber.trim()) {
      newErrors.phoneNumber = t("phone_number_required");
    } else if (!phoneRegex.test(fullPhoneNumber)) {
      newErrors.phoneNumber = t("invalid_phone_number_format");
    }

    if (!termPolicyChecked) {
      newErrors.termPolicyChecked = t("term");
    }

    setErrors(newErrors);

    // If no errors, proceed with form submission
    if (Object.keys(newErrors).length === 0) {
      try {
        const contactUsData = {
          fullName: fullName,
          email: email,
          userType: userType,
          phoneNumber: formattedPhoneNumber,
          message: message,
          companyName: companyName,
          companyWebsite: companyWebSite,
        };

        const resp = await contactUsSendForm({
          credentials: contactUsData,
          lang: i18n.language,
        }).unwrap();

        if (resp.status === 201) {
          dispatch(
            setContactUsFormSnackBarOpen({
              message: t("feed_back_form_succ_send"),
              type: NotificationStatus.SUCCESSFULL,
            })
          );
          reset();
          resetLocalState();
        }
      } catch (err) {
        console.log("log:err ", err);
        dispatch(
          setContactUsFormSnackBarOpen({
            message: t("feed_back_form_err_send"),
            type: NotificationStatus.ERROR,
          })
        );
      }
    }
  };

  const contactUsFormContext = useForm<IContactUsUserData>({
    defaultValues: {
      fullName: "",
      email: "",
      userType: "",
      companyName: "",
      phoneNumber: "",
      message: "",
      companyWebsite: "",
    },
    reValidateMode: "onChange",
    shouldUseNativeValidation: false,
    mode: "onSubmit",
  });

  const { reset } = contactUsFormContext;

  const handleFullNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setFullName(value);

    const newErrors = { ...errors };

    if (!value.trim()) {
      newErrors.fullName = "full_name_required";
    } else if (value.length >= MAX_FULL_NAME_LENG) {
      newErrors.fullName = "maximum_length_exceeded";
    } else {
      delete newErrors.fullName;
    }

    setErrors(newErrors);
  };

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setEmail(value);

    const newErrors = { ...errors };

    if (!value.trim()) {
      newErrors.email = "email_required";
    } else if (!/\S+@\S+\.\S+/.test(value)) {
      newErrors.email = "email_invalid";
    } else {
      delete newErrors.email;
    }
    setErrors(newErrors);
  };

  // const handlePhoneNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   const value = e.target.value;
  //   setPhoneNumber(value);

  //   const newErrors = { ...errors };
  //   const phoneRegex = /^\+?[1-9]\d{1,14}$/;

  //   let formattedValue = value;
  //   if (selectedCode === "+972" && value.startsWith("0")) {
  //     formattedValue = value.substring(1);
  //   }

  //   const fullPhoneNumber = selectedCode + formattedValue;

  //   if (!value.trim()) {
  //     newErrors.phoneNumber = t("phone_number_required");
  //   } else if (!phoneRegex.test(fullPhoneNumber)) {
  //     newErrors.phoneNumber = t("invalid_phone_number_format");
  //   } else if (
  //     formattedValue.length < MIN_PHONE_NUMBER_LENGTH ||
  //     fullPhoneNumber.length > MAX_PHONE_NUMBER_LENGTH
  //   ) {
  //     newErrors.phoneNumber = t("phone_number_length_invalid");
  //   } else {
  //     delete newErrors.phoneNumber;
  //   }

  //   setErrors(newErrors);
  // };

  const handlePhoneNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setPhoneNumber(value);

    const newErrors = { ...errors };
    const phoneRegex = /^\+?[1-9]\d{1,14}$/;

    let formattedValue = value;
    if (selectedCode === "+972" && value.startsWith("0")) {
      formattedValue = value.substring(1);
    }

    const fullPhoneNumber = selectedCode + formattedValue;

    if (!value.trim()) {
      newErrors.phoneNumber = t("phone_number_required");
    } else if (!phoneRegex.test(fullPhoneNumber)) {
      newErrors.phoneNumber = t("invalid_phone_number_format");
    } else if (
      formattedValue.length < MIN_PHONE_NUMBER_LENGTH ||
      fullPhoneNumber.length > MAX_PHONE_NUMBER_LENGTH
    ) {
      newErrors.phoneNumber = t("phone_number_length_invalid");
    } else {
      delete newErrors.phoneNumber;
    }

    setErrors(newErrors);
  };

  const handleCodeChange = (event: SelectChangeEvent<string>) => {
    setSelectedCode(event.target.value);
  };

  const handleCompanyWebSiteChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = e.target.value;
    setCompanyWebSite(value);

    const newErrors = { ...errors };

    if (value.trim() === "") {
      delete newErrors.companyWebSite;
    } else if (!isValidUrl(value)) {
      newErrors.companyWebSite = "invalid_link";
    } else if (value.length >= MAX_WEBSITE_LENGTH) {
      newErrors.companyWebSite = "maximum_length_exceeded";
    } else {
      delete newErrors.companyWebSite;
    }
    setErrors(newErrors);
  };

  const handleMessageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setMessage(value);

    const newErrors = { ...errors };

    if (value.length >= MAX_MESSAGE_LENGTH) {
      newErrors.message = "maximum_length_exceeded";
    } else {
      delete newErrors.message;
    }

    setErrors(newErrors);
  };

  const handleCompanyNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setCompanyName(value);

    const newErrors = { ...errors };

    if (value.trim() === "") {
      delete newErrors.companyName;
    } else if (value.length >= MAX_COMPANY_NAME_LENG) {
      newErrors.companyName = "maximum_length_exceeded";
    } else {
      delete newErrors.companyName;
    }
    setErrors(newErrors);
  };

  useEffect(() => {
    const newErrors = { ...errors };

    if (!termPolicyChecked) {
      newErrors.termPolicyChecked = t("term");
    } else {
      delete newErrors.termPolicyChecked;
    }
    setErrors(newErrors);

    if (isSubmited) {
      if (termPolicyChecked) {
        delete newErrors.termPolicyChecked;
      } else {
        newErrors.termPolicyChecked = t("term");
      }

      setErrors(newErrors);
    }
  }, [termPolicyChecked, isSubmited]);

  const hasError = useMemo(() => Object.keys(errors).length !== 0, [errors]);

  return (
    <FormContainer
      formContext={contactUsFormContext}
      onSuccess={(data) => onSubmit(data)}
    >
      <div
        className="contact-us-form-container"
        style={{
          fontFamily: langTheme.mainFont(),
        }}
      >
        <div className="contact-us-for-fullname-container contact-us-item-container">
          <div className="contact-us-input-label">{t("full_name")}</div>
          <TextField
            type="text"
            name="fullName"
            value={fullName}
            onChange={handleFullNameChange}
            fullWidth
            required
            inputProps={{
              maxLength: MAX_FULL_NAME_LENG,
            }}
            placeholder={t("full_name_example")}
            error={!!errors.fullName}
            helperText={errors.fullName ? t(errors.fullName) : " "}
            className={`form-input-wrapper ${!!errors.fullName ? "error" : ""}`}
            sx={(theme) =>
              authTextFieldStyles.textField(theme, isRTL, !!errors.fullName)
            }
            FormHelperTextProps={{
              sx: {
                textAlign: isRTL ? "right" : "left",
                fontFamily: langTheme.mainFont(),
              },
            }}
          />
        </div>

        <div className="contact-us-for-email-container contact-us-item-container">
          <div className="contact-us-input-label">{t("email")}</div>
          <TextField
            type="email"
            value={email}
            onChange={handleEmailChange}
            fullWidth
            required
            inputProps={{
              maxLength: 254,
            }}
            error={!!errors.email}
            helperText={errors.email ? t(errors.email) : " "}
            placeholder={t("email_placeholder")}
            sx={(theme) =>
              authTextFieldStyles.textField(theme, isRTL, !!errors.email)
            }
            FormHelperTextProps={{
              sx: {
                textAlign: isRTL ? "right" : "left",
                fontFamily: langTheme.mainFont(),
              },
            }}
          />
        </div>

        <div className="contact-us-for-user-type-container contact-us-item-container">
          <UserTypeCheckboxSelect
            userType={userType}
            setUserType={setUserType}
          />
        </div>

        <div className="contact-us-for-company-name-container contact-us-item-container">
          <div className="contact-us-input-label">
            {t("company_name_title")}
          </div>
          <TextField
            type="text"
            value={companyName}
            onChange={handleCompanyNameChange}
            fullWidth
            required
            inputProps={{
              maxLength: MAX_COMPANY_NAME_LENG,
            }}
            error={!!errors.companyName}
            helperText={errors.companyName ? t(errors.companyName) : " "}
            placeholder={t("company_name_placeholder")}
            sx={(theme) =>
              authTextFieldStyles.textField(theme, isRTL, !!errors.companyName)
            }
            FormHelperTextProps={{
              sx: {
                textAlign: isRTL ? "right" : "left",
                fontFamily: langTheme.mainFont(),
              },
            }}
          />
        </div>

        <div className="contact-us-for-phone-container contact-us-item-container">
          <div className="contact-us-input-label">{t("phone_number")}</div>
          <TextField
            type="tel"
            fullWidth
            required
            value={phoneNumber}
            onChange={handlePhoneNumberChange}
            error={!!errors.phoneNumber}
            helperText={errors.phoneNumber ? t(errors.phoneNumber) : " "}
            inputProps={{
              maxLength: MAX_PHONE_NUMBER_LENGTH,
            }}
            FormHelperTextProps={{
              sx: {
                textAlign: isRTL ? "right" : "left",
                fontFamily: langTheme.mainFont(),
              },
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <PhoneSelect
                    selectedCode={selectedCode}
                    handleCodeChange={handleCodeChange}
                  />
                </InputAdornment>
              ),
            }}
            sx={(theme) => ({
              ...authTextFieldStyles.textField(
                theme,
                isRTL,
                !!errors.phoneNumber
              ),
              input: {
                textAlign: isRTL ? "right" : "left",
                direction: i18n.dir(),
              },
            })}
          />
        </div>
        <div className="contact-us-for-message-container contact-us-item-container">
          <div className="contact-us-input-label">{t("message")}</div>
          <TextField
            type="text"
            value={message}
            onChange={handleMessageChange}
            fullWidth
            inputProps={{
              maxLength: MAX_MESSAGE_LENGTH,
            }}
            multiline
            error={!!errors.message}
            helperText={errors.message ? t(errors.message) : " "}
            placeholder={t("message_placeholder")}
            sx={(theme) => ({
              ...authTextFieldStyles.textField(theme, isRTL, !!errors.message),
              "& .MuiInputBase-root": {
                padding: 0,
              },
              "& textarea": {
                minHeight: "119px",
              },
            })}
            FormHelperTextProps={{
              sx: {
                textAlign: isRTL ? "right" : "left",
                fontFamily: langTheme.mainFont(),
              },
            }}
          />
        </div>

        <div className="contact-us-for-company-website-container contact-us-item-container">
          <div className="contact-us-input-label">
            {t("company_website_title")}
          </div>

          <TextField
            type="text"
            value={companyWebSite}
            onChange={handleCompanyWebSiteChange}
            fullWidth
            required
            inputProps={{
              maxLength: MAX_WEBSITE_LENGTH,
            }}
            error={!!errors.companyWebSite}
            helperText={errors.companyWebSite ? t(errors.companyWebSite) : " "}
            placeholder={t("company_website_placeholder")}
            sx={(theme) =>
              authTextFieldStyles.textField(
                theme,
                isRTL,
                !!errors.companyWebSite
              )
            }
            FormHelperTextProps={{
              sx: {
                textAlign: isRTL ? "right" : "left",
                fontFamily: langTheme.mainFont(),
              },
            }}
          />
        </div>
      </div>

      <div className="contact-us-submit-and-term-policy-container">
        <div
          className="sign-up-term-policy"
          style={{
            marginTop: "10px",
          }}
        >
          <Checkbox
            checked={termPolicyChecked}
            onChange={() => setTermPolicyChecked(!termPolicyChecked)}
            sx={termPolicyCheckboxStyles.root}
          />
          <div
            style={{
              fontFamily: langTheme.mainFont(),
              fontWeight: isRTL ? 400 : 500,
            }}
          >
            {t("term_policy")}
          </div>
        </div>
        <Button
          disabled={hasError}
          type="submit"
          variant="contained"
          className="contact-us-submit-btn"
          sx={{
            textTransform: "capitalize",
            backgroundColor: "rgba(3, 74, 115, 1)",
            borderRadius: "16px",
            padding: "13px",
            fontSize: "16px",
            fontWeight: 500,
            fontFamily: langTheme.mainFont(),
            marginTop: downMd ? "20px" : "35px",
            "&.Mui-disabled": {
              backgroundColor: "rgba(90, 130, 153, 0.716)",
              color: "rgb(255, 255, 255)",
            },
          }}
        >
          {t("submit")}
        </Button>
      </div>
    </FormContainer>
  );
};
