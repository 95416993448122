import { Box, Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import Megaphone from "../../assets/icons/megaphone.svg";
import Discount from "../../assets/icons/discount.svg";
import Award from "../../assets/icons/award.svg";
import Negotiation from "../../assets/icons/negotiation.svg";

export default function WhyChooseUs() {
  const { t, i18n } = useTranslation(["sellWithUs"]);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isRotatedMobile = useMediaQuery(theme.breakpoints.down(800));
  const isRTL = i18n.dir() === "rtl";

  const titleFont = isRTL ? "Noto Sans Hebrew" : "Frank Ruhl Libre";
  const bodyFont = isRTL ? "Noto Sans Hebrew" : "DM Sans";

  const benefits = [
    {
      icon: Megaphone,
      title: t("reach"),
      description: t("leverage"),
    },
    {
      icon: Discount,
      title: t("accelerated_cycle"),
      description: t("ai_powered"),
    },
    {
      icon: Award,
      title: t("high_quality"),
      description: t("marketing_team"),
    },
    {
      icon: Negotiation,
      title: t("simple_effective"),
      description: t("from_lead"),
    },
  ];

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        backgroundColor: "#034A73",
        marginTop: isMobile ? "50px" : "150px",
        padding: isMobile ? "20px" : "20px",
        boxSizing: "border-box",
      }}
    >
      <div className="xl-width">
        <Box
          sx={{
            color: "white",
            display: "flex",
            flexDirection: { xs: "column", md: isRTL ? "row-reverse" : "row" },
            justifyContent: "space-between",
            alignItems: { xs: "center", md: "flex-start" },
            gap: { xs: 4, md: 0 },
          }}
        >
          {/* Title Section */}
          <Box
            sx={{
              alignSelf: "center",
              justifySelf: "center",
              textAlign: isRotatedMobile ? "center" : isRTL ? "right" : "left",
              fontFamily: titleFont,
              width: { xs: "100%", md: "30%" },
              // px: 1
            }}
          >
            <Typography
              variant="h4"
              sx={{
                mb: 1,
                fontWeight: 600,
                fontSize: { xs: "1.5rem", sm: "2rem", md: "24px", lg: "36px" },
                textTransform: "uppercase",
                lineHeight: 1.2,
              }}
            >
              {t("why_sellers")}
            </Typography>
            <Typography
              variant="h4"
              sx={{
                mb: 1,
                fontWeight: "bold",
                fontSize: { xs: "1.5rem", sm: "2rem", md: "24px", lg: "36px" },
                textTransform: "uppercase",
                lineHeight: 1.2,
              }}
            >
              {t("choose")}
            </Typography>
            <Typography
              variant="h4"
              sx={{
                fontWeight: "bold",
                fontSize: { xs: "1.5rem", sm: "2rem", md: "24px", lg: "36px" },
                textTransform: "uppercase",
                position: "relative",
                display: "inline-block",
                lineHeight: 1.2,
                "&::before": {
                  content: '""',
                  position: "absolute",
                  top: 0,
                  left: "-5%",
                  right: 0,
                  bottom: "5%",
                  backgroundColor: "#E8EFF236",
                  borderRadius: "4px",
                  zIndex: 2,
                  width: "110%",
                  height: "100%",
                },
              }}
            >
              {t("sentom")}
            </Typography>
          </Box>

          {/* Benefits Section */}
          <Box
            sx={{
              width: { xs: "100%", md: "70%" },
              position: "relative",
              order: isMobile ? 2 : isRTL ? 1 : 2,
            }}
          >
            <Grid container spacing={0} sx={{ position: "relative" }}>
              {benefits.map((benefit, index) => (
                <Grid item xs={12} sm={6} key={index}>
                  <Box
                    sx={{
                      position: "relative",
                      pb: 0,
                      minHeight: !isMobile ? "250px" : "auto",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    {/* Single Container for Icon + Text */}
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: isMobile
                          ? "column"
                          : isRTL
                          ? "row-reverse"
                          : "row",
                        alignItems: "flex-start",
                        alignSelf:
                          index === 0 || index === 1
                            ? "flex-end"
                            : "flex-start",
                        mb: isMobile
                          ? 4
                          : index === 0 || index === 1
                          ? isRTL
                            ? "10px"
                            : "20px"
                          : "",
                        mt: isMobile
                          ? "5px"
                          : index === 0 || index === 1
                          ? ""
                          : "22px",
                        marginLeft: !isMobile
                          ? isRTL
                            ? index % 2 === 0
                              ? 3
                              : 6
                            : index % 2 === 1
                            ? 3
                            : 0
                          : 0,
                        marginRight: !isMobile
                          ? isRTL
                            ? index % 2 === 1
                              ? 3
                              : 6
                            : index % 2 === 0
                            ? 3
                            : 2
                          : 0,
                        paddingTop: { xs: 2, md: 0 },
                        paddingBottom: { xs: 2, md: 0 },
                        height: isMobile ? "175px" : "auto",
                        justifyContent: "space-between",
                        width: "100%",
                      }}
                    >
                      <Box
                        sx={{
                          minHeight: "44px",
                          minWidth: "44px",
                          borderRadius: "50%",
                          background:
                            "linear-gradient(30deg, #4698C6, #FCBC5CB8 72%)",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          alignSelf:
                            isMobile && isRTL ? "flex-end" : "flex-start",
                          mx: 0,
                        }}
                      >
                        <img src={benefit.icon} alt={benefit.title} />
                      </Box>
                      <Box
                        sx={{
                          mx: { xs: 2, md: "15px" },
                          textAlign: isRTL ? "right" : "left",
                          fontFamily: bodyFont,
                          height: "100%",
                        }}
                      >
                        <Typography
                          variant="h6"
                          sx={{
                            mt: 2,
                            mb: 1,
                            fontWeight: 600,
                            fontSize: { xs: "1.1rem", sm: "1.25rem" },
                            fontFamily: isRTL ? "Noto Sans Hebrew" : "DM sans",
                            letterSpacing: "4%",
                            lineHeight: "20px",
                          }}
                        >
                          {benefit.title}
                        </Typography>
                        <Typography
                          variant="body2"
                          sx={{
                            fontSize: { xs: "16px", sm: "16px" },
                            fontFamily: isRTL ? "Noto Sans Hebrew" : "DM sans",
                            lineHeight: 1.6,
                            fontWeight: 400,
                            color: "#FFFFFFE0",
                          }}
                        >
                          {benefit.description}
                        </Typography>
                      </Box>
                    </Box>
                    {/* Horizontal Border for Mobile */}
                    {isMobile && index < benefits.length - 1 && (
                      <Box
                        sx={{
                          position: "absolute",
                          bottom: 0,
                          left: 0,
                          right: 0,
                          width: "100%",
                          height: "1px",
                          backgroundColor: "#FFFFFF57",
                        }}
                      />
                    )}
                  </Box>
                </Grid>
              ))}

              {/* Vertical Border for Desktop */}
              {!isMobile && (
                <>
                  <Box
                    sx={{
                      position: "absolute",
                      top: "8%",
                      left: !isRTL ? "50%" : "auto",
                      right: isRTL ? "50%" : "auto",
                      width: "1px",
                      height: "40%",
                      backgroundColor: "#FFFFFF57",
                    }}
                  />
                  <Box
                    sx={{
                      position: "absolute",
                      top: "53%",
                      left: !isRTL ? "50%" : "auto",
                      right: isRTL ? "50%" : "auto",
                      width: "1px",
                      height: "40%",
                      backgroundColor: "#FFFFFF57",
                    }}
                  />
                </>
              )}

              {/* Horizontal Border for Desktop */}
              {!isMobile && (
                <>
                  <Box
                    sx={{
                      position: "absolute",
                      top: "50%",
                      left: 0,
                      right: "53%",
                      width: "49%",
                      height: "1px",
                      backgroundColor: "#FFFFFF57",
                    }}
                  />
                  <Box
                    sx={{
                      position: "absolute",
                      top: "50%",
                      left: "51%",
                      right: 0,
                      width: "48%",
                      height: "1px",
                      backgroundColor: "#FFFFFF57",
                    }}
                  />
                </>
              )}
            </Grid>
          </Box>
        </Box>
      </div>
    </div>
  );
}
