import React from "react";
import AssistantPng from "../../../assets/campaignBuilder/ai-chat/assistant.png";
import { useTranslation } from "react-i18next";

interface AssistantProps {
  message: string;
}

export const Assistant: React.FC<AssistantProps> = ({ message })=>{
   const { t, i18n } = useTranslation(["aiChart"]);
  return (
    <div className="assistent">
      <div className="assistent_first_child">
        <div className="img_box">
          <img src={AssistantPng} alt="" />
        </div>
        <h3>{t("ai_assistant")}</h3>
      </div>

      <div className="assistent_second_child">
        <p>{message}</p>
      </div>

    </div>
  );
};

