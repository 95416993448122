import React from "react";
import { useTranslation } from "react-i18next";
import {
  IconButton,
  InputAdornment,
  TextField,
  Theme,
  useMediaQuery,
} from "@mui/material";
import Visibility1 from "../../assets/icons/visibility.svg";
import VisibilityOff from "../../assets/icons/visibility_off.svg";
import { useNavigate } from "react-router-dom";
import { SignWithGoogleBtn } from "../auth/SignWithGoogleBtn";
import { ORBlock } from "../auth/ORBlock";
import { useForm } from "react-hook-form";
import { FormContainer } from "react-hook-form-mui";
import { authTextFieldStyles, ISignupError } from "../../pages/Signup";
import { useLanguageTheme } from "../../hooks/useLanguageTheme";
import { useSigninMutation } from "../../store/api/authApiSlice";
import { BackBtnAuth } from "../auth/BackBtnAuth";
import { CustomBlueCheckbox } from "../auth/CustomBlueCheckbox";
import { useSelector } from "react-redux";
import {
  authPopupOpenSelector,
  setAuthPopupClose,
  setAuthPopupOpen,
} from "../../store/features/common";
import { useAppDispatch } from "../../store/store";
import { setCredentials } from "../../store/features/authSlice";
import { AuthPopupContentType } from "../auth/types";

export interface ISignInData {
  identifier: string;
  password: string;
}

export interface ISignInError {
  email?: string;
  password?: string;
}

export const SigninContent = ({ isPopup }: { isPopup: boolean }) => {
  const dispatch = useAppDispatch();
  const downMd = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));
  const navigate = useNavigate();
  const { t } = useTranslation(["signin"]);
  const { i18n } = useTranslation();
  const isRTL = i18n.dir() === "rtl";

  const langTheme = useLanguageTheme();
  const [showPassword, setShowPassword] = React.useState(false);
  const [keepMeLoggedInChecked, setKeepMeLoggedInChecked] =
    React.useState(false);

  const [signin] = useSigninMutation();

  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [errors, setErrors] = React.useState<Partial<ISignInError>>({});
  const [submitError, setSubmitError] = React.useState("");

  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const validatePassword = (
    password: string,
    errors: Partial<ISignupError>
  ) => {
    if (!password.trim()) {
      errors.password = t("password_required");
    }
  };

  const onSubmit = async (data: ISignInData) => {
    const newErrors: Partial<ISignInError> = {};

    validatePassword(password, newErrors);

    if (!email.trim()) {
      newErrors.email = t("email_required");
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      newErrors.email = t("email_invalid");
    }

    setErrors(newErrors);

    if (Object.keys(newErrors).length === 0) {
      try {
        const response = await signin({
          identifier: email,
          password: password,
        }).unwrap();

        if (keepMeLoggedInChecked) {
          localStorage.setItem("jwt", JSON.stringify(response.jwt));
          localStorage.setItem("user", JSON.stringify(response.user));
        } else {
          sessionStorage.setItem("jwt", JSON.stringify(response.jwt));
          sessionStorage.setItem("user", JSON.stringify(response.user));
        }
        dispatch(setCredentials({ jwt: response.jwt, user: response.user }));
        dispatch(setAuthPopupClose());

        if (window.location.pathname === "/home") {
          window.location.reload();
        } else {
          navigate("/home");
        }
      } catch (err: any) {
        if (err.data.error.message === "Your account email is not confirmed") {
          dispatch(setAuthPopupOpen(AuthPopupContentType.SIGNUP));
        }
        setSubmitError(t("authentication.error.invalidCredentials"));
      }
    }
  };

  const handleForgotPassword = () => {
    dispatch(setAuthPopupOpen(AuthPopupContentType.FORGOT_PASSWORD));
  };

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setEmail(value);

    const newErrors = { ...errors };

    if (!value.trim()) {
      newErrors.email = t("email_required");
    } else if (!/\S+@\S+\.\S+/.test(value)) {
      newErrors.email = t("email_invalid");
    } else {
      delete newErrors.email;
    }
    setErrors(newErrors);
  };

  const formContext = useForm<ISignInData>({
    defaultValues: {
      identifier: "",
      password: "",
    },
    shouldUseNativeValidation: false,
    mode: "onSubmit",
  });

  const signupPopupOpen = useSelector(authPopupOpenSelector);

  const handleGoToSignup = () => {
    if (signupPopupOpen) {
      //TODO
      // dispatch(setIsLoginPopupContent({ isLoginPopupContent: false }));
      dispatch(setAuthPopupOpen(AuthPopupContentType.SIGNUP));
    } else {
      navigate("/signup");
    }
  };

  return (
    <div
      className="signin-form-section"
      style={{
        height: downMd ? "100vh" : "",
      }}
    >
      <div
        className="signin-form-container"
        style={{
          direction: i18n.dir(),
        }}
      >
        {!isPopup && <BackBtnAuth />}
        <div
          className="signin-form-title-container"
          style={{
            direction: i18n.dir(),
          }}
        >
          <div
            className="signin-form-title-signin"
            style={{
              fontFamily: isRTL ? "Noto Sans Hebrew" : "Frank Ruhl Libre",
              textTransform: "capitalize",
            }}
          >
            {t("sign_in")}
          </div>
          <div
            className="signin-vertical-divider"
            style={{
              marginRight: isRTL ? "12px" : "16px",
              marginLeft: isRTL ? "12px" : "20px",
            }}
          ></div>
          <div
            className="signin-form-title-welcom"
            style={{
              fontFamily: langTheme.mainFont(),
            }}
          >
            {t("welcome_back")}
          </div>
        </div>
        <SignWithGoogleBtn type={"signin"} />
        <ORBlock />

        <div className="signin-form-inputs-container">
          <FormContainer
            formContext={formContext}
            onSuccess={(data) => onSubmit(data)}
          >
            <div
              className="signin-form-inputs-label"
              style={{
                fontFamily: langTheme.mainFont(),
              }}
            >
              {t("email")}
            </div>
            <TextField
              type="email"
              fullWidth
              required
              value={email}
              error={!!errors.email}
              helperText={errors.email || " "}
              onChange={handleEmailChange}
              placeholder={t("email_placeholder")}
              sx={(theme) => authTextFieldStyles.textField(theme, isRTL, false)}
              FormHelperTextProps={{
                sx: {
                  textAlign: isRTL ? "right" : "left",
                  fontFamily: langTheme.mainFont(),
                },
              }}
            />
            <div
              className="signin-form-inputs-label"
              style={{
                fontFamily: langTheme.mainFont(),
                marginTop: "15px",
              }}
            >
              {t("password")}
            </div>
            <TextField
              type={showPassword ? "text" : "password"}
              fullWidth
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              error={!!errors.password}
              helperText={errors.password || " "}
              required
              FormHelperTextProps={{
                sx: {
                  textAlign: isRTL ? "right" : "left",
                  fontFamily: langTheme.mainFont(),
                },
              }}
              placeholder={t("password_placeholder")}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={handleTogglePasswordVisibility}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? (
                        <img
                          src={VisibilityOff}
                          style={{
                            width: "24px",
                            height: "24px",
                            marginRight: "8px",
                          }}
                        />
                      ) : (
                        <img
                          src={Visibility1}
                          style={{
                            width: "24px",
                            height: "24px",
                            marginRight: "8px",
                          }}
                        />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              sx={(theme) => authTextFieldStyles.textField(theme, isRTL, false)}
            />
            <div className="signin-form-k-m-l-in-and-g-p-container">
              <div className="signin-form-keep-me-logged-in-container">
                <CustomBlueCheckbox
                  checked={keepMeLoggedInChecked}
                  setChecked={setKeepMeLoggedInChecked}
                />
                <div
                  style={{
                    fontFamily: langTheme.mainFont(),
                    fontWeight: isRTL ? 400 : 500,
                    color: "rgba(43, 54, 116, 1)",
                    fontSize: "14px",
                    letterSpacing: "-0.32px",
                    lineHeight: "20px",
                  }}
                >
                  {t("keep_me_logged_in")}
                </div>
              </div>
              <div
                onClick={() => handleForgotPassword()}
                style={{
                  fontFamily: langTheme.mainFont(),
                  fontWeight: isRTL ? 400 : 500,
                  color: "rgba(215, 161, 72, 1)",
                  fontSize: "14px",
                  letterSpacing: "-0.32px",
                  lineHeight: "20px",
                  cursor: "pointer",
                }}
              >
                {t("forget_password")}
              </div>
            </div>

            {submitError && (
              <div
                className="submit-error"
                style={{
                  fontFamily: langTheme.mainFont(),
                  direction: i18n.dir(),
                }}
              >
                {submitError}
              </div>
            )}
            <button
              type="submit"
              className="signin-form-inputs-signin-btn"
              style={{
                fontFamily: langTheme.mainFont(),
                fontWeight: isRTL ? 700 : 500,
              }}
            >
              {t("sign_in")}
            </button>
          </FormContainer>

          <div className="not-registred-yet-container">
            <div
              className="not-registred-yet-container-item"
              style={{
                fontFamily: langTheme.mainFont(),
                fontWeight: isRTL ? 400 : 500,
                color: "rgba(43, 54, 116, 1)",
              }}
            >
              {t("not_registered_yet")}
            </div>
            <div
              className="not-registred-yet-container-item"
              onClick={() => handleGoToSignup()}
              style={{
                fontFamily: langTheme.mainFont(),
                fontWeight: isRTL ? 700 : 500,
                color: "rgba(215, 161, 72, 1)",

                cursor: "pointer",
              }}
            >
              {t("create_an_account")}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
