import { skipToken } from "@reduxjs/toolkit/query";
import {
  Dispatch,
  SetStateAction,
  useLayoutEffect,
  useMemo,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import FilledHeartIcon from "../../assets/icons/filled_heart.svg";
import FilledHeartLargeIcon from "../../assets/icons/filled_heart_large.svg";
import OutlinedHeartIcon from "../../assets/icons/outline_heart.svg";
import OutlinedHeartLargeIcon from "../../assets/icons/outline_heart_large.svg";
import {
  useCreateUserWishlistMutation,
  useGetUserWishlistWithoutParamsQuery,
  useUpdateUserWishlistMutation,
} from "../../store/api/wishlistApiSlice";
import { setWishlistSnackbarOpen } from "../../store/features/wishlistSlice";
import { useAppDispatch } from "../../store/store";
import { IInitialProperty } from "../../types/initialProperty";
import { setAuthPopupOpen } from "../../store/features/common";
import { useAuth } from "../../hooks/useAuth";
import { MappedPropertyPoint } from "../portfolio/content/map/MapUpMd";
import { AuthPopupContentType } from "../auth/types";

export enum LikeSize {
  SMALL = "small",
  LARGE = "large",
}

export const LikeDemo = ({
  propertyId,
  likeSize,
  setSelectedPoint,
}: {
  propertyId: number;
  likeSize: string;
  setSelectedPoint?: Dispatch<SetStateAction<MappedPropertyPoint | null>>;
}) => {
  const { t, i18n } = useTranslation(["profile"]);
  const dispatch = useAppDispatch();
  const isAuthenticated = useAuth();

  const userString =
    localStorage.getItem("user") || sessionStorage.getItem("user");
  const user = userString ? JSON.parse(userString) : null;
  const storedJwt =
    localStorage.getItem("jwt") || sessionStorage.getItem("jwt");
  const jwt = storedJwt ? storedJwt.replace(/"/g, "") : "";

  const getWishlistParams = useMemo<any | null>(() => {
    if (!user || !user.id) return null;
    return {
      params: {
        userId: user.id,
        jwt: jwt,
      },
      lang: i18n.language,
    };
  }, [user, jwt, i18n.language]);

  const { data: userWishlistData } = useGetUserWishlistWithoutParamsQuery(
    getWishlistParams ?? skipToken
  );

  const [createWishlist] = useCreateUserWishlistMutation();
  const [updateWishlist] = useUpdateUserWishlistMutation();
  const [isHovered, setIsHovered] = useState(false);
  const [isCheckedLike, setIsCheckedLike] = useState(false);

  const [propertyIds, setPropertyIds] = useState<number[]>([]);
  const isDisabled = propertyId === -1;

  useLayoutEffect(() => {
    if (userWishlistData?.data) {
      setPropertyIds(
        userWishlistData?.data.properties.map(
          (property: IInitialProperty) => property?.id
        )
      );
    }
  }, [userWishlistData]);

  useLayoutEffect(() => {
    if (checkIsPropertyInWishlist(propertyId)) {
      setIsCheckedLike(true);
    } else {
      setIsCheckedLike(false);
    }
  }, [propertyIds, propertyId]);

  function checkIsPropertyInWishlist(propertyId: number) {
    return propertyIds.includes(propertyId);
  }

  const handleClickLike = async (e: React.MouseEvent<HTMLDivElement>) => {
    if (isDisabled) return;
    e.stopPropagation();

    if (!user || !user.id || !jwt) {
      console.error("User not logged in");
      dispatch(setAuthPopupOpen(AuthPopupContentType.SIGNUP));

      return;
    }

    if (isAuthenticated) {
      const wasChecked = isCheckedLike;
      setIsCheckedLike(!isCheckedLike);
      dispatch(
        setWishlistSnackbarOpen({
          message: isCheckedLike
            ? t("likeSnackBarDeletedFromWishlist")
            : t("likeSnackBarAddedToWishlist"),
        })
      );

      try {
        let newWishlist: number[] = [];
        if (!userWishlistData?.data) {
          newWishlist = [propertyId];
          const data = {
            data: {
              users_permissions_user: [user.id],
              properties: newWishlist,
            },
          };
          await createWishlist({ wishlistData: data, jwt }).unwrap();
          setSelectedPoint?.(null);
        } else {
          newWishlist = checkIsPropertyInWishlist(propertyId)
            ? propertyIds.filter((id) => id !== propertyId)
            : [...propertyIds, propertyId];
          const data = {
            data: {
              properties: newWishlist,
            },
          };
          await updateWishlist({
            jwt,
            wishlistId: userWishlistData.data.documentId,
            updatedData: data,
          }).unwrap();
          setSelectedPoint?.(null);
        }
      } catch (e) {
        console.error("Error updating wishlist:", e);
        setIsCheckedLike(wasChecked);
      }
    } else {
      dispatch(setAuthPopupOpen(AuthPopupContentType.SIGNUP));
    }
  };

  const handleMouseEnter = () => {
    if (!isDisabled) {
      setIsHovered(true);
    }
  };

  const handleMouseLeave = () => {
    if (!isDisabled) {
      setIsHovered(false);
    }
  };

  return (
    <>
      <div
        className={`
        ${
          likeSize === LikeSize.SMALL
            ? "featured-properties-like"
            : "featured-properties-like-large"
        }
        ${isDisabled ? "disabled-like" : ""}
      `}
        onClick={handleClickLike}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        style={isDisabled ? { pointerEvents: "none" } : {}}
      >
        {likeSize === LikeSize.SMALL ? (
          <img
            src={
              isHovered || isCheckedLike ? FilledHeartIcon : OutlinedHeartIcon
            }
            alt="Like Button"
          />
        ) : (
          <img
            src={
              isHovered || isCheckedLike
                ? FilledHeartLargeIcon
                : OutlinedHeartLargeIcon
            }
            alt="Like Button"
          />
        )}
      </div>
    </>
  );
};
