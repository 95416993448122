import {
  Button,
  InputAdornment,
  Menu,
  MenuItem,
  TextField,
  Theme,
  useMediaQuery,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { CampaignSideNavStep } from "../layout/CampaignBuilderSideNav";
import { CampaignBuilderSideNavHeader } from "../layout/CampaignBuilderSideNavHeader";
import { useLanguageTheme } from "../../../../hooks/useLanguageTheme";
import { useEffect, useRef, useState } from "react";
import { ExpandMore } from "@mui/icons-material";
import { IconSelect } from "../selects/IconSelect";
import {
  HighlightIconMap,
  highlightOptions,
  BuildingBlueIcon,
} from "../../../../constants/campaignBuilderData";
import { Option } from "../selects/HighlightSelect";
import TrashIcon from "../../../../assets/campaignBuilder/trash-icon.svg";
import HideIcon from "../../../../assets/campaignBuilder/eye-off.svg";
import UnhideIcon from "../../../../assets/campaignBuilder/eye.svg";
import {
  useCreateInvestmentHightLightMutation,
  useUpdateInvestmentHightLightMutation,
  useLazyGetHightLightsWithParamsQuery,
} from "../../../../store/api/campaignBuilderApiSlice";
import { useAppDispatch } from "../../../../store/store";
import { useParams } from "react-router-dom";
import { textFieldStyles3 } from "./ContactInformation";
import { useResetToDraft } from "../../../../hooks/useResetToDraft";
import { useSelector } from "react-redux";
import { setHightlightsBack } from "../../../../store/features/campaignBuilderSlice";
import {
  setDeleteHighlightOpen,
  setToggleVisibilityNotification,
} from "../../../../store/features/campaignBuilderSetup";
import { MAX_HIGHTLIGHT_LENGTH } from "../../constants";
import { CampainBuilderBackBtn } from "../buttons/CampainBuilderBackBtn";

export const AddNewHightlight = ({
  setCurrentStep,
  hightlightOptionsState,
  setSelectedHightLight,
  selectedHightLight,
  handleSaveHighlights,
  highlightForEditId,
}: {
  setCurrentStep: (step: CampaignSideNavStep) => void;
  hightlightOptionsState: any;
  setSelectedHightLight: any;
  selectedHightLight: any;
  handleSaveHighlights: any;
  highlightForEditId: any;
}) => {
  const langTheme = useLanguageTheme();
  const { t, i18n } = useTranslation(["campaignBuilder"]);
  const currentLanguage = i18n.language;
  const isRTL = currentLanguage === "he";
  const downMd = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));

  const { hightlightsBack } = useSelector(
    (state: any) => state.campaignBuilder
  );

  const [createHightlight] = useCreateInvestmentHightLightMutation();
  const [updateHighlight] = useUpdateInvestmentHightLightMutation();
  const [triggerHightLightsWithParams] = useLazyGetHightLightsWithParamsQuery();

  const dispatch = useAppDispatch();
  const dropdownRef = useRef<any>(null);
  const [debounceTrigger, setDebounceTrigger] = useState(false);
  const userId = JSON.parse(
    localStorage.getItem("user") || sessionStorage.getItem("user") || "{}"
  )?.id;
  const { documentId } = useParams();

  const resetToDraft = useResetToDraft();

  const [openCustomDropdown, setOpenCustomDropdown] = useState(false);
  const componentRef = useRef<any>(null);

  const highlightDefConfig = {
    id: 0,
    labelNameEn: "",
    labelNameHe: "",
    iconKey: "location",
    authorId: userId,
    type: "custom",
    details: {},
  };

  const [editHighlightParams, setEditHighlightParams] =
    useState<any>(highlightDefConfig);

  const [anchorEl, setAnchorEl] = useState<any>(null);

  const createHighLight = async (creationParams?: any) => {
    let params = {} as any;
    const optionData = {
      labelNameEn: "",
      labelNameHe: "",
      iconKey: "location",
      authorId: userId,
      type: "custom",
      details: {},
    };
    params = await createHightlight({
      optionData: creationParams ?? optionData,
    });
    params = params.data.data;
    resetToDraft();
    localStorage.setItem("onChangeCompanyBuilder", "1");

    return params;
  };

  useEffect(() => {
    const editHighlightId = localStorage.getItem("editKeyFeatureHighlightId");
    if (
      editHighlightId &&
      hightlightOptionsState &&
      Array.isArray(hightlightOptionsState) &&
      hightlightOptionsState.length > 0 &&
      editHighlightId != "0"
    ) {
      const highlightParams = hightlightOptionsState.find(
        (el: any) => el.id == editHighlightId
      ) as Option;
      setEditHighlightParams(highlightParams);
    }
  }, [hightlightOptionsState]);

  const handleDeleteOption = async (documentId: string) => {
    dispatch(
      setSelectedHightLight(
        selectedHightLight.filter((el: any) => el.documentId !== documentId)
      )
    );
    resetToDraft();
    localStorage.setItem("onChangeCompanyBuilder", "1");

    dispatch(
      setDeleteHighlightOpen({
        deleteHightlightMessage: t("highlight_was_deleted"),
      })
    );
    triggerHightLightsWithParams({ userId });
    setCurrentStep(CampaignSideNavStep.investmentHighlights);
  };

  function updateItemById(array: any[], id: number, newData: any) {
    return array.map((item) =>
      item.id === id ? { ...item, ...newData } : item
    );
  }

  const handleHideOption = async (
    documentIdHighlight: string,
    isHide = true,
    params: any
  ) => {
    let hideParams = { ...params.details };
    if (isHide) {
      hideParams = { ...hideParams, [documentId ?? ""]: documentIdHighlight };
    } else {
      delete hideParams[documentId ?? ""];
    }
    await updateHighlight({
      documentId: documentIdHighlight,
      optionData: {
        details: hideParams,
      },
    });
    setEditHighlightParams((prev: any) => ({ ...prev }));
    dispatch(
      setSelectedHightLight(
        updateItemById(selectedHightLight, editHighlightParams.id, {
          details: hideParams,
        })
      )
    );

    dispatch(
      setToggleVisibilityNotification({
        toggleVisibilityNotificationMessage: isHide
          ? t("highlight_hided")
          : t("highlight_showed"),
      })
    );
    resetToDraft();
    localStorage.setItem("onChangeCompanyBuilder", "1");

    triggerHightLightsWithParams({ userId });
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      fetchData(editHighlightParams);
    }, 1000);
    return () => clearTimeout(timeoutId);
  }, [debounceTrigger]);

  const fetchData = async (params: any) => {
    try {
      const obj = { ...params };
      const documentId = obj.documentId;
      if (documentId) {
        if (obj.type === "custom") {
          await updateHighlight({
            documentId: documentId,
            optionData: {
              iconKey: obj.iconKey,
              labelNameEn: obj.labelNameEn,
              labelNameHe: obj.labelNameHe,
            },
          });
        } else {
          params = await createHighLight({
            authorId: userId,
            backgroundColor: obj.backgroundColor,
            iconKey: obj.iconKey,
            labelNameEn: obj.labelNameEn,
            labelNameHe: obj.labelNameHe,
            locale: obj.locale,
            type: "custom",
          });
        }
        setEditHighlightParams(params);
        triggerHightLightsWithParams({ userId });
        localStorage.setItem("editKeyFeatureHighlightId", params.id.toString());
        dispatch(
          setSelectedHightLight(
            updateItemById(selectedHightLight, editHighlightParams.id, {
              ...params,
            })
          )
        );
      } else {
        if (
          (i18n.language === "he" && editHighlightParams.labelNameHe) ||
          (i18n.language === "en" && editHighlightParams.labelNameEn) ||
          highlightDefConfig.iconKey !== editHighlightParams.iconKey
        ) {
          let params = await createHighLight({
            authorId: userId,
            iconKey: editHighlightParams.iconKey,
            labelNameEn: editHighlightParams.labelNameEn,
            labelNameHe: editHighlightParams.labelNameHe,
            type: "custom",
          });
          triggerHightLightsWithParams({ userId });
          setEditHighlightParams(params);
          localStorage.setItem(
            "editKeyFeatureHighlightId",
            params.id.toString()
          );
          dispatch(
            setSelectedHightLight(
              updateItemById(selectedHightLight, editHighlightParams.id, {
                ...params,
              })
            )
          );
        }
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleSelect = (option: any, id: number) => {
    setEditHighlightParams(option);

    dispatch(
      setSelectedHightLight(
        updateItemById(selectedHightLight, id, { ...option })
      )
    );
    resetToDraft();

    localStorage.setItem("onChangeCompanyBuilder", "1");
    setOpenCustomDropdown((prev) => !prev);
  };

  const handleBackBtn = (step: CampaignSideNavStep) => {
    setCurrentStep(step);
    localStorage.removeItem("editKeyFeatureHighlightId");
    if (hightlightsBack) {
      const filteredHighlights = hightlightsBack.filter(
        (item: any) => item.id !== 0
      );
      dispatch(setHightlightsBack(filteredHighlights));
    }
  };

  return (
    <div className="campaign-builder-side-nav-wrapper">
      <CampaignBuilderSideNavHeader
        header="investment_highlights"
        leftBtn={
          <CampainBuilderBackBtn
            setCurrentStep={(step) => {
              handleBackBtn(step);
            }}
            step={CampaignSideNavStep.investmentHighlights}
          />
        }
        rightBtn={<div className="campaign-builder-sidenav-empty-btn"></div>}
      />

      <div
        className="campaign-builder-sidenav-main-content-wrapper"
        style={{
          paddingTop: downMd ? "30px" : "40px",
          direction: i18n.dir(),
        }}
        ref={componentRef}
      >
        <div className="campaign-builder-sidenav-settings-fileds-container">
          <div className="campaign-builder-sidenav-settings-fileds-item highlight-add-update-elem-wrapper">
            <div className="highlight-add-update-elem">
              <div
                className="campaign-builder-sidenav-settings-fileds-item"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 20,
                }}
              >
                <div>
                  <div>
                    <Button
                      variant="outlined"
                      onClick={(event) => {
                        if (event.detail === 0) return;
                        setOpenCustomDropdown((prev) => !prev);
                        setAnchorEl(event.currentTarget);
                      }}
                      disableRipple
                      ref={dropdownRef}
                      sx={{
                        width: "100%",
                        justifyContent: "space-between",
                        textTransform: "none",
                        borderRadius: "16px",
                        height: 53,
                        paddingLeft: 0,
                        paddingRight: 0,
                        backgroundColor: "#fff",
                        borderColor: "#fff",
                        color: "#000",
                        zIndex: 14,
                      }}
                      endIcon={
                        <ExpandMore
                          style={{
                            marginRight: isRTL ? "15px" : "20px",
                            marginLeft: isRTL ? "20px" : "15px",
                          }}
                        />
                      }
                    >
                      <TextField
                        fullWidth
                        placeholder={t("message_placeholder")}
                        value={
                          editHighlightParams
                            ? editHighlightParams[
                                currentLanguage === "he"
                                  ? "labelNameHe"
                                  : "labelNameEn"
                              ]
                            : ""
                        }
                        onKeyDown={(e) => {
                          if (e.key === " ") {
                            e.stopPropagation();
                          }
                        }}
                        onClick={(e) => {
                          e.stopPropagation();
                          e.preventDefault();
                        }}
                        onChange={(e) => {
                          e.stopPropagation();
                          e.preventDefault();
                          setEditHighlightParams((prev: any) => ({
                            ...prev,
                            labelNameEn:
                              currentLanguage === "en"
                                ? e.target.value
                                : undefined,
                            labelNameHe:
                              currentLanguage === "he"
                                ? e.target.value
                                : undefined,
                          }));

                          setDebounceTrigger((prev) => !prev);
                        }}
                        sx={textFieldStyles3(langTheme.mainFont(), isRTL)}
                        autoComplete="off"
                        inputProps={{
                          maxLength: MAX_HIGHTLIGHT_LENGTH,
                        }}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment
                              sx={{
                                height: "100%",
                                ml: 0,
                                maxHeight: 50,
                                marginRight: isRTL ? "-14px" : "0",
                                pl: isRTL ? "20px" : "0px",
                                pr: isRTL ? "-14px" : "-14px",
                                color: "rgba(0, 0, 0, 1)",
                                zIndex: 15,
                                backgroundColor: "transparent",
                              }}
                              position="start"
                            >
                              <div
                                className="campaign-builder-sidenav-number-of-char"
                                style={{
                                  fontFamily: langTheme.mainFont(),
                                }}
                              >
                                {editHighlightParams
                                  ? editHighlightParams[
                                      currentLanguage === "he"
                                        ? "labelNameHe"
                                        : "labelNameEn"
                                    ]?.length || 0
                                  : 0}
                                /{MAX_HIGHTLIGHT_LENGTH}
                              </div>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Button>

                    <Menu
                      anchorEl={anchorEl}
                      open={openCustomDropdown}
                      onClose={() => {
                        setOpenCustomDropdown((prev) => !prev);
                      }}
                      PaperProps={{
                        sx: {
                          width: dropdownRef?.current?.offsetWidth,
                          maxHeight: componentRef?.current?.offsetHeight - 110,
                          direction: i18n.dir(),
                        },
                      }}
                    >
                      <div
                        className="campaign-builder-sidenav-field-name"
                        style={{
                          color: "#000",
                          fontFamily: "DM Sans",
                          margin: "10px 15px",
                          fontSize: 16,
                          fontWeight: 500,
                        }}
                      >
                        {t("system_highlights")}
                      </div>
                      <>
                        {hightlightOptionsState
                          .filter(
                            (elem: any) =>
                              !selectedHightLight.find(
                                (el: any) => el.id === elem.id
                              ) && elem.type === "system"
                          )
                          .map((option: Option, index: number) => {
                            const IconComponent =
                              HighlightIconMap[option.iconKey] ||
                              BuildingBlueIcon;
                            return (
                              <MenuItem
                                key={index}
                                onClick={() =>
                                  handleSelect(option, editHighlightParams?.id)
                                }
                              >
                                <div style={{ display: "flex", gap: 5 }}>
                                  {IconComponent && (
                                    <IconComponent
                                      isSelected={false}
                                      width={17}
                                      height={19}
                                      backgroundColor={"#034A73"}
                                    />
                                  )}
                                  <p
                                    style={{ margin: 0, fontFamily: "DM Sans" }}
                                  >
                                    {
                                      option[
                                        currentLanguage === "he"
                                          ? "labelNameHe"
                                          : "labelNameEn"
                                      ]
                                    }
                                  </p>
                                </div>
                              </MenuItem>
                            );
                          })}
                      </>

                      <div
                        className="campaign-builder-sidenav-field-name"
                        style={{
                          color: "#000",
                          fontFamily: "DM Sans",
                          margin: "25px 15px 10px",
                          fontSize: 16,
                          fontWeight: 500,
                        }}
                      >
                        {t("custom_highlights")}
                      </div>
                      <>
                        {hightlightOptionsState
                          .filter(
                            (elem: any) =>
                              !selectedHightLight.find(
                                (el: any) => el.id === elem.id
                              ) && elem.type === "custom"
                          )
                          .map((option: Option, index: number) => {
                            const IconComponent =
                              HighlightIconMap[option.iconKey] ||
                              BuildingBlueIcon;
                            return (
                              <MenuItem
                                key={index}
                                onClick={() =>
                                  handleSelect(option, editHighlightParams?.id)
                                }
                              >
                                <div style={{ display: "flex", gap: 5 }}>
                                  {IconComponent && (
                                    <IconComponent
                                      isSelected={false}
                                      width={17}
                                      height={19}
                                      backgroundColor={"#034A73"}
                                    />
                                  )}
                                  <p
                                    style={{ margin: 0, fontFamily: "DM Sans" }}
                                  >
                                    {
                                      option[
                                        currentLanguage === "he"
                                          ? "labelNameHe"
                                          : "labelNameEn"
                                      ]
                                    }
                                  </p>
                                </div>
                              </MenuItem>
                            );
                          })}
                      </>
                    </Menu>
                  </div>
                </div>
              </div>
              <div className="campaign-builder-sidenav-settings-fileds-item">
                <div className="campaign-builder-sidenav-settings-fileds-item">
                  <div className="campaign-builder-sidenav-field-name">
                    {t("choose_icon")}
                  </div>
                  <IconSelect
                    selectedIcon={editHighlightParams?.iconKey}
                    setSelectedIcon={(param) => {
                      setEditHighlightParams((prev: any) => ({
                        ...prev,
                        iconKey: param,
                      }));
                      dispatch(
                        setSelectedHightLight(
                          updateItemById(
                            selectedHightLight,
                            editHighlightParams.id,
                            { ...editHighlightParams, iconKey: param }
                          )
                        )
                      );
                      setDebounceTrigger((prev) => !prev);
                    }}
                    options={highlightOptions}
                    iconMap={HighlightIconMap}
                  />
                </div>
                <div style={{ display: "flex", gap: 15 }}>
                  <div
                    className="campaign-builder-sidenav-add-new-btn update-highlight-btn"
                    onClick={() =>
                      handleDeleteOption(editHighlightParams?.documentId)
                    }
                    style={{ width: 70 }}
                  >
                    <img src={TrashIcon} />
                    <span>{t("delete")}</span>
                  </div>
                  {documentId ? (
                    <div
                      className="campaign-builder-sidenav-add-new-btn update-highlight-btn"
                      onClick={() =>
                        handleHideOption(
                          editHighlightParams?.documentId,
                          !(
                            editHighlightParams?.details &&
                            editHighlightParams?.details[documentId]
                          ),
                          editHighlightParams
                        )
                      }
                      style={{ width: 70 }}
                    >
                      <img
                        src={
                          editHighlightParams?.details &&
                          editHighlightParams?.details[documentId]
                            ? UnhideIcon
                            : HideIcon
                        }
                        alt="hide"
                        style={{ fill: "#fff" }}
                      />
                      <span>
                        {editHighlightParams?.details &&
                        editHighlightParams?.details[documentId]
                          ? t("show")
                          : t("hide")}
                      </span>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="campaign-builder-sidenav-line" />
            </div>
          </div>
        </div>

        <div
          className={`campaign-builder-sidenav-add-label-btn-big ${
            editHighlightParams.id === 0 ? "disabled" : ""
          }`}
          onClick={async () => {
            if (editHighlightParams.id === 0) return;
            await handleSaveHighlights();
          }}
          style={{
            pointerEvents: editHighlightParams.id === 0 ? "none" : "auto",
            opacity: editHighlightParams.id === 0 ? 0.5 : 1,
          }}
        >
          {t("save")}
        </div>
      </div>
    </div>
  );
};
