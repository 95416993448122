import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import i18n from '../../i18n';
import { useGetPartnersQuery } from '../../store/api/sellWithUsApiSlice';

interface IPartner {
  name: string;
  companyLogo: {
    url: string;
  };
}

export default function OurPartners() {
  const { t } = useTranslation(['sellWithUs']);
  const theme = useTheme();
  const isTabletOrSmaller = useMediaQuery(theme.breakpoints.down(1200));
  const isRTL = i18n.language === 'he';
  const [currentIndex, setCurrentIndex] = useState(0);
  const { data: fetchedPartners } = useGetPartnersQuery(i18n.language);
  const [partners, setPartners] = useState<IPartner[]>([]);
  const sliderRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (fetchedPartners) {
      setPartners(
        fetchedPartners.data.length <= 6
          ? fetchedPartners.data
          : fetchedPartners.data.slice(0, 6),
      );
    }
  }, [fetchedPartners]);

  // Set initial scroll position when partners load
  useEffect(() => {
    if (sliderRef.current && isTabletOrSmaller && partners.length > 0) {
      const logoWidth = 150;
      const maxScroll = (partners.length - 1) * logoWidth;
      const initialScrollPosition = isRTL ? -maxScroll : 0;

      sliderRef.current.scrollTo({
        left: initialScrollPosition,
        behavior: 'auto', // Instant scroll on mount
      });
      setCurrentIndex(0); // Ensure the index starts at 0
    }
  }, [isTabletOrSmaller, isRTL, partners.length]);

  // Scroll to the current index when it changes (e.g., dot click)
  useEffect(() => {
    if (sliderRef.current && isTabletOrSmaller) {
      const logoWidth = 150;
      const maxScroll = (partners.length - 1) * logoWidth;
      const scrollPosition = isRTL
        ? -maxScroll + currentIndex * logoWidth
        : currentIndex * logoWidth;

      sliderRef.current.scrollTo({
        left: scrollPosition,
        behavior: 'smooth',
      });
    }
  }, [currentIndex, isTabletOrSmaller, isRTL, partners.length]);

  // Update currentIndex based on scroll position
  useEffect(() => {
    const slider = sliderRef.current;
    if (!slider || !isTabletOrSmaller) return;

    let timeout: NodeJS.Timeout;
    const handleScroll = () => {
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        const scrollPosition = slider.scrollLeft;
        const logoWidth = 150;
        const maxScroll = (partners.length - 1) * logoWidth;

        // Log scroll position for debugging
        console.log('scrollPosition:', scrollPosition);

        // Check if scrolled to the end
        const threshold = 20;
        const isAtEnd = isRTL
          ? Math.abs(scrollPosition) <= threshold
          : scrollPosition >= maxScroll - threshold;

        if (isAtEnd) {
          setCurrentIndex(partners.length - 1);
          return;
        }

        // Check if scrolled to the start
        const isAtStart = isRTL
          ? Math.abs(scrollPosition + maxScroll) <= threshold
          : scrollPosition <= threshold;

        if (isAtStart) {
          setCurrentIndex(0);
          return;
        }

        // Calculate the current index based on scroll position
        const scrollOffset = isRTL
          ? Math.abs(scrollPosition + maxScroll) // Normalize to 0 at the start
          : scrollPosition;
        const newIndex = Math.round(scrollOffset / logoWidth);
        const clampedIndex = Math.max(
          0,
          Math.min(newIndex, partners.length - 1),
        );
        setCurrentIndex(clampedIndex);
      }, 100);
    };

    slider.addEventListener('scroll', handleScroll);
    return () => slider.removeEventListener('scroll', handleScroll);
  }, [isTabletOrSmaller, isRTL, partners.length]);

  const handleDotClick = (index: number) => {
    setCurrentIndex(index);
  };

  return (
    <Box
      sx={{
        mt: isTabletOrSmaller ? '30px' : '110px',
        mb: isTabletOrSmaller ? 0 : '50px',
      }}
    >
      {/* Title */}
      <Typography
        variant="h2"
        sx={{
          textAlign: 'center',
          mb: isTabletOrSmaller ? '15px' : '50px',
          textTransform: 'uppercase',
          fontSize: isTabletOrSmaller ? '24px' : '36px',
          fontFamily: isRTL ? 'Noto Sans Hebrew' : 'Frank Ruhl Libre',
          fontWeight: 600,
        }}
      >
        {t('partners')}
      </Typography>

      {/* Partners Display */}
      {isTabletOrSmaller ? (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            mx: '30px',
          }}
        >
          {/* Horizontal Slider */}
          <Box
            sx={{
              width: '150px',
              overflow: 'hidden',
            }}
          >
            <Box
              ref={sliderRef}
              sx={{
                display: 'flex',
                flexDirection: 'row',
                overflowX: 'auto',
                overflowY: 'hidden',
                scrollSnapType: 'x mandatory',
                scrollbarWidth: 'none',
                '&::-webkit-scrollbar': { display: 'none' },
                scrollBehavior: 'smooth',
                direction: isRTL ? 'rtl' : 'ltr',
                mb: 1,
              }}
            >
              {partners?.map((partner, index) => (
                <Box
                  key={index}
                  sx={{
                    flex: '0 0 auto',
                    width: '150px',
                    height: '100px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    scrollSnapAlign: 'start',
                  }}
                >
                  <img
                    src={partner?.companyLogo?.url}
                    alt={`Partner ${index + 1}`}
                    style={{
                      maxWidth: '100%',
                      maxHeight: '100%',
                      objectFit: 'contain',
                    }}
                  />
                </Box>
              ))}
            </Box>
          </Box>

          {/* Navigation Dots */}
          <Box
            sx={{
              display: 'flex',
              gap: '8px',
            }}
          >
            {partners?.map((_, index) => (
              <Box
                key={index}
                onClick={() => handleDotClick(index)}
                sx={{
                  width: '10px',
                  height: '10px',
                  borderRadius: '50%',
                  backgroundColor:
                    currentIndex === index ? '#034A73' : '#D3D3D3',
                  cursor: 'pointer',
                }}
              />
            ))}
          </Box>
        </Box>
      ) : (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            mx: '20px',
            gap: '40px',
          }}
        >
          {partners?.map((partner, index) => (
            <Box
              key={index}
              sx={{
                width: '150px',
                height: '100px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                position: 'relative',
                '&:not(:last-child)::after': {
                  content: '""',
                  position: 'absolute',
                  right: '-20px',
                  width: '1px',
                  height: '100px',
                  bgcolor: '#0000001F',
                },
              }}
            >
              <img
                src={partner?.companyLogo?.url}
                alt={`Partner ${index + 1}`}
                style={{
                  maxWidth: '100%',
                  maxHeight: '100%',
                  objectFit: 'contain',
                }}
              />
            </Box>
          ))}
        </Box>
      )}
    </Box>
  );
}
