import { Theme, useMediaQuery } from "@mui/material";
import { useTranslation } from "react-i18next";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { MainBlueArticleCard } from "./MainBlueArticleCard";
import { IArticle } from "../interfaces";
import { WhiteMiddleArcitleCard } from "./WhiteMiddleArcitleCard";

const whiteTopCardStyles = {
  fontSize: "20px",
  lineHeight: "24px",
  padding: "20px 25px 25px 20px",
  minWidth: "auto",
  imgFlex: "1",
  height: "100%",
};

export const whiteTopCardStylesSlider = {
  fontSize: "20px",
  lineHeight: "24px",
  padding: "20px 25px 35px 20px",
  minWidth: "300px",
  imgFlex: "1",
  height: "100%",
};

export interface IWhiteTopCardStyles {
  fontSize: string;
  lineHeight: string;
  padding: string;
  minWidth: string;
  imgFlex: string;
  height: string;
}

export const TopArticleSection = ({ articles }: { articles: IArticle[] }) => {
  const { i18n } = useTranslation();
  const downMd = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));

  return (
    <div
      className="top-arctile-section-wrapper xl-width"
      style={{
        marginBottom: downMd ? "34px" : "24px",
        direction: i18n.dir(),
      }}
    >
      {downMd ? (
        <div className="swiper-slider-article">
          <Swiper
            pagination={{
              clickable: true,
            }}
            modules={[Pagination]}
          >
            <SwiperSlide>
              <MainBlueArticleCard article={articles[0]} />
            </SwiperSlide>
            <SwiperSlide>
              <WhiteMiddleArcitleCard
                styles={whiteTopCardStylesSlider}
                showClueContainer={true}
                article={articles[1]}
                descTrancateLength={175}
              />
            </SwiperSlide>
          </Swiper>
        </div>
      ) : (
        <>
          <div
            style={{
              flex: 1.4,
            }}
          >
            <MainBlueArticleCard article={articles[0]} />
          </div>
          <div
            style={{
              flex: 0.7,
            }}
          >
            <WhiteMiddleArcitleCard
              styles={whiteTopCardStyles}
              showClueContainer={true}
              article={articles[1]}
              descTrancateLength={175}
            />
          </div>
        </>
      )}
    </div>
  );
};
