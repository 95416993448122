import React from "react";
import { useTranslation } from "react-i18next";
import { MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { ReactComponent as SelectArrow } from "../../../../assets/campaignBuilder/select_arror.svg";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../../../store/store";
import { TimePeriod } from "../layout/CampaignBuilderSideNav";
import { useLanguageTheme } from "../../../../hooks/useLanguageTheme";
import {
  setCountOfTimePeriods,
  setTimePeriod,
} from "../../../../store/features/campaignBuilderSlice";
import { getFormattedEndDate } from "../../../../helpers/dataConverts";

export const DAYS = Array.from({ length: 90 }, (_, i) => (i + 1).toString());
export const WEEKS = Array.from({ length: 26 }, (_, i) => (i + 1).toString());
export const MONTHS = Array.from({ length: 6 }, (_, i) => (i + 1).toString());

export const CampaignBuilderDurationSelect = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation(["campaignBuilder"]);
  const langTheme = useLanguageTheme();
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const isRTL = currentLanguage === "he";

  const { countOfTimePeriods, timePeriod } = useSelector(
    (state: any) => state.campaignBuilder
  );

  const timePeriodOptionsMap: Record<TimePeriod, string[]> = {
    [TimePeriod.DAYS]: DAYS,
    [TimePeriod.WEEKS]: WEEKS,
    [TimePeriod.MONTHS]: MONTHS,
  };

  const handleChangeCountOfTimePeriod = (event: SelectChangeEvent) => {
    localStorage.setItem("onChangeCompanyBuilder", "1");
    dispatch(setCountOfTimePeriods(event.target.value));
  };

  const handleChangeTimePeriod = (event: SelectChangeEvent<TimePeriod>) => {
    const newPeriod = event.target.value as TimePeriod;
    localStorage.setItem("onChangeCompanyBuilder", "1");
    dispatch(setTimePeriod(newPeriod));
    dispatch(setCountOfTimePeriods(timePeriodOptionsMap[newPeriod][0]));
  };

  const currentOptions = timePeriodOptionsMap[timePeriod as TimePeriod];

  const endDateFormatted = getFormattedEndDate(
    countOfTimePeriods,
    timePeriod,
    currentLanguage
  );

  return (
    <div>
      <div className="campaign-builder-sidenav-duration-select-container">
        <div
          style={{
            flex: 1,
          }}
        >
          <Select
            value={countOfTimePeriods}
            onChange={handleChangeCountOfTimePeriod}
            sx={{
              width: "100%",
              fontSize: "14px",
              fontFamily: langTheme.mainFont(),
              fontWeight: 500,
              backgroundColor: "rgba(232, 239, 242, 1)",
              borderRadius: "16px",
              "& .MuiOutlinedInput-notchedOutline": {
                border: "none",
              },
              "&:hover .MuiOutlinedInput-notchedOutline": {
                border: "none",
              },

              "& .MuiSelect-icon": {
                top: "calc(50% - 0.2em)",
                right: isRTL ? "auto" : "15px",
                left: isRTL ? "15px" : "auto",
              },
              "& .MuiSelect-select": {
                paddingY: "15px",
                display: "flex",
                alignItems: "center",
                paddingRight: isRTL ? "24px" : "24px",
                paddingLeft: isRTL ? "24px" : "20px",
              },
            }}
            MenuProps={{
              slotProps: {
                paper: {
                  sx: {
                    direction: i18n.dir(),
                    background: "rgba(232, 239, 242, 1)",
                  },
                },
              },
            }}
            IconComponent={SelectArrow}
          >
            {currentOptions.map((item) => (
              <MenuItem
                key={item}
                value={item}
                sx={{
                  color: "rgba(0, 0, 0, 1)",
                  fontFamily: langTheme.mainFont(),
                  fontWeight: 500,
                  fontSize: "14px",
                }}
              >
                {item}
              </MenuItem>
            ))}
          </Select>
        </div>

        <div className="campaign-builder-sidenav-duration-select-line"></div>
        <div
          style={{
            flex: 2,
          }}
        >
          <Select
            value={timePeriod}
            onChange={handleChangeTimePeriod}
            sx={{
              width: "100%",
              fontSize: "14px",
              fontFamily: langTheme.mainFont(),
              fontWeight: 500,
              backgroundColor: "rgba(232, 239, 242, 1)",
              borderRadius: "16px",
              "& .MuiOutlinedInput-notchedOutline": {
                border: "none",
              },
              "&:hover .MuiOutlinedInput-notchedOutline": {
                border: "none",
              },

              "& .MuiSelect-icon": {
                top: "calc(50% - 0.2em)",
                right: isRTL ? "auto" : "25px",
                left: isRTL ? "25px" : "auto",
              },
              "& .MuiSelect-select": {
                paddingY: "15px",
                display: "flex",
                alignItems: "center",
                paddingRight: isRTL ? "24px" : "24px",
                paddingLeft: isRTL ? "24px" : "10px",
              },
            }}
            MenuProps={{
              slotProps: {
                paper: {
                  sx: {
                    direction: i18n.dir(),
                    background: "rgba(232, 239, 242, 1)",
                  },
                },
              },
            }}
            IconComponent={SelectArrow}
          >
            {Object.values(TimePeriod).map((item) => (
              <MenuItem
                key={item}
                value={item}
                sx={{
                  color: "rgba(0, 0, 0, 1)",
                  fontFamily: langTheme.mainFont(),
                  fontWeight: 500,
                  fontSize: "14px",
                  direction: i18n.dir(),
                }}
              >
                {t(item)}
              </MenuItem>
            ))}
          </Select>
        </div>
      </div>
      <div
        className="campaign-builder-sidenav-field-name"
        style={{
          marginTop: "12px",
        }}
      >
        {t("end_date")}:{endDateFormatted}
      </div>
    </div>
  );
};
