import React from "react";
import { useTranslation } from "react-i18next";
import { Theme, useMediaQuery } from "@mui/material";
import { useLanguageTheme } from "../../../../hooks/useLanguageTheme";
import PhoneIcon from "../../../../assets/contactUs/phone.svg";
import EmailIcon from "../../../../assets/contactUs/email.svg";
import WhatsupIcon from "../../../../assets/contactUs/whatsup.svg";

import { useGetContactUsInfoQuery } from "../../../../store/api/contactUsApiSlice";

export const ContactUsInfoBlock = () => {
  const { i18n, t } = useTranslation("contactUs");
  const langTheme = useLanguageTheme();

  const { data: contactUsData } = useGetContactUsInfoQuery(i18n.language);

  const [email, setEmail] = React.useState("");
  const [whatsupPhone, setWhatsupPhone] = React.useState("");
  const [address, setAdress] = React.useState("");

  const openWhatsApp = (phoneNumber: string) => {
    const whatsappUrl = `https://wa.me/${phoneNumber}`;
    window.open(whatsappUrl, "_blank");
  };

  React.useEffect(() => {
    if (contactUsData) {
      setEmail(contactUsData.data.email);
      setWhatsupPhone(contactUsData.data.phone);
      setAdress(contactUsData.data.address);
    }
  }, [contactUsData]);

  return (
    <div
      style={{ fontFamily: langTheme.mainFont() }}
      className="contact-us-info-block-wrapper"
    >
      <div className="contact-us-chat-w-us-block">
        <div className="contact-us-info-block-title">{t("chat_with_us")}</div>
        <div className="contact-us-info-block-desc">
          {t("contact_with_us_placeholder")}
        </div>
        <div className="contact-us-info-block-email-wrapper">
          <a href={`mailto:${email}`} className="reset-a">
            <div className="contact-us-info-block-phone-wrapper">
              <img src={EmailIcon} />
              <div>{t("send_us_an_email")}</div>
            </div>
          </a>
          <div
            className="contact-us-info-block-phone-wrapper"
            onClick={() => openWhatsApp(whatsupPhone)}
          >
            <img src={WhatsupIcon} />
            <div>{t("message_us_on_whatsup")}</div>
          </div>
        </div>
      </div>

      <div className="contact-us-chat-w-us-block">
        <div className="contact-us-info-block-title">{t("call_us")}</div>
        {/* <div className="contact-us-info-block-desc">{t("call_our_team")}</div> */}
        <div className="contact-us-info-block-desc">{address}</div>

        <a href={`tel:${whatsupPhone}`} className="reset-a">
          <div className="contact-us-info-block-phone-wrapper">
            <img src={PhoneIcon} />
            <div
              style={{
                direction: "ltr",
              }}
            >
              {whatsupPhone}
            </div>
          </div>
        </a>
      </div>
    </div>
  );
};
